import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserDataTable from "../../../components/universityUsersDataTable/userDataTable";
import {
  AddAnotherUser,
  AddUserWrapper,
  CardPadded39Darker,
  JustifyBetweenRow,
  SectionTextDescription,
  SectionTitle,
  SectionTitleEdit,
  SectionTitleSecondary,
} from "../../analyticalDashboardPage/commonStyles";

export default function UniversityUsersSummary() {
  const navigate = useNavigate();
  const totalRows = useSelector((state) => state.userManagement.totalRows);
  const params = useParams();
  const id = params && params.id;
  const { t } = useTranslation();

  return (
    <CardPadded39Darker className="mt-5">
      <Row>
        <Col>
          <JustifyBetweenRow>
            <span>
              <SectionTitle>{t("User Management")}</SectionTitle>
              <SectionTitleSecondary>
                {totalRows ? `${totalRows} ` + t("Users registered") : null}
              </SectionTitleSecondary>
            </span>
            <SectionTitleEdit
              onClick={() =>
                navigate("/universities/detail/" + id + "/user-managment")
              }
            >
              {t("View All")}
            </SectionTitleEdit>
          </JustifyBetweenRow>
          <Row>
            <Col xs="12">
              <hr />
            </Col>
            <Col xs="12">
              <SectionTextDescription>
                {t("Edit or add contact person for your university to give them access to MyNext.")}
              </SectionTextDescription>
            </Col>
            <Col xs="12">
              <UserDataTable
                backgroundColor="#fdfdfd"
                setModalMode={null}
                fromUserManagement={false}
                id={id}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <AddUserWrapper>
                <AddAnotherUser
                  to={"/universities/detail/" + id + "/user-managment/new"}
                >
                  + {t("Add Another User")}
                </AddAnotherUser>
              </AddUserWrapper>
            </Col>
          </Row>
        </Col>
      </Row>
    </CardPadded39Darker>
  );
}
