import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import CloseIcon from "../../assets/close-80.png";



export default function CustomModal({ children, onCloseModal, open, styles }) {
  const closeIcon = (
    <div>
      <img
        style={{ marginLeft: "80%" }}
        src={CloseIcon}
        height="7%"
        width="7%"
        alt="close icon modal"
      />
    </div>
  );
  return (
    <Modal
      open={open}
      closeIcon={closeIcon}
      center
      onClose={onCloseModal}
      styles={styles}
    >
      {children}
    </Modal>
  );
}
