import styled from 'styled-components';

  const HrLine = styled.hr`
    border: ${(props) =>
      props.name === 'login'
      ? '1px solid rgba(235, 235, 235, 0.62)'
      : '0.4px solid rgba(32, 32, 32, 0.22)'};
    max-width: ${(props) =>
      props.width};
    margin: 20px 0;
    min-width: 200px;
    background: ${(props) =>
      props.name === 'login'
    ? 'rgba(235, 235, 235, 0.62)'
    : ''};
    margin-right: ${(props) =>
      props.right ? props.right : '0px'};
    @media (max-width: 1024px) {
      max-width: 100%;
    }
  `;
export default function Divider(Props) {
  

  return <HrLine />;
}
