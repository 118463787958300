import styled from 'styled-components';
import { Colors } from '../../utilities/colors';
import { Link } from 'react-router-dom';

export const ImageDropdown = styled.img`
  width: 20px;
  height: 20px;
`;

export const LabelText = styled.label`
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;
`;
export const IconsImage = styled.img`
  width: fit-content;
  height: fit-content;
  cursor: pointer;
`;

export const CardMiddle = styled.div`
  display: block;
  height: 600px;
  margin-top: 5%;
  margin-left: 10vh;
  margin-right: 10vh;
`;

export const LightText = styled(LabelText)`
  color: ${Colors.black};
  margin-left: 0vh;
  font-size: 28px;
`;

export const ButtonView = styled.div`
  text-decoration: underline;
  border: none;
  font-weight: 600;
  font-size: 14px;
  color: #d04c8d;
  background: none;
  text-align: right;
  display: flex;
  align-items: center;
  cursor: pointer;
  // margin-right: 20px;
`;
export const NoData = styled.div`
  font-size: 20px;
  width: 100%;
  background: ${Colors.secondaryColor};
  color: black;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;
export const ViewButton = styled.button`
  text-decoration: underline;
  border: none;
  font-weight: 600;
  font-size: 14px;
  color: #d04c8d;
  background: none;
`;

export const RightTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PendingRight = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  @media (max-width: 767px) {
    gap: 20px;
    width: auto;
    justify-content: unset;
  }
`;
export const DisplayBlock = styled.div`
  height: 50px;
`;

export const ButtonSize = styled.div`
  align-self: center;
  width: 150px;
  margin-left: 30px;
`;
export const SetOne = styled.div`
  display: flex;
  width: 10%;
  justify-content: space-between;
  padding-right: 10px;
  @media (max-width: 500px) {
    width: auto;
  }
`;
export const PendingSetOne = styled.div`
  display: flex;
  width: 13%;
  justify-content: space-between;
  padding-right: 10px;
  @media (max-width: 500px) {
    width: auto;
  }
`;
export const Footer = styled.div`
  margin-top: 100px;
`;
export const PendingLeft = styled.div`
  display: flex;
  width: 70%;
  cursor: pointer;
  @media (max-width: 500px) {
    width: auto;
    margin-top: 10px;
  }
`;
export const DividerWrapper = styled.div`
  // margin-left: 10vh;
  // margin-right: 10vh;
`;
export const LeftTag = styled.div`
  display: flex;
  width: 90%;
  @media (max-width: 500px) {
    width: auto;
  }
`;

export const AcceptedDiv = styled.div`
  margin: 0 5vh;
  overflow-y: auto;
  cursor: pointer;
  display: flex;
  border-bottom: 1px solid #dcdcdc;
  justify-content: space-between;
  @media (max-width: 800px) {
    display: flex;
    gap: 30px;
    /* flex-direction:column; */
  }
`;
export const PendingDiv = styled.div`
  margin: 0 5vh;
  overflow-y: auto;
  display: flex;
  border-bottom: 1px solid #dcdcdc;
  justify-content: space-between;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

export const StatusIcon = styled.div`
  align-self: center;
  margin-right: 5px;
`;
export const Bold = styled.div`
  font-weight: 600;
  padding-left: 3px;
`;
export const Companydiv = styled.div`
  width: 20px;
  height: 20px;
`;
export const ComapanyImage = styled.img`
  width: 45px;
  height: 80px;
  align-self: center;
  padding: 18px 0px;
  border-radius: 50%;
`;

export const CompanyName = styled.span`
  align-self: center;
  font-size: 16px;
  width: 50%;
  color: #202020;
  padding-left: 10px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;
export const CreatedBy = styled.span`
  align-self: center;
  color: ${Colors.createdBy};
  font-size: 14px;
  font-style: italic;
  display: flex;
  width: 45%;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const LightText_ = styled(LabelText)`
  color: ${Colors.pink};
  font-size: 20px;
  cursor: pointer;
  align-self: center;
`;
export const StatusFilterScreen = styled.div``;

export const RouteLink = styled(Link)`
  align-self: center;
  font-size: 14px;
`;
export const TextDivision = styled.div`
  align-self: center;
  display: flex;
  width: 85%;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const AcceptTextDivision = styled.div`
  align-self: center;
  display: flex;
  width: 80%;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;