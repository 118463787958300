import styled from 'styled-components';

import { Colors } from '../../utilities/colors';


const InputField = styled.input`
  background: ${(props) => (props.bg ? props.bg : Colors.inputFieldBg)};
  border-radius: 128px;
  border: 0px;
  padding: 12px 20px;
  outline: none;
  width: -webkit-fill-available;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: black;
  &.invalid {
    border: 1px solid red;
  }
  &:disabled {
    opacity: 0.4;
  }

  &:hover:not(:disabled),
  :focus:not(:disabled),
  :active:not(:disabled) {
    box-shadow: 0 0 0 1px #ff60b1 !important;
    border-color: #ff60b1 !important;
    &.invalid {
      box-shadow: 0 0 0 1px red !important;
      border-color: red !important;
    }
  }
`;
export default function Input(props) {
  return (
    <InputField
      type={props.type ? props.type : 'text'}
      bg={props.bg}
      {...props}
    />
  );
}
