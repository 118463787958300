import React, { useEffect, useState, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { formatDate } from "../../utilities/commonFunctions";
import Input from "../../components/input/input";
import MainLayout from "../../layouts/mainLayout/mainlayout";
import { Colors } from "../../utilities/colors";
import JobsCards from "../../components/jobsCards/jobsCards";
import {
  getInternLists,
  jobAction,
  jobActionReview,
 
  statusDropdownJob,
} from "../../services/apiCalls";
import { staticState } from "./staticContent";
import Loader from "../../components/loader/loader";
import Button from "../../components/button/button";
import Pending from "../../assets/icons/svg/pendingIcon.svg";
import All from "../../assets/icons/svg/all.svg";
import Expired from "../../assets/icons/svg/expiredIcon.svg";
import Remarks from "../../assets/icons/svg/remarks.svg";
import Reject from "../../assets/icons/reject-48.png";
import PendingPayment from "../../assets/icons/pending-payment.png";
import Accepted from "../../assets/icons/svg/acceptedIcon.svg";
import { debounce } from "../../utilities/commonFunctions";
import { ReactComponent as CalenderIcon } from "../../assets/icons/svg/calendarIcon.svg";
import Select from "react-select";
import "react-phone-number-input/style.css";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import AlertModalCompany from "../../components/alertModal/alertModalCompany";
import AlertModalReview from "../../components/alertModal/alertModalReview";
import "./intership.css";
import TextArea from "../../components/description/description";
import ErrorField from "../../components/errorField/errorField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TablePagination } from "@mui/base";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";

import {
  resetAllInternships,
  setStatus as setFilterStatus,
 
} from "./internshipFilter";

const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#D44B9C",
    },
  },
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: "#D44B9C",
      },
    },
  },
});
const DashboardWrapper = styled.div``;

const Section = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`;


const PageTitle = styled.h1`
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;
  color: ${Colors.dark};
`;
const FilterHead = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 10px;
  color: #000000;
  visibility: ${(props) => (props.visibility ? props.visibility : "initial")};
`;

const SingleSection = styled.div`
  // position: relative;
  // flex-grow: 1;
  width: 100%;
  @media (max-width: 900px) {
    width: 100%;
  }
  label {
    position: relative;
    width: inherit;
  }
  button {
    position: relative;
    width: inherit;
  }
`;

const CalenderIconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  cursor: pointer;
`;

const ImageDropdown = styled.img`
  width: 20px;
  height: 20px;
`;

const Message = styled.div`
  padding-left: 20px;
  font-size: 22px;
  padding-bottom: 5px;
`;
const SingleField = styled.div`
  margin-bottom: 30px;
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;

const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${grey[100]};
  }
  `
);



const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .MuiTablePaginationUnstyled-spacer {
    display: none;
  }
  & .MuiTablePaginationUnstyled-toolbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }
  & .MuiTablePaginationUnstyled-selectLabel {
    margin: 0;
  }
  & .MuiTablePaginationUnstyled-select {
    padding: 2px;
    border: 1px solid ${grey[200]};
    border-radius: 50px;
    background-color: transparent;
    &:hover {
      background-color: ${grey[50]};
    }
    &:focus {
      outline: 1px solid ${blue[200]};
    }
  }
  & .MuiTablePaginationUnstyled-displayedRows {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }
  & .MuiTablePaginationUnstyled-actions {
    padding: 2px;
    border: 1px solid ${grey[200]};
    border-radius: 50px;
    text-align: center;
  }
  & .MuiTablePaginationUnstyled-actions > button {
    margin: 0 8px;
    border: transparent;
    border-radius: 2px;
    background-color: transparent;
    &:hover {
      background-color: ${grey[50]};
    }
    &:focus {
      outline: 1px solid ${blue[200]};
    }
  }
  `
);
export default function Internships() {
  const [loading, setLoading] = useState(true);
  const [, setShow] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [updateTime, setUpdateTime] = useState("");


  const [showConfirmationModalSendReview, setShowConfirmationModalReview] =
    useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [theArray, setTheArray] = useState([]);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - theArray.length) : 0;
  const [dataList, setDataList] = useState([]);
  const search = useLocation().search;

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("all");
  
  const [qty, setQty] = useState();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [domain, setDomain] = useState();
  const [action, setAction] = useState("");
  const [code, setCode] = useState("");
  var codesss = new URLSearchParams(search).get("title") ?? "";
  const [searchval, setSearchFilter] = useState("");
  

  
  const [, setPendingpage] = useState(false);
  const [name, setName] = useState("all");
  

  const { t } = useTranslation();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const navigate = useNavigate();
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const dispatch = useDispatch();
  const statusFilter = useSelector((state) => state.internshipFilter.status);

  const setPreviousData = () => {
    return options.filter((e) => {
      return e.value === statusFilter;
    });
  };

  useEffect(() => {
    setSearchFilter(codesss);
    getDatas(codesss, 1, statusFilter);
    statusDropdownApi();
    setStatus(statusFilter ? statusFilter : "all");

    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (window.location.href.indexOf("internships") === -1) {
        dispatch(resetAllInternships());
      }
    };
  }, [dispatch]);

  const fetchData = () => {
    if (total > currentPage) {
      setCurrentPage(currentPage + 1);
      getDatas("", currentPage + 1);
    }
  };

  

  const getDatas = async (name, pageNumber, updatedStatus) => {
    let req = {};
    let filter = {};
    req["start_date"] = fromDate || "";
    req["end_date"] = toDate || "";
    if (req["start_date"] && req["end_date"]) {
      filter = { period: { ...req } };
    }
    const body = {
      ...staticState,
      search: name ? name : searchval,
      filters: {
        ...filter,
        status: updatedStatus ? updatedStatus : status ? status : "all",
      },
      page_number: pageNumber || 1,
    };

    const { data } = await getInternLists({
      ...body,
    });

    if (data && data.status === 1) {
      setLoading(false);
      
      if (data?.pagination?.current_page > 1) {
        setDataList(
          dataList.length ? [...dataList, ...data.data] : [...data.data]
        );
      } else {
        setDataList(data?.data?.length ? [...data.data] : []);
      }

      setTotal(data?.pagination?.total_pages);
      setCurrentPage(data?.pagination?.current_page);
      setDomain(data?.domain);
    } else {
      setLoading(false);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChange = useCallback(debounce(getDatas, 3000), []);

  const onSearch = (e) => {
    if (e.target.value === "" && codesss !== "") {
      setSearchFilter(" ");
      debounceChange(" ", 0, status);
      navigate("/internships");
      
    }
    setSearchFilter(e.target.value);
    debounceChange(e.target.value, 1, status);
  };

  const dateChange = (name, val) => {
    if (name === "fromDate") setFromDate(formatDate(val));
    console.log(fromDate);
    if (name === "toDate") setToDate(formatDate(val));
  };

  

  const selectState = () => {
    if (selectOptions === "pending") {
      setPendingpage(true);
      setName("Pending");
    } else if (selectOptions === "accepted") {
      setPendingpage(true);
      setName("Approved");
    } else if (selectOptions === "rejected") {
      setPendingpage(true);
      setName("Denied");
    } else if (selectOptions === "review") {
      setPendingpage(true);
      setName("Sent back for Review");
    } else {
      setPendingpage(true);
      setName("All");
    }
  };

  const statusDropdownApi = async () => {
    const { data } = await statusDropdownJob();
    if (data.status === 1) {
      setQty(data.data);
    }
  };

  const Icon = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${(props) => (props.bg ? props.bg : "#33ff00")};
  `;

  const options = [
    {
      value: "all",
      label: t("All"),
      customAbbreviation: qty ? qty.all : null,
      image: <ImageDropdown src={All} />,
    },
    {
      value: "pending",
      label: t("Pending"),
      customAbbreviation: qty ? qty.pending : null,
      image: <ImageDropdown src={Pending} />,
    },
    {
      value: "accepted",
      label: t("Approved"),
      customAbbreviation: qty ? qty.accepted : null,
      image: <ImageDropdown src={Accepted} />,
    },
    {
      value: "expired",
      label: t("Expired"),
      customAbbreviation: qty ? qty.expired : null,
      image: <ImageDropdown src={Expired} />,
    },
    {
      value: "activate",
      label: t("Activate"),
      customAbbreviation: qty ? qty.activate : null,
      image: <Icon bg="#33ff00" />,
    },
    {
      value: "deactivate",
      label: t("Deactivate"),
      customAbbreviation: qty ? qty.deactivate : null,
      image: <Icon bg="#FF0000" />,
    },
    {
      value: "rejected",
      label: t("Denied"),
      customAbbreviation: qty ? qty.rejected : null,
      image: <ImageDropdown src={Reject} />,
    },
    {
      value: "review",
      label: t("Send Back For Review"),
      customAbbreviation: qty ? qty.review : null,
      image: <ImageDropdown src={Remarks} />,
    },
    {
      value: "pending_payment",
      label: t("Pending Payment"),
      customAbbreviation: qty ? qty.pending_payment : null,
      image: <ImageDropdown src={PendingPayment} />,
    },
  ];
  const [selectOptions, setSelectOptions] = useState(options[0]);
  useEffect(() => {
    selectState();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions]);
  const formatOptionLabel = ({ value, label, customAbbreviation, image }) => (
    <>
      <Row>
        <Col xs={2}>{image}</Col>
        <Col xs={7}>{label}</Col>
        <Col xs={3} className="text-end">
          {customAbbreviation}
        </Col>
      </Row>
    </>
  );

  const handleSelect = (value) => {
    setLoading(true);
    setSelectOptions(value);
    setStatus(value.value);
    dispatch(setFilterStatus(value.value));
    debounceChange(searchval, 1, value.value);
  };

  const compo = {
    IndicatorSeparator: () => null,
    dropdownIndicator: () => null,
  };

  const styles = {
    control: (base, state) => ({
      ...base,
      height: "45px",
      padding: "5.5px 5px",
      transition: "none",
      textTransform: "capitalize",
      width: "100%",
      zIndex: "100",
      background: `${Colors.light}`,
      border: "0px",
      outline: "none",
      boxShadow: " inset 0px 0px 4px rgba(0, 0, 0, 0)",
      borderRadius: "128px",
      "&:hover": {
        boxShadow: "0 0 0 1px #ff60b1",
        borderColor: "#ff60b1",
      },
    }),
    menu: (base) => ({
      ...base,
      marginTop: "0px",
      outline: "none",
      zIndex: "3",
      textTransform: "capitalize",
      border: "0px solid white",
      transition: "none",
      minWidth: "234px",
      width: "117%",
    }),
    menuList: (base) => ({
      ...base,
      color: "#575757;",
      fontSize: "12px",
      
      textTransform: "capitalize",
    }),
    option: (provided, state) => ({
      ...provided,
      width: "100%",
      height: "100%",
      fontSize: `14px`,
      
      fontWeight: `500`,
      backgroundColor: state.isSelected ? `#D04C8D` : `transparent`,
      color: state.isSelected ? `white` : `#202020`,
      "&:hover": {
        backgroundColor: state.isSelected ? `#D04C8D` : `#FFE9F4`,
        Color: `#D04C8D`,
      },
    }),
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        color: `#575757; !important`,
      },
    }),
  };

  const handleActionCall = (e, code, actionName, data) => {
    e.stopPropagation();
    setAction(actionName);
    setCode(code);
    setShowConfirmationModal(true);
    setUpdateTime(data);
  };

 
  const handleCloseModal = (e) => {
    e.stopPropagation();
    reset();
    setShowConfirmationModal(false);
  };

  const content = () => {
    return (
      <div style={{ padding: "0 40px 20px 40px" }}>
        <Message
          style={{
            textAlign: "center",
            fontSize: "26px",
            fontWeight: "bold",
            margin: "0px auto 10px auto",
          }}
        >
          {t("Are you sure you want to")}{" "}
          {action === "accepted" ? t("approve") : t("deny")}{" "}
          {t("this internship ?")}
        </Message>
        {action !== "accepted" ? (
          <form onSubmit={handleSubmit(onRejectionSubmit)}>
            <div className="container">
              <SingleField style={{ marginBottom: 0 }}>
                <FormLabel>{t("Rejection Note")}</FormLabel>
                <Controller
                  name="comments"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      style={{ background: "#F3F3F3" }}
                      {...field}
                      rows="3"
                      placeholder={t("Enter Comment")}
                    />
                  )}
                />
                {errors.comments && (
                  <ErrorField err={t(errors.comments.message)} />
                )}
              </SingleField>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Button
                  width="180px"
                  name={t("Yes")}
                  type="submit"
                  style={{ marginRight: "30px" }}
                />
                <Button
                  width="180px"
                  type="button"
                  name={t("No")}
                  color="#FFFFFF"
                  onClick={(e) => {
                    handleCloseModal(e);
                  }}
                />
              </div>
            </div>
          </form>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <Button
              width="180px"
              name={t("Yes")}
              onClick={() => approveIntern()}
              style={{ marginRight: "30px" }}
            />
            <Button
              width="180px"
              name={t("No")}
              color="#FFFFFF"
              onClick={() => setShowConfirmationModal(false)}
            />
          </div>
        )}
      </div>
    );
  };

  const onRejectionSubmit = async (values) => {
    console.log("action", action);
    const body = {
      id: code,
      status: action === "accepted" ? "accepted" : "rejected",
      comments: values.comments,
      update_time: updateTime,
    };

    const { data, status } = await jobAction(body);
    setLoading(true);
    if (status === 200) {
      setShow(false);
      if (data.status === 1) {
        getDatas("", 0); 

        toast.success(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        statusDropdownApi();
      }
    }
    setShowConfirmationModal(false);
  };

  const approveIntern = async () => {
    const body = {
      id: code,
      status: action === "accepted" ? "accepted" : "rejected",
      update_time: updateTime,
    };

    const { data, status } = await jobAction(body);
    setLoading(true);
    if (status === 200) {
      setShow(false);

      if (data.status === 1) {
        getDatas("", 0); 

        toast.success(t(data.message), {
          position: toast.POSITION.TOP_RIGHT,
        });
        statusDropdownApi();
      }
    }
    setShowConfirmationModal(false);
  };

 
  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };
  const closeConfirmationModalReview = () => {
    setShowConfirmationModalReview(false);
  };
  const handleActionCallReview = (e, codess, actionName, data) => {
    e.stopPropagation();
    setCode(codess);
    var comments = dataList?.find((e) => e.id === codess);
    console.log(dataList, comments, "26586", codess);
    setTheArray(comments?.intern_review_comments);
    setShowConfirmationModalReview(true);
    setUpdateTime(data);
    setValue("comments", "");
    reset();
  };
  const schema = yup.object().shape({
    comments: yup.string().required("Comments is a required field."),
  });
  const {
    control,
    handleSubmit,
    setValue,
    
    formState: { errors },
    reset,
  } = useForm({
   
    resolver: yupResolver(schema),
   
  });
  const showToast = () => {
    toast.success(t("Internship status updated successfully"), {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const onSubmit = async (data, e) => {
    const body = {
      id: code,
      status: action === "review" ? "review" : "review",
      comments: data.comments,
      update_time: updateTime,
    };
    const result = await jobActionReview(body);
    if (result.status === 200) {
      await setShow(false);
      if (result.data.status === 1) {
        await setShowConfirmationModalReview(false);
        await toast.success(t("Review comments has been sent successfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        statusDropdownApi();
        await getDatas("", 1);
        setValue("comments", "");
        setCode("");
      }
    }
  };

  const content2 = () => {
    return (
      <>
        
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container">
            <SingleField>
              <FormLabel>{t("Comment")}</FormLabel>
              <Controller
                name="comments"
                control={control}
                render={({ field }) => (
                  <TextArea
                    style={{ background: "#F3F3F3" }}
                    {...field}
                    rows="6"
                    placeholder={t("Enter Comment")}
                  />
                )}
              />
              {errors.comments && (
                <ErrorField err={t(errors.comments.message)} />
              )}
            </SingleField>
            <Button
              width="100px"
              name={t("Save")}
              className="mt-0 mb-5"
              type="submit"
              style={{ float: "right" }}
            />
          </div>
        </form>
        <div className="container mb-5">
          <Root sx={{ width: 200, maxWidth: "50%" }}>
            <table aria-label="custom pagination table">
              <thead>
                <tr>
                  <th
                    style={{
                      background: "rgb(250, 74, 161)",
                      color: "#fff",
                    }}
                  >
                    {t("Comments")}
                  </th>
                  <th
                    style={{
                      background: "rgb(250, 74, 161)",
                      color: "#fff",
                    }}
                  >
                    {t("Created")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {theArray?.length > 0
                  ? (rowsPerPage > 0 && theArray?.length
                    ? theArray.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    : theArray
                  ).map((row) => (
                    <tr key={row.comment}>
                      <td>{row.comment}</td>
                      <td style={{ width: 120 }} align="right">
                        {moment(new Date(row.created_at)).format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                    </tr>
                  ))
                  : ""}

                {emptyRows > 0 && (
                  <tr style={{ height: 41 * emptyRows }}>
                    <td colSpan={3} />
                  </tr>
                )}
              </tbody>
              {theArray?.length > 0 ? (
                <tfoot>
                  <tr>
                    <CustomTablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={3}
                      count={theArray?.length ?? "0"}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      componentsProps={{
                        select: {
                          "aria-label": "rows per page",
                        },
                        actions: {
                          showFirstButton: true,
                          showLastButton: true,
                        },
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </tr>
                </tfoot>
              ) : (
                <div className="container col-lg-12">{t("No Comments")}</div>
              )}
            </table>
          </Root>
        </div>
      </>
    );
  };
  return (
    <MainLayout>
      
      <DashboardWrapper>
        
        <PageTitle>{t("Manage Internships")}</PageTitle>
        <Row
          xs={1}
          md={3}
          lg={4}
          style={{ minHeight: "102px", alignItems: "center" }}
        >
          <Col lg="12">
            <Input
              color="#fff"
              placeholder={t("Search Keywords for internship or Company Name")}
              value={searchval}
              onChange={onSearch}
            />
          </Col>
        </Row>

        <Row xs={1} md={3} lg={4}>
          <Col>
            <SingleSection>
              <FilterHead>{t("Date Published")} </FilterHead>
              <label className="custom-date">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <ThemeProvider theme={materialTheme}>
                    <DatePicker
                      autoOk
                      closeonscroll={"true"}
                      variant="inline"
                      format="dd/MM/yyyy"
                      placeholder={t("From")}
                      views={["year", "month", "date"]}
                      value={fromDate ? new Date(fromDate) : null}
                      onChange={(val) => dateChange("fromDate", val)}
                      fullWidth
                      inputadornmentprops={{ position: "start" }}
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
                <CalenderIconWrapper>
                  <CalenderIcon />
                </CalenderIconWrapper>
              </label>
            </SingleSection>
          </Col>
          <Col>
            <SingleSection>
              <FilterHead visibility="hidden">{t("Date")}</FilterHead>
              <label className="custom-date">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <ThemeProvider theme={materialTheme}>
                    <DatePicker
                      clearable="true"
                      variant="inline"
                      closeonscroll={"true"}
                      minDate={fromDate ? new Date(fromDate) : new Date()}
                      format="dd/MM/yyyy"
                      placeholder={t("To")}
                      views={["year", "month", "date"]}
                      value={toDate ? new Date(toDate) : null}
                      onChange={(val) => dateChange("toDate", val)}
                      fullWidth
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
                <CalenderIconWrapper>
                  <CalenderIcon />
                </CalenderIconWrapper>
              </label>
            </SingleSection>
          </Col>
          <Col
            style={
              dataList.length === 0 ? { minHeight: "180px" } : { zIndex: 100 }
            }
          >
            <SingleSection>
              <FilterHead>{t("Status")}</FilterHead>
              <Select
                
                formatOptionLabel={formatOptionLabel}
                options={options}
                components={compo}
                styles={styles}
                onChange={handleSelect}
                defaultValue={
                  statusFilter && statusFilter !== null && statusFilter !== ""
                    ? setPreviousData()
                    : options[0]
                }
                
                value={
                  statusFilter && statusFilter !== null && statusFilter !== ""
                    ? setPreviousData()
                    : options[0]
                }
              />
            </SingleSection>
          </Col>
          <Col>
            <SingleSection>
              <FilterHead className="invisible mb-2">Button</FilterHead>
              <div className="row">
                <Button
                  className="col-md-6 mb-1 mt-0 py-auto"
                  style={{ width: "43%" }}
                  onClick={() => getDatas("", 0)}
                  name={t("Search")}
                />
                <Button
                  color="#FFFFFF"
                  name={t("Reset")}
                  className="col-md-6 mb-1 mt-0 py-auto mx-2"
                  style={{ width: "43%" }}
                  onClick={() => {
                    setSelectOptions(options[0]);
                    setStatus(options[0].value); 
                    setFromDate(null);
                    setToDate(null);
                    setSearchFilter("");
                    setLoading(true); 
                    dispatch(resetAllInternships());
                    debounceChange("", 0, "all");
                  }}
                />
                
              </div>
            </SingleSection>
          </Col>
        </Row>
        {loading ? (
          <Loader />
        ) : (
          <Section>
            <InfiniteScroll
              dataLength={dataList?.length}
              next={fetchData}
              hasMore={currentPage < total}
              loader={total / 10 > page ? <Loader /> : ""}
              scrollThreshold={0.5}
            >
              {dataList.length > 0 ? (
                dataList.map((list, index) => (
                  <JobsCards
                    intern={true}
                    pending={status === "pending"}
                    domain={domain}
                    
                    handleApprove={handleActionCall}
                    handleApproveReview={handleActionCallReview}
                    key={list + index}
                    datas={list}
                    showToast={showToast}
                    name={selectOptions}
                    title={name}
                    searchval={searchval}
                  />
                ))
              ) : (
                <p className="d-flex justify-content-center">
                  {t("No data found")}
                </p>
              )}
            </InfiniteScroll>
          </Section>
        )}
      </DashboardWrapper>

      <AlertModalCompany
        show={showConfirmationModal}
        close={closeConfirmationModal}
        content={content()}
      />
      <AlertModalReview
        show={showConfirmationModalSendReview}
        close={closeConfirmationModalReview}
        content={content2()}
      />
      
    </MainLayout>
  );
}
