import * as yup from 'yup';

export const initialObj = {
  rgtr_address: '',
  country: '',
  state: '',
  city: '',
  status: '',
  country_code: '',
};

export const schema = yup.object().shape({
  rgtr_address: yup.string().required('Address is required'),
  country: yup.string().required('Country is required'),
  state: yup.string().required('State is required'),
  city: yup.string().required('City is required'),
  status: yup.string().required('Status is required'),
  country_code: yup.string().required('Post Code is required'),
});
