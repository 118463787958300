import { useState, useEffect } from 'react';
import { CONSTANTS } from '../constants/constants';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import { Dropdown, Nav, Navbar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { AiOutlineLogout } from 'react-icons/ai';
import Button from '../../components/button/button';
import { Row, Col } from 'react-bootstrap';
import AlertModal from '../alertModal/alertModal';
import { getAdminUserInfo, getAdminData, adminLogout } from '../../services/apiCalls'; //ARCHMAGE
import Logo from '../../assets/companylogosettings.png';
import Hamburger from '../../components/hamburgermenu/hamburger';
import Menus from '../../components/hamburgermenu/menus';
import englishFlag from "../../assets/en2.png";
import malayFlag from "../../assets/my2.png";

const HeaderWrapper = styled.div`
  display: flex;
  -webkit-justify-content: flex-end;
  align-items: flex-end;
  -webkit-align-items: flex-end;

  > img {
    margin-right: 10px;
    max-width: 30px;
    cursor: pointer;
    &:last-child() {
      margin-right: 0px;
    }
  }
`;


const ProfileLogo = styled.div`
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    height: 28px;
    width: 28px;
  }
`;
const Message = styled.div`
  padding-left: 20px;
  font-size: 22px;
  padding-bottom: 5px;
`;
const DropdownList = styled.span`
  padding-left: 10px;
`;

const HamburgerLayout = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
`;
const HamburgerDiv = styled.div`
  display: flex;
`;

const FlagLogo = styled.div`
  width: 30px;
  height: 30px;
  background: rgb(208, 76, 141);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0.5rem;
  img {
    height: 32px;
    width: 32px;
  }
`;

export default function TopNavbar() {
  const [show, setShow] = useState(false);
  const [, setData] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [admin_data, setAdminData] = useState('');

  useEffect(() => {
    getDataOfUser();
    getAdminAllData();
  }, []);
  const getAdminAllData = async () => {
    const { data, } = await getAdminData();
    if (data && data.status === true) {
      setAdminData(data.data)
    }
  };
  const getDataOfUser = async () => {
    const { data, status } = await getAdminUserInfo();
    if (status === 200) {
      if (data.status === 1) {
        setData(data.data);
      }
    }
  };
  const navigate = useNavigate();
  const closeModal = () => {
    setShow(false);
  };
  const onLogout = async () => {

    await adminLogout(); 

    Cookies.remove('token');
    navigate('/login');

  };
  const handleAction = () => {
    setShow(true);
  };
  const { t, i18n } = useTranslation();
  const changeLanguageHandler = (lang) => {
    const languageValue = lang
    i18n.changeLanguage(languageValue);
    sessionStorage.setItem("lang", languageValue);
  };
  const content = () => {
    return (
      <>
        <Message>{t("Are you sure you want to logout?")}</Message>
        <Row>
          <Col md="6" />
          <Col
            md="12"
            className="d-flex justify-content-center align-items-center"
          >
            <Button
              name={t("Yes")}
              style={{ margin: "30px 0px 30px 0px", width: "200px", }}
              onClick={onLogout} />
            <Button
              secondary
              style={{ width: "200px", margin: "30px 0px 30px 10px" }}
              name={t("No")}
              color="#FFFFFF"
              onClick={closeModal}
            />
          </Col>
        </Row>
      </>
    );
  };
  return (
    <HeaderWrapper>
      {isOpen && <Menus />}
      <Navbar >
        <Nav className='margin_right_63'>
          <Nav.Link>
            <Dropdown className="dropdown__toggle">
              <Dropdown.Toggle variant="">
                <FlagLogo>
                  <img
                    src={
                      i18n.language === "my" ? malayFlag : englishFlag
                    }
                    alt="profile-logo"
                  />
                </FlagLogo>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#" onClick={() => changeLanguageHandler("en")}>
                  <img
                    width={32}
                    height={32}
                    src={englishFlag}
                    alt="profile-logo"
                  />
                  <DropdownList>{"English"}</DropdownList>
                </Dropdown.Item>
                <Dropdown.Item href="#" onClick={() => changeLanguageHandler("my")}>
                  <img
                    width={30}
                    height={30}
                    src={malayFlag}
                    alt="profile-logo"
                  />
                  <DropdownList>{"Malay"}</DropdownList>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Link>
          {process.env.NODE_ENV !== 'production' && (
            <Nav.Link className='d-none'>Version: {CONSTANTS.version}</Nav.Link>
          )}
          
          <Nav.Link>
            <Dropdown className="dropdown__toggle">
              <Dropdown.Toggle variant="">
                <ProfileLogo>
                  <img
                    src={
                      admin_data && admin_data.profile_picture
                        ? admin_data.profile_picture
                        : Logo
                    }
                    alt="profile-logo"
                  />
                </ProfileLogo>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#" onClick={handleAction}>
                  <AiOutlineLogout />
                  <DropdownList>{t("Logout")}</DropdownList>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Link>
        </Nav>
        <HamburgerDiv>
          <HamburgerLayout>
            <Hamburger onToggle={() => setOpen(!isOpen)} />
          </HamburgerLayout>
        </HamburgerDiv>
      </Navbar>
      <AlertModal show={show} close={closeModal} content={content()} />
    </HeaderWrapper>
  );
}
