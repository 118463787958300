import React from "react";
import MainLayout from "../../../layouts/mainLayout/mainlayout";
import DoghnutChart from "../../../components/analyticalDashboard/charts/doughnutChart.js";
import DoubleBarChart from "../../../components/analyticalDashboard/charts/doubleBarChart.js";
import Button from "../../../components/button/button";
import { Link, Navigate } from "react-router-dom";
import Map from "../../../assets/map.png";
import colors from "../../../colors.js";
import { onApiCall } from "../../../services/CommonApi";
import Multiselect from "multiselect-react-dropdown";
import styled from "styled-components";
import MasterdataService from "../../../services/masterdata.service";
import { getAdminUserInfo } from "../../../services/apiCalls";
import { withTranslation } from "react-i18next";

const DropdownTitle = styled.h1`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
`;
const controller = new AbortController();
const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

class Demographics extends React.Component {
  constructor(props) {
    super(props);
    this.multiselectRefCampus = React.createRef();
    this.multiselectRefFaculty = React.createRef();
    this.multiselectRefStudyProgram = React.createRef();
    this.update = debounce(function () {
      this.onDashCall();
    }, 0);
    this.state = {
      userStatus: {},
      userEducation: {},
      userScholarship: {},
      userDemographics: {},
      userSignups: {},
      selectedOption: [],
      selectedUniversity: [],
      selectedCampus: [],
      selectedFaculty: [],
      selectedStudyProgram: [],
      selectedScopeOfStudy: [],
      selectedYearOfStudy: [],
      selectedYearOfRegister: "",
      university: {},
      campus: {},
      faculty: {},
      studyProgram: {},
      scope: {},
      year: {},
    };
  }

  fetchUniversities = async () => {
    const { data, status } = await MasterdataService.getUniversity();
    if (status === 200) {
      const campusData = data.data;
      const campus = campusData.map((x) => {
        return { ...x, label: x.Name, value: x.Id, data: x };
      });
      this.setState({ university: campus });
      
    }
  };

  fetchCampus = async () => {
    const params1 = {
      UniversityId: this.state.selectedUniversity,
    };
    const { data, status } = await MasterdataService.getCampus(params1);
    if (status === 200) {
      if (data.status === 1) {
        const campusData = data.data;
        const campus = campusData.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        this.setState({ campus: campus });
        
      }
    }
  };
  fetchFaculty = async () => {
    const params1 = {
      CollegeId: this.state.selectedCampus,
    };
    const { data, status } = await MasterdataService.getFaculty(params1);
    if (status === 200) {
      if (data.status === 1) {
        const facultyData = data.data;
        const faculty = facultyData.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        this.setState({ faculty: faculty });
      }
    }
  };
  fetchStudyPrograms = async () => {
    const params1 = {
      DepartmentId: this.state.selectedFaculty,
    };
    const { data, status } = await MasterdataService.getStudyPrograms(params1);
    if (status === 200) {
      if (data.status === 1) {
        const studyprograms = data.data;
        const studyProgram = studyprograms.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        this.setState({ studyProgram: studyProgram });
      }
    }
  };
  fetchScopeOfStudy = async () => {
    const { data, status } = await MasterdataService.getScopeOfStudies();
    if (status === 200) {
      if (data.status === 1) {
        const scopeofstudies = data.data;
        const scope = scopeofstudies.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        this.setState({ scope: scope });
      }
    }
  };
  fetchYearOfStudy = async () => {
    const { data, status } = await MasterdataService.getYearOfStudies();
    if (status === 200) {
      if (data.status === 1) {
        const yearOfStudy = data.data;
        const year = yearOfStudy.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        this.setState({ year: year });
      }
    }
  };

  onDashCall() {

    var params = {campus: this.state.selectedCampus.join(","),
      faculty: this.state.selectedFaculty.join(","),
      study_program: this.state.selectedStudyProgram.join(","),
      year_of_study: this.state.selectedYearOfStudy.join(","),
      insti_name: this.state.selectedUniversity.join(","),
      scope_of_study: this.state.selectedScopeOfStudy.join(","),
      
    }

    this.setState({
      prevReq: this.state.prevReq + 1,
    });

    onApiCall({
      url: `/api/dashboard/analytical/demographics/userStatus/`,
      method: "GET",
      params: params,
      controller,
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ userStatus: res.data.data.results });
      }
    });

    onApiCall({
      url: `/api/dashboard/analytical/demographics/userEducation/`,
      method: "GET",
      params:params,
      controller,
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ userEducation: res.data.data.results });
      }
    });

    onApiCall({
      url: `/api/dashboard/analytical/demographics/userScholarship/`,
      method: "GET",
      params: params,
      controller,
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ userScholarship: res.data.data.results });
      }
    });

    onApiCall({
      url: `/api/dashboard/analytical/demographics/userDemographics/`,
      method: "GET",
      params: params,
      controller,
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ userDemographics: res.data.data.results });
      }
    });

    onApiCall({
      url: `/api/dashboard/analytical/demographics/userSignups/`,
      method: "GET",
      params: params,
      controller,
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ userSignups: res.data.data.results });
      }
    });
  }

  getDatas = async () => {
    var result1 = await getAdminUserInfo();
    if (result1.status === "401") {
      this.setState({ visib: false });
      return this.render(<Navigate to="/login" />);
    } else {     
      
      this.fetchScopeOfStudy();
      this.fetchYearOfStudy();
      this.fetchUniversities();
      this.setState({ visib: true });
     }
  };


  componentDidMount() {
    
    this.getDatas();
    
    
    
    onApiCall({
      url: `/api/dashboard/analytical/demographics/userStatus/`,
      method: "GET",
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ userStatus: res.data.data.results });
      }
    });

    onApiCall({
      url: `/api/dashboard/analytical/demographics/userEducation/`,
      method: "GET",
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ userEducation: res.data.data.results });
      }
    });

    onApiCall({
      url: `/api/dashboard/analytical/demographics/userScholarship/`,
      method: "GET",
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ userScholarship: res.data.data.results });
      }
    });

    onApiCall({
      url: `/api/dashboard/analytical/demographics/userDemographics/`,
      method: "GET",
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ userDemographics: res.data.data.results });
      }
    });

    onApiCall({
      url: `/api/dashboard/analytical/demographics/userSignups/`,
      method: "GET",
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ userSignups: res.data.data.results });
      }
    });
  }

  shuffleArray(array) {
    let inputArray = JSON.parse(JSON.stringify(array));

    inputArray.sort(() => Math.random() - 0.5);

    return inputArray;
  }

  formatStatus(item) {
    return {
      labels: ["Employed", "Internship", "Studying", "Unemployed"],
      datasets: [
        {
          data: [
            item?.employed,
            item?.internship,
            item?.studying,
            item?.unemployed,
          ],
          backgroundColor: ["#a0dfde", "#7cd2d2", "#4bc2c1", "red"],
        },
      ],
    };
  }

  formatEducation(item) {
    const data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            "#4571b0",
            "#6aa1c7",
            "#9ccbdf",
            "#cbe8ee",
            "#fbd185",
            "#eb6947",
            "#fcf2b4",
            "#d03533",
            "#F1C40F",
          ],
        },
      ],
    };

    for (const [, value] of Object.entries(item)) {
      data.labels.push(value['_id'][0]);
      data.datasets[0].data.push(value['total']);
    }

    return data;
  }

  formatScholarship(item) {
    return {
      labels: ["Scholarship / Loan", "Self Funded"],
      datasets: [
        {
          data: [item.scholarship, item.selfFunded],
          backgroundColor: ["#a0dfde", "#7cd2d2"],
        },
      ],
    };
  }

  formatDemographics(item) {
    const data = {
      labels: [],
      datasets: [{ data: [], backgroundColor: this.shuffleArray(colors) }],
    };

    for (const [, value] of Object.entries(item)) {
      data.labels.push(value['_id'][0]);
      data.datasets[0].data.push(value['total']);
    }

    return data;
  }

  formatUserSignups(item) {
    // const data = {
    //   labels: [],
    //   datasets: [
    //     {
    //       data: [],
    //       backgroundColor: [
    //         "#4571b0",
    //         "#4571b0",
    //         "#4571b0",
    //         "#4571b0",
    //         "#4571b0",
    //         "#4571b0",
    //         "#4571b0",
    //         "#4571b0",
    //         "#4571b0",
    //         "#4571b0",
    //         "#4571b0",
    //         "#4571b0",
    //         "#4571b0",
    //         "#4571b0",
    //         "#4571b0",
    //       ],
    //     },
    //   ],
    // };

    const sortedKeys = Object.keys(item).sort((a, b) => parseInt(a) - parseInt(b));

    // Create an array to hold the sorted data temporarily
    const sortedData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            "#4571b0",
            "#4571b0",
            "#4571b0",
            "#4571b0",
            "#4571b0",
            "#4571b0",
            "#4571b0",
            "#4571b0",
            "#4571b0",
            "#4571b0",
            "#4571b0",
            "#4571b0",
            "#4571b0",
            "#4571b0",
            "#4571b0",
          ],
        },
      ],
    };

    for (const currentIndex of sortedKeys) {
      sortedData.labels.push(currentIndex);
      sortedData.datasets[0].data.push(item[currentIndex]);
    }

    return sortedData;
  }

  setOpen = (isOpen1) => {
    this.setState(!isOpen1);
  };

  handleChangeScopeOfStudy = (selectedOption) => {
    var datas = [];
    selectedOption.map((x) => {
      datas.push(x.Id);
      return x.Id;
    });
    this.setState(
      {
        selectedScopeOfStudy: datas,
      },
      () => {
        this.onDashCall();
      }
    );
  };

  handleChangeStudyprograme = (selectedOption) => {
    var datas = [];
    selectedOption.map((x) => {
      datas.push(x.Id);
      return x.Id;
    });
    this.setState(
      {
        selectedStudyProgram: datas,
      },
      () => {
        this.onDashCall();
      }
    );
  };

  handleChangeYearOfStudy = (selectedOption) => {
    var datas = [];
    selectedOption.map((x) => {
      datas.push(x.Id);
      return x.id;
    });
    this.setState(
      {
        selectedYearOfStudy: datas,
      },
      () => {
        this.onDashCall();
      }
    );
  };

  setOpen = (isOpen1) => {
    this.setState(!isOpen1);
  };

  handleChangeFaculty = (selectedOption) => {
    var datas = [];
    selectedOption.map((x) => {
      datas.push(x.Id);
      return x.Id;
    });
    this.setState(
      {
        selectedFaculty: datas,
      },
      () => {
        
        this.update();
        this.resetValuesStudyProgram();
        this.setState({
          selectedStudyProgram: [],
          studyProgram: {},
        });

        if (datas.length > 0) {
          this.fetchStudyPrograms();
        }
      }
    );
  };

  handleChangecampus = (selectedOption) => {
    var datas = [];
    selectedOption.map((x) => {
      datas.push(x.Id);
      return x.Id;
    });
    this.setState(
      {
        selectedCampus: datas,
      },
      () => {
        
        this.update();
        this.resetValuesFaculty();
        this.resetValuesStudyProgram();
        this.setState({
          faculty: {},
          selectedFaculty: [],
          selectedStudyProgram: [],
          studyProgram: {},
        });

        if (datas.length > 0) {
          this.fetchFaculty();
        }
      }
    );
  };
  handleChangeUniversity = (selectedOption) => {
    var datas = [];
    selectedOption.map((x) => {
      datas.push(x.Id);
      return x.Id;
    });
    this.setState(
      {
        selectedUniversity: datas,
      },
      () => {
        
        this.update();
        this.resetValuesCampus();
        this.resetValuesFaculty();
        this.resetValuesStudyProgram();
        this.setState({
          campus: {},
          selectedCampus: [],
          faculty: {},
          selectedFaculty: [],
          selectedStudyProgram: [],
          studyProgram: {},
        });

        if (datas.length > 0) {
          this.fetchCampus();
        }
      }
    );
  };

  resetValuesCampus() {
    this.multiselectRefCampus.current.resetSelectedValues();
  }

  resetValuesFaculty() {
    this.multiselectRefFaculty.current.resetSelectedValues();
  }

  resetValuesStudyProgram() {
    this.multiselectRefStudyProgram.current.resetSelectedValues();
  }

  
  
  render() {
    const { t } = this.props;
    console.log("currentData",this.state.userSignups);
    return (
      <MainLayout>
        <div
          className="d-flex mb-5"
          style={{ fontSize: "1.1rem", fontWeight: "400" }}
        >
          <Link
            to="/analyticaldashboard"
            style={{ textDecoration: "none", color: "#ACACAC" }}
          >
            {t("Analytical Dashboard")}
          </Link>

          <div className='mx-2' style={{ color: '#ACACAC' }}> {">"} </div>

          <span style={{ textDecoration: "none", color: "#D04C8D" }}>
          {t("Demographics")}
          </span>
        </div>
        <div className="row mb-2">
                <div className="col-12 col-lg-6 col-xl-12">
                  <DropdownTitle>{t("Universities")}</DropdownTitle>
                  <Multiselect
                    style={{ width: "100%" }}
                    options={
                      this?.state?.university &&
                        this?.state?.university.length > 0
                        ? this?.state?.university
                        : []
                    }
                    
                    onSelect={this.handleChangeUniversity}
                    onRemove={this.handleChangeUniversity} 
                    displayValue="label" 
                    showCheckbox="true"
                    placeholder={t("Select")}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <style type="text/css">
                  {`
                    @media (min-width: 576px){
                    .modal-dialog {
                        max-width: 671px;
                        margin: 1.75rem auto;
                    }
                    }
                    .searchWrapper{
                      background:white;
                      border-radius:23px!important;
                      padding:9px!important;
                    }
                    .searchWrapper:hover, .searchWrapper:focus, .searchWrapper:active{
                      border-color:#FF60B1!important;
                    }
                    .multiSelectContainer li:hover{
                      background:#FFE9F5!important;
                      color:black!important;
                    
                    }
                    .multiSelectContainer li:select{
                    background:#FF60B1!important;
                    }
                    .highlightOption{
                      background:#D24B8D!important;
                    }
                    .chip{
                      background:#FF60B1!important;
                    }
                    .rmsc multi-select{
                      max-width:350px!important
                    }
                  `}
                </style>
                <div className="col-12 col-lg-6 col-xl-6">
                  <DropdownTitle>{t("Campus")}</DropdownTitle>
                  <Multiselect
                    style={{ width: "100%" }}
                    disable={!this.state.selectedUniversity.length}
                    ref={this.multiselectRefCampus}
                    options={
                      this?.state?.campus && this?.state?.campus.length > 0
                        ? this?.state?.campus
                        : []
                    }
                    
                    onSelect={this.handleChangecampus} 
                    onRemove={this.handleChangecampus} 
                    displayValue="Name" 
                    showCheckbox="true"
                    placeholder={t("Select")}
                  />
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <DropdownTitle>{t("Faculty")}</DropdownTitle>
                  <Multiselect
                    style={{ width: "100%" }}
                    ref={this.multiselectRefFaculty}
                    disable={!this.state.selectedCampus.length}
                    options={
                      this?.state?.faculty && this?.state?.faculty.length > 0
                        ? this?.state?.faculty
                        : []
                    }
                    
                    onSelect={this.handleChangeFaculty} 
                    onRemove={this.handleChangeFaculty} 
                    displayValue="Name" 
                    showCheckbox="true"
                    placeholder={t("Select")}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-12 col-lg-6 col-xl-6">
                  <DropdownTitle>{t("Study Programme")}</DropdownTitle>
                  <Multiselect
                    style={{ width: "100%" }}
                    disable={!this.state.selectedFaculty.length}
                    ref={this.multiselectRefStudyProgram}
                    options={
                      this?.state?.studyProgram &&
                        this?.state?.studyProgram.length > 0
                        ? this?.state?.studyProgram
                        : []
                    }
                    
                    onSelect={this.handleChangeStudyprograme} 
                    onRemove={this.handleChangeStudyprograme} 
                    displayValue="Name" 
                    showCheckbox="true"
                    placeholder={t("Select")}
                  />
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <DropdownTitle>{t("Scope of Study")}</DropdownTitle>
                  <Multiselect
                    style={{ width: "100%" }}
                    options={
                      this?.state?.scope && this?.state?.scope.length > 0
                        ? this?.state?.scope
                        : []
                    }
                   
                    onSelect={this.handleChangeScopeOfStudy} 
                    onRemove={this.handleChangeScopeOfStudy}
                    displayValue="Name" 
                    showCheckbox="true"
                    placeholder={t("Select")}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-12 col-lg-6 col-xl-6">
                  <DropdownTitle>{t("Year of Study")}</DropdownTitle>
                  <Multiselect
                    style={{ width: "100%" }}
                    options={
                      this?.state?.year && this?.state?.year.length > 0
                        ? this?.state?.year
                        : []
                    }
                    
                    onSelect={this.handleChangeYearOfStudy} 
                    onRemove={this.handleChangeYearOfStudy} 
                    displayValue="Name" 
                    showCheckbox="true"
                    placeholder={t("Select")}
                  />
                </div>
                </div>
        <div className="row">
          <div className="col-12 col-lg-6  mb-4">
            <div
              className="p-4 text-center bg-white rounded shadow"
              style={{ minHeight: "100%" }}
            >
              <h3 className="mb-3" style={{ fontWeight: "300" }}>
              {t("STATUS")}
              </h3>

              <DoghnutChart data={this.formatStatus(this.state.userStatus)} />

              <p className="text-secondary my-4">
                {t("Shows what percentage of talents are studying, unemployed, interns, or employed.")}
              </p>
            </div>
          </div>

          <div className="col-12 col-lg-6  mb-4">
            <div
              className="p-4 text-center bg-white rounded shadow"
              style={{ minHeight: "100%" }}
            >
              <div style={{ height: "450px" }}>
                <h3 className="mb-4" style={{ fontWeight: "300" }}>
                {t("EDUCATION")}
                </h3>

                <p className="text-secondary mb-4">
                  {t("Click “View Details” to learn more about talents’ academic information")}
                </p>

                <DoghnutChart
                  data={this.formatEducation(this.state.userEducation)}
                />
              </div>

              <Link
                className="mt-4"
                width="150px"
                to="/analyticaldashboard/demographics/education"
              >
                <Button width="150px" name={t("View Details")} />
              </Link>
            </div>
          </div>

          <div className="col-12 col-lg-6  mb-4">
            <div
              className="p-4 text-center bg-white rounded shadow"
              style={{ minHeight: "100%" }}
            >
              <div style={{ height: "450px" }}>
                <h3 className="mb-4" style={{ fontWeight: "300" }}>
                {t("FINANCIAL SUPPORT")}
                </h3>

                <p className="text-secondary mb-4">
                  {t("Click “View Details” to learn more about the financial support status of talents")}
                </p>

                <DoghnutChart
                  data={this.formatScholarship(this.state.userScholarship)}
                />
              </div>

              <Link
                className="mt-4"
                width="150px"
                to="/analyticaldashboard/demographics/financial-support"
              >
                <Button width="150px" name={t("View Details")} />
              </Link>
            </div>
          </div>

          <div className="col-12 col-lg-6  mb-4">
            <div
              className="p-4 text-center bg-white rounded shadow"
              style={{ minHeight: "100%" }}
            >
              <div style={{ height: "450px" }}>
                <h3 className="mb-4" style={{ fontWeight: "300" }}>
                {t("GEOGRAPHY")}
                </h3>

                <p className="text-secondary mb-4">
                  {t("Click “View Details” to learn more about talents’ place of residence")}
                </p>

                <div style={{ height: "300px" }}>
                  <img
                    src={Map} alt={"map"}
                    className="mx-auto d-block mb-4"
                    style={{ maxHeight: "100%", maxWidth: "100%" }}
                  />
                </div>
              </div>

              <Link
                className="mt-4"
                width="150px"
                to="/analyticaldashboard/demographics/geography"
              >
                <Button width="150px" name={t("View Details")} />
              </Link>
            </div>
          </div>

          <div className="col-12 col-lg-6  mb-4">
            <div
              className="p-4 text-center bg-white rounded shadow"
              style={{ minHeight: "100%" }}
            >
              <div style={{ height: "450px" }}>
                <h3 className="mb-4" style={{ fontWeight: "300" }}>
                {t("NATIONALITY AND ETHNICITY")}
                </h3>

                <p className="text-secondary mb-4">
                  {t("Click “View Details” to learn more about talents’ nationality, ethnicity, and gender")}
                </p>

                <DoghnutChart
                  data={this.formatDemographics(this.state.userDemographics)}
                />
              </div>

              <Link
                className="mt-4"
                width="150px"
                to="/analyticaldashboard/demographics/nationality"
              >
                <Button width="150px" name={t("View Details")} />
              </Link>
            </div>
          </div>

          <div className="col-12 col-lg-6  mb-4">
            <div
              className="p-4 text-center bg-white rounded shadow"
              style={{ minHeight: "100%" }}
            >
              <div style={{ height: "450px" }}>
                <h3 className="mb-4" style={{ fontWeight: "300" }}>
                {t("SIGN-UP INFORMATION")}
                </h3>

                <p className="text-secondary mb-4">
                  {t("Click “View Details” to learn more about sign up habits of talents")}
                </p>

                <DoubleBarChart
                  data={this.formatUserSignups(this.state.userSignups)}
                />
              </div>

              <Link
                className="mt-4"
                width="150px"
                to="/analyticaldashboard/demographics/signup-info"
              >
                <Button width="150px" name={t("View Details")} />
              </Link>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

export default withTranslation()(Demographics);
