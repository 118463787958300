import { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { Colors } from '../../utilities/colors';
import CustomModal from '../modal/modal';
import Input from '../input/input';
import RadioInput from '../radioInput/radioInput';
import Button from '../button/button';
import { schema } from './staticContent';
import ErrorField from '../errorField/errorField';
import { Grid } from '@material-ui/core';
import {
  onAddCompanyNewLocation,
  getCompanyData,
  onUpdateCompanyLocation,
  getCountriesMasterList,
  getSatesMasterList,
  getCitieMasterList,
} from '../../services/apiCalls';

import {
  CancelAndExit,
} from '../../pages/analyticalDashboardPage/commonStyles';

import { updateCompanyData } from '../../pages/companies/viewDetails/reducer';
import { updateLocationData } from './reducer';
import { initialObj } from './staticContent';
import Loader from '../loader/loader';
import Dropdown from '../dropdown/dropdown';
import { toast } from 'react-toastify';
const ModalWrapper = styled.div`
  padding: 20px;
  min-width: 320px;
  > h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #696969;
    margin: 0;
    margin-bottom: 10px;
  }
  @media (max-width: 900px) {
    padding: 0px;
    min-width: auto;
  }
`;
const FormWrapper = styled.div`
  @media (max-width: 320px) {
    width: 200px;
  }
`;

const SingleField = styled.div`
  margin-bottom: 20px;
  @media (max-width: 400px) {
    overflow-x: auto;
  }
`;
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.primaryText};
  margin-bottom: 10px;
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;



const statuses = ['primary', 'secondary'];

export default function AddNewLocation(props) {
  const onClose = props.onClose;
  const location = useSelector((state) => state.location.locationData);
  const isEdit = useSelector((state) => state.location.isEdit);
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('code');
  const company = useSelector((state) => state.company.companyData);
  const [loading, setLoading] = useState(false);
  
  
  const [formErrors, setFormErrors] = useState('');

  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);

  const [stateList, setStateList] = useState([]);
  const [selectedState, setSelectedState] = useState([]);

  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);

  const { t, } = useTranslation();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { ...location },
    resolver: yupResolver(schema),
  });

  const formVal = watch();
  const dispatch = useDispatch();

  const onSubmit = async () => {
    if (isEdit) {
      if (company.location && company.location.length) {
        company.locations.forEach(async (element) => {
          if (formVal.id !== element.id) {
              await onUpdateCompanyLocation({
              id: element.id,
              data: { status: 'primary' },
              code: code,
            });
          } else {
              await onUpdateCompanyLocation({
              id: element.id,
              data: { status: 'secondary' },
              code: code,
            });
          }
        });
      }
    }
    setLoading(true);

    const { data, } = isEdit
      ? await onUpdateCompanyLocation({
        id: formVal.id,
        data: { ...formVal },
        code: code,
      })
      : await onAddCompanyNewLocation({
        code: code,
        data: { ...formVal },
      });
    if (data && data.status === 1) {
      
      getData();
      toast.success(t("Details updated successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      props.onClose();
      dispatch(updateLocationData(initialObj));
      setFormErrors('');
    } else {
      setLoading(false);
      setFormErrors(data.message);
    }
  };

  useEffect(() => {
    getData();
    fetchCounty();
    fetchSavedState();
    fetchSavedCity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    const { data, } = await getCompanyData({
      code: code,
    });
    if (data && data.status === 1) {
      setLoading(false);
      dispatch(updateCompanyData(data.data));

      if (!formVal.status) {
        setValue('status', 'secondary');
      }
    } else {
      setLoading(false);
    }
  };

  const radioChange = (value, name) => {
    setValue(name, value);
  };

  const fetchCounty = async () => {
    const { data, status } = await getCountriesMasterList();
    if (status === 200) {
      if (data.status === 1) {
        const countryList = data.data;
        const country = countryList.map((x) => {
          return { ...x, label: x.Name, value: x.Id };
        });
        setCountryList(country);
        if (formVal.country) {
          let selCountry = country.filter(
            (x) => x.value === formVal.country
          );
          if (selCountry.length > 0) {
            selCountry = selCountry[0];
            setValue("country", selCountry.value);
          }
          setSelectedCountry(selCountry);
        }
      }
    }
  };

  const fetchState = async (CountryId) => {
    const { data, status } = await getSatesMasterList(CountryId);
    if (status === 200) {
      if (data.status === 1) {
        const StateList = data.data;
        const state = StateList.map((x) => {
          return { ...x, label: x.Name, value: x.Id };
        });
        setStateList(state);
        if (formVal.state) {
          let selState = state.filter(
            (x) => x.value === formVal.state
          );
          if (selState.length > 0) {
            selState = selState[0];
            setValue("state", selState.value);
          }
          setSelectedState(selState);
        }
      }
    }
  };
  const fetchSavedState = () => {
    if (formVal.country) {
      fetchState(formVal.country)
    }
  }


  const fetchCity = async (CountryId, StateId) => {
    const { data, status } = await getCitieMasterList(CountryId, StateId);
    if (status === 200) {
      if (data.status === 1) {
        const CityList = data.data;
        const city = CityList.map((x) => {
          return { ...x, label: x.Name, value: x.Id };
        });
        setCityList(city);
        if (formVal.city) {
          let selCity = city.filter((x) => x.value === formVal.city);
          if (selCity.length > 0) {
            selCity = selCity[0];
            setValue("city", selCity.value);
          }
          setSelectedCity(selCity);
        }
      }
    }
  };
  const fetchSavedCity = () => {
    if (formVal.country || formVal.state) {
      fetchCity(formVal.country, formVal.state)
    }
  }


  const dropdownChange = (name, val) => {
    setValue(name, val.value);
    switch (name) {
      default:
        break;
      case "country":
        setSelectedCountry(val);
        
        setValue('state', '')
        setValue('city', '')
        fetchState(val.value)
        setSelectedCity('')
        break;
      case "state":
        setSelectedState(val);
       
        setValue('city', '')
        fetchCity(formVal.country, val.value)
        break;
      case "city":
        setSelectedCity(val);
        
        break;
    }
  }
  const [is_disabled, setIsDisabled] = useState(false);
  useEffect(() => {
    if (formVal.status === "primary") {
      setIsDisabled(true);
    }
  }, [formVal.status])
  return (
    <CustomModal open={props.open} onCloseModal={props.onClose}>
      <ModalWrapper>
        <h3>{formVal.rgtr_address ? t('Edit Location') : t('Add New Location')}</h3>
        <hr />
        <FormWrapper>
          <Grid
            container
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid item lg={8}>
              <SingleField style={{ margin: '1%' }}>
                <FormLabel>{t("Address")}*</FormLabel>
                <Controller
                  name="rgtr_address"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      style={{ background: '#f3f3f3' }}
                      value={formVal.rgtr_address}
                      placeholder={t("Address")}
                    />
                  )}
                />
                {errors.rgtr_address && (
                  <ErrorField err={t(errors.rgtr_address.message)} />
                )}
              </SingleField>
            </Grid>
            <Grid item lg={4}>
              <SingleField style={{ margin: '1%' }}>
                <FormLabel>{t("Country")}*</FormLabel>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      search={true}
                      options={countryList}
                      placeholder={t("Enter Country")}
                      minWidth="auto"
                      val={selectedCountry?.value}
                      label={selectedCountry?.label}
                      change={(val) => dropdownChange("country", val)}
                      {...field}
                    />
                    
                  )}
                />
                {errors.country && <ErrorField err={t(errors.country.message)} />}
              </SingleField>
            </Grid>

            <Grid item lg={4}>
              <SingleField style={{ margin: '1%' }}>
                <FormLabel>{t("State")}*</FormLabel>
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      options={stateList}
                      placeholder={t("Enter Location")}
                      minWidth="auto"
                      val={selectedState?.value}
                      label={selectedState?.label}
                      change={(val) => dropdownChange("state", val)}
                      {...field}
                    />
                  )}
                />
                {errors.state && <ErrorField err={t(errors.state.message)} />}
              </SingleField>
            </Grid>

            <Grid item lg={4}>
              <SingleField style={{ margin: '1%' }}>
                <FormLabel>{t("City")}*</FormLabel>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      options={cityList}
                      placeholder={t("Enter Location")}
                      minWidth="auto"
                      val={selectedCity?.value}
                      label={selectedCity?.label}
                      change={(val) => dropdownChange("city", val)}
                      {...field}
                    />
                  )}
                />
                {errors.city && <ErrorField err={t(errors.city.message)} />}
              </SingleField>
            </Grid>

            <Grid item lg={4}>
              <SingleField style={{ margin: '1%' }}>
                <FormLabel>{t("Postcode")}*</FormLabel>
                
                <Controller
                  name="country_code"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      style={{ background: '#f3f3f3' }}
                      value={formVal.country_code}
                      placeholder={t("Postcode")}
                    />
                  )}
                />
                {errors.country_code && (
                  <ErrorField err={t(errors.country_code.message)} />
                )}
              </SingleField>
            </Grid>
          </Grid>
          <Grid item lg={4}>
            <SingleField style={{ margin: '1%' }}>
              <FormLabel>{t("Status")}</FormLabel>
              {is_disabled === true ?
                t("Primary") :
                <StatusWrapper>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) =>
                      statuses.map((val, index) => {
                        if (
                          val === "secondary" &&
                          props.isPrimaryLocation &&
                          formVal.status !== "primary"
                        ) {
                          return (
                            <RadioInput
                              key={val + index}
                              {...field}
                              checked={props.isPrimaryLocation}
                              onChange={() => radioChange(val, "status")}
                              label={val}
                              disabled={is_disabled}
                            />
                          );
                        } else {
                          return (
                            <RadioInput
                              key={val + index}
                              {...field}
                              checked={val === formVal.status}
                              onChange={() => radioChange(val, "status")}
                              label={val}
                              disabled={is_disabled}
                            />
                          );
                        }
                      })
                    }
                  />
                </StatusWrapper>
              }
              
              {errors.status && <ErrorField err={t(errors.status.message)} />}
            </SingleField>
          </Grid>

          <Grid
            container
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
            }}>
            {formErrors && <ErrorField err={t(formErrors)} />}
            {loading ? (
              <Loader />
            ) : (
              <Button
                style={{
                  borderRadius: '128px',
                  marginLeft: '10px',
                  width: '200px',
                  padding: '1.25%',
                  borderColor: '#D04C8D',
                }}
                name={t("Confirm")}
                onClick={handleSubmit(onSubmit)}
              />
            )}


            <CancelAndExit
              style={{ width: "20%", marginLeft: "10px" }}
              type="button"
              className="cancelBtn"
              onClick={onClose}
            >
              {t("Cancel")}
            </CancelAndExit>
          </Grid>
        </FormWrapper>
      </ModalWrapper>
    </CustomModal>
  );
}
