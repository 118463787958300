import { useState, useEffect } from "react";

import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Colors } from "../../utilities/colors";
import CustomModal from "../modal/modal";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup"; 
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import { useTranslation } from "react-i18next";

import Input from "../input/input";
import Button from "../button/button";
import ErrorField from "../errorField/errorField";
import {
  onUpdateCompanyDatas,
  getCompanyData,
  onCompanyProfilePicUpdate,
  getOrganisationTypesMasterList,
  getSectorMasterList,
} from "../../services/apiCalls";
import Logo from "../../assets/companylogosettings.png";
import { CancelAndExit } from "../../pages/analyticalDashboardPage/commonStyles";

import { updateCompanyData } from "../../pages/companies/viewDetails/reducer";
import Loader from "../loader/loader";
import Dropdown from "../dropdown/dropdown";
import { Grid } from "@material-ui/core";
import { useCallback } from "react";

const ModalWrapper = styled.div`
  padding: 50px;
  min-width: 420px;
  > h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color: #696969;
    margin: 0;
    margin-bottom: 10px;
  }
  @media (max-width: 500px) {
    padding: 0px;
    min-width: 15px;
  }
`;
const FormWrapper = styled.div`
  @media (max-width: 500px) {
    width: 200px;
  }
`;

const SingleField = styled.div`
  margin-bottom: 20px;
  @media (max-width: 500px) {
    overflow-x: auto;
  }
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.primaryText};
  margin-bottom: 0px;
`;

const Uploader = styled.div`
  position: relative;
  width: "100%";
  > input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
`;
const LinkText = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: inline-block
  color: #D04C8D;
  text-transform: capitalize;
`;


export default function UpdateCompanyIntroSection(props) {
  const company = useSelector((state) => state.company.companyData);
  const search = useLocation().search;
  const code = new URLSearchParams(search).get("code");
  const onClose = props.onClose;
  const { t, } = useTranslation();

  const defaultVal = {
    name: company && company.name ? company.name : "",
    ssm_number: company && company.ssm_number ? company.ssm_number : "",
    sector: company && company.sector ? company.sector : "",
    type_of_org: company && company.type_of_org ? company.type_of_org : "",
    sector_abbr: company && company.sector_abbr ? company.sector_abbr : "",
    type_of_org_abbr: company && company.type_of_org_abbr ? company.type_of_org_abbr : "",
    moto: company && company.moto ? company.moto : "",
    org_number: company && company.org_number ? company.org_number : "",
  };
  console.log(defaultVal);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const [organisationTypesList, setOrganisationTypes] = useState([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState([]);
  const [sectorList, setSectorList] = useState([]);
  const [selectedSector, setSelectedSector] = useState([]);

  
  const schema = yup.object().shape({
    name: yup.string().required("Name is a required field."),
    ssm_number: yup.string().required("SSM number is a required field."),
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, },
  } = useForm({
    defaultValues: { ...defaultVal },
    resolver: yupResolver(schema)
  });

  const uploadFile = async (event) => {
    const formdata = new FormData();
    let maxFileSize = 1024 * 1024 * 5; 
    if (event.target.files[0].size > maxFileSize) {
      toast.error(
        t("File size exceeds maximum limit."),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else {
      formdata.append(
        "logo_path",
        event.target.files[0],
        event.target.files[0].name
      );
      
      const { data, status } = await onCompanyProfilePicUpdate({
        code: code,
        data: formdata,
      });
      if (status === 200) onUploaded(data);
    }
  };

  const onUploaded = async (uploadData) => {
    const { status, } = uploadData;
    if (status === 1) {
      let obj = { logo_path: uploadData.data.obj_content };
      const { data, } = await onUpdateCompanyDatas({
        code: code,
        data: { ...obj },
      });
      if (data && data.status === 1) {
        getData();
      }
    }
  };

  const formVal = watch();
  const dispatch = useDispatch();

  const onSubmit = async () => {
    setLoading(true);
    const { data, } = await onUpdateCompanyDatas({
      code: code,
      data: { ...formVal },
    });

    if (data && data.status === 1) {

      getData();
      props.onClose();
      setFormErrors("");

      

      toast.success(t('Successfully Updated'), {
        position: toast.POSITION.TOP_RIGHT,
      });

      if (data.code !== code) { 
        window.location.reload();
      }

    } else {
      setLoading(false);
      setFormErrors(data && data.message);
    }
  };

  const getData = async () => {
    const { data, } = await getCompanyData({
      code: code,
    });
    if (data && data.status === 1) {
      setLoading(false);
      dispatch(updateCompanyData(data.data));
    } else {
      setLoading(false);
    }
  };

  const getOrganisationTypes = useCallback (async () => {
    const { data, status } = await getOrganisationTypesMasterList();
    if (status === 200) {
      if (data.status === 1) {
        const typeOfOrglist = data.data;
        const Organisation = typeOfOrglist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setOrganisationTypes(Organisation);
        if (formVal.type_of_org) {
          console.log(formVal.type_of_org_abbr);
          var selTypeOfOrglist;
          if (isNaN(formVal.type_of_org) === false) {
             selTypeOfOrglist = Organisation.filter(
              (x) => x.label === formVal.type_of_org_abbr
            );
          } else {
             selTypeOfOrglist = Organisation.filter(
              (x) => x.label === formVal.type_of_org
            );
          }
          if (selTypeOfOrglist.length > 0) {
            selTypeOfOrglist = selTypeOfOrglist[0];
            setValue("type_of_org", selTypeOfOrglist.label);
          }
          setSelectedOrganisation(selTypeOfOrglist);

        }
      }
    }
  }, [formVal.type_of_org, formVal.type_of_org_abbr, setValue])

  const getSector = useCallback (async () => {
    const { data, status } = await getSectorMasterList();
    if (status === 200) {
      if (data.status === 1) {
        const Sectorlist = data.data;
        const sector = Sectorlist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setSectorList(sector);
        if (formVal.sector) {
          var selSector
          if (isNaN(formVal.sector) === false) {
             selSector = sector.filter((x) => x.label === formVal.sector_abbr);
          } else {
             selSector = sector.filter((x) => x.label === formVal.sector);
          }

          if (selSector.length > 0) {
            selSector = selSector[0];
            setValue("sector", selSector.label);
          }
          setSelectedSector(selSector);

        }
      }
    }
  }, [formVal.sector, formVal.sector_abbr, setValue])

  useEffect(() => {
    getOrganisationTypes();
    getSector();
  }, [getOrganisationTypes, getSector]);

  

  const dropdownChange = (name, val) => {
    setValue(name, val.label);
    switch (name) {
      default: 
        break;
      case "type_of_org":
        setSelectedOrganisation(val);
        break;
      case "sector":
        setSelectedSector(val);
        break;
    }
  };

  const ImageWrapper = styled.div`
    text-align: center;
  `;
  const Image = styled.img`
    width: 210px;
    height: 210px;
    border-radius: 50%;
    // margin-bottom: 20px;
    margin-left: -64px;
  `;
  console.log("formVal---", formVal);

  return (
    <CustomModal
      open={props.open}
      onCloseModal={props.onClose}
      styles={{ modal: { overflowY: "unset" } }}
    >
      <ModalWrapper>
        <Grid container>
          <Grid item lg={3}>
            <ImageWrapper>
              <Image
                src={company && company.logo_path ? company.logo_path : Logo}
                alt="company logo"
              />
              <Uploader style={{ "margin-left": "-58px" }}>
                <LinkText style={{ color: "#D04C8D" }}>{t("Change Picture")}</LinkText>
                <input
                  onChange={uploadFile}
                  accept="image/x-png,image/jpeg"
                  type="file"
                />
              </Uploader>
              <p style={{ fontSize: "12px", color: "gray" }}>
                {t("The maximum image size limit is 5 MB")}
              </p>
            </ImageWrapper>
          </Grid>
          <Grid item lg={9}>
            <h3 style={{ width: "auto" }}>{company && company.name}</h3>
            <hr />
            <FormWrapper>

              {/* INIVOS */}
              <SingleField
                style={{ display: "flex", alignItems: "space-between" }}
              >
                <Grid container alignItems="center">
                  <Grid item lg={3}>
                    <FormLabel>{t("Company Name")}</FormLabel>
                  </Grid>
                  <Grid item lg={9}>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <Input
                          style={{ background: "#f3f3f3" }}
                          {...field}
                          placeholder={company.name}
                        />
                      )}
                    />
                    {errors.name && (
                      <ErrorField err={t(errors.name.message)} />
                    )}
                  </Grid>
                </Grid>
              </SingleField>
              <SingleField
                style={{ display: "flex", alignItems: "space-between" }}
              >
                <Grid container alignItems="center">
                  <Grid item lg={3}>
                    <FormLabel>{t("SSM")}</FormLabel>
                  </Grid>
                  <Grid item lg={9}>
                    <Controller
                      name="ssm_number"
                      control={control}
                      render={({ field }) => (
                        <Input
                          style={{ background: "#f3f3f3" }}
                          {...field}
                          placeholder={company.ssm_number}
                        
                        />
                      )}
                    />
                    {errors.ssm_number && (
                      <ErrorField err={t(errors.ssm_number.message)} />
                    )}
                  </Grid>
                </Grid>
              </SingleField>

              <SingleField
                style={{ display: "flex", alignItems: "space-between" }}
              >
                <Grid container alignItems="center">
                  <Grid item lg={3}>
                    <FormLabel>{t("Motto")}</FormLabel>
                  </Grid>
                  <Grid item lg={9}>
                    <Controller
                      name="moto"
                      control={control}
                      render={({ field }) => (
                        <Input
                          style={{ background: "#f3f3f3" }}
                          {...field}
                          placeholder={t("Enter Motto")}
                        />
                      )}
                    />
                    {errors.moto && <ErrorField err={t(errors.moto.message)} />}
                  </Grid>
                </Grid>
              </SingleField>

              <SingleField
                style={{ display: "flex", alignItems: "space-between" }}
              >
                <Grid container alignItems="center">
                  <Grid item lg={3}>
                    <FormLabel>{t("Type of Organisation")}</FormLabel>
                  </Grid>
                  <Grid item lg={9}>
                    <Controller
                      name="type_of_org"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          bgGrey
                          search={true}
                          options={organisationTypesList}
                          placeholder={t("Select Organisation")}
                          val={selectedOrganisation?.value}
                          label={selectedOrganisation?.label}
                          change={(val) => dropdownChange("type_of_org", val)}
                          {...field}
                        />
                      )}
                    />
                    {errors.type_of_org && (
                      <ErrorField err={t(errors.type_of_org.message)} />
                    )}
                  </Grid>
                </Grid>
              </SingleField>

              <SingleField
                style={{ display: "flex", alignItems: "space-between" }}
              >
                <Grid container alignItems="center">
                  <Grid item lg={3}>
                    <FormLabel>{t("Sector")}</FormLabel>
                  </Grid>
                  <Grid item lg={9}>
                    <Controller
                      name="sector"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          bgGrey
                          search={true}
                          placeholder={t("sector")}
                          change={(val) => dropdownChange("sector", val)}
                          options={sectorList}
                          val={selectedSector?.value}
                          label={selectedSector?.label}
                          {...field}
                        />
                      )}
                    />
                    {errors.sector && (
                      <ErrorField err={t(errors.sector.message)} />
                    )}
                  </Grid>
                </Grid>
              </SingleField>
              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "end",
                }}
              >
                {formErrors && <ErrorField err={t(formErrors)} />}
                {loading ? (
                  <Loader />
                ) : (
                  <Button
                    style={{
                      borderRadius: "128px",
                      marginLeft: "10px",
                      width: "200px",
                      padding: "1.9%",
                      borderColor: "#D04C8D",
                    }}
                    name={t("Confirm")}
                    onClick={handleSubmit(onSubmit)}
                  />
                )}
                <CancelAndExit
                  style={{ width: "200px", marginLeft: "10px" }}
                  type="button"
                  className="cancelBtn"
                  onClick={onClose}
                >
                  {t("Cancel")}
                </CancelAndExit>
              </Grid>
            </FormWrapper>
          </Grid>
        </Grid>
      </ModalWrapper>
    </CustomModal>
  );
}
