import React from 'react';
import MainLayout from '../../../layouts/mainLayout/mainlayout';
import DoghnutChart from '../../../components/analyticalDashboard/charts/doughnutChart.js';
import HorizontalBarChart from '../../../components/analyticalDashboard/charts/horizontalBarChart.js';
import { Link } from "react-router-dom";
import { onApiCall } from '../../../services/CommonApi';
import { withTranslation } from "react-i18next";

class FinancialSupport extends React.Component {

  constructor(props) {

    super(props);
    this.state = { financialSupport: {}, scholarshipLoan: {} }

  }

  componentDidMount() {

    onApiCall({
      url: `/api/dashboard/analytical/demographics/userScholarship/`,
      method: 'GET'
    }).then(res => {
      if (res.status === 200) {
        this.setState({ financialSupport: res.data.data.results });
      }
    })

    onApiCall({
      url: `/api/dashboard/analytical/demographics/financial-support/usersByScholarData/`,
      method: 'GET'
    }).then(res => {
      if (res.status === 200) {
        this.setState({ scholarshipLoan: res.data.data.results });
      }
    })

  }

  formatFinancialSupport(item) {

    return { "labels": ["Scholarship / Loan", "Self Funded"], "datasets": [{ "data": [item.scholarship, item.selfFunded], "backgroundColor": ["#a0dfde", "#7cd2d2"] }] }
  }

  format(item) {
    const data = { "labels": [], "datasets": [{ "data": [], "backgroundColor": ["#4571b0"] }] }

    for (const [, value] of Object.entries(item)) {
      data.labels.push(value['_id'][0])
      data.datasets[0].data.push(value['total'])
    }

    return data
  }

  formatUniversityList(item) {
    const data = { "labels": [], "datasets": [{ "data": [], "backgroundColor": ["#4571b0"] }] }

    for (const [, value] of Object.values(item)) {
      data.labels.push(value['_id'][0])
      data.datasets[0].data.push(value['total'])
    }

    return data
  }

  render() {
    const { t } = this.props;

    return (
      <MainLayout>

        <div className="d-flex mb-5" style={{ fontSize: '1.1rem', fontWeight: '400' }}>

          <Link to="/analyticaldashboard" style={{ textDecoration: 'none', color: '#ACACAC' }}>{t("Analytical Dashboard")}</Link>

          <div className='mx-2' style={{ color: '#ACACAC' }}> > </div>

          <Link to="/analyticaldashboard/demographics" style={{ textDecoration: 'none', color: '#ACACAC' }}>{t("Demographics")}</Link>

          <div className='mx-2' style={{ color: '#ACACAC' }}> > </div>

          <span style={{ textDecoration: 'none', color: '#D04C8D' }}>{t("Financial Support")}</span>

        </div>

        <div className='row'>

          <div className='col-12 col-lg-6 mb-4'>

            <div className='p-4 text-center bg-white rounded shadow' style={{ minHeight: '100%' }}>

              <h3 className='mb-3' style={{ fontWeight: '300' }}>{t("FINANCIAL SUPPORT")}</h3>

              <DoghnutChart data={this.formatFinancialSupport(this.state.financialSupport)} />

            </div>

          </div>

          <div className='col-12 col-lg-6 mb-4'>

            <div className='p-4 text-center bg-white rounded shadow' style={{ minHeight: '100%' }}>

              <h3 className='mb-3' style={{ fontWeight: '300' }}>{t("SCHOLARSHIP/LOAN")}</h3>

              <p className='text-secondary mb-4'>{t("Talents that chose “Scholarship/Loan” have reported to receive the following scholarship and/or loan:")}</p>

              <HorizontalBarChart data={this.format(this.state.scholarshipLoan)} />

            </div>

          </div>

        </div>
      </MainLayout>
    );

  }

}

export default withTranslation()(FinancialSupport);
