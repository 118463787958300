import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  companyData: {},
};

export const mySettings = createSlice({
  name: 'settingImage',
  initialState,
  reducers: {
    updateSetting: (state, action) => {
      state.companyData = action.payload;
    },
  },
});


export const { updateSetting } = mySettings.actions;

export default mySettings.reducer;
