import React, { useState, useEffect } from "react";

import Mainlayout from "../../layouts/mainLayout/mainlayout";
// import { useState, useEffect, useRef } from "react";

import styled from "styled-components";

// import Input from "../../components/input/input";

import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Colors } from "../../utilities/colors";
import FooterAddress from "../../components/footeraddress/footeraddress";
import { viewBlog } from "../../services/apiCalls";
import { Description } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { AspectRatio } from "react-aspect-ratio";
import "react-aspect-ratio/aspect-ratio.css";

const LabelText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;
const LightText = styled(LabelText)`
  color: ${Colors.pink};
  font-size: 20px;
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: -5px;
  justify-content: none;
  cursor: pointer;
  @media (max-width: 650px) {
    display: block;
  }
`;

const FlexWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BlogCardWrapper = styled.div`
  position: relative;
  background: #fdfdfd;
  border-radius: 30px;
  margin: 2rem 0;
  margin-bottom: 20px;
  border-radius: 30px;
`;

const BlogTitle = styled.div`
  font-weight: 500;
  opacity: 0.75;
  font-size: 40px;
  font-style: italic;
  padding: 4rem 0 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BlogDescription = styled.div`
  font-size: 20px;
  padding: 3rem;
  line-height: 36px;
`;
const Uploader = styled.img`
  cursor: pointer;
  max-width: 100%;
  background-size: cover;
  height: 50vh;
  width: 100%;

  // @media (max-width: 500px) {
  //   max-width: 280px;
  // }
`;

export default function BlogView(props) {
  const [request, setRequest] = useState({
    title: "",
    description: "",
    image: "",
    status: "public",
  });
  const { t } = useTranslation();

  const [showImg, setShowImg] = useState("");

  const [data, setData] = useState([]);

  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      getBlog(location.state.code);
    }
  }, [location.state]);

  const getBlog = async (code) => {
    const { data, status } = await viewBlog(code);
    if (data.status == 1) {
      setData(data.data);
      const resultDesc = data.data.description.replace(/((&nbsp;))*/gim, "");
      const newDetails = {
        ...request,
        title: data.data.title,
        description: resultDesc,
        image: data.data.image,
        status: data.data.status,
      };
      if (data.data.image) {
        setShowImg(data.data.image);
      }
      setRequest(newDetails);
    }
  };
  const ChangeNavigate = (path) => {
    navigate(`${path}`);
  };

  return (
    <Mainlayout>
      <div
        className="d-flex mb-5"
        style={{
          fontSize: "1.1rem",
          fontWeight: "400",
          marginLeft: "15px",
        }}
      >
        <Link
          to="/blogs"
          style={{
            textDecoration: "none",
            color: "#ACACAC",
            marginLeft: "15px",
          }}
        >
          Blogs &nbsp; {">"}
        </Link>
        <div className="mx-2" style={{ color: "#ACACAC" }}></div>
        <span style={{ textDecoration: "none", color: "#D04C8D" }}>
          {t("Blog View")}
        </span>
      </div>

      <BlogCardWrapper>
        <BlogTitle>{request.title}</BlogTitle>
        {showImg && (
          <AspectRatio ratio="16/9">
            <Uploader src={showImg} alt="uploader logo" />
          </AspectRatio>
        )}

        <BlogDescription
          dangerouslySetInnerHTML={{ __html: request.description }}
        />
      </BlogCardWrapper>
    </Mainlayout>
  );
}
