import { Col, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import FormErrors from "../../../components/formErrors/formErrors";
import Input from "../../../components/input/input";
import { useTranslation } from "react-i18next";
import {
  JustifyBetweenRow,
  LabelHeading,
  OverviewDetailWrapper,
  SectionTextDescription,
  SectionTitle,
  SelectorWrapper,
} from "../../analyticalDashboardPage/commonStyles";

export default function UniversityOverview({
  totalForm,
  isEditable,
  setIsEditable,
}) {
  const {
    
    formState: { errors },
    control,
    
    
    getValues,
    
  } = totalForm;
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Col xs="12">
          <JustifyBetweenRow>
            <SectionTitle>{t("Overview")}</SectionTitle>
          </JustifyBetweenRow>
          <hr />
        </Col>
      </Row>
      {!isEditable ? (
        <Row>
          <Col xs="12">
            <SectionTextDescription>
              <div
                dangerouslySetInnerHTML={{ __html: getValues().description }}
              />
            </SectionTextDescription>
          </Col>
          <Col xs="12">
            <OverviewDetailWrapper>
              <span>{t("Website")}</span>
              <a href={getValues().website} target="_blank" rel="noreferrer">
                {getValues().website}
              </a>
            </OverviewDetailWrapper>
            <OverviewDetailWrapper>
              <span>{t("Year Founded")}</span>
              <span>{getValues().yearFounded}</span>
            </OverviewDetailWrapper>
            <OverviewDetailWrapper>
              <span>{t("University Population Size")}</span>
              <span>{getValues().noOfEmployees} {t("Employees")}</span>
            </OverviewDetailWrapper>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col>
              <LabelHeading>{t("Description")}</LabelHeading>
              <SelectorWrapper>
                <Controller
                  control={control}
                  name="description"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <Input
                      placeholder={t("Description")}
                      as="textarea"
                      onChange={onChange}
                      disabled={!isEditable}
                      onBlur={onBlur}
                      rows="5"
                      type="text"
                      value={value}
                      className={error ? "invalid" : ""}
                      style={{
                        color: "#000",
                        background: "rgb(243, 243, 243)",
                        borderRadius: "25px",
                      }}
                    />
                  )}
                />
                <FormErrors errors={errors} formControlName="description" />
              </SelectorWrapper>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <LabelHeading>{t("Website")}</LabelHeading>
              <SelectorWrapper>
                <Controller
                  control={control}
                  name="website"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <Input
                      placeholder={t("Website")}
                      onChange={onChange}
                      disabled={!isEditable}
                      onBlur={onBlur}
                      rows="5"
                      type="text"
                      value={value}
                      className={error ? "invalid" : ""}
                      style={{
                        color: "#000",
                        background: "rgb(243, 243, 243)",
                      }}
                    />
                  )}
                />
                <FormErrors errors={errors} formControlName="website" />
              </SelectorWrapper>
            </Col>
            <Col md="4">
              <LabelHeading>{t("Year Founded")}</LabelHeading>
              <SelectorWrapper>
                <Controller
                  control={control}
                  name="yearFounded"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <Input
                      placeholder={t("Year Founded")}
                      onChange={onChange}
                      disabled={!isEditable}
                      onBlur={onBlur}
                      type="number"
                      onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                      onInput={(e) => {
                        if (e.target.value > 0) {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 4);
                        }
                      }}
                      value={value}
                      className={error ? "invalid" : ""}
                      style={{
                        color: "#000",
                        background: "rgb(243, 243, 243)",
                      }}
                    />
                  )}
                />
                <FormErrors errors={errors} formControlName="yearFounded" />
              </SelectorWrapper>
            </Col>
            <Col md="4">
              <LabelHeading>{t("University Population Size")}</LabelHeading>
              <SelectorWrapper>
                <Controller
                  control={control}
                  name="noOfEmployees"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <Input
                      placeholder={t("University Population Size")}
                      onChange={onChange}
                      disabled={!isEditable}
                      onBlur={onBlur}
                      type="text"
                      value={value}
                      className={error ? "invalid" : ""}
                      style={{
                        color: "#000",
                        background: "rgb(243, 243, 243)",
                      }}
                    />
                  )}
                />
                <FormErrors errors={errors} formControlName="noOfEmployees" />
              </SelectorWrapper>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
