import React from "react";
import styled from "styled-components";
import { Row as RowNew, Col } from "react-bootstrap";
import { Colors } from "../../utilities/colors";
import Button from "../button/button";
import Divider from "../divider/divider";
import PDF from "../../assets/icons/svg/pdf.svg";
import moment from "moment";
import {
  filePathToFileName,
  downloadPdf,
} from "../../utilities/commonFunctions";
import { TablePagination } from "@mui/base";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import { styled as muiStyled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { logsDateFormat } from "../../utilities/commonFunctions";
import { useTranslation } from "react-i18next";

const SpanText = styled.label`
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: #bcbcbc;
  text-transform: lowercase;
`;

const WrapCell = muiStyled(TableCell)(({ theme }) => ({
  overflowWrap: "anywhere",
}));
const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};

const approvedStatus = ["accepted", "expired", "activate", "deactivate"];

const AcadamicWrapper = styled.div`
  // font-family: General Sans;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  > h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: ${Colors.labelText};
  }
  p {
    margin: 0;
    margin-left: 5px;
    font-weight: normal !important;
    font-size: 16px;
    line-height: 22px;
    color: ${Colors.labelText};
  }
`;
const InternTitle = styled.div`
  // font-family: General Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
`;
const InternDescription = styled.div`
  // font-family: "General Sans";
  font-style: normal;
  font-weight: normal !important;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 3em;
  color: #202020;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > h4 {
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #b3b3b3;
  }
`;
const ListsWrapper = styled.ul`
  // font-family: General Sans;
  margin-bottom: 3em;
  > li {
    padding: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    color: #202020;
    margin-bottom: 10px;
  }
`;

const AttachedDocument = styled.div`
  // font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 33px;
  text-decoration-line: underline;
  color: #d04c8d;
`;

const IconSingleSection = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${(props) => (props.margin ? props.margin : "0px")};
`;

const OtherText = styled.p`
  margin: 0 0 0 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #525252;
`;
const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .MuiTablePaginationUnstyled-spacer {
    display: none;
  }
  & .MuiTablePaginationUnstyled-toolbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }
  & .MuiTablePaginationUnstyled-selectLabel {
    margin: 0;
  }
  & .MuiTablePaginationUnstyled-select {
    padding: 2px;
    border: 1px solid ${grey[200]};
    border-radius: 50px;
    background-color: transparent;
    &:hover {
      background-color: ${grey[50]};
    }
    &:focus {
      outline: 1px solid ${blue[200]};
    }
  }
  & .MuiTablePaginationUnstyled-displayedRows {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }
  & .MuiTablePaginationUnstyled-actions {
    padding: 2px;
    border: 1px solid ${grey[200]};
    border-radius: 50px;
    text-align: center;
  }
  & .MuiTablePaginationUnstyled-actions > button {
    margin: 0 8px;
    border: transparent;
    border-radius: 2px;
    background-color: transparent;
    &:hover {
      background-color: ${grey[50]};
    }
    &:focus {
      outline: 1px solid ${blue[200]};
    }
  }
  `
);

const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }
  tr:last-child > td {
    border-bottom: 1px solid #E0E3E7;
  }
  td,
  th {
    border: 1px solid ${grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${grey[100]};
  }
  `
);

const AcadamicWrapperTwo = styled.div`
  // font-family: General Sans;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  > h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: ${Colors.labelText};
  }
  p {
    margin: 0;
    margin-left: 5px;
    font-weight: normal !important;
    font-size: 16px;
    line-height: 22px;
    color: ${Colors.labelText};
  }
`;
export default function InternShipDetails({
  data,
  setShowApplicants,
  noOfApplicants,
  theArray,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const internshipLog = useSelector(
    (state) => state.internshipLog.internshipLogData
  );
  const unique = [...new Set(data?.documents?.map((item) => item?.doc_type))];
  const { t } = useTranslation();

 

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - theArray?.length) : 0;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function Row(props) {
    const { log } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <tr sx={{ "& > *": { borderBottom: "unset" } }}>
          <td style={{ textAlign: "center" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </td>
          <th component="th" scope="row" style={{ fontWeight: "normal" }}>
            {t(log.change_type)} {/* */}
            <br />
            <SpanText>{log.change_agent}</SpanText>
          </th>
          <td>
            {log.change_timestamp ? logsDateFormat(log.change_timestamp) : "-"}
          </td>
        </tr>
        <tr>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <tr>
                      <TableCell
                        style={{
                          background: "rgb(240, 118, 181)",
                          color: "#fff",
                        }}
                      >
                        {t("Field")}
                      </TableCell>
                      <TableCell
                        style={{
                          background: "rgb(240, 118, 181)",
                          color: "#fff",
                        }}
                      >
                        {t("New Value")}
                      </TableCell>
                      <TableCell
                        style={{
                          background: "rgb(240, 118, 181)",
                          color: "#fff",
                        }}
                      >
                        {t("Old Value")}
                      </TableCell>
                    </tr>
                  </TableHead>
                  <TableBody>
                    {log.change_details.map((changeDetail) => (
                      <tr key={changeDetail.field}>
                        <WrapCell scope="row">{changeDetail.field}</WrapCell>
                        <WrapCell>
                          {changeDetail.new_value === ""
                            ? "-"
                            : changeDetail.new_value}
                        </WrapCell>
                        <WrapCell>
                          {changeDetail.old_value === ""
                            ? "-"
                            : changeDetail.old_value}
                        </WrapCell>
                      </tr>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </tr>
      </React.Fragment>
    );
  }

  return (
    <>
      <ButtonWrapper>
        <Button name={t("View Applicants")} onClick={setShowApplicants} />
        <h4>
          {" "}
          {noOfApplicants > 0
            ? noOfApplicants + t(" Applications")
            : t("No Applications")}
        </h4>
        <row>
          {data && data?.intern_type === "sip" && data?.sip_framework_url ? (
            <a
              href={data?.sip_framework_url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: "1rem" }}
            >
              <Button name={t("View MySIP Framework")} />
            </a>
          ) : (
            ""
          )}
          {data &&
            data?.intern_type === "sip" &&
            approvedStatus.includes(data?.status) &&
            data?.sip_application_url ? (
            <a
              href={data?.sip_application_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button name={t("View LOE Application")} />
            </a>
          ) : (
            ""
          )}
        </row>
        {data && data?.intern_type === "sip" && data?.sip_enrollment_url ? (
          <a
            href={data?.sip_enrollment_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button name={t("Complete MySIP Enrollment")} />
          </a>
        ) : (
          ""
        )}

        
      </ButtonWrapper>
      <Divider bg="#202020" />
      <InternTitle>{t("Internship Description")}</InternTitle>
      <InternDescription dangerouslySetInnerHTML={{ __html: data.description }}>
       
      </InternDescription>
      <InternTitle>{t("Academic Requirements")}</InternTitle>
      <AcadamicWrapperTwo>
        <h5> {t("Scope of Study")}: </h5>
        {data &&
          data?.scopes?.map((x, index) => (
            <>
              <p key={index + x.scope}>
                {x.scope_abbr}
                {index === data.scopes.length - 1 ? "" : ","}
              </p>
            </>
          ))}
      </AcadamicWrapperTwo>
      <AcadamicWrapper>
        <h5> {t("Minimum required CGPA")}: </h5>
        <p> {data && data.req_gpa} </p>
      </AcadamicWrapper>
      <AcadamicWrapper>
        <h5> {t("Minimum education level required")}: </h5>
        <p> {data && data.min_degree} </p>
      </AcadamicWrapper>
      <AcadamicWrapper>
        <h5> {t("Minimum year of study")}: </h5>
        {data && data.grade}
      </AcadamicWrapper>
      <RowNew>
        <Col lg="3" xs="12" className="p-0">
          <AcadamicWrapper>
            <h5 style={{ marginLeft: 10 }}> {t("Skills")} :</h5>
          </AcadamicWrapper>
        </Col>
        <Col lg="8" xs="12" className="p-0">
          <>
            {data &&
              data.skills.map((x, index) => (
                <div key={index + x.scope}>
                  <AcadamicWrapper>
                    <p>
                      {x.skill}
                      {index === data.scopes.length - 1 ? "" : ","}
                    </p>
                  </AcadamicWrapper>
                </div>
              ))}
          </>
        </Col>
      </RowNew>
      <InternTitle>{t("Required Documents")}</InternTitle>
      <ListsWrapper>
        {data &&
          unique.map((x, index) =>
            x !== "All" ? <li key={index + x}>{x}</li> : ""
          )}
      </ListsWrapper>
      <InternTitle>{t("Optional Documents")}</InternTitle>
      <InternDescription>
        {data && data.optional_documents ? data.optional_documents : "N/A"}
      </InternDescription>
      <InternTitle>{t("Location")}</InternTitle>
      {data &&
        data.locations.map((x, index) => (
          <InternDescription key={x.state + index}>
            {x.city}, {x.state}, {x.country}
          </InternDescription>
        ))}
      <InternTitle>{t("Attached Documents")}</InternTitle>
      <InternDescription>
        {data &&
          data.file_paths.map((file, index) => (
            <IconSingleSection key={file.id + index}>
              <img src={PDF} alt="PDF" />
              <OtherText>
                <AttachedDocument
                  onClick={() => downloadPdf(file.file_path)}
                  className="pointer-link"
                >
                  {filePathToFileName(file?.file_path)}
                </AttachedDocument>
              </OtherText>
            </IconSingleSection>
          ))}
      </InternDescription>
      
      <InternTitle style={{ "margin-left": "-3px" }}>
        {t("Reviews")}
      </InternTitle>
      <div
        className="container"
        style={{ "margin-left": "-16px", "margin-bottom": "20px" }}
      >
        <Root sx={{ width: 200, maxWidth: "50%" }}>
          <table aria-label="custom pagination table">
            <thead>
              <tr>
                <th
                  style={{
                    background: "rgb(250, 74, 161)",
                    color: "#fff",
                  }}
                >
                  {t("Comments")}
                </th>
                <th
                  style={{
                    background: "rgb(250, 74, 161)",
                    color: "#fff",
                  }}
                >
                  {t("Created")}
                </th>
              </tr>
            </thead>
            <tbody>
              {theArray?.length > 0
                ? (rowsPerPage > 0 && theArray?.length
                  ? theArray.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                  : theArray
                ).map((row) => (
                  <tr key={row.comment}>
                    <td>{row.comment}</td>
                    <td style={{ width: 200 }} align="right">
                      {moment(new Date(row.created_at)).format(
                        "DD/MM/YYYY hh:mm:ssA"
                      )}
                    </td>
                  </tr>
                ))
                : ""}

              {emptyRows > 0 && (
                <tr style={{ height: 41 * emptyRows }}>
                  <td colSpan={3} />
                </tr>
              )}
            </tbody>
            {theArray?.length > 0 ? (
              <tfoot>
                <tr>
                  <CustomTablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={theArray?.length ?? "0"}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    componentsProps={{
                      select: {
                        "aria-label": "rows per page",
                      },
                      actions: {
                        showFirstButton: true,
                        showLastButton: true,
                      },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </tr>
              </tfoot>
            ) : (
              <div className="container col-lg-12">{t("No Comments")}</div>
            )}
          </table>
        </Root>
      </div>
      
      <InternTitle>{t("Contact")}</InternTitle>
      <InternDescription>
        {data && data.contact_name} <br />
        {data && data.contact_email} <br />
        {data && data.contact_mob}
      </InternDescription>
      <InternTitle style={{ "margin-left": "-3px" }}>{t("Logs")}</InternTitle>
      <div
        className="container"
        style={{ "margin-left": "-16px", "margin-bottom": "20px" }}
      >
        <Root sx={{ width: 200, maxWidth: "50%" }}>
          <table aria-label="custom pagination table">
            <thead>
              <tr>
                <th
                  style={{
                    background: "rgb(250, 74, 161)",
                    color: "#fff",
                  }}
                ></th>
                <th
                  style={{
                    background: "rgb(250, 74, 161)",
                    color: "#fff",
                  }}
                >
                  {t("Change")}
                </th>
                <th
                  style={{
                    background: "rgb(250, 74, 161)",
                    color: "#fff",
                  }}
                >
                  {t("Timestamp")}
                </th>
              </tr>
            </thead>
            <tbody>
              {internshipLog.logs.map((log) => (
                <Row key={log.change_type} log={log} />
              ))}
            </tbody>
          </table>
        </Root>
      </div>
    </>
  );
}
