import { GrClose } from 'react-icons/gr';
import { Modal } from 'react-bootstrap';
import { Row } from "react-bootstrap";
import styled from "styled-components";
import Button from "../../../components/button/button";
import { useState } from "react";
import { createUniversity as createUniversityAPI, updateUniversity as updateUniversityAPI, deleteUniversity as deleteUniversityAPI} from '../../../services/apiCalls';
import { useTranslation } from "react-i18next";

const AddUniversityModalWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 30px;
  margin-right: 30px;
`

function CreateUniversityModal(props) {
    const [name, setName] = useState("")
    const [error, setError] = useState({})
    const { t } = useTranslation();
    
    const createUniversity = async () => {
        if (name === ''){
            setError(prevState => ({
                ...prevState,
                name: "Field name cannot be blank."
            }));
        }else{
            const {data, status} = await createUniversityAPI({Name: name})

            if (status !== 201 && status !== 200){
                var message;
                if (data.message){
                  message = data.message
                } else {
                  message = "Error. Please try again or contact the administrator."
                }
                  setError(prevState => ({
                      ...prevState,
                      name: message
                  }));
            }else{
                props.close()
            }
        }
    }

    const handleChange = (e) => {
        setError(prevState => ({
            ...prevState,
            name: ""
        }));
    }
    
    return (
        <div>
        <style type="text/css">
            {`
    @media (min-width: 576px){
    .modal-dialog {
        max-width: 671px;
        margin: 1.75rem auto;
    }
    }
        `}
    </style>
      <Modal show={props.show} onHide={props.close} centered>
        <div className="post-modal mx-2">
          <div
            className=" d-flex justify-content-end align-items-center pt-3 pe-3 modal_header"
            style={{ cursor: 'pointer' }}
          >
            <GrClose onClick={props.close} />
          </div>
          <div>
            <AddUniversityModalWrapper >
                <Row>
                    <h3>{t("Add University")}</h3>
                </Row>
                <Row>
                    <span>
                        <label className="pe-3" name="university-name"><h6>{t("University Name")}: </h6></label>{"  "}
                        <input 
                            style={{width: "70%"}} 
                            type="text" 
                            name="university-name" 
                            value={name} 
                            onInput={e => setName(e.target.value)}
                            onChange = {handleChange}
                        />
                        <div>
                            {error.name !== '' && <span style={{color: "red"}}>{t(error.name)}</span>}
                        </div>
                    </span>
                </Row>
                <Row>
                    <span>
                        <Button 
                            name={t("Submit")}
                            onClick={createUniversity}
                            style={{
                                marginRight: "10px", 
                                minWidth: "100px",
                                fontSize: "18px",
                                borderRadius: "24px",
                                padding: "8px 8px 8px 8px",
                            }}
                            />
                        <Button 
                            name={t("Cancel")}
                            onClick={props.close}
                            style={{
                                marginRight: "10px", 
                                minWidth: "100px",
                                fontSize: "18px",
                                borderRadius: "24px",
                                padding: "8px 8px 8px 8px",
                            }}
                            />
                    </span>
                </Row>
            </AddUniversityModalWrapper>
          </div>
        </div>
      </Modal>
    </div>
  );
}

function EditUniversityModal(props) {
    const [name, setName] = useState("")
    const [error, setError] = useState({})
    const { t } = useTranslation();
    
    const updateUniversity = async () => {
        if (name === ''){
            setError(prevState => ({
                ...prevState,
                name: "Field name cannot be blank."
            }));
        }else{
            const {data, status} = await updateUniversityAPI(props.university.Id, {Name: name})

            if (status !== 201 && status !== 200){
                var message;
                if (data.message){
                  message = data.message
                } else {
                  message = "Error. Please try again or contact the administrator."
                }
                  setError(prevState => ({
                      ...prevState,
                      name: message
                  }));
              }else{
                props.close()
                props.refresh()
            }
        }
    }

    const handleChange = (e) => {
        setError(prevState => ({
            ...prevState,
            name: ""
        }));
    }
    
    return (
        <div>
        <style type="text/css">
            {`
    @media (min-width: 576px){
    .modal-dialog {
        max-width: 671px;
        margin: 1.75rem auto;
    }
    }
        `}
    </style>
      <Modal show={props.show} onHide={props.close} centered>
        <div className="post-modal mx-2">
          <div
            className=" d-flex justify-content-end align-items-center pt-3 pe-3 modal_header"
            style={{ cursor: 'pointer' }}
          >
            <GrClose onClick={props.close} />
          </div>
          <div>
            <AddUniversityModalWrapper >
                <Row>
                    <h3>{t("Edit")} <b>{props.university.Name}</b> </h3>
                </Row>
                <Row>
                    <span>
                        <label className="pe-3" name="university-name"><h6>{t("New Name")}: </h6></label>{"  "}
                        <input 
                            style={{width: "70%"}} 
                            type="text" 
                            name="university-name" 
                            value={name} 
                            onInput={e => setName(e.target.value)}
                            onChange = {handleChange}
                        />
                        <div>
                            {error.name !== '' && <span style={{color: "red"}}>{t(error.name)}</span>}
                        </div>
                    </span>
                </Row>
                <Row>
                    <span>
                        <Button 
                            name={t("Submit")}
                            onClick={updateUniversity} 
                            style={{
                                marginRight: "10px", 
                                minWidth: "100px",
                                fontSize: "18px",
                                borderRadius: "24px",
                                padding: "8px 8px 8px 8px",
                            }}
                            />
                        <Button 
                            name={t("Cancel")}
                            onClick={props.close}
                            style={{
                                marginRight: "10px", 
                                minWidth: "100px",
                                fontSize: "18px",
                                borderRadius: "24px",
                                padding: "8px 8px 8px 8px",
                            }}
                            />
                    </span>
                </Row>
            </AddUniversityModalWrapper>
          </div>
        </div>
      </Modal>
    </div>
  );
}

function DeleteUniversityModal(props) {
    
    const { t } = useTranslation();

    const deleteUniversity = async () => {
        const {data, status} = await deleteUniversityAPI(props.university.Id)
        props.refresh()
        props.close()
    }

    

    return (
        <div>
        <style type="text/css">
            {`
    @media (min-width: 576px){
    .modal-dialog {
        max-width: 671px;
        margin: 1.75rem auto;
    }
    }
        `}
    </style>
      <Modal show={props.show} onHide={props.close} centered>
        <div className="post-modal mx-2">
          <div
            className=" d-flex justify-content-end align-items-center pt-3 pe-3 modal_header"
            style={{ cursor: 'pointer' }}
          >
            <GrClose onClick={props.close} />
          </div>
          <div>
            <AddUniversityModalWrapper >
                <Row>
                    <h3>{t("Are you sure you want to delete")} <b>{props.university.Name}</b>?</h3>
                    { props.campusCount ? (<h6>{t("Campuses")} : { props.campusCount}</h6>) : (" ") }                    
                </Row>
                <Row>
                    <span>
                        <Button 
                            name={t("Yes")} 
                            onClick={deleteUniversity}
                            style={{
                                marginRight: "10px", 
                                minWidth: "100px",
                                fontSize: "18px",
                                borderRadius: "24px",
                                padding: "8px 8px 8px 8px",
                            }}
                            />
                        <Button 
                            name={t("No")} 
                            onClick={props.close}
                            style={{
                                marginRight: "10px", 
                                minWidth: "100px",
                                fontSize: "18px",
                                borderRadius: "24px",
                                padding: "8px 8px 8px 8px",
                            }}
                            />
                    </span>
                </Row>
            </AddUniversityModalWrapper>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export {CreateUniversityModal, EditUniversityModal, DeleteUniversityModal};

