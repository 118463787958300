import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Adminlogin from "../../assets/adminLogin.png";
import Loader from "../../components/loader/loader";
import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
import IntroSection from "../../components/introSection/introSection";
import Divider from "../../components/divider/divider";
import Button from "../../components/button/button";
import Logo from "../../assets/icons/svg/mynextLogo.svg";
import { Colors } from "../../utilities/colors";
import { authSignin, onTriggerOtp, forceLogout } from "../../services/apiCalls";
import ErrorField from "../../components/errorField/errorField";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "../../components/input/input";
import { useDispatch } from "react-redux";
import { updateAdminData } from "./reducer";
import Cookies from "js-cookie";
import AlertModal from "../../components/alertModal/alertModal";
import { toast } from "react-toastify";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Dropdown} from "react-bootstrap";
import englishFlag from "../../assets/en2.png";
import malayFlag from "../../assets/my2.png";

const LoginPageWrapper = styled.section`
  margin: 50px 90px 60px 0px;
  margin-left: -10px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: none;

  /* @media (max-width: 650px) {
    display: flex;
  } */
`;
const LabelText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;

const SignUpBtn = styled(LabelText)`
  color: ${Colors.primary};
  text-decoration: none;
  font-size: 25px;
  margin-left: 0px;
  cursor: pointer;
`;

const FormWrapper = styled.form`
  margin: 50px 0px;
  max-width: 440px;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;
const Label = styled.label`
  font-weight: 500;
  display: block;
`;
const FormLabel = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const FormInput = styled.input`
  visibility: hidden;
`;
const GroupWrapper = styled.div`
  margin-bottom: 20px;
`;

const Message = styled.div`
  padding-left: 20px;
  font-size: 22px;
  padding-bottom: 5px;
`;

const FlagLogo = styled.div`
  width: 30px;
  height: 30px;
  background: rgb(208, 76, 141);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    height: 32px;
    width: 32px;
  }
`;

const DropdownList = styled.span`
  padding-left: 10px;
`;

const IntroHeadSection = styled.div`
  display: flex;
  float:right;
`;

const initialState = {
  username: "",
  password: "",
};
const schema = yup
  .object({
    username: yup
      .string()
      .email("The e-mail address entered is incorrect")
      .required("E-mail address is required."),
    password: yup.string().required(),
  })
  .required();

export default function LoginPage() {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, },
  } = useForm({
    defaultValues: { ...initialState },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const userDetail = watch();
  const dispatch = useDispatch();

 
  const [show, setShow] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const [fieldErrors,] = useState("");
  const [loading, setLoading] = useState(false);
  const [, setLoginError] = useState("");
  let navigate = useNavigate();
  const { username,  } = userDetail;

  
  const onLogin = (e) => {
    
    onFormSubmission();
  };

  

  const content = () => {
    return (
      <Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Message>{t("User is already logged in some device.")}</Message>
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Message>{t("Do you want to logout ?")}</Message>
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button style={{ width: "25%" }} name={t("Yes")} onClick={onLogout} />
          <Button
            secondary
            color="#FFFFFF"
            name={t("No")}
            style={{
              width: "25%",
              marginLeft: "10px",
            }}
            variant="outlined"
            onClick={closeModal}
          />
        </Grid>
      </Grid>
    );
  };

  const closeModal = () => {
    setShow(false);
  };
  const onLogout = async () => {
    
    const { data } = await forceLogout(
      userDetail.username.toLowerCase().trim()
    );

    if (data.status === 1) {
      setLoading(true);
      setShow(false);
      Cookies.remove("token");
      toast.dismiss();
      onFormSubmission();
    }
  };

  const onFormSubmission = async () => {
    setLoading(true);
    Cookies.remove("token");
    const { data, status } = await authSignin({
      username: userDetail.username.toLowerCase().trim(),
      password: userDetail.password,
    });

    setLoading(false);
    if (status === 200) {
      if (data.status === 1) {
        
        if (userDetail.username) {
          const { data, } = await onTriggerOtp({
            email_address: userDetail.username,
          });
          ValidateUser(data);
        }
      } else {
        setFormErrors();
        setLoginError(data.message);
        if (data.message !== "User is already logged in some device") {
          toast.error(t(data.message), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        if (data.message === "User is already logged in some device") {
          console.log("this is target");
          setShow(true);
        }
      }
    } else {
    }
  };

  const ValidateUser = ({ data, message, status }) => {
    if (status === 1) {
      console.log("->>data", data);
      dispatch(updateAdminData(data));
      navigate("/verify", { state: { name: username } });
    } else if (status === 0) {
      setFormErrors();
      setLoginError(message);
    }
  };

  

  const responseGoogle = (response) => {};

  const { t, i18n } = useTranslation();
  const changeLanguageHandler = (lang) => {
    const languageValue = lang
    i18n.changeLanguage(languageValue);
  }

  return (
    <UserCreationLayout coverImg={Adminlogin}>
      <LoginPageWrapper>
        
        <IntroHeadSection>
          <Dropdown className="dropdown__toggle loginDropdown">
            <Dropdown.Toggle variant="">
              <FlagLogo>
                <img
                  src={
                    i18n.language === "my" ? malayFlag : englishFlag
                  }
                  alt="profile-logo"
                />
              </FlagLogo>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#" onClick={() => changeLanguageHandler("en")}>
                <img
                  width={32}
                  height={32}
                  src={englishFlag}
                  alt="profile-logo"
                />
                <DropdownList>{"English"}</DropdownList>
              </Dropdown.Item>
              <Dropdown.Item href="#" onClick={() => changeLanguageHandler("my")}>
                <img
                  width={30}
                  height={30}
                  src={malayFlag}
                  alt="profile-logo"
                />
                <DropdownList>{"Malay"}</DropdownList>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </IntroHeadSection>
       
        <HeaderWrapper>
          <img src={Logo} alt="logo my next" />
          <SignUpBtn> ADMIN </SignUpBtn>

        </HeaderWrapper>
        <IntroSection name="login" />
        <Divider name="login" width="450px" />
        <ReactTooltip
          id="emailTip"
          globalEventOff="focusin"
          place="top"
          effect="solid"
        >
          {t("E-mail address that can be used to contact you")}{" "}
        </ReactTooltip>
        <ReactTooltip
          id="passwordTip"
          globalEventOff="focusin"
          place="top"
          effect="solid"
        >
          {t("Enter Password")}{" "}
        </ReactTooltip>
        
        <FormWrapper onSubmit={handleSubmit(onLogin)}>
          <GroupWrapper>
            <FormLabel>{t("Email address*")}</FormLabel>
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <Input
                  disabled={false}
                  autoFocus
                  placeholder={t("Enter email address")}
                  bg={Colors.blogTextBox}
                  {...field}
                />
              )}
            />
            {errors.username && <ErrorField err={t(errors.username.message)} />}
          </GroupWrapper>
          <GroupWrapper>
            <FormLabel>{t("Password*")}</FormLabel>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Input
                  placeholder={t("Password")}
                  disabled={false}
                  type="password"
                  bg={Colors.blogTextBox}
                  {...field}
                />
              )}
            />
            
            {errors.password && <ErrorField err={t(errors.password.message)} />}
          </GroupWrapper>
          {formErrors && <ErrorField err={t(formErrors)} />}
          
          {loading ? (
            <Loader />
          ) : (
            <Button
              onClick={handleSubmit(onLogin)}
              width="100%"
              disabled={fieldErrors}
              name={t("Sign in")}
            />
          )}

          <FormInput type="submit" />
        </FormWrapper>
      </LoginPageWrapper>
      <AlertModal show={show} close={closeModal} content={content()} />
     
    </UserCreationLayout>
  );
}
