import React from 'react';
import MainLayout from '../../../../layouts/mainLayout/mainlayout';
import DoghnutChart from '../../../../components/analyticalDashboard/charts/doughnutChart.js';
import Button from '../../../../components/button/button';
import { Link, useParams } from 'react-router-dom';
import { onApiCall } from '../../../../services/CommonApi';
import { withTranslation } from 'react-i18next';

class FutureOfWork extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      totalsData: {},
      showCognitive: false,
      showInterpersonal: false,
      showSelfLeadership: false,
      showDigital: false,
    };
  }

  getData() {
    onApiCall({
      url: `/api/dashboard/analytical/century-skills/`,
      method: 'GET',
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ data: res.data?.data?.results });
      }
    });
  }

  getDataTotals() {
    onApiCall({
      url: `/api/dashboard/analytical/century-skills/totals/`,
      method: 'GET',
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ totalsData: res.data?.data?.results });
      }
    });
  }

  componentDidMount() {
    this.getData();
    this.getDataTotals();
  }

  componentWillReceiveProps(newProps) {
    this.getData();
    this.getDataTotals();
  }

  formatChartData(data) {
    const { t } = this.props;
    return {
      labels: [t('Low'), t('Moderate'), t('High')],
      datasets: [
        {
          data: [data.results.low, data.results.moderate, data.results.high],
          backgroundColor: ['#366eb6', '#7db8ff', '#b69036'],
        },
      ],
    };
  }

  formatChartDataTotals(data) {
    const { t } = this.props;
    return {
      labels: [t('Cognitive'), t('Interpersonal'), t('Self Leadership'), t('Digital')],
      datasets: [
        {
          data: [
            data['Cognitive'],
            data['Interpersonal'],
            data['Self Leadership'],
            data['Digital'],
          ],
          backgroundColor: ['#366eb6', '#7db8ff', '#36b6b4', '#b69036'],
        },
      ],
    };
  }

  render() {
    const { t } = this.props;
    return (
      <MainLayout>
        <div
          className="d-flex mb-5"
          style={{ fontSize: '1.1rem', fontWeight: '400' }}
        >
          <Link
            to="/analyticaldashboard"
            style={{ textDecoration: 'none', color: '#ACACAC' }}
          >
            {t("Analytical Dashboard")}
          </Link>

          <div className="mx-2" style={{ color: '#ACACAC' }}>
            {' '}
            >{' '}
          </div>

          <span style={{ textDecoration: 'none', color: '#D04C8D' }}>
          {t("The Future of Work")}
          </span>
        </div>

        <div className="row">
          <div className="col-12 mb-4 col-lg-6">
            <div
              className="p-4 text-center bg-white rounded shadow"
              style={{ minHeight: '100%' }}
            >
              <h3 className="mb-3" style={{ fontWeight: '300' }}>
              {t("Top Score Percentage of Each Domain")}
              </h3>

              <DoghnutChart
                style={{ width: '100%', height: '500px' }}
                data={this.formatChartDataTotals(this.state.totalsData)}
              />

              <div className="mb-5 mt-5 text-start">
                <h5
                  className="text-secondary"
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    this.setState({ showCognitive: !this.state.showCognitive })
                  }
                >
                  {t("COGNITIVE")}
                </h5>

                {this.state.showCognitive ? (
                  <div>
                    <p className="text-secondary">
                      {t("The Cognitive domain relates to the ability to obtain relevant information and use critical thinking to solve problems, make clear plans and manage one’s time and communicate effectively.")}
                    </p>

                    <p className="text-secondary">
                    {t("It also relates to the ability to apply mental flexibility  – creativity, imagination, seeing things from different perspectives and learning – to adapt to change and meet one’s goals.")}
                    </p>
                  </div>
                ) : null}
              </div>

              <div className="mb-5 text-start">
                <h5
                  className="text-secondary"
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    this.setState({
                      showInterpersonal: !this.state.showInterpersonal,
                    })
                  }
                >
                  {t("INTERPERSONAL")}
                </h5>

                {this.state.showInterpersonal ? (
                  <div>
                    <p className="text-secondary">
                    {t("The Interpersonal domain describes the ability to relate to others and build relationships with colleagues and clients, enhancing motivation and engagement so that others work together towards inspiring goals.")}
                    </p>

                    <p className="text-secondary">
                    {t("It also relates to the skills required for effective teamwork including listening to, coaching, collaborating alongside and problem solving with, a diverse group of people.")}
                    </p>
                  </div>
                ) : null}
              </div>

              <div className="mb-5 text-start">
                <h5
                  className="text-secondary"
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    this.setState({
                      showSelfLeadership: !this.state.showSelfLeadership,
                    })
                  }
                >
                  {t("SELF-LEADERSHIP")}
                </h5>

                {this.state.showSelfLeadership ? (
                  <div>
                    <p className="text-secondary">
                    {t("This domain relates to the ability to use self-awareness, self-management including looking after one’s wellbeing and building stress resilience, and the setting and meeting important goals.")}
                    </p>

                    <p className="text-secondary">
                    {t("This domain also refers to the skills required for entrepreneurship including the ability to take appropriate risks, be innovate and infuse one’s work with energy, passion and optimism.")}
                    </p>
                  </div>
                ) : null}
              </div>

              <div className="text-start">
                <h5
                  className="text-secondary"
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    this.setState({ showDigital: !this.state.showDigital })
                  }
                >
                  {t("DIGITAL")}
                </h5>

                {this.state.showDigital ? (
                  <div>
                    <p className="text-secondary">
                    {t("This domain relates to the ability to use digital technologies in the course of one’s work to gather information, communicate with others and enhance insights into relevant trends.")}
                    </p>

                    <p className="text-secondary">
                    {t("A key part of this domain refers to the ability to adapt to new technologies as the pace of change will be accelerated in the future of work.")}
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-12 mb-4 col-lg-6 mb-lg-0">
            <div className="row">
              {Object.keys(this.state.data).map((key, index) => (
                <div className="col-12 col-lg-6 mb-4" key={index}>
                  <div
                    className="p-4 text-center bg-white rounded shadow"
                    style={{ minHeight: '100%' }}
                  >
                    <h3 className="mb-3" style={{ fontWeight: '300' }}>
                      {t(key)}
                    </h3>

                    <DoghnutChart
                      data={this.formatChartData(this.state.data[key])}
                    />

                    <p
                      className="text-secondary mt-4"
                      style={{ fontSize: '0.9rem' }}
                    >
                      {t("Percentage of talents who have scored low, moderate or high in the ")}{t(key)}{' '}
                      {this.props.params.id ? t('value') : t('domain')}
                    </p>

                    {!this.props.params.id && (
                      <Link
                        to={
                          '/analyticaldashboard/21-century-skills/' +
                          this.state.data[key].id +
                          '?name=' +
                          key
                        }
                      >
                        <Button name={t("Competencies Analysis")} />
                      </Link>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

const FutureOfWorkComponent = withTranslation()(FutureOfWork)

export default  function FutureOfWorkWrapper (props) {
  const params = useParams();

  return <FutureOfWorkComponent params={params} {...props} />;
}
