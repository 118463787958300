import axios from "axios";
import EventBus from "../utilities/eventBus";
import cookiesService from "./cookies.service";
const httpDashboardClient = axios.create({
  baseURL: process.env.REACT_APP_ASSESMENT_URL,
  
  timeout: 36000,
});


httpDashboardClient.interceptors.request.use(
  function (config) {
    
    const token = cookiesService.getItem("token");
    console.log("Token", token);
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  function (error) {
    
    return Promise.reject(error);
  }
);


httpDashboardClient.interceptors.response.use(
  function (response) {
    
    return response;
  },
  function (error) {
    
    if (error.response) {
      
      console.log(error.response.data);
      if (error.response.status === 401) {
        cookiesService.removeItem("token");
        EventBus.dispatch("invalidToken");
      }
      
    } else if (error.request) {
      
    } else {
      
      console.log("Error", error.message);
    }
    

    return Promise.reject(error);
  }
);

export default httpDashboardClient;
