import { useEffect, useState, useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import Input from "../../../components/input/input";
import Mainlayout from "../../../layouts/mainLayout/mainlayout";
import { getFacultyList} from "../../../services/apiCalls";
import Button from "../../../components/button/button";
import { CreateFacultyModal, EditFacultyModal, DeleteFacultyModal }from "../modals/facultyModals";
import { facultyHasStudyProgram as facultyHasStudyProgramAPI } from "../../../services/apiCalls";
import { FacultyListMenu } from "../components/listMenu";
import { useTranslation } from "react-i18next";

import {
  CardPadded39,
  AddUserWrapper,
  DataTableWrapper,
} from "../../analyticalDashboardPage/commonStyles";

import Loader from "../../../components/loader/loader";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

const FacultyRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.4px solid rgba(32, 32, 32, 0.22);
  padding: 10px 10px;
  .left-content {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 50px;
      width: 50px;
      margin-right: 20px;
    }
    .name {
      font-family: "General Sans", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #202020;
    }
  }
  .right-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const PageTitle = styled.h1`
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;
`;

const Subtext = styled.h5`
  margin-bottom: 0;

`


export default function FacultyMasterData() {

  const [faculties, setFaculties] = useState([]);
  const [addFacultyModalShow, setAddFacultyModalShow] = useState(false)
  const [editFacultyModalShow, setEditFacultyModalShow] = useState(false)
  const [deleteFacultyModalShow, setDeleteFacultyModalShow] = useState(false)
  const [faculty, setFaculty] = useState("")

  const [dataCount, setDataCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);

  const { t } = useTranslation();
  const [, setLoading] = useState(true);
  const [showOne, setShowNewOne] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [studyProgramCount, setStudyProgramCount] = useState(0)

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  var page = params.get("page_number")
  
  if(!page){
    page = 1
  } 
  const [pageNumber, setPageNumber] = useState(page);

  useEffect(() => {
   
    
    setPageCount(Math.ceil(dataCount / itemsPerPage));
  }, [dataCount, itemOffset, itemsPerPage]);

  var controller = null;
 

  const getData = useCallback (async (pageNumber) => {
    const pageSize = 10
    // eslint-disable-next-line react-hooks/exhaustive-deps
    controller = new AbortController();
    const {data,} = await getFacultyList({
      page: pageNumber,
      page_size: pageSize,
      search: searchField,
    }, controller.signal);

    if (data !== "api error"){
      setFaculties(data.results);
      setDataCount(data.count);
      setItemsPerPage(pageSize)
      setLoading(false);
      setShowNewOne(true);
    }
  }, [searchField])

  useEffect (() => {
    getData(pageNumber);
  }, [pageNumber, getData]);

  useEffect(() => {
    setPageNumber(1)
    getData(1)
  }, [searchField, getData]);

  const refreshFacultyList = async () => {
    getData(pageNumber)
  }

  const addFacultyToggle = () => {
    setAddFacultyModalShow(true);
  }

  const editFacultyToggle = (e, faculty) => {
    setEditFacultyModalShow(true)
    setFaculty(faculty)
  }

  const deleteFacultyToggle = async (e, faculty) => {
    const {data,} = await facultyHasStudyProgramAPI(faculty.Id)
    setDeleteFacultyModalShow(true)
    setFaculty(faculty)
    setStudyProgramCount(data.count)
  }

  const onCloseModal = () => {
    setAddFacultyModalShow(false)
    setEditFacultyModalShow(false)
    setDeleteFacultyModalShow(false)
  }

  const handleSearchChange = (e) => {
    if (controller){
      controller.abort()
    }
    setSearchField(e.target.value)
};

  
  const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % faculties.length;
      setPageNumber(event.selected + 1)
      setItemOffset(newOffset);
    };

  return (
    <>
      <Mainlayout>
        <div
            className="d-flex mb-5"
            style={{ fontSize: "1.1rem", fontWeight: "400" }}
          >
            <Link
              to="/master-data"
              style={{ textDecoration: "none", color: "#ACACAC" }}
            >
              {t("Master Data")}
            </Link>

            <div className='mx-2' style={{ color: '#ACACAC' }}> {">"} </div>

            <span style={{ textDecoration: "none", color: "#D04C8D" }}>
            {t("Faculties")}
            </span>
          </div>

      {addFacultyModalShow && (
        <CreateFacultyModal
          
          show={addFacultyModalShow}
          close={onCloseModal}
        />
      )}

      {editFacultyModalShow && (
        <EditFacultyModal
         
          show={editFacultyModalShow}
          close={onCloseModal}
          faculty = {faculty}
          refresh = {refreshFacultyList}
        />
      )}

      {deleteFacultyModalShow && (
        <DeleteFacultyModal
          close={onCloseModal}
          faculty = {faculty}
          refresh = {refreshFacultyList}
          studyProgramCount = {studyProgramCount}
          show={deleteFacultyModalShow}
        />
      )}

        {showOne ? (
          <div style={{ width: "100% !important" }}>
            <PageTitle>{t("Faculties")}</PageTitle>
            <Row
              xs={1}
              md={3}
              lg={4}
              style={{ minHeight: "102px", alignItems: "center" }}
            >
              <Col lg="12">
                <span className="left-content">
                  <Input
                  width="60%"
                    color="#fff"
                    placeholder={t("Search Keywords for Faculty Name")}
                    onChange={handleSearchChange}
                  />
                </span>

                <span className="right-content">
                  <Button 
                    onClick={addFacultyToggle}
                    
                    name={t("+ Add Faculty")}
                    type="submit"
                    style={{ marginRight: "30px" }}
                  />
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <AddUserWrapper>
                  
                </AddUserWrapper>
              </Col>
            </Row>
            <Row>
              <Col>
                <CardPadded39>
                  <DataTableWrapper>
                    <Row>
                      {faculties.map((faculty, index) => (
                        <FacultyRow key={index}>
                           <div className="left-content">
                            <Container>
                              <Row>
                                <Subtext>
                                  {faculty.Name}
                                </Subtext>
                              </Row>
                              <Row>
                                <div>
                                  {faculty.university_name} {"- "} 
                                  {faculty.campus_name}
                                </div>
                              </Row>
                            </Container>
                            </div>
                          <div className="right-content"> 
                            <FacultyListMenu 
                                faculty={faculty}
                                edit={editFacultyToggle}
                                delete={deleteFacultyToggle}
                              />                                                
                          </div>
                        </FacultyRow>
                      ))}
                    </Row>
                      <span id="container">
                            <ReactPaginate
                              breakLabel={null}
                              nextLabel={t("next >")}
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={3}
                              marginPagesDisplayed={0}
                              pageCount={pageCount}
                              previousLabel={t("< previous")}
                              renderOnZeroPageCount={null}
                              itemsPerPage={itemsPerPage}
                              dataCount = {dataCount}   
                              setItems = {setFaculties}    
                              items = {faculties}
                              forcePage = {pageNumber - 1}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination"
                              activeClassName="active"
                            />
                      </span>
                  </DataTableWrapper>
                </CardPadded39>
              </Col>
            </Row>
          </div>
        ) : (
          <Loader />
        )}
      </Mainlayout>
    </>
  );
}
