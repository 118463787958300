
import { useParams } from 'react-router-dom';
import { CardPadded39 } from '../analyticalDashboardPage/commonStyles';
import UniversityLocationSummary from './universityLocationSummaryDetail/universityLocationSummary';
import UniversityHeader from './universityHeaderDetail/universityHeader';
import UniversityUsersSummary from './universityUserSummaryDetail/universityUserSummary';
import UniversityLoader from '../../components/universityLoader/universityLoader';
import Mainlayout from '../../layouts/mainLayout/mainlayout';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export default function UniversityPage() {
  const params = useParams();
  const id = params && params.id;
  console.log(id);
  const { t, } = useTranslation();

  return (
    <Mainlayout>
      <div role="presentation">
        <div
          className="d-flex mb-5"
          style={{ fontSize: '1.1rem', fontWeight: '400', marginLeft: '15px' }}
        >
          <Link
            to="/universities"
            style={{ textDecoration: 'none', color: '#ACACAC' }}
          >
            {t("Universities")}
          </Link>
          <div className="mx-2" style={{ color: '#ACACAC' }}>
            >
          </div>
          <span style={{ textDecoration: 'none', color: '#D04C8D' }}>
            {t("University Details")}
          </span>
        </div>
      </div>

      <div>
        {id ? (
          <CardPadded39>
            <UniversityHeader />
            <UniversityUsersSummary />
            <UniversityLocationSummary />
          </CardPadded39>
        ) : (
          <UniversityLoader />
        )}
      </div>
    </Mainlayout>
  );
}
