import React from 'react';
import MainLayout from '../../../layouts/mainLayout/mainlayout';
import HorizontalBarChart from '../../../components/analyticalDashboard/charts/horizontalBarChart.js';
import { Link } from "react-router-dom";
import { onApiCall } from '../../../services/CommonApi';
import { withTranslation } from "react-i18next";

class Education extends React.Component {

  constructor(props) {

    super(props);
    this.state = { usersByInstitution: {}, usersByScope: {}, usersByQualification: {}, usersByStudyYear: {} }

  }

  componentDidMount() {
    onApiCall({
      url: `/api/dashboard/analytical/demographics/education/usersByInstitution/`,
      method: 'GET'
    }).then(res => {
      if (res.status === 200) {
        this.setState({ usersByInstitution: res.data.data.results });
      }
    })
    onApiCall({
      url: `/api/dashboard/analytical/demographics/education/usersByScope/`,
      method: 'GET'
    }).then(res => {
      if (res.status === 200) {
        this.setState({ usersByScope: res.data.data.results });
      }
    })

    onApiCall({
      url: `/api/dashboard/analytical/demographics/userEducation/`,
      method: 'GET'
    }).then(res => {
      if (res.status === 200) {
        this.setState({ usersByQualification: res.data.data.results });
      }
    })
    onApiCall({
      url: `/api/dashboard/analytical/demographics/education/usersByStudyYear/`,
      method: 'GET'
    }).then(res => {
      if (res.status === 200) {
        this.setState({ usersByStudyYear: res.data.data.results });
      }
    })
  }


  format(item) {

    const data = { "labels": [], "datasets": [{ "data": [], "backgroundColor": ["#4bc2c1"] }] }

    for (const [, value] of Object.entries(item)) {

      data.labels.push(value['_id'][0])

      data.datasets[0].data.push(value['total'])

    }

    return data

  }

  formatInstitution(item) {

    const data = { "labels": [], "datasets": [{ "data": [], "backgroundColor": ["#4bc2c1"] }] }

    for (const [, value] of Object.entries(item)) {

      data.labels.push(value['_id'][0])

      data.datasets[0].data.push(value['total'])

    }

    var n = item.length;
    data.labels.splice(1,n-20)
    data.datasets[0].data.splice(1,n-20)



    return data

  }


  render() {
    const { t } = this.props;
    
    return (
      <MainLayout>

        <div className="d-flex mb-5" style={{ fontSize: '1.1rem', fontWeight: '400' }}>

          <Link to="/analyticaldashboard" style={{ textDecoration: 'none', color: '#ACACAC' }}>{t("Analytical Dashboard")}</Link>

          <div className='mx-2' style={{ color: '#ACACAC' }}> > </div>

          <Link to="/analyticaldashboard/demographics" style={{ textDecoration: 'none', color: '#ACACAC' }}>{t("Demographics")}</Link>

          <div className='mx-2' style={{ color: '#ACACAC' }}> > </div>

          <span style={{ textDecoration: 'none', color: '#D04C8D' }}>{t("Education")}</span>

        </div>

        <div className='row'>

          <div className='col-12 col-lg-6 mb-4'>

            <div className='p-4 text-center bg-white rounded shadow' style={{ minHeight: '100%' }}>

              <h3 className='mb-3' style={{ fontWeight: '300' }}>{t("TOTAL USERS BY INSTITUTION (TOP 20)")}</h3>

              <HorizontalBarChart data={this.formatInstitution(this.state.usersByInstitution)} />

            </div>

          </div>

          <div className='col-12 col-lg-6 mb-4'>

            <div className='p-4 text-center bg-white rounded shadow' style={{ minHeight: '100%' }}>

              <h3 className='mb-3' style={{ fontWeight: '300' }}>{t("SCOPE OF STUDY (TOP 20)")}</h3>

              <HorizontalBarChart data={this.formatInstitution(this.state.usersByScope)} />

            </div>

          </div>

          <div className='col-12 col-lg-6 mb-4'>

            <div className='p-4 text-center bg-white rounded shadow' style={{ minHeight: '100%' }}>

              <h3 className='mb-3' style={{ fontWeight: '300' }}>{t("ACADEMIC QUALIFICATION")}</h3>

              <HorizontalBarChart data={this.format(this.state.usersByQualification)} />

            </div>

          </div>

          <div className='col-12 col-lg-6 mb-4'>

            <div className='p-4 text-center bg-white rounded shadow' style={{ minHeight: '100%' }}>

              <h3 className='mb-3' style={{ fontWeight: '300' }}>{t("YEAR OF STUDY")}</h3>

              <HorizontalBarChart data={this.format(this.state.usersByStudyYear)} />

            </div>

          </div>

        </div>
      </MainLayout>
    );

  }

}

export default withTranslation()(Education);
