import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import styled from "styled-components";
import { Colors } from '../../utilities/colors';
import { useSelector } from 'react-redux';
import { logsDateFormat } from "../../utilities/commonFunctions";
import { useTranslation } from "react-i18next";


const FlexWrapper = styled.div`
  display: flex;
`;
const SectionWrapper = styled.div`
  background: #fdfdfd;
  border: 1px solid rgba(228, 228, 228, 0.26);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 25px;
  margin: 30px 0;
`;
const TableWrapper = styled.div`
  margin: 20px 0px;
`;
const HeaderSection = styled(FlexWrapper)`
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.3px solid ${Colors.labelText};
  padding-bottom: 10px;
  > h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    
    margin: 0;
  }
`;
const TableData = styled.td`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 10px;
  color: #525252;
  text-transform: capitalize;
  text-align: center;
`;
const TableDataLeft = styled(TableData)`
  text-align: left;
`;
const SpanText = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #bcbcbc;
  text-transform:lowercase ;
`;

function Row(props) {
  const { log } = props;
  const [open, setOpen] = React.useState(false);
  const { t, } = useTranslation();

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableData style={{ textAlign: "center" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableData>
        <TableDataLeft component="th" scope="row" >
          {t(log.change_type)} {/* */}
          <br />
          <SpanText >{log.change_agent}</SpanText>
        </TableDataLeft>
        <TableData >{log.change_timestamp ? logsDateFormat(log.change_timestamp ):'-'}</TableData>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 5 }}>
            <Typography variant="h6" gutterBottom component="div">
                  {t("History")}
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>{t("Field")}</strong></TableCell>
                      <TableCell align="center"><strong>{t("New Value")}</strong></TableCell>
                      <TableCell align="center"><strong>{t("Old Value")}</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {log.change_details.map((changeDetail) => (
                      <TableRow key={changeDetail.field}>
                        <TableCell component="th" scope="row" >
                            {changeDetail.field}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                            {changeDetail.new_value === ""
                             ? '-'
                             : changeDetail.new_value}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                            {changeDetail.old_value == null
                             ? '-'
                             : changeDetail.old_value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CompanyUserTimestamp({ props }) {
  const companyUserLog = useSelector((state) => state.companyUserLog.companyUserLogData);
  const { t, } = useTranslation();

  return (
    <SectionWrapper>
      <HeaderSection >
        <h3>{t("Logs")}</h3>
      </HeaderSection>
      <TableWrapper>
        <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center"><strong>{t("Change")}</strong></TableCell>
              <TableCell align="center"><strong>{t("Timestamp")}</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companyUserLog.logs.map((log) => (
              <Row key={log.change_type} log={log} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </TableWrapper>
      
    </SectionWrapper>
  );
}