export const Colors = {
  primary: '#D44B9C',
  greyText: '#9d9d9d',
  labelText: '#202020',
  inputBg: '#FFFFFF',
  inputFieldBg:'#F3F3F3',
  inputText: '#bcbcbc',
  inputHighlightText: '#DA4B9D',
  light: '#fff',
  pink: '#D04C8D',
  darkBlue: '#504F8C',
  black: '#000000',
  lightGray: '#ACACAC',
  visiblity: '#8F8F8F',
  blogIconColor: '#666666',
  expiredDateColor: '#504F8C',
  vacencyColor: '#525252',
  statusGreen: '#00FF47',
  createdBy: '#656565',
  statusred: '#FF0000',
  secondaryColor: '#E5E5E5',
  primaryText: '#D04C8D',
  blogTextBox: '#F3F3F3',
  verifyCode: ' #696F79',
};
