import { useState, useEffect } from "react";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import Button from "../../components/button/button";
import Divider from "../../components/divider/divider";
import Card from "../../components/card/cardImage";
import FirstIcon from "../../assets/DemoIcon.png";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/loader";
import { useTranslation } from "react-i18next";

import { ReactComponent as AcceptedIcon } from "../../assets/icons/svg/acceptedIcon.svg";
import { ReactComponent as PendingIcon } from "../../assets/icons/svg/pendingIcon.svg";
import { ReactComponent as Expired } from "../../assets/icons/svg/expiredIcon.svg";
import { ReactComponent as Trash } from "../../assets/icons/svg/trash.svg";
import SubModal from './viewDetails/reviewModal';

const LabelText = styled.label`
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;
`;


const LightText = styled(LabelText)`
  color: ${Colors.black};
  margin-left: 0vh;
  font-size: 28px;
`;


const NoData = styled.div`
  font-size: 20px;
  width: 100%;
  background: ${Colors.secondaryColor};
  color: black;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;
const ViewButton = styled.button`
  text-decoration: underline;
  border: none;
  font-weight: 600;
  font-size: 14px;
  color: #d04c8d;
  background: none;
  cursor: pointer;
  @media (max-width: 500px){
    position:absolute;
    right:20px;
    margin-top:-60px;
  }
`;

const RightTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PendingRight = styled.span`
  display: flex;
  justify-content: space-around;
  gap: 20px;
  @media (max-width: 767px) {
    gap: 10px;
    width: auto;
    justify-content: unset;
    margin-right:20px;
  }
`;

const SetOne = styled.div`
  display: flex;
  width: 10%;
  justify-content: space-between;
  padding-right: 10px;
  @media (max-width: 500px) {
    width: auto;
  }
`;
const PendingSetOne = styled.div`
  display: flex;
  width: 13%;
  justify-content: space-between;
  padding-right: 10px;
  @media (max-width: 500px) {
    width: auto;
  }
`;
const PendingLeft = styled.div`
  display: flex;
  width: 70%;
  cursor: pointer;
  @media (max-width: 500px) {
    width: auto;
    margin-top: 10px;
  }
`;
const DividerWrapper = styled.div`
  // margin-left: 10vh;
  // margin-right: 10vh;
`;
const LeftTag = styled.div`
  display: flex;
  width: 90%;
  @media (max-width: 500px) {
    width: auto;
  }
`;

const AcceptedDiv = styled.div`
  margin: 0 5vh;
  overflow-y: auto;
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid #dcdcdc;
  justify-content: space-between;
  @media (max-width: 800px) {
    display: flex;
    gap: 30px;
    /* flex-direction:column; */
  }
`;
const PendingDiv = styled.div`
  margin: 0 5vh;
  overflow-y: auto;
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid #dcdcdc;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    flex-wrap:wrap;
  }
`;

const StatusIcon = styled.div`
  align-self: center;
  margin-right: 5px;
`;
const Bold = styled.div`
  font-weight: 600;
  padding-left: 3px;
`;

const ComapanyImage = styled.img`
  width: 45px;
  height: 80px;
  align-self: center;
  padding: 18px 0px;
  border-radius: 50%;
`;

const CompanyName = styled.span`
  align-self: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  width: 50%;
  color: #202020;
  padding-left: 10px;
  cursor: pointer;
  @media (max-width: 500px) {
    width: 100%;
  }
`;
const CreatedBy = styled.span`
  align-self: center;
  color: ${Colors.createdBy};
  font-size: 14px;
  font-style: italic;
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  color: #656565;
  width: 45%;
  @media (max-width: 500px) {
    width: 100%;
  }
`;


const StatusFilterScreen = styled.div``;


const TextDivision = styled.div`
  align-self: center;
  display: flex;
  width: 85%;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const AcceptTextDivision = styled.div`
  align-self: center;
  display: flex;
  width: 80%;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const handleClick = () => {
  return(<div><h1>Welcome</h1></div>)
}

export default function Pendingpage(props) {
  const [pendingArray, setPendingArray] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    
    setPendingArray(props.value);
  }, [props]);
  let navigate = useNavigate();
  
  const onNavigate = (id, code) => {
    navigate({ pathname: "/company-detail", search: `?code=${code}&id=${id}` });
  };
  const { t, } = useTranslation();
  return (
    <StatusFilterScreen>
      <DividerWrapper>
        <Divider />
      </DividerWrapper>
      <div>
        <LightText>
          {props.name === "pending"
            ? t("Pending Requests")
            : t(props.title)
          }
        </LightText>
      </div>{" "}
      <br />
      {props.loading ? (
        <Loader />
      ) : (
        <>
          {props.name === "pending" || props.name === "review" ? (
            <>
              {pendingArray.length > 0 ? (
                <Card size="100" left="0">
                  {pendingArray &&
                    pendingArray.length > 0 &&
                    pendingArray.map((a) => {
                      return (
                        <PendingDiv>
                          <PendingLeft>
                            <PendingSetOne>
                              <StatusIcon>
                                
                                <PendingIcon />
                              </StatusIcon>
                              <ComapanyImage
                                src={
                                  a.logo_path
                                    ? a.logo_path
                                    : FirstIcon
                                }
                              />
                            </PendingSetOne>

                            <TextDivision>
                              <CompanyName>{a.name}</CompanyName>
                              &nbsp;&nbsp;
                              {a.user_data === "N/A" ? (
                                <CreatedBy>&nbsp;N/A</CreatedBy>
                              ) : (
                                <CreatedBy>
                                  <></>
                                  {t("Created by")} <Bold>{a.user_data.substring(0,12)}...</Bold>
                                </CreatedBy>
                              )}
                            </TextDivision>
                          </PendingLeft>
                          <PendingRight>
                            <Button
                              width="120px"
                              name={t("Approve")}
                              onClick={(e) => {
                                props.handleApprove(
                                  e,
                                  a.code,
                                  "accepted",
                                  a.update_time,
                                  a.id,
                                  a.comments
                                );
                              }}
                            />
                            <Button
                              width="200px"
                              name={t("Send for Review")}
                              color="#FFFFFF"
                              onClick={()=>setOpenModal(true)}
                            />
                            <SubModal open={openModal} onClose={()=>setOpenModal(false)} /> 
                            <div>
                            <Button
                              width="120px"
                              name={t("Deny")}
                              color="#FFFFFF"
                              onClick={(e) => {
                                props.handleApprove(
                                  e,
                                  a.code,
                                  "rejected",
                                  a.update_time,
                                  a.id,
                                  a.comments
                                );
                              }}
                            />
                            {a.status === "rejected" ?
                              t("Denied") : ""}
                              </div>
                            <ViewButton
                              onClick={() => onNavigate(a.id, a.code)}
                            >
                              {t("View")}
                            </ViewButton>
                          </PendingRight>
                        </PendingDiv>
                      );
                    })}
                </Card>
              ) : (
                <NoData>
                  <p className="noDataFound">{t("No data found")}</p>
                </NoData>
              )}
            </>
          ) : null}
          {props.name === "accepted" ? (
            <>
              {pendingArray.length > 0 ? (
                <Card size="100" left="0">
                  {pendingArray &&
                    pendingArray.length > 0 &&
                    pendingArray.map((a) => {
                      return (
                        <AcceptedDiv >
                          <LeftTag>
                            <SetOne>
                              <StatusIcon>
                                <AcceptedIcon />
                              </StatusIcon>
                              <ComapanyImage
                                src={
                                  a.logo_path
                                    ? a.logo_path
                                    : FirstIcon
                                }
                              />
                            </SetOne>
                            <AcceptTextDivision>
                              <CompanyName>{a.name}</CompanyName>
                              &nbsp;&nbsp;
                              <CreatedBy>
                                {t("Created by")} <Bold>&nbsp;{a.user_data.substring(0,12)}...</Bold>
                              </CreatedBy>
                            </AcceptTextDivision>
                          </LeftTag>
                          <RightTag>
                            {a.status === "rejected" ?
                              t("Denied") : ""}
                            <ViewButton
                              onClick={() => onNavigate(a.id, a.code)}
                            >
                              {t("View")}
                            </ViewButton>
                            
                          </RightTag>
                        </AcceptedDiv>
                      );
                    })}
                </Card>
              ) : (
                <NoData>
                  <p className="noDataFound">{t("No data found")}</p>
                </NoData>
              )}
            </>
          ) : null}

          {props.name === "All" || props.name === "all" ? (
            <>
              {pendingArray.length > 0 ? (
                <Card size="100" left="0">
                  {pendingArray &&
                    pendingArray.length > 0 &&
                    pendingArray.map((a) => {
                      return (
                        <PendingDiv>
                          <PendingLeft>
                            <PendingSetOne>
                              <StatusIcon>
                                {
                                  a.status === "pending"
                                    ? <PendingIcon />
                                    : a.status === "rejected"
                                      ? <Trash />
                                      : <AcceptedIcon />
                                }
                              </StatusIcon>
                              <ComapanyImage
                                src={
                                  a.logo_path
                                    ? a.logo_path
                                    : FirstIcon
                                }
                              />
                            </PendingSetOne>

                            <TextDivision>
                              <CompanyName>{a.name}</CompanyName>
                              &nbsp;&nbsp;
                              {a.user_data === "N/A" ? (
                                <CreatedBy>&nbsp;N/A</CreatedBy>
                              ) : (
                                <CreatedBy>
                                  <></>
                                  {t("Created by")} <Bold>{a.user_data.substring(0,12)}...</Bold>
                                </CreatedBy>
                              )}
                            </TextDivision>
                          </PendingLeft>
                          {a.status === "pending" || a.status === "review" ? (
                            <PendingRight>
                              <>
                                <Button
                                  width="120px"
                                  name={t("Approve")}
                                  onClick={(e) => {
                                    props.handleApprove(
                                      e,
                                      a.code,
                                      "accepted",
                                      a.update_time,
                                      a.id,
                                      a.comments
                                    );
                                  }}
                                />
                                <Button
                                  width="200px"
                                  name={t("Send for Review")}
                                  color="#FFFFFF"
                                  onClick={(e) => {
                                    props.handleApprove(
                                      e,
                                      a.code,
                                      "review",
                                      a.update_time,
                                      a.id,
                                      a.comments
                                    );
                                  }}
                                />
                                
                                <Button
                                  width="120px"
                                  name={t("Deny")}
                                  color="#FFFFFF"
                                  onClick={(e) => {
                                    props.handleApprove(
                                      e,
                                      a.code,
                                      "rejected",
                                      a.update_time,
                                      a.id,
                                      a.comments
                                    );
                                  }}
                                />
                                {a.status === "rejected" ?
                                  t("Denied") : ""}
                                <ViewButton
                                  onClick={() => onNavigate(a.id, a.code)}
                                >
                                  {t("View")}
                                </ViewButton>
                              </>
                            </PendingRight>
                          ) : (
                            <>
                              <PendingRight>
                                <div style={{ marginTop: '28px' }}>
                                  {a.status === "rejected" ?
                                    t("Denied") : ""}
                                </div>
                                <ViewButton onClick={() => onNavigate(a.id, a.code)} >
                                  {t("View")}
                                </ViewButton>
                              </PendingRight>
                            </>
                          )
                          }
                        </PendingDiv>
                      );
                    })}
                </Card>
              ) : (
                <NoData>
                  <p className="noDataFound">{t("No data found")}</p>
                </NoData>
              )}
            </>
          ) : null}
          {props.name === "rejected" ? (
            <>
              {pendingArray.length > 0 ? (
                <Card size="100" left="0">
                  {pendingArray &&
                    pendingArray.length > 0 &&
                    pendingArray.map((a) => {
                      return (
                        <AcceptedDiv>
                          <LeftTag>
                            <SetOne>
                              <StatusIcon>
                                <Trash />
                              </StatusIcon>
                              <ComapanyImage
                                src={
                                  a.logo_path
                                    ? a.logo_path
                                    : FirstIcon
                                }
                              />
                            </SetOne>
                            <AcceptTextDivision>
                              <CompanyName>{a.name}</CompanyName>
                              &nbsp;&nbsp;
                              <CreatedBy>
                                {t("Created by")} <Bold>&nbsp;{a.user_data.substring(0,12)}...</Bold>
                              </CreatedBy>
                            </AcceptTextDivision>
                          </LeftTag>
                          <RightTag>
                            {t("Denied")}&nbsp;&nbsp;
                            <ViewButton
                              onClick={() => onNavigate(a.id, a.code)}
                            >
                              {t("View")}
                            </ViewButton>
                            
                          </RightTag>
                        </AcceptedDiv>
                      );
                    })}
                </Card>
              ) : (
                <NoData>
                  <p className="noDataFound">{t("No data found")}</p>
                </NoData>
              )}
            </>
          ) : null}
          {props.name === "review" ? (
            <>
              {pendingArray.length > 0 ? (
                <Card size="100" left="0">
                  {pendingArray &&
                    pendingArray.length > 0 &&
                    pendingArray.map((a) => {
                      return (
                        <AcceptedDiv>
                          <LeftTag>
                            <SetOne>
                              <StatusIcon>
                                <Expired />
                              </StatusIcon>
                              <ComapanyImage
                                src={
                                  a.logo_path
                                    ? a.logo_path
                                    : FirstIcon
                                }
                              />
                            </SetOne>
                            <AcceptTextDivision>
                              <CompanyName>{a.name}</CompanyName>
                              &nbsp;&nbsp;
                              {a.user_data.length >= 12 ? 
                              <CreatedBy>
                                {t("Created by")} <Bold>&nbsp;{a.user_data.substring(0,12)}...</Bold>
                              </CreatedBy> : 
                              <CreatedBy>
                              {t("Created by")} <Bold>&nbsp;{a.user_data}</Bold>
                            </CreatedBy>
                              }
                            </AcceptTextDivision>
                          </LeftTag>
                          <RightTag>
                            {a.status === "rejected" ?
                              t("Denied") : ""}
                            <ViewButton
                              onClick={() => onNavigate(a.id, a.code)}
                            >
                              {t("View")}
                            </ViewButton>
                            
                          </RightTag>
                        </AcceptedDiv>
                      );
                    })}
                </Card>
              ) : (
                <NoData>
                  <p className="noDataFound">{t("No data found")}</p>
                </NoData>
              )}
            </>
          ) : null}
        </>
      )
      }
    </StatusFilterScreen >
  );
}
