import { useState, useEffect, useCallback } from "react";

import styled from "styled-components";

import Mainlayout from "../../layouts/mainLayout/mainlayout";
import { Colors } from "../../utilities/colors";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button/button";
import BlogCard from "../../components/blogCard/blogCard";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { allBlogList } from "../../services/apiCalls";
import Loader from "../../components/loader/loader";
import Hamburger from "../../components/hamburgermenu/hamburger";
import Menus from "../../components/hamburgermenu/menus";
import { useTranslation } from "react-i18next";
import Input from "../../components/input/input";

const BlogPageWrapper = styled.div`
  padding: 2.5rem 0;
  background: #e5e5e5;
`;
const HamburgerDiv = styled.div`
  display: flex;
`;

const HamburgerLayout = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
`;

const LabelText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;
const LightText = styled(LabelText)`
  color: ${Colors.pink};
  cursor: pointer;
  font-weight: 700;
`;
const Light_1Text = styled(LabelText)`
  color: ${Colors.black};
  font-size: 24px;
`;
const Inputwrapper = styled.div`
  margin: 30px 0;
  // width: 75%;
  @media (max-width: 500px) {
    width: 100%;
  }
`;
const SearchWrapper_1 = styled.div`
  display: flex;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
const ButtonSize = styled.div`
  margin-top: 15px;
  @media (max-width: 768px) {
    margin-top: unset;
  }
`;
const AddnewWrapper = styled.div`
  width: fit-content;
  margin-left: 0vh;
`;
const CardsWrapper = styled.div`
  padding-top: 20px;
`;

export default function Blogs() {
  const adminUser = useSelector((state) => state.adminDataReducer.orgData);

  const [data, setData] = useState("");
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(true);
  const [isOpen, setOpen] = useState(false);

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    blogList();
  }, [search]);

  const onNavigate = (path) => {
    navigate(path);
  };

  const blogList = async () => {
    let body = {
      search: search,
    };
    setStatus(true);
    const { data, status } = await allBlogList(body);

    if (data?.status === 1) {
      setData(data.data);
      setStatus(false);
    } else {
      setStatus(false);
      setData([]);
    }
  };
  const getTodayPart = () => {
    var today = new Date();
    var curHr = today.getHours();
    if (curHr < 12) {
      return "Good Morning";
    } else if (curHr < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  return (
    <Mainlayout>
      <>
        <BlogPageWrapper>
          <HamburgerDiv>
            <HamburgerLayout>
              <Hamburger onToggle={() => setOpen(!isOpen)} />
            </HamburgerLayout>
            {isOpen && <Menus />}
          </HamburgerDiv>
          <Light_1Text>
            {t(getTodayPart())}, {adminUser && adminUser.name}
          </Light_1Text>
          <Row>
            <Col md="10">
              <Inputwrapper>
                <Input
                  placeholder={t("Search Blogs and updates")}
                  onChange={handleChange}
                />
              </Inputwrapper>
            </Col>
            <Col md="2">
              <ButtonSize>
                <Button width="100%" name={t("Search")} onClick={blogList} />
              </ButtonSize>
            </Col>
          </Row>
          <AddnewWrapper>
            <LightText onClick={() => onNavigate("/blogs/create-blog")}>
              + {t("Add New Blog")}
            </LightText>
          </AddnewWrapper>
          {status ? (
            <Loader />
          ) : (
            <CardsWrapper>
              {data.length > 0 ? (
                <BlogCard value={data} blogList={blogList} />
              ) : (
                <div className="noDataFound">{t("No Data Found")}</div>
              )}
            </CardsWrapper>
          )}
        </BlogPageWrapper>
      </>
    </Mainlayout>
  );
}
