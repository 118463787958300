import React from 'react';
import Chart from 'chart.js/auto';



class DoubleBarChart extends React.Component {

  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.chart = null;
  }

  makeChart() {

    const ctx = this.chartRef.current.getContext("2d");

    this.chart = new Chart(ctx, {
      type: 'bar',
      data: this.props.data,
      options: {
        responsive: true,
        maintainAspectRatio: true,
        showScale: true,
        plugins: {
          legend: {
            display: false
          },
        },
        scales: {
          y: {
            beginAtZero: true
          },

        }
      },

    });

  }

  componentDidMount() {

    this.makeChart();

    window.scrollTo(0, 0)

  }

  componentWillReceiveProps(nextProps) {

    this.chart.data = nextProps.data

    this.chart.update();

  }

  render() {

    return (
      <div>
        <canvas className="mx-auto w-100" style={{ width: '100%', height: '300px' }} ref={this.chartRef} />
      </div>
    );

  }

}
export default DoubleBarChart;
