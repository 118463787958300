import styled from 'styled-components';

import Input from '../input/input';
import { Colors } from '../../utilities/colors';
import ErrorField from '../errorField/errorField';

const GroupWrapper = styled.div`
  margin-bottom: 20px;
`;
const Label = styled.label`
  font-weight: 500;
  display: block;
`;
const FormLabel = styled(Label)`
  font-size: 20px;
  line-height: 22px;
  color: ${Colors.labelText};
  margin-bottom: 10px;
`;
const UserHelpText = styled(Label)`
  color: ${Colors.inputHighlightText};
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  text-align: right;
`;


export default function InputGroup({
  name,
  input,
  needHelp,
  helpText,
  err,
  type,
  dataId,
}) {
  return (
    <GroupWrapper>
      <FormLabel>{name ? name : <br />}</FormLabel>
      
      <div data-tip data-for={dataId ? dataId : null}>
        <Input {...input} type={type ? type : 'text'} />
      </div>
      
      {err && <ErrorField err={err} />}
      {needHelp && <UserHelpText>{helpText}</UserHelpText>}
    </GroupWrapper>
  );
}
