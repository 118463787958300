import { useEffect, useState, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import universityService from '../../../services/university.service';
import { LabelHeading, MainTitle, SelectorWrapper, StyledDarkerCardCustom, SubTitle } from '../../analyticalDashboardPage/commonStyles';
import './phone.css';
import { ReactComponent as Email } from '../../../assets/svg/email.svg';
import { ReactComponent as Phone } from '../../../assets/svg/phone.svg';
import { Controller } from 'react-hook-form';
import FormErrors from '../../../components/formErrors/formErrors';
import Input from '../../../components/input/input';
import { getLocationFormatted } from '../../../utilities/commonFunctions';
import { useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";

export const JustifyBetweenRow = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const SectionTitle = styled.span`
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #d04c8d;
`;
export const SectionTitleEdit = styled.span`
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  color: #d04c8d;
`;
export default function UniversityPrimaryDetails({
  totalForm,
  isEditable,
  defaultValues,
  setIsEditable,
}) {
  const [location, setLocation] = useState(null);
  const {
   
    formState: { errors },
    control,
    
    getValues,
  
  } = totalForm;
  
  const params = useParams();
  const id = params && params.id;
  var temp = id;
  const { t } = useTranslation();

  const getUniversityPrimaryLocation = useCallback(() => {
    universityService.getUniversityLocations(temp).then((response) => {
      const foundPrimaryLocation = response.data.find(
        (item) => item.type === 'primary'
      );

      if (foundPrimaryLocation) {
        setLocation(foundPrimaryLocation);
      }
    });
  }, [setLocation, temp]);
  useEffect(() => {
    getUniversityPrimaryLocation();
  }, [setLocation, getUniversityPrimaryLocation]);
  return (
    <>
      {!isEditable ? (
        <Row>
          <JustifyBetweenRow>
            <MainTitle>{getValues().name}</MainTitle>

            {!isEditable && (
              <SectionTitleEdit onClick={setIsEditable}>{t("Edit")}</SectionTitleEdit>
            )}
          </JustifyBetweenRow>
          <Col xs="12" className="mb-2 mt-2">
            <MainTitle style={{ color: '#D04C8D' }}>
              {getValues().shortName}
            </MainTitle>
          </Col>
          {location && (
            <Col xs="12">
              <SubTitle>{getLocationFormatted(location)}</SubTitle>
            </Col>
          )}

          <Col xs="12">
            <hr />
          </Col>
          <Col xs="12" className="mb-4">
            <SubTitle>{getValues().motto}</SubTitle>
          </Col>
          <Col xs="12">
            <StyledDarkerCardCustom>
              <Phone />
              {getValues().phone}
            </StyledDarkerCardCustom>
            <StyledDarkerCardCustom>
              <Email />
              {getValues().email}
            </StyledDarkerCardCustom>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col md="9">
              <LabelHeading>{t("Name")}*</LabelHeading>
              <SelectorWrapper>
                <Controller
                  control={control}
                  name="name"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <Input
                      disabled
                      placeholder={t("Name")}
                      onChange={onChange}
                      
                      onBlur={onBlur}
                      type="text"
                      value={value}
                      className={error ? 'invalid' : ''}
                      style={{
                        color: '#000',
                        background: 'rgb(243, 243, 243)',
                      }}
                    />
                  )}
                />
                <FormErrors errors={errors} formControlName="name" />
              </SelectorWrapper>
            </Col>
            <Col md="3">
              <LabelHeading>{t("Short Name")}*</LabelHeading>
              <SelectorWrapper>
                <Controller
                  control={control}
                  name="shortName"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <Input
                      placeholder={t("Short Name")}
                      onChange={onChange}
                      disabled={!isEditable}
                      onBlur={onBlur}
                      type="text"
                      value={value}
                      className={error ? 'invalid' : ''}
                      style={{
                        color: '#000',
                        background: 'rgb(243, 243, 243)',
                      }}
                    />
                  )}
                />
                <FormErrors errors={errors} formControlName="shortName" />
              </SelectorWrapper>
            </Col>
          </Row>
          <Row>
            <Col>
              <LabelHeading>{t("Motto")}*</LabelHeading>
              <SelectorWrapper>
                <Controller
                  control={control}
                  name="motto"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <Input
                      placeholder={t("Motto")}
                      onChange={onChange}
                      disabled={!isEditable}
                      onBlur={onBlur}
                      type="text"
                      value={value}
                      className={error ? 'invalid' : ''}
                      style={{
                        color: '#000',
                        background: 'rgb(243, 243, 243)',
                      }}
                    />
                  )}
                />
                <FormErrors errors={errors} formControlName="motto" />
              </SelectorWrapper>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <LabelHeading>{t("Mobile Number")}*</LabelHeading>
              <SelectorWrapper>
                <Controller
                  control={control}
                  name="phone"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <PhoneInput
                      value={value}
                      onChange={onChange}
                      defaultCountry="MY"
                      international
                      countryCallingCodeEditable={false}
                      style={{
                        color: '#000',
                        background: 'rgb(243, 243, 243)',
                      }}
                    />
                  )}
                />
                <FormErrors errors={errors} formControlName="phone" />
              </SelectorWrapper>
            </Col>
            <Col md="6">
              <LabelHeading>{t("Email")}*</LabelHeading>
              <SelectorWrapper>
                <Controller
                  control={control}
                  name="email"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <Input
                      placeholder={t("Email")}
                      onChange={onChange}
                      disabled={!isEditable}
                      onBlur={onBlur}
                      type="email"
                      value={value}
                      className={error ? 'invalid' : ''}
                      style={{ color: '#000', background: '#F3F3F3' }}
                    />
                  )}
                />
                <FormErrors errors={errors} formControlName="email" />
              </SelectorWrapper>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
