import { Col, Container, Row } from "react-bootstrap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import universityService from "../../../../services/university.service";
import { toast } from "react-toastify";
import {
  CancelAndExit,
  DropdownTitle,
  DropdownWrapper,
  LabelHeading,
  SelectorWrapper,
  RadioWrapper,
} from "../../../analyticalDashboardPage/commonStyles";
import FormErrors from "../../../../components/formErrors/formErrors";
import Input from "../../../../components/input/input";
import Selector from "../../../../components/select/select";
import Loader from "../../../../components/loader/loader";
import Button from "../../../../components/newButton/button/button";
import masterdataService from "../../../../services/masterdata.service";
import { isEmpty } from "../../../../utilities/commonFunctions";
import { updateModal } from "../../../../reducers/universityModalReducer";
import EventBus from "../../../../utilities/eventBus";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function UniversityLocationNewEdit({
  addressId = null,
  setIsEditable,
  setModalMode,
  type,
  getUniversityLocations,
  universityLocations,
}) {
  const params = useParams();
  const id = params && params.id;
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [countries, setNewCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const dispatch = useDispatch();
  const [defaultValues, setDefaultValues] = useState({
    addressLineOne: "",
    addressLineTwo: "",
    city: "",
    state: "",
    country: "",
    postCode: "",
    type: type,
  });
  const formSchema = Yup.object().shape({
    addressLineOne: Yup.string()
      .max(255, "should be within 1 and 255 characters")
      .min(1, "should be within 1 and 255 characters")
      .required("Address line one is required"),
    addressLineTwo: Yup.string()
      .max(255, "should be within 1 and 255 characters")
      .required("Address line two is required"),
    city: Yup.object().nullable().required("City is required"),
    state: Yup.object().nullable().required("State is required"),
    country: Yup.object().nullable().required("Country is required"),
    postCode: Yup.string()
      .max(20, "should be within 1 and 20 characters")
      .required("Postcode is required"),
    type: Yup.string().matches(
      /^(primary|secondary)$/gm,
      "Either primary or secondary address needs to be selected"
    ),
  });
  const totalForm = useForm({
    criteriaMode: "all",
    resolver: yupResolver(formSchema),
    defaultValues: defaultValues,
  });

  const {
    
    formState: { errors },
    control,
    handleSubmit,
    formState: { touchedFields },
    getValues,
    reset,
    setValue,
  } = totalForm;

  const getCurrentUniversityLocationDetails = useCallback(() => {
    
    const countryPromise = masterdataService.getCountries();
    countryPromise.then((countries) => {
      if (countries && countries.length) {
        setNewCountries(countries);
      }
      if (addressId) {
        universityService
          .getUniversityLocationById(id, addressId)
          .then((response) => {
            const statePromise = masterdataService.getStates(
              response.data.country
            );
            const cityPromise = masterdataService.getCities(
              response.data.country,
              response.data.state
            );

            Promise.all([statePromise, cityPromise]).then(
              ([states, cities]) => {
                if (states && states.length) {
                  setStates(states);
                }
                if (cities && cities.length) {
                  setCities(cities);
                }
              }
            );
            const formValues = {
              addressLineOne: response.data.address_line_one,
              addressLineTwo: response.data.address_line_two,
              city: {
                label: response.data.city,
                value: response.data.city,
              },
              state: {
                label: response.data.state,
                value: response.data.state,
              },
              country: {
                label: response.data.country,
                value: response.data.country,
              },
              postCode: response.data.postcode,
              type: response.data.type,
            };
            setDefaultValues(formValues);
            reset(formValues);
          });
      } else {
        setStates([]);
        setCities([]);
      }
    });
  }, [reset, addressId, id]);

  useEffect(() => {
    getCurrentUniversityLocationDetails();
  }, [getCurrentUniversityLocationDetails, addressId]);

  const onCancel = useCallback(() => {
    if (!isEmpty(touchedFields)) {
      setModalMode("unsavedChanges");
      dispatch(updateModal(true));
    } else {
      dispatch(updateModal(false));
      console.log("cancel");
      reset(defaultValues);
      setIsEditable(false);
      setLoading(false);
    }
  }, [
    defaultValues,
    setIsEditable,
    setLoading,
    dispatch,
    reset,
    setModalMode,
    touchedFields,
  ]);

  const onCountryChange = (event, onChangeCallback) => {
    console.log(event);
    if (event) {
      setValue("state", "");
      setValue("city", "");
      masterdataService.getStates(event.label).then((states) => {
        setStates(states || []);
      });
    } else {
      setStates([]);
      setCities([]);
    }
    onChangeCallback(event);
  };

  const onStateChange = (event, onChangeCallback) => {
    console.log(event);
    if (event) {
      setValue("city", "");
      const values = getValues();
      masterdataService
        .getCities(
          values && values.country && values.country.label,
          event.label
        )
        .then((cities) => {
          setCities(cities || []);
        });
    } else {
      setCities([]);
    }
    onChangeCallback(event);
  };

  const onSubmit = (data) => {
    if (!isEmpty(errors)) {
      console.log("errors there", errors);
      return;
    }
    setModalMode(addressId ? "update" : "create");
    onSubmitConfirm(data);
    ;
  };

  const onSubmitConfirm = useCallback(
    (data) => {
      console.log("======================THIS IS NOT WORKING=========", id);
      setLoading(true);
      
      const payload = {
        address_line_one: data.addressLineOne,
        address_line_two: data.addressLineTwo,
        city: typeof data.city !== "string" ? data.city.label : data.city,
        state: typeof data.state !== "string" ? data.state.label : data.state,
        country:
          typeof data.country !== "string" ? data.country.label : data.country,
        postcode: data.postCode,
        type: data.type,
      };

      if (addressId) {
        var selectLocation = universityLocations.find(
          (item) => item.id === addressId
        );

        var existencePrimary = universityLocations.find(
          (item) => item.type === "primary"
        );

        if (
          selectLocation &&
          existencePrimary &&
          data.type === "primary" &&
          selectLocation.id !== existencePrimary.id &&
          selectLocation.type === "secondary"
        ) {
          const payload23 = {
            address_line_one: existencePrimary.address_line_one,
            address_line_two: existencePrimary.address_line_two,
            city:
              typeof existencePrimary.city !== "string"
                ? existencePrimary.city.label
                : existencePrimary.city,
            state:
              typeof existencePrimary.state !== "string"
                ? existencePrimary.state.label
                : existencePrimary.state,
            country:
              typeof existencePrimary.country !== "string"
                ? existencePrimary.country.label
                : existencePrimary.country,
            postcode: existencePrimary.postcode,
            type: "secondary",
          };
          universityService.updateUniversityLocation(
            id,
            existencePrimary.id,
            payload23
          );
        }
      } else {
         existencePrimary = universityLocations.find(
          (item) => item.type === "primary"
        );

        if (existencePrimary && data.type === "primary") {
          const payload23 = {
            address_line_one: existencePrimary.address_line_one,
            address_line_two: existencePrimary.address_line_two,
            city:
              typeof existencePrimary.city !== "string"
                ? existencePrimary.city.label
                : existencePrimary.city,
            state:
              typeof existencePrimary.state !== "string"
                ? existencePrimary.state.label
                : existencePrimary.state,
            country:
              typeof existencePrimary.country !== "string"
                ? existencePrimary.country.label
                : existencePrimary.country,
            postcode: existencePrimary.postcode,
            type: "secondary",
          };
          universityService.updateUniversityLocation(
            id,
            existencePrimary.id,
            payload23
          );
        }
      }

      const putCreateCall = addressId
        ? universityService.updateUniversityLocation(id, addressId, payload)
        : universityService.createUniversity(id, payload);
      putCreateCall.then((response) => {
        if (response.status) {
          toast.success(
            t("University location successfully ") + `${addressId ? t("updated") : t("created")}!`
          );
          setLoading(false);
          setIsEditable(false);
          getUniversityLocations();
        } else {
          toast.error(
            t("University location failed ") + `${addressId ? t("update") : t("create")}`
          );
          onCancel();
        }
      });
    },
    [addressId, setIsEditable, onCancel, getUniversityLocations, id, t, universityLocations]
  );

  useEffect(() => {
    EventBus.on("locationCreateUpdateEvent", (data) => {
      onSubmitConfirm(data);
    });

    return () => {
      EventBus.remove("locationCreateUpdateEvent");
    };
  }, [onSubmitConfirm]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <LabelHeading>{t("Address Line One")}</LabelHeading>
            <SelectorWrapper>
              <Controller
                control={control}
                name="addressLineOne"
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <Input
                    placeholder={t("Address Line One")}
                    onChange={onChange}
                    onBlur={onBlur}
                    type="text"
                    value={value}
                    className={error ? "invalid" : ""}
                    style={{ backgroundColor: "#f1f3f4" }}
                  />
                )}
              />
              <FormErrors errors={errors} formControlName="addressLineOne" />
            </SelectorWrapper>
          </Col>
          <Col>
            <LabelHeading>{t("Address Line Two")}</LabelHeading>
            <SelectorWrapper>
              <Controller
                control={control}
                name="addressLineTwo"
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <Input
                    placeholder={t("Address Line Two")}
                    onChange={onChange}
                    onBlur={onBlur}
                    type="text"
                    value={value}
                    className={error ? "invalid" : ""}
                    style={{ backgroundColor: "#f1f3f4" }}
                  />
                )}
              />
              <FormErrors errors={errors} formControlName="addressLineTwo" />
            </SelectorWrapper>
          </Col>
        </Row>
        <Row>
          <DropdownWrapper md="6">
            <DropdownTitle>{t("Country")}</DropdownTitle>
            <SelectorWrapper>
              <Controller
                control={control}
                name="country"
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <Selector
                    placeholder={t("Country")}
                    backgroundColor="#F3F3F3"
                    name="country"
                    userOptions={countries || []}
                    defaultValue={value}
                    onChange={(event) => onCountryChange(event, onChange)}
                    onBlur={onBlur}
                    value={value}
                    className={error ? "invalid" : ""}
                  />
                )}
              />
              <FormErrors errors={errors} formControlName="country" />
            </SelectorWrapper>
          </DropdownWrapper>
          <DropdownWrapper md="6">
            <DropdownTitle>{t("State")}</DropdownTitle>
            <SelectorWrapper>
              <Controller
                control={control}
                name="state"
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <Selector
                    placeholder={t("State")}
                    backgroundColor="#F3F3F3"
                    isDisabled={!getValues().country}
                    name="state"
                    userOptions={states || []}
                    onChange={(event) => onStateChange(event, onChange)}
                    onBlur={onBlur}
                    defaultValue={value}
                    value={value}
                    className={error ? "invalid" : ""}
                  />
                )}
              />
              <FormErrors errors={errors} formControlName="state" />
            </SelectorWrapper>
          </DropdownWrapper>
        </Row>
        <Row>
          <DropdownWrapper md="6">
            <DropdownTitle>{t("City")}</DropdownTitle>
            <SelectorWrapper>
              <Controller
                control={control}
                name="city"
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <Selector
                    placeholder={t("City")}
                    backgroundColor="#F3F3F3"
                    isDisabled={!(getValues().country && getValues().state)}
                    name="city"
                    userOptions={cities || []}
                    onChange={onChange}
                    defaultValue={value}
                    onBlur={onBlur}
                    value={value}
                    className={error ? "invalid" : ""}
                  />
                )}
              />
              <FormErrors errors={errors} formControlName="city" />
            </SelectorWrapper>
          </DropdownWrapper>
          <Col md="6">
            <LabelHeading>{t("Postcode")}</LabelHeading>
            <SelectorWrapper>
              <Controller
                control={control}
                name="postCode"
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <Input
                    placeholder={t("Postcode")}
                    onChange={onChange}
                    onBlur={onBlur}
                    name="postCode"
                    type="text"
                    value={value}
                    className={error ? "invalid" : ""}
                    style={{ backgroundColor: "#f1f3f4" }}
                  />
                )}
              />
              <FormErrors errors={errors} formControlName="postCode" />
            </SelectorWrapper>
          </Col>
        </Row>
        <Row>
          {defaultValues.type === "secondary" ||
            defaultValues.type !== "primary" ? (
            <Col>
              <RadioWrapper>
                <Controller
                  control={control}
                  name="type"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <>
                      <input
                        type="radio"
                        id="primary"
                        name="type"
                        onChange={onChange}
                        onBlur={onBlur}
                        value="primary"
                        style={{ marginRight: 15 }}
                        checked={value === "primary"}
                      />
                      <label htmlFor="primary">{t("Primary")}</label>
                      <input
                        type="radio"
                        id="secondary"
                        name="type"
                        onChange={onChange}
                        onBlur={onBlur}
                        value="secondary"
                        style={{ marginLeft: 30, marginRight: 15 }}
                        checked={value === "secondary"}
                      />
                      <label htmlFor="secondary">{t("Secondary")}</label>
                    </>
                  )}
                />
                <FormErrors errors={errors} formControlName="type" />
              </RadioWrapper>
            </Col>
          ) : (
            <Col>{t("Type")}: {t(defaultValues.type)}</Col>
          )}
        </Row>
        <Row>
          <Col>
            <Container className="">
              <Row className="d-flex justify-content-end mb-4">
                <Col lg="3">
                  {loading ? <Loader /> : <Button name={t("Save")} type="submit" />}
                </Col>
                <Col lg="3">
                  <CancelAndExit
                    type="button"
                    className="cancelBtn"
                    onClick={onCancel}
                  >
                    {t("Cancel")}
                  </CancelAndExit>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </form>
    </>
  );
}
