import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getOrganizationHistory } from "../../../services/apiCalls";

const initialState = {
    companyLogData: {
        status: 0,
        logs:[]
    },
  };

export const myCompanyLogReducer = createSlice({
    name: "companylog",
    initialState,
    extraReducers : builder => {
        builder.addCase(fetchCompanyLog.fulfilled, (state,action) => {
            state.companyLogData = action.payload;
        })
    } 
  })
  
  export const fetchCompanyLog = createAsyncThunk(
    'companylog/fetchCompanyLog', 
    async (id)=>{
        const response = await getOrganizationHistory({id:id})
        return response.data;
    }
  )

export default myCompanyLogReducer.reducer;