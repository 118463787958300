import { stringFormat } from "../utilities/commonFunctions";
import { onApiCall, onMasterdataApiCall } from "./CommonApi";

const MASTERDATA_ROUTES = {
  ROLES_GET_URL: "/api/roles/",
  GET_COUNTRIES: "/base/api/scroll-list/all/loc/country/",
  GET_STATES_BY_COUNTRY: "/base/api/scroll-list/hint/{}/state/",
  GET_CITY_BY_COUNTRY_AND_STATE: "/base/api/scroll-list/{}/{}/city/",
};

class MasterdataService {
  getRoles() {
    return onApiCall({
      url: MASTERDATA_ROUTES.ROLES_GET_URL,
      method: "GET",
    });
  }

  getCountries() {
    return onMasterdataApiCall({
      url: MASTERDATA_ROUTES.GET_COUNTRIES,
      method: "POST",
      data: {
        search_key: "",
      },
    }).then((response) => {
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.length
      ) {
        response.data = response.data.data.map((x) => {
          return { label: x, value: x };
        });
      }
      return response && response.data;
    });
  }

  getStates(countryName) {
    return onMasterdataApiCall({
      url: stringFormat(MASTERDATA_ROUTES.GET_STATES_BY_COUNTRY, countryName),
      method: "POST",
      data: {
        search_key: "",
      },
    }).then((response) => {
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.length
      ) {
        response.data = response.data.data.map((x) => {
          return { label: x, value: x };
        });
      }
      return response && response.data;
    });
  }

  getCities(countryName, stateName) {
    return onMasterdataApiCall({
      url: stringFormat(
        MASTERDATA_ROUTES.GET_CITY_BY_COUNTRY_AND_STATE,
        countryName,
        stateName
      ),
      method: "POST",
      data: {
        search_key: "",
      },
    }).then((response) => {
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.length
      ) {
        response.data = response.data.data.map((x) => {
          return { label: x, value: x };
        });
      }
      return response && response.data;
    });
  }
  getCampus = (params) => {
    return onApiCall({
      url: `/api/master/campus/?${
        params.UniversityId.length > 0
          ? "UniversityId=" + params.UniversityId
          : ""
      }`,
      method: "GET",
      
    });
  };
  getFaculty = (params) => {
    return onApiCall({
      url: `/api/master/faculty/?${
        params.CollegeId.length > 0 ? "CollegeId=" + params.CollegeId : ""
      }`,
      method: "GET",
    });
  };
  getStudyPrograms = (params) => {
    return onApiCall({
      url: `/api/master/study-program/?${
        params.DepartmentId.length > 0
          ? "FacultyId=" + params.DepartmentId 
          : ""
      }`,
      method: "GET",
    });
  };
  getScopeOfStudies = () => {
    return onApiCall({
      url: `/api/master/scope-of-studies/`,
      method: "GET",
    });
  };
  getYearOfStudies = () => {
    return onApiCall({
      url: `/api/master/year-of-studies/`,
      method: "GET",
    });
  };
  getUniversity = () => {
    return onApiCall({
      
      url: `/api/master/universities/`,
      method: "GET",
    });
  };
}

export default new MasterdataService();
