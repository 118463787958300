import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  countries: [],
};

export const universityReducer = createSlice({
  name: "university",
  initialState,
  reducers: {
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
  },
});


export const { setCountries } = universityReducer.actions;

export default universityReducer.reducer;
