import React from "react";
import styled from "styled-components";
import MainLayout from "../../layouts/mainLayout/mainlayout";
import CircleProgressBar from "../../components/analyticalDashboard/charts/progressBar.js";
import Button from "../../components/button/button";
import { Link, Navigate } from "react-router-dom";
import { onApiCall } from "../../services/CommonApi";
import MasterdataService from "../../services/masterdata.service";
import Multiselect from "multiselect-react-dropdown";
import { getAdminUserInfo } from "../../services/apiCalls";
import { withTranslation } from "react-i18next";



const DropdownTitle = styled.h1`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
`;

const controller = new AbortController();

const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

class AnalyticalDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.multiselectRefCampus = React.createRef();
    this.multiselectRefFaculty = React.createRef();
    this.multiselectRefStudyProgram = React.createRef();

    this.update = debounce(function () {
      this.onDashCall();
    }, 0);

    this.state = {
      data: { totalUsers: 0, quizzes: [] },
      campus: {},
      faculty: {},
      studyProgram: {},
      scope: {},
      year: {},
      options: [
        { label: "Grapes 🍇", value: "grapes" },
        { label: "Mango 🥭", value: "mango" },
        { label: "Strawberry 🍓", value: "strawberry", disabled: true },
      ],
      selectedOption: [],
      selectedUniversity: [],
      selectedCampus: [],
      selectedFaculty: [],
      selectedStudyProgram: [],
      selectedScopeOfStudy: [],
      selectedYearOfStudy: [],
      selectedYearOfRegister: "",
      university: {},
      temp_s: "",
      visib: true,
      prevReq: 0,
      isOpen: false,
    };
  }

  fetchUniversities = async () => {
    const { data, status } = await MasterdataService.getUniversity();
    if (status === 200) {
      const campusData = data.data;
      const campus = campusData.map((x) => {
        return { ...x, label: x.Name, value: x.Id, data: x };
      });
      this.setState({ university: campus });
      
    }
  };
  fetchCampus = async () => {
    const params1 = {
      UniversityId: this.state.selectedUniversity,
    };
    const { data, status } = await MasterdataService.getCampus(params1);
    if (status === 200) {
      if (data.status === 1) {
        const campusData = data.data;
        const campus = campusData.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        this.setState({ campus: campus });
        
      }
    }
  };
  fetchFaculty = async () => {
    const params1 = {
      CollegeId: this.state.selectedCampus,
    };
    const { data, status } = await MasterdataService.getFaculty(params1);
    if (status === 200) {
      if (data.status === 1) {
        const facultyData = data.data;
        const faculty = facultyData.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        this.setState({ faculty: faculty });
      }
    }
  };
  fetchStudyPrograms = async () => {
    const params1 = {
      DepartmentId: this.state.selectedFaculty,
    };
    const { data, status } = await MasterdataService.getStudyPrograms(params1);
    if (status === 200) {
      if (data.status === 1) {
        const studyprograms = data.data;
        const studyProgram = studyprograms.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        this.setState({ studyProgram: studyProgram });
      }
    }
  };
  fetchScopeOfStudy = async () => {
    const { data, status } = await MasterdataService.getScopeOfStudies();
    if (status === 200) {
      if (data.status === 1) {
        const scopeofstudies = data.data;
        const scope = scopeofstudies.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        this.setState({ scope: scope });
      }
    }
  };
  fetchYearOfStudy = async () => {
    const { data, status } = await MasterdataService.getYearOfStudies();
    if (status === 200) {
      if (data.status === 1) {
        const yearOfStudy = data.data;
        const year = yearOfStudy.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        this.setState({ year: year });
      }
    }
  };

  onDashCall() {
    this.setState({
      prevReq: this.state.prevReq + 1,
    });
    onApiCall({
      url: `/api/dashboard/analytical/quizzes/`,
      method: "GET",
      params: {
        campus: this.state.selectedCampus.join(","),
        faculty: this.state.selectedFaculty.join(","),
        study_program: this.state.selectedStudyProgram.join(","),
        year_of_study: this.state.selectedYearOfStudy.join(","),
        insti_name: this.state.selectedUniversity.join(","),
        scope_of_study: this.state.selectedScopeOfStudy.join(","),
        
      },
      controller,
    }).then((res) => {
      if (res.status === 200) {
        this.setState({
          prevReq: 0,
        });
        let results = res?.data?.data?.results;
        results.quizzes = results.quizzes.filter(
          (x) => x.title !== "Career Explorer"
        );
        this.setState({ data: results });
      }
    });
  }

  getCampusAnalytics(selectedCampus) {
    onApiCall({
      url: `/api/dashboard/analytical/quizzes/`,
      method: "GET",
      params: {
        campus: selectedCampus,
      },
    }).then((res) => {
      if (res.status === 200) {
        let results = res?.data?.data?.results;
        results.quizzes = results.quizzes.filter(
          (x) => x.title !== "Career Explorer"
        );
        this.setState({ data: results });
      }
    });
  }

  getFacultyAnalytics(selectedFaculty) {
    onApiCall({
      url: `/api/dashboard/analytical/quizzes/`,
      method: "GET",
      params: {
        faculty: selectedFaculty,
      },
    }).then((res) => {
      if (res.status === 200) {
        let results = res?.data?.data?.results;
        results.quizzes = results.quizzes.filter(
          (x) => x.title !== "Career Explorer"
        );
        this.setState({ data: results });
      }
    });
  }

  getStudyProgramAnalytics(selectedStudy) {
    onApiCall({
      url: `/api/dashboard/analytical/quizzes/`,
      method: "GET",
      params: {
        study_program: selectedStudy,
      },
    }).then((res) => {
      if (res.status === 200) {
        let results = res?.data?.data?.results;
        results.quizzes = results.quizzes.filter(
          (x) => x.title !== "Career Explorer"
        );
        this.setState({ data: results });
      }
    });
  }

  getYearOfStudyAnalytics(selectedStudy) {
    onApiCall({
      url: `/api/dashboard/analytical/quizzes/`,
      method: "GET",
      params: {
        year_of_study: selectedStudy,
      },
    });
  }

  getUniversityAnalytics(SelectedUniveristies) {
    if (SelectedUniveristies) {
      onApiCall({
        url: `/api/dashboard/analytical/quizzes/`,
        method: "GET",
        params: {
          insti_name: SelectedUniveristies,
          
        },
      }).then((res) => {
        if (res.status === 200) {
          let results = res?.data?.data?.results;
          results.quizzes = results.quizzes.filter(
            (x) => x.title !== "Career Explorer"
          );
          this.setState({ data: results });
        }
      });
    } else {
      onApiCall({
        url: `/api/dashboard/analytical/quizzes/`,
        method: "GET",
      }).then((res) => {
        if (res.status === 200) {
          let results = res?.data?.data?.results;
          results.quizzes = results.quizzes.filter(
            (x) => x.title !== "Career Explorer"
          );
          this.setState({ data: results });
        }
      });
    }
  }

  getDatas = async () => {
    var result1 = await getAdminUserInfo();
    if (result1.status === "401") {
      this.setState({ visib: false });
      return this.render(<Navigate to="/login" />);
    } else {
      this.getUniversityAnalytics();
      this.fetchScopeOfStudy();
      this.fetchYearOfStudy();
      this.fetchUniversities();
      this.setState({ visib: true });
    }
  };
  componentDidMount() {
    this.getDatas();
  }

  calcPercent(item) {
    return Number(item?.completed) / Number(this.state.data?.totalUsers);
  }

  handleChangeScopeOfStudy = (selectedOption) => {
    var datas = [];
    selectedOption.map((x) => {
      datas.push(x.Id);
      return x.id;
    });
    this.setState(
      {
        selectedScopeOfStudy: datas,
      },
      () => {
        this.update();
      }
    );
  };

  handleChangeStudyprograme = (selectedOption) => {
    var datas = [];
    selectedOption.map((x) => {
      datas.push(x.Id);
      return x.id;
    });
    this.setState(
      {
        selectedStudyProgram: datas,
      },
      () => {
        this.update();
        this.fetchScopeOfStudy();
      }
    );
  };

  handleChangeYearOfStudy = (selectedOption) => {
    var datas = [];
    selectedOption.map((x) => {
      datas.push(x.Id);
      return x.id;
    });
    this.setState(
      {
        selectedYearOfStudy: datas,
      },
      () => {
        this.update();
      }
    );
  };

  setOpen = (isOpen1) => {
    this.setState(!isOpen1);
  };

  handleChangeFaculty = (selectedOption) => {
    var datas = [];
    selectedOption.map((x) => {
      datas.push(x.Id);
      return x.id;
    });
    this.setState(
      {
        selectedFaculty: datas,
      },
      () => {
        this.update();
        this.resetValuesStudyProgram();
        this.setState({
          studyProgram: {},
          selectedStudyProgram: [],
        });

        if (datas.length > 0) {
          this.fetchStudyPrograms();
        }
      }
    );
  };

  handleChangecampus = (selectedOption) => {
    var datas = [];
    selectedOption.map((x) => {
      datas.push(x.Id);
      return x.id;
    });
    this.setState(
      {
        selectedCampus: datas,
      },
      () => {
        this.update();
        this.resetValuesFaculty();
        this.resetValuesStudyProgram();
        this.setState({
          faculty: {},
          selectedFaculty: [],
          studyProgram: {},
          selectedStudyProgram: [],
        });

        if (datas.length > 0) {
          this.fetchFaculty();
        }
      }
    );
  };
  handleChangeUniversity = (selectedOption) => {
    var datas = [];
    selectedOption.map((x) => {
      datas.push(x.Id);
      return x.id;
    });
    this.setState(
      {
        selectedUniversity: datas,
      },
      () => {
        this.update();
        this.resetValuesCampus();
        this.resetValuesFaculty();
        this.resetValuesStudyProgram();
        this.setState({
          campus: {},
          selectedCampus: [],
          faculty: {},
          selectedFaculty: [],
          selectedStudyProgram: [],
          studyProgram: {},
        });

        if (datas.length > 0) {
          this.fetchCampus();
        }
      }
    );
  };

  resetValuesCampus() {
    
    this.multiselectRefCampus.current.resetSelectedValues();
  }

  resetValuesFaculty() {
    
    this.multiselectRefFaculty.current.resetSelectedValues();
  }

  resetValuesStudyProgram() {
    
    this.multiselectRefStudyProgram.current.resetSelectedValues();
  }

  render() {
    const { t } = this.props;
    return this.state.visib ? (
      <MainLayout>
        <div>
        
        <>
          <div className="row mb-4">
            <div className="col-12 col-lg-6 col-xl-4">
              <div className="p-4 text-center bg-white rounded shadow">
                <h3 className="mb-3" style={{ fontWeight: "300" }}>
                  {t("DEMOGRAPHICS")}
                </h3>

                <p
                  className="text-secondary mb-4"
                  style={{ fontSize: "0.9rem" }}
                >
                  {t("To learn more about the users’ demographics click on “view details” below")}
                </p>

                <h1
                  className="mb-4"
                  style={{ fontSize: "3rem", color: "#ff60b1" }}
                >
                  {this.state.data?.totalUsers}
                </h1>

                <h3 style={{ color: "#5c5b9d" }}>{t("signed up users")}</h3>

                <Link width="150px" to="/analyticaldashboard/demographics">
                  <Button width="150px" name={t("View Details")} />
                </Link>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-8">
              <style type="text/css">
                {`
 @media (min-width: 576px){
.modal-dialog {
    max-width: 671px;
    margin: 1.75rem auto;
}
}
.searchWrapper{
  background:white;
  border-radius:23px!important;
   padding:9px!important;
}
.searchWrapper:hover, .searchWrapper:focus, .searchWrapper:active{
  border-color:#FF60B1!important;
}
.multiSelectContainer li:hover{
  background:#FFE9F5!important;
  color:black!important;
 
}
.multiSelectContainer li:select{
 background:#FF60B1!important;
}
.highlightOption{
  background:#D24B8D!important;
}
.chip{
   background:#FF60B1!important;
   overflow: hidden;
   text-overflow: ellipsis;
}
.chip, .singleChip{
    white-space: normal!important;
}
.rmsc multi-select{
  max-width:350px!important
}
    `}
              </style>
              <div className="row mb-2">
                <div className="col-12 col-lg-6 col-xl-12">
                  <DropdownTitle>{t("Universities")}</DropdownTitle>
                  <Multiselect
                    options={
                      this?.state?.university &&
                      this?.state?.university.length > 0
                        ? this?.state?.university
                        : []
                    }
                   
                    onSelect={this.handleChangeUniversity}
                    onRemove={this.handleChangeUniversity} 
                    displayValue="label" 
                    showCheckbox="true"
                    placeholder={t("Select")}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-12 col-lg-6 col-xl-6">
                  <DropdownTitle>{t("Campus")}</DropdownTitle>
                  <Multiselect
                    style={{ width: "100%" }}
                    disable={!this.state.selectedUniversity.length}
                    ref={this.multiselectRefCampus}
                    options={
                      this?.state?.campus && this?.state?.campus.length > 0
                        ? this?.state?.campus
                        : []
                    }
                    
                    onSelect={this.handleChangecampus} 
                    onRemove={this.handleChangecampus} 
                    displayValue="Name" 
                    showCheckbox="true"
                    placeholder={t("Select")}
                  />
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <DropdownTitle>{t("Faculty")}</DropdownTitle>
                  <Multiselect
                    ref={this.multiselectRefFaculty}
                    disable={!this.state.selectedCampus.length}
                    style={{ width: "100%" }}
                    options={
                      this?.state?.faculty && this?.state?.faculty.length > 0
                        ? this?.state?.faculty
                        : []
                    }
                    
                    onSelect={this.handleChangeFaculty}
                    onRemove={this.handleChangeFaculty} 
                    displayValue="Name" 
                    showCheckbox="true"
                    placeholder={t("Select")}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-12 col-lg-6 col-xl-6">
                  <DropdownTitle>{t("Study Programme")}</DropdownTitle>
                  <Multiselect
                    style={{ width: "100%" }}
                    disable={!this.state.selectedFaculty.length}
                    ref={this.multiselectRefStudyProgram}
                    options={
                      this?.state?.studyProgram &&
                      this?.state?.studyProgram.length > 0
                        ? this?.state?.studyProgram
                        : []
                    }
                    
                    onSelect={this.handleChangeStudyprograme} 
                    onRemove={this.handleChangeStudyprograme} 
                    displayValue="Name" 
                    showCheckbox="true"
                    placeholder={t("Select")}
                  />
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <DropdownTitle>{t("Scope of Study")}</DropdownTitle>
                  <Multiselect
                    style={{ width: "100%" }}
                    options={
                      this?.state?.scope && this?.state?.scope.length > 0
                        ? this?.state?.scope
                        : []
                    }
                    
                    onSelect={this.handleChangeScopeOfStudy} 
                    onRemove={this.handleChangeScopeOfStudy}
                    displayValue="Name" 
                    showCheckbox="true"
                    placeholder={t("Select")}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-12 col-lg-6 col-xl-6">
                  <DropdownTitle>{t("Year of Study")}</DropdownTitle>
                  <Multiselect
                    style={{ width: "100%" }}
                    options={
                      this?.state?.year && this?.state?.year.length > 0
                        ? this?.state?.year
                        : []
                    }
                    
                    onSelect={this.handleChangeYearOfStudy} 
                    onRemove={this.handleChangeYearOfStudy} 
                    displayValue="Name" 
                    showCheckbox="true"
                    placeholder={t("Select")}
                  />
                </div>
                
              </div>
            </div>
          </div>
          <div className="row">
            {[...Array(this.state.data?.quizzes.length)].map((x, i) => (
              <div className="col-12 col-lg-6 col-xl-4 mb-4" key={i}>
                <div
                  className="p-4 text-center bg-white rounded shadow"
                  style={{ minHeight: "100%" }}
                >
                  <div style={{ height: "130px" }}>
                    <h3 className="mb-3" style={{ fontWeight: "300" }}>
                      {t(this.state.data?.quizzes[i]?.title)}
                    </h3>

                    <p
                      className="text-secondary"
                      style={{ fontSize: "0.9rem" }}
                    >
                      {t("To learn more about the users’ ")}{t(this.state.data?.quizzes[i]?.title)}{t(" click on “view details” below")}
                    </p>
                  </div>

                  <h5 className="mb-3 text-muted" style={{ fontWeight: "500" }}>
                    {t("COMPLETION RATE")}
                  </h5>

                  <CircleProgressBar
                    initialAnimate={true}
                    percent={
                      this.state?.data?.quizzes[i].completed
                        ? this.calcPercent(this.state?.data?.quizzes[i])
                        : 0
                    }
                  />

                  <h5 className="mb-4">
                    {this.state?.data?.quizzes[i].completed} /{" "}
                    {this.state?.data?.totalUsers}
                  </h5>

                  <Link
                    width="150px"
                    to={
                      "/analyticaldashboard/" +
                      this.state?.data?.quizzes[i].name
                    }
                  >
                    <Button width="150px" name={t("View Details")} />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </>
        </div>
      </MainLayout>
    ) : (
      <Navigate to="/login" />
    );
  }
}

export default withTranslation()(AnalyticalDashboard);
