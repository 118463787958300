import {useState} from "react";
import { Message } from "./companyUserListStyles";
import Button from "../../components/button/button";
import { toast } from "react-toastify";
import Loader from "../../components/loader/loader";
import { companyUserAction } from "../../services/apiCalls";
import { findIndex } from "lodash";
import { useTranslation } from "react-i18next";

export default function AlertBody({ close, user_id, update_time, users, setUsers }) {
  const [syncing, setSyncing] = useState(false);
  const { t, } = useTranslation();

  
  const handleApproved = async () => {
    const body = {
      id: user_id,
      status: "accepted",
      update_time: update_time,
    };

    
    setSyncing(true);
    const { data, status } = await companyUserAction(body);
    setSyncing(false);

    if (status === 200) {
      if (data.status === 1) {

        var tempUsers = [...users];
        var index = findIndex(tempUsers,{id:user_id});
        tempUsers[index].status = "accepted";
        setUsers(tempUsers);

        toast.success(t(data.message), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(t(data.message), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      
      close();
    }
  };

  return (
    <div style={{ padding: "0 40px 20px 40px" }}>
      <Message
        style={{
          textAlign: "center",
          fontSize: "26px",
          fontWeight: "bold",
          margin: "0px auto 10px auto",
        }}
        close
      >
        {t("Are you sure you want to approve this company user ?")}
      </Message>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        {syncing === false ? (
          <Button
            width="180px"
            name={t("Yes")}
            style={{ marginRight: "30px" }}
            onClick={() => handleApproved()}
            disabled={syncing}
          />
        ) : (
          <Loader />
        )}
        <Button
          width="180px"
          name={t("No")}
          color="#FFFFFF"
          onClick={() => {
            close();
          }}
        />
      </div>
    </div>
  );
}
