import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button/button";
import Divider from "../../components/divider/divider";
import Card from "../../components/card/cardImage";
import FirstIcon from "../../assets/DemoIcon.png";
import { ReactComponent as AcceptedIcon } from "../../assets/icons/svg/acceptedIcon.svg";
import { ReactComponent as PendingIcon } from "../../assets/icons/svg/pendingIcon.svg";
import Reject from "../../assets/icons/reject-48.png";
import { useTranslation } from "react-i18next";

import {
  PendingRight,
  StatusFilterScreen,
  DividerWrapper,
  LightText,
  PendingDiv,
  PendingLeft,
  PendingSetOne,
  StatusIcon,
  ComapanyImage,
  TextDivision,
  CompanyName,
  CreatedBy,
  Bold,
  ViewButton,
  ImageDropdown,
} from "./resultTableStyles.js";

export default function ResultTable({ data, title, showAlert }) {
  const navigate = useNavigate();
  const { t, } = useTranslation();

  return (
    <StatusFilterScreen>
      <DividerWrapper>
        <Divider />
      </DividerWrapper>
      <div>
        <LightText>{t(title)}</LightText>
      </div>
      <br />
      <Card size="100" left="0">
        {data.map((user) => (
          <PendingDiv>
            <PendingLeft>
              <PendingSetOne>
                <StatusIcon>
                  {user.status === "pending" ? <PendingIcon /> : ""}
                  {user.status === "accepted" ? <AcceptedIcon /> : ""}
                  {user.status === "rejected" ? (
                    <ImageDropdown src={Reject} />
                  ) : (
                    ""
                  )}
                  {user.status === "N/A" ? <PendingIcon /> : ""}
                </StatusIcon>
                <ComapanyImage
                  src={user.profile_picture ? user.profile_picture : FirstIcon}
                  onClick={() => {
                    navigate(
                      "/companiesusers/view/" + user.org_code + "/" + user.id
                    );
                  }}
                />
              </PendingSetOne>
              <TextDivision>
                {user.name.length >= 20 ? <CompanyName>{user.name.substring(0,20)}...</CompanyName> : <CompanyName>{user.name}</CompanyName>}
                <CreatedBy>
                  {user.org_name == "N/A" ? 
                  <Bold>{t("pending company registration")}</Bold> :
                  <Bold>{user.org_name}</Bold> 
                  }
                </CreatedBy>
              </TextDivision>
            </PendingLeft>
            {/* {user.status === "pending" || user.status === "N/A" ? (
              <PendingRight>
                <Button
                  width="120px"
                  name={t("Approve")}
                  onClick={() => {
                    showAlert(1, user.id, user.update_time);
                  }}
                />
                <Button
                  width="120px"
                  name={t("Deny")}
                  color="#FFFFFF"
                  onClick={() => {
                    showAlert(0, user.id, user.update_time);
                  }}
                />
              </PendingRight>
            ) : (
              ""
            )} */}
            &nbsp; &nbsp; &nbsp;
            {user.org_name != "N/A" &&
              <ViewButton
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(
                    "/companiesusers/view/" + user.org_code + "/" + user.id
                  );
                }}
              >
                {t("View")}
              </ViewButton>
            }
          </PendingDiv>
        ))}
      </Card>
    </StatusFilterScreen>
  );
}
