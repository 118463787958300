import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  search: null,
  status: null
};

export const companyFilter = createSlice({
  name: "companyFilter",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    resetAllCompanies: (state) => {
      state.search = null;
      state.status = null;
    },
  }
});


export const {
  setSearch,
  setStatus,
  resetAllCompanies
} = companyFilter.actions;

export default companyFilter.reducer;
