import Blogs from "../src/pages/blogs/blogs";
import Companies from "../src/pages/companies/companies";
import Companiesusers from "../src/pages/companiesusers/companiesusers";
import CompanyUserList from "../src/pages/companiesusers/companyUserlList"; // INIVOS

import CompaniesUserView from "./pages/companyUsersView/CompanyUserView";
import LoginPage from "../src/pages/login/loginPage";
import Mainpage from "../src/pages/mainpage/mainpage";
import Verify from "../src/pages/verify/verify";
import AnalyticalDashboard from "./pages/analyticalDashboard/analyticalDashboard";
import FutureOfWork from "./pages/analyticalDashboard/assessments/21-century-skills/futureOfWork";
import FutureOfWorkValues from "./pages/analyticalDashboard/assessments/21-century-skills/futureOfWorkValues";
import EmployabilityDomains from "./pages/analyticalDashboard/assessments/employability/employabilityDomains";
import EmployabilityValues from "./pages/analyticalDashboard/assessments/employability/employabilityValues";
import EnglishTest from "./pages/analyticalDashboard/assessments/english-test/englishTest";
import FiveFactorValues from "./pages/analyticalDashboard/assessments/five-factor/fiveFactorValues";
import WorkInterestValues from "./pages/analyticalDashboard/assessments/interest-riasec/workInterestValues";
import WorkValues from "./pages/analyticalDashboard/assessments/work-values/workValues";
import WorkValuesValues from "./pages/analyticalDashboard/assessments/work-values/workValuesValues";
import Demographics from "./pages/analyticalDashboard/demographics/demographics.js";
import Education from "./pages/analyticalDashboard/demographics/education.js";
import FinancialSupport from "./pages/analyticalDashboard/demographics/financialSupport.js";
import Geography from "./pages/analyticalDashboard/demographics/geography.js";
import Nationality from "./pages/analyticalDashboard/demographics/nationality.js";
import SignupInfo from "./pages/analyticalDashboard/demographics/signupInfo.js";
import Applicants from "./pages/applicants";
import ViewDetails from "./pages/companies/viewDetails";
import ForgotPassword from "./pages/forgotPassword/forgotpassword";
import Internships from "./pages/internship/internship";
import ViewInternship from "./pages/jobs/viewInternship/viewInternship";
import NewBlog from "./pages/newBlog/newBlog";
import NewIntern from "./pages/newIntern/newIntern";
import Settings from "./pages/settings/settings";
import AddUniversity from "./pages/universitiesModule/AddUniversity";
import UniversityLandingScreen from "./pages/universitiesModule/universityLandingPage";
import UniversityPage from "./pages/universityPage/universityDetailPage";
import UserManagment from "./pages/universityUserManagementPage/userManagementPage";
import ViewInternshipDetails from "./pages/viewDetails";
import AuthRoute from "./utilities/authRoute";

import MasterDataList from "./pages/masterData/";
import UniversitesList from "./pages/masterData/university/universities";
import CampusList from "./pages/masterData/university/campus";
import FacultiesList from "./pages/masterData/university/faculties";
import StudyProgramList from "./pages/masterData/university/study_program";
import BlogView from "./pages/newBlog/blogView";

export const route = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/verify",
    element: <Verify />,
  },
  {
    path: "/forgotPassword",
    element: <ForgotPassword />,
  },
  {
    path: "blogs/create-blog",
    element: (
      <AuthRoute>
        <NewBlog />
      </AuthRoute>
    ),
  },
  {
    path: "/blogs/edit-blog",
    element: (
      <AuthRoute>
        <NewBlog />
      </AuthRoute>
    ),
  },
  {
    path: "/blogs/view-blog",
    element: (
      <AuthRoute>
        <BlogView />
      </AuthRoute>
    ),
  },
  {
    path: "/company-detail",
    element: (
      <AuthRoute>
        <ViewDetails />
      </AuthRoute>
    ),
  },
  {
    path: "/internships/details",
    element: (
      <AuthRoute>
        <ViewInternshipDetails />
      </AuthRoute>
    ),
  },
  {
    path: "/internships/applicants",
    element: (
      <AuthRoute>
        <Applicants />
      </AuthRoute>
    ),
  },
  {
    path: "/internships/new-intern",
    element: (
      <AuthRoute>
        <NewIntern />
      </AuthRoute>
    ),
  },
  {
    path: "/internships",
    element: (
      <AuthRoute>
        <Internships />
      </AuthRoute>
    ),
  },
  {
    path: "/",
    element: (
      <AuthRoute>
        <Mainpage />
      </AuthRoute>
    ),
  },
  {
    path: "/companies",
    element: (
      <AuthRoute>
        <Companies />
      </AuthRoute>
    ),
  },
  {
    path: "/blogs",
    element: (
      <AuthRoute>
        <Blogs />
      </AuthRoute>
    ),
  },
  
  {
    path: "/jobs/details",
    element: (
      <AuthRoute>
        <ViewInternship />
      </AuthRoute>
    ),
  },
  {
    path: "/internships",
    element: (
      <AuthRoute>
        <Internships />
      </AuthRoute>
    ),
  },
  {
    path: "/companiesusers",
    element: (
      <AuthRoute>
        <Companiesusers />
      </AuthRoute>
    ),
  },
  {
    path: "/companiesuserslist",
    element: (
      <AuthRoute>
        <CompanyUserList />
      </AuthRoute>
    ),
  },
  {
    path: "/companiesusers/view/:company/:id",
    element: (
      <AuthRoute>
        <CompaniesUserView />
      </AuthRoute>
    ),
  },

  {
    path: "/universities",
    element: (
      <AuthRoute>
        <UniversityLandingScreen />
      </AuthRoute>
    ),
  },

  {
    path: "/universities/add-university",
    element: (
      <AuthRoute>
        <AddUniversity />
      </AuthRoute>
    ),
  },

  {
    path: "/universities/detail/:id",
    element: (
      <AuthRoute>
        <UniversityPage />
      </AuthRoute>
    ),
  },

  {
    path: "/universities/detail/:id/user-managment",
    element: (
      <AuthRoute>
        <UserManagment />
      </AuthRoute>
    ),
  },

  {
    path: "/universities/detail/:id/user-managment/*",
    element: (
      <AuthRoute>
        <UserManagment />
      </AuthRoute>
    ),
  },

  
  {
    path: "/analyticaldashboard",
    element: (
      <AuthRoute>
        <AnalyticalDashboard />
      </AuthRoute>
    ),
  },
  {
    path: "/analyticaldashboard/work-values",
    element: (
      <AuthRoute>
        <WorkValues />
      </AuthRoute>
    ),
  },
  {
    path: "/analyticaldashboard/work-values/:id",
    element: (
      <AuthRoute>
        <WorkValuesValues />
      </AuthRoute>
    ),
  },
  {
    path: "/analyticaldashboard/interest-riasec",
    element: (
      <AuthRoute>
        <WorkInterestValues />
      </AuthRoute>
    ),
  },
  {
    path: "/analyticaldashboard/five-factor",
    element: (
      <AuthRoute>
        <FiveFactorValues />
      </AuthRoute>
    ),
  },
  {
    path: "/analyticaldashboard/employability",
    element: (
      <AuthRoute>
        <EmployabilityDomains />
      </AuthRoute>
    ),
  },
  {
    path: "/analyticaldashboard/employability/:id",
    element: (
      <AuthRoute>
        <EmployabilityValues />
      </AuthRoute>
    ),
  },
  {
    path: "/analyticaldashboard/21-century-skills",
    element: (
      <AuthRoute>
        <FutureOfWork />
      </AuthRoute>
    ),
  },
  {
    path: "/analyticaldashboard/21-century-skills/:id",
    element: (
      <AuthRoute>
        <FutureOfWorkValues />
      </AuthRoute>
    ),
  },
  {
    path: "/analyticaldashboard/english-test",
    element: (
      <AuthRoute>
        <EnglishTest />
      </AuthRoute>
    ),
  },
  {
    path: "/analyticaldashboard/demographics",
    element: (
      <AuthRoute>
        <Demographics />
      </AuthRoute>
    ),
  },
  {
    path: "/analyticaldashboard/demographics/education",
    element: (
      <AuthRoute>
        <Education />
      </AuthRoute>
    ),
  },
  {
    path: "/analyticaldashboard/demographics/financial-support",
    element: (
      <AuthRoute>
        <FinancialSupport />
      </AuthRoute>
    ),
  },
  {
    path: "/analyticaldashboard/demographics/geography",
    element: (
      <AuthRoute>
        <Geography />
      </AuthRoute>
    ),
  },
  {
    path: "/analyticaldashboard/demographics/nationality",
    element: (
      <AuthRoute>
        <Nationality />
      </AuthRoute>
    ),
  },
  {
    path: "/analyticaldashboard/demographics/signup-info",
    element: (
      <AuthRoute>
        <SignupInfo />
      </AuthRoute>
    ),
  },

  /*============================================*/

  {
    path: "/master-data",
    element: (
      <AuthRoute>
        <MasterDataList />
      </AuthRoute>
    ),
  },

  {
    path: "/master-data/universities",
    element: (
      <AuthRoute>
        <UniversitesList />
      </AuthRoute>
    ),
  },

  {
    path: "/master-data/campuses",
    element: (
      <AuthRoute>
        <CampusList />
      </AuthRoute>
    ),
  },
  {
    path: "/master-data/faculties",
    element: (
      <AuthRoute>
        <FacultiesList />
      </AuthRoute>
    ),
  },

  {
    path: "/master-data/study-programs",
    element: (
      <AuthRoute>
        <StudyProgramList />
      </AuthRoute>
    ),
  },

  
  {
    path: "/settings",
    element: (
      <AuthRoute>
        <Settings />
      </AuthRoute>
    ),
  }
];
