import styled from 'styled-components';
import { useTranslation } from "react-i18next";

import { staticContent } from './staticContent';

const IntroSectionWrapper = styled.div`
  padding: 0px;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const IntroSectionHeader = styled.h2`
  font-weight: 500;
  font-size: ${(props) => (props.size ? props.size : '30px')};
  line-height: ${(props) => (props.lh ? props.lh : '41px')};
  margin-bottom: 0px;
  margin-top: ${(props) => (props.margin ? props.margin : '')};
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 30px;
  }
`;
const IntroSectionSubHeader = styled.p`
  font-weight: ${(props) => (props.fw ? props.fw : 'normal')};
  font-size: 16px;
  line-height: ${(props) => (props.lh ? props.lh : '25px')};
  color: ${(props) => (props.color ? props.color : '#707070')};
  margin: 0px;
  // width: 90%;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
export default function IntroSection({ name, margin }) {
  const { t, } = useTranslation();
  return (
    <IntroSectionWrapper>
      <IntroSectionHeader
        lh={staticContent[name].nameStyle.lh}
        size={staticContent[name].nameStyle.size}
        margin={margin}
      >
        {t(staticContent[name].name)}
      </IntroSectionHeader>
      <IntroSectionSubHeader
        lh={staticContent[name].descriptionStyle.lh}
        fw={staticContent[name].descriptionStyle.fw}
        color={staticContent[name].descriptionStyle.color}
      >
        {t(staticContent[name].description)}
      </IntroSectionSubHeader>
    </IntroSectionWrapper>
  );
}
