import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orgData: {},
};

export const adminDataReducer = createSlice({
  name: "admin",
  initialState,
  reducers: {
    updateAdminData: (state, action) => {
      state.orgData = action.payload;
    },
  },
});

export const { updateAdminData } = adminDataReducer.actions;

export default adminDataReducer.reducer;
