import { useState, useEffect, useCallback } from "react";
import Cookies from "js-cookie";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Colors } from "../../utilities/colors";

import Logo from "../../assets/icons/svg/mynextLogo.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CompaniesusersIcon } from "../../assets/icons/svg/companyusers.svg";
import { ReactComponent as CompaniesIcon } from "../../assets/icons/svg/companies.svg";
import { ReactComponent as InternshipIcon } from "../../assets/icons/svg/internships.svg";
import { ReactComponent as UniversitiesIcon } from "../../assets/icons/svg/universities.svg";
import { ReactComponent as AnalyticalDashboardIcon } from "../../assets/icons/svg/analytical dashboard.svg";
import { ReactComponent as BlogsIcon } from "../../assets/icons/svg/blogs.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/svg/settings.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/svg/home.svg";

const SidebarWrapper = styled.nav``;

const MenuDiv = styled.div`
  width: 100%;
  height: 10%;
  margin: 0px;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
`;
const MenuList = styled(NavLink)`
  padding: 10px 0px;
  font-family: "General Sans", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  color: #acacac;
  margin-left: 5rem;
  text-decoration: none;
  &:hover {
    color: ${Colors.pink};
    border-right: 5px solid ${Colors.pink};
  }

  &.active {
    color: ${Colors.pink};
    border-right: 5px solid ${Colors.pink};
  }
`;

const MenuSpanText = styled.p`
  margin-left: 20px;
  margin-top: unset;
`;

const MenuSpanIcons = styled.span`
  width: auto;
  margin-bottom: 1rem;
  > img {
    width: 30px;
    height: 30px;
  }
  svg {
    width: 22px;
    height: 20px;
    margin-top: 2px;
  }
`;

const SideNavHeaderImage = styled.div`
  background-image: url(${Logo});
  background-repeat: no-repeat;
  width: 202px;
  height: 66px;
  margin: 66px 104px 10px 70px;
  position: relative;
  cursor: pointer;
`;

const SideNavHeaderImageSubTitle = styled.span`
  color: #d04d8b;
  position: absolute;
  top: 4.5vh;
  right: 2rem;
`;
const menuItems = [
  {
    title: "Dashboard",
    icon: <HomeIcon />,
    url: "/",
    type: "admin",
  },
  {
    title: "Companies",
    icon: <CompaniesIcon />,
    url: "/companies",
    type: "admin",
  },
  {
    title: "Company Users",
    icon: <CompaniesusersIcon />,
    url: "/companiesuserslist",
    type: "admin",
  },
  {
    title: "Internships",
    icon: <InternshipIcon />,
    url: "/internships",
    type: "admin",
  },
  {
    title: "Universities",
    icon: <UniversitiesIcon />,
    url: "/universities",
    type: "admin",
  },
  {
    title: "Analytical Dashboard",
    icon: <AnalyticalDashboardIcon />,
    url: "/analyticaldashboard",
    type: "admin",
  },

  {
    title: "Master Data",
    icon: <AnalyticalDashboardIcon />,
    url: "/master-data",
    type: "admin",
  },
  {
    title: "Blogs",
    icon: <BlogsIcon />,
    url: "/blogs",
    type: "admin",
  },
  {
    title: "Settings",
    icon: <SettingsIcon />,
    url: "/settings",
    type: "admin",
  },
];
export default function Sidebar() {
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState("");
  const location = useLocation();
  const { t } = useTranslation();
  const onNavigate = () => {
    navigate({ pathname: "/" });
  };
  const onUserLogout = (title) => {
    if (title === "Logout") {
      Cookies.remove("token");
    }
  };
  const getActiveLocation = useCallback(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    getActiveLocation();
  }, [getActiveLocation]);

  const pathname = window.location.pathname;
  return (
    <SidebarWrapper>
      <div>
        <SideNavHeaderImage onClick={onNavigate}>
          <SideNavHeaderImageSubTitle>
            <h5>ADMIN</h5>
          </SideNavHeaderImageSubTitle>
        </SideNavHeaderImage>
      </div>
      <MenuDiv>
        {menuItems.map((menuItem, i) => (
          <MenuList
            className={
              pathname === menuItem.url ||
              (pathname === "/company-detail" && menuItem.url === "/companies")
                ? "active"
                : ""
            }
            key={i}
            to={menuItem.url}
            onClick={() => {
              onUserLogout(menuItem.title);
            }}
          >
            <MenuSpanIcons>
              {activeLink === menuItem.url ? menuItem.icon : menuItem.icon}
            </MenuSpanIcons>
            <MenuSpanText>{t(menuItem.title)}</MenuSpanText>
          </MenuList>
        ))}
      </MenuDiv>
    </SidebarWrapper>
  );
}
