import { useEffect, useState } from "react";
import {
  Outlet,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import CreateUser from "./createUser/createUser";
import UpdateUser from "./updateUser/updateUser";
import UserLandingScreen from "./userLandingScreen/userLandingScreen";
import ConfirmUser from "../../assets/confirmUser.png";
import Warning from "../../assets/warning.png";
import {
  UserManagementModalContainer,
  UserModal,
} from "../analyticalDashboardPage/commonStyles";
import Button from "../../components/button/button";
import UniversityModal from "../../components/universityModal/universityModal";
import { useDispatch, useSelector } from "react-redux";
import { updateModal } from "../../reducers/universityModalReducer";
import EventBus from "../../utilities/eventBus";
import { updateGlobalLoader } from "../../reducers/universityLoadingReducer";
import userManagementService from "../../services/userManagement.service";
import { setMasterdataRoles } from "../../reducers/userManagementReducer";
import Mainlayout from "../../layouts/mainLayout/mainlayout";
import { useTranslation } from "react-i18next";

export default function UserManagementPage() {
  const modalCache = useSelector((state) => state?.modal?.cache);
  const navigate = useNavigate();
  const [modalMode, setModalMode] = useState("");
  const dispatch = useDispatch();
  const params = useParams();
  const id = params && params.id;
  const { t } = useTranslation();
  useEffect(() => {
    userManagementService.getMasterdata().then((roles) => {
      dispatch(setMasterdataRoles(roles));
    });
  }, [dispatch]);

  const getModalContent = () => {
    switch (modalMode) {
      case "deactivate":
        return (
          <UserModal>
            <div className="modal-wrapper rotated">
              <img
                src={ConfirmUser}
                alt="deactivate user"
                className="modal-image rotated"
              />
            </div>
            <div className="modal-title">{t("Confirm Deactivating User")}</div>
            <div className="modal-description">
              {t("Are you sure you want to deactivate the user? This user will not be able to access the system again after this.")}
            </div>
            <div className="modal-buttons">
              <Button
                name={t("Yes")}
                onClick={() => {
                  EventBus.dispatch("userActivateDeactivateEvent", {
                    userId: modalCache,
                    activate: false,
                  });
                  dispatch(updateModal(false));
                }}
              />
              <Button
                name={t("No")}
                className="inverted"
                onClick={() => {
                  EventBus.dispatch("userActivateDeactivateCancelEvent");
                  dispatch(updateModal(false));
                }}
                color="#fff"
              />
            </div>
          </UserModal>
        );
      case "activate":
        return (
          <UserModal>
            <div className="modal-wrapper rotated">
              <img
                src={ConfirmUser}
                alt="activate user"
                className="modal-image rotated"
              />
            </div>
            <div className="modal-title">{t("Confirm Activate User")}</div>
            <div className="modal-description">
              {t("Are you sure you want to reactivate the user? This user will be able to access the system again after this.")}
            </div>
            <div className="modal-buttons">
              <Button
                name={t("Yes")}
                onClick={() => {
                  EventBus.dispatch("userActivateDeactivateEvent", {
                    userId: modalCache,
                    activate: true,
                  });
                  dispatch(updateModal(false));
                }}
              />
              <Button
                name={t("No")}
                className="inverted"
                onClick={() => {
                  EventBus.dispatch("userActivateDeactivateCancelEvent");
                  dispatch(updateModal(false));
                }}
                color="#fff"
              />
            </div>
          </UserModal>
        );
      case "delete":
        return (
          <UserModal>
            <div className="modal-wrapper rotated">
              <img
                src={ConfirmUser}
                alt="delete user"
                className="modal-image rotated"
              />
            </div>
            <div className="modal-title">{t("Delete User")}</div>
            <div className="modal-description">
              {t("Are you sure you want to delete the user? This user will be deleted from the system.")}
            </div>
            <div className="modal-buttons">
              <Button
                name={t("Yes")}
                onClick={() => {
                  EventBus.dispatch("userDeleteEvent", modalCache);
                  dispatch(updateModal(false));
                }}
              />
              <Button
                name={t("No")}
                className="inverted"
                onClick={() => {
                  dispatch(updateModal(false));
                }}
                color="#fff"
              />
            </div>
          </UserModal>
        );
      case "update":
        return (
          <UserModal>
            <div className="modal-wrapper">
              <img
                src={ConfirmUser}
                alt="update user"
                className="modal-image"
              />
            </div>
            <div className="modal-title">{t("Update User")}</div>
            <div className="modal-description">
              {t("Are you sure you want to update the user?")}
            </div>
            <div className="modal-buttons">
              <Button
                name={t("Yes")}
                onClick={() => {
                  dispatch(updateGlobalLoader(true));
                  dispatch(updateModal(false));
                  EventBus.dispatch("userUpdateEvent", modalCache);
                }}
              />
              <Button
                name={t("No")}
                className="inverted"
                onClick={() => dispatch(updateModal(false))}
                color="#fff"
              />
            </div>
          </UserModal>
        );
      case "create":
        return (
          <UserModal>
            <div className="modal-wrapper">
              <img
                src={ConfirmUser}
                alt="create user"
                className="modal-image"
              />
            </div>
            <div className="modal-title">{t("Create User(s)")}</div>
            <div className="modal-description">
              {t("Are you sure you want to create the user(s)?")}
            </div>
            <div className="modal-buttons">
              <Button
                name={t("Yes")}
                onClick={() => {
                  dispatch(updateGlobalLoader(true));
                  dispatch(updateModal(false));
                  EventBus.dispatch("userCreateEvent", {
                    data: modalCache,
                    university_id: id,
                  });
                }}
              />
              <Button
                name={t("No")}
                className="inverted"
                onClick={() => dispatch(updateModal(false))}
                color="#fff"
              />
            </div>
          </UserModal>
        );
      case "unsavedChanges":
        return (
          <UserModal>
            <div className="modal-wrapper fixed">
              <img
                src={Warning}
                alt="unsaved changes"
                className="modal-image fixed"
              />
            </div>
            <div className="modal-title">{t("Unsaved Changes")}</div>
            <div className="modal-description">
              {t("Are you sure you want to discard unsaved changes?")}
            </div>
            <div className="modal-buttons">
              <Button
                name={t("Yes")}
                onClick={() => {
                  dispatch(updateModal(false));
                  navigate(-1);
                }}
              />
              <Button
                name={t("No")}
                className="inverted"
                onClick={() => dispatch(updateModal(false))}
                color="#fff"
              />
            </div>
          </UserModal>
        );
      default:
        break;
    }
  };

  return (
    <Mainlayout>
      <>
        <div>
          <Routes>
            <Route path="/" element={<Outlet />}>
              <Route
                path="new"
                element={<CreateUser setModalMode={setModalMode} />}
              />
              <Route
                path="edit/:user_id"
                element={<UpdateUser setModalMode={setModalMode} />}
              />
              <Route
                index
                element={<UserLandingScreen setModalMode={setModalMode} />}
              />
            </Route>
          </Routes>
        </div>
        <UniversityModal>
          <UserManagementModalContainer>
            {getModalContent()}
          </UserManagementModalContainer>
        </UniversityModal>
      </>
    </Mainlayout>
  );
}
