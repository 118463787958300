


export default function UniversityLoader({ isFixed = true }) {
  
  return (
    
    <></>
  );
}
