import { FiHome } from 'react-icons/fi';
import { BsBag } from 'react-icons/bs';
import { RiFileList3Line } from 'react-icons/ri';
import CompanyIcon from './assets/icons/svg/companies.svg';
import { IoIosLogOut } from 'react-icons/io';

export const menuItems = [
  {
    title: 'Dashboard',
    icon: <FiHome size={25} />,
    url: '/',
    type: 'admin',
  },
  
  {
    title: 'Companies',
    icon: <img src={CompanyIcon} alt={"company icon"}/>,
    url: '/companies',
    type: 'admin',
  },
  
  {
    title: 'Internships',
    icon: <BsBag size={25} />,
    url: '/jobs',
    type: 'admin',
  },
  
  {
    title: 'Blogs',
    icon: <RiFileList3Line size={25} />,
    url: '/blogs',
    type: 'admin',
  },
  {
    title: 'Logout',
    icon: <IoIosLogOut size={25} />,
    url: '/login',
    type: 'admin',
  },
];
