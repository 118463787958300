import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  companyData: {},
};

export const myCompanyReducer = createSlice({
  name: "company",
  initialState,
  reducers: {
    updateCompanyData: (state, action) => {
      state.companyData = action.payload;
    },
  },
});


export const { updateCompanyData, } =
  myCompanyReducer.actions;

export default myCompanyReducer.reducer;
