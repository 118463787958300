import React from 'react';
import MainLayout from '../../../../layouts/mainLayout/mainlayout';
import DoubleBarChart from '../../../../components/analyticalDashboard/charts/doubleBarChart.js';
import { Link, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Realistic from '../../../../assets/assesment/realistic-practical.svg';
import Investigative from '../../../../assets/assesment/investigative-thinker.svg';
import Artistic from '../../../../assets/assesment/artistic-creative.svg';
import Social from '../../../../assets/assesment/social-helper.svg';
import Enterprising from '../../../../assets/assesment/enterprising-persuader.svg';
import Conventional from '../../../../assets/assesment/conventional-organizer.svg';
import { onApiCall } from '../../../../services/CommonApi';

class WorkInterestValues extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: {} };
  }

  getData() {
    onApiCall({
      url: `api/dashboard/analytical/interest-riasec/5/`,
      method: "GET",
    }).then((res) => {
      if (res.status === 200) {
        console.log(res.data.data);
        this.setState({ data: this.formatChartData(res.data.data.results) });
      }
    });
  }

  componentDidMount() {
    this.getData();
  }

  componentWillReceiveProps(newProps) {
    this.getData();
  }

  formatChartData(data) {
    const { t } = this.props;
    return {
      labels: [
        t('Realistic'),
        t('Investigative'),
        t('Artistic'),
        t('Social'),
        t('Enterprising'),
        t('Conventional'),
      ],
      datasets: [
        {
          label: 'Top Interest',
          data: [
            data['Realistic'].top,
            data['Investigative'].top,
            data['Artistic'].top,
            data['Social'].top,
            data['Enterprising'].top,
            data['Conventional'].top,
          ],
          backgroundColor: [
            '#71254B',
            '#71254B',
            '#71254B',
            '#71254B',
            '#71254B',
            '#71254B',
          ],
        },
        {
          label: 'Top 3 Interest',
          data: [
            data['Realistic'].topThree,
            data['Investigative'].topThree,
            data['Artistic'].topThree,
            data['Social'].topThree,
            data['Enterprising'].topThree,
            data['Conventional'].topThree,
          ],
          backgroundColor: [
            '#DF5297',
            '#DF5297',
            '#DF5297',
            '#DF5297',
            '#DF5297',
            '#DF5297',
          ],
        },
      ],
    };
  }

  render() {
    const { t } = this.props;
    return (
      <MainLayout>
        <div
          className="d-flex mb-5"
          style={{ fontSize: '1.1rem', fontWeight: '400' }}
        >
          <Link
            to="/analyticaldashboard"
            style={{ textDecoration: 'none', color: '#ACACAC' }}
          >
            {t("Analytical Dashboard")}
          </Link>

          <div className="mx-2" style={{ color: '#ACACAC' }}>
            {' '}
            >{' '}
          </div>
          <span
            to="/analyticaldashboard/work-values"
            style={{ textDecoration: 'none', color: '#D04C8D' }}
          >
            {t("Work Interests")}
          </span>
        </div>
        <div className="bg-white rounded border shadow p-4 mb-4">
          <DoubleBarChart data={this.state.data} />
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 mb-4">
            <div
              className="bg-white rounded border shadow p-4 text-center"
              style={{ minHeight: '100%' }}
            >
              <img src={Realistic} alt={"realistic"} className="mx-auto d-block mb-4" />

              <h3 className="mb-4">{t("Realistic (Practical)")}</h3>

              <p className="text-secondary">
                {t("Enjoy practical, hands-on work with materials and nature; problem-solving in real life")}
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6 mb-4">
            <div
              className="bg-white rounded border shadow p-4 text-center"
              style={{ minHeight: '100%' }}
            >
              <img src={Investigative} alt={"investigate"} className="mx-auto d-block mb-4" />

              <h3 className="mb-4">{t("Investigative (Thinker)")}</h3>

              <p className="text-secondary">
                {t("Enjoy like working with theory and abstract problem-solving, research and intellectual inquiry, investigating ideas")}
              </p>
            </div>
          </div>

          <div className="col-12 col-lg-6 mb-4">
            <div
              className="bg-white rounded border shadow p-4 text-center"
              style={{ minHeight: '100%' }}
            >
              <img src={Artistic} alt={"artistic"} className="mx-auto d-block mb-4" />

              <h3 className="mb-4">{t("Artistic (Creative)")}</h3>

              <p className="text-secondary">
                {t("Enjoy working in the arts and in creative work that involves designing new approaches, solutions or products")}
              </p>
            </div>
          </div>

          <div className="col-12 col-lg-6 mb-4">
            <div
              className="bg-white rounded border shadow p-4 text-center"
              style={{ minHeight: '100%' }}
            >
              <img src={Social} alt={"social"} className="mx-auto d-block mb-4" />

              <h3 className="mb-4">{t("Social (Helper)")}</h3>

              <p className="text-secondary">
                {t("Enjoy helping, serving and assisting other people and promoting others' welfare")}
              </p>
            </div>
          </div>

          <div className="col-12 col-lg-6 mb-4">
            <div
              className="bg-white rounded border shadow p-4 text-center"
              style={{ minHeight: '100%' }}
            >
              <img src={Enterprising} alt={"enterprising"} className="mx-auto d-block mb-4" />

              <h3 className="mb-4">{t("Enterprising (Persuader)")}</h3>

              <p className="text-secondary">
                {t("Enjoy leading, motivating and influencing others and taking a lead in starting and initiating projects")}
              </p>
            </div>
          </div>

          <div className="col-12 col-lg-6 mb-4">
            <div
              className="bg-white rounded border shadow p-4 text-center"
              style={{ minHeight: '100%' }}
            >
              <img src={Conventional} alt={"conventional"} className="mx-auto d-block mb-4" />

              <h3 className="mb-4">{t("Conventional (Organiser)")}</h3>

              <p className="text-secondary">
                {t("Enjoy organising and managing processes, information and data accurately")}
              </p>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

const WorkInterestValuesComponent = withTranslation()(WorkInterestValues)

export default function WorkInterestValuesComponentWrapper (props) {
  const params = useParams();

  return <WorkInterestValuesComponent params={params} {...props} />;
}
