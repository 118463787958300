import { useEffect } from 'react';
import Sidebar from '../../components/sidebar/sidebar';
import styled from 'styled-components';
import { Colors } from '../../utilities/colors';
import TopNavbar from '../../components/navbar/navbar';
import FooterAddress from '../../components/footeraddress/footeraddress';


const LayoutWrapper = styled.section`
  display: flex;
  // height: 100vh;
  background: ${Colors.secondaryColor};
  @media (max-width: 1024px) {
    display: block;
  }
`;

const NavbarSection = styled.div`
  width: 346px;
  height: 100vh;
  background: #ffffff;
  border-radius: 0px 10px 10px 0px;
  position: sticky;
  top: 0;

  @media (max-width: 1024px) {
    display: none;
  }
`;
const PageContentSection = styled.div`
  padding: 0rem 3rem;
  @media (max-width: 767px) {
    padding: 0rem 0rem;
  }
  @media (min-width: 767px) and (max-width: 1200px) {
    padding: 2rem 2rem;
  }
`;

const MainWrapper = styled.div`
  overflow-y: auto;
  flex-grow: 2;
  &::-webkit-scrollbar {
    width: 2px;
  }
  @media (max-width: 1090px) {
    padding: 1rem 1rem;
  }
`;




export default function Mainlayout({ children }) {
  useEffect(() => {
    document.body.classList.add('bg-body-cover');
  }, []);

  return (
    <LayoutWrapper>
      <NavbarSection>
        <Sidebar />
      </NavbarSection>
      <MainWrapper>
        <TopNavbar />
        <PageContentSection>
          {children}
          <FooterAddress />
        </PageContentSection>
      </MainWrapper>
    </LayoutWrapper>
  );
}
