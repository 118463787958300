import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  search: null,
  status: null
};

export const internshipFilter = createSlice({
  name: "internshipFilter",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    resetAllInternships: (state) => {
      state.search = null;
      state.status = null;
    },
  }
});


export const {
  setSearch,
  setStatus,
  resetAllInternships
} = internshipFilter.actions;

export default internshipFilter.reducer;
