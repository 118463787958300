import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import Input from '../input/input';
import Button from '../../components/button/button';
import FormErrors from '../formErrors/formErrors.js';
import {
  CancelAndExit,
  SubHeading,
  SubHeadingText,
  Label,
  SelectorWrapper,
  StyledSimpleCardCustom,
} from '../button/commonStyles';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { updateCompanyUserInfo } from '../../services/apiCalls';
import Loader from '../loader/loader';
import { isValidPhoneNumber } from "react-phone-number-input";

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const schema = yup.object().shape({
  mob_number: yup.string()
    .required()
    .test("mob_number", "Incorrect mobile number", (value) =>
      value ? isValidPhoneNumber(value) : false
    ),
  email: yup.string().email('Entered email id is invalid').required(),
  department: yup.string().required(),
  name: yup.string().required(),
});

const SettingsOrganisationDetail = ({
  data,
  updateUserData,
  orgUser,
  currentUserEmail,
  userData
}) => {
  const params = useParams();
  const ids = params && params.id;
  const [isEditable, setIsEditable] = useState(false);
  const [updateTime, setUpdatedTime] = useState('');
  
  const [loading, setLoading] = useState(false);

  const [defaultValues,] = useState({
    name: userData.first_name + ' ' + userData.last_name,
    department: userData.department,
    mob_number: userData.mob_number,
    email: userData.email,
  });

  const getOrgInfo = useCallback(async () => {
    setUpdatedTime(userData.update_time);
  }, [userData.update_time]);
  
  useEffect(() => {
    getOrgInfo();
  }, [getOrgInfo]);
  

  const {
    
    formState: { errors },
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    
  } = useForm({
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onMblNumberChange = (val) => {
    if (val) {
      setValue("mob_number", val);
    }
  };

  
  const getUpdatedCompanyUserProfile = async ({
    name,
    mob_number,
    department,
  }) => {
    const { data, status } = await updateCompanyUserInfo({
      name: name,
      phone_number: mob_number,
      department: department,
      ids: ids,
      update_time: updateTime,
    });
    if (status === 200) {
      if (data.status === 0) {
        toast.error(t(data.message));
      } else if (data.status === 1) {
        toast.success(t(data.message));
        setLoading(false);
      }
    }
  };

  const onFormSubmit = (data) => {
    if (!errors) {
      return;
    } else {
      setIsEditable(false);
      getUpdatedCompanyUserProfile(data);
    }
    setLoading(true);
  };

  const onCancel = () => {
    reset(defaultValues);
    setIsEditable(false);
    setLoading(false);
  };
  
  const { t, } = useTranslation();

  return (
    <>
      {userData && (
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Row>
            <Col>
              <Container className="p-4 container-br">
                <SubHeading>
                  <SubHeadingText>{t("Profile Information")}</SubHeadingText>
                  {!isEditable && (
                    <div
                      className="changeText"
                      onClick={() => setIsEditable(true)}
                    >
                      {t("Edit")}
                    </div>
                  )}
                </SubHeading>
                <div>
                  <Row>
                    <Col lg="6">
                      <div className="mb-4 mt-4">
                        <Label style={{ 'font-family': 'inherit!important' }}>
                          {t("Name")}*{' '}
                        </Label>
                        <SelectorWrapper>
                          {isEditable ? (
                            <>
                              <SelectorWrapper>
                                <Controller
                                  style={{ 'font-family': 'inherit!important' }}
                                  control={control}
                                  name="name"
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState: { error },
                                  }) => (
                                    <Input
                                      style={{ background: '#f3f3f3' }}
                                      placeholder={t("Name")}
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      value={value}
                                      className={error ? 'invalid' : ''}
                                    />
                                  )}
                                />
                                <FormErrors
                                  errors={errors}
                                  formControlName="name"
                                />
                              </SelectorWrapper>
                            </>
                          ) : (
                            <>
                              <StyledSimpleCardCustom className="inputReadOnly">
                                {getValues().name}
                              </StyledSimpleCardCustom>
                            </>
                          )}
                        </SelectorWrapper>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="mb-4 mt-4">
                        <Label>{t("Email")}*</Label>
                        <SelectorWrapper>
                          <>
                            <StyledSimpleCardCustom className="inputReadOnly">
                              {getValues().email}
                            </StyledSimpleCardCustom>
                          </>
                        </SelectorWrapper>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <div className="mb-4 mt-4">
                        <Label>{t("Phone no.")}*</Label>
                        <SelectorWrapper>
                          {isEditable ? (
                            <>
                              <SelectorWrapper>
                                <Controller
                                  name="mob_number"
                                  control={control}
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState: { error },
                                  }) => (
                                    <PhoneInput
                                      value={value}
                                      defaultCountry="MY"
                                      withCountryCallingCode
                                      onChange={onMblNumberChange}
                                      placeholder={t("Number")}
                                      className={error ? 'invalid' : ''}
                                      maxLength={15}
                                      minLength={12}
                                      countryCallingCodeEditable={false}
                                      international
                                    />
                                  )}
                                />
                                <FormErrors
                                  errors={errors}
                                  formControlName="mob_number"
                                />
                              </SelectorWrapper>
                            </>
                          ) : (
                            <>
                              <StyledSimpleCardCustom className="inputReadOnly">
                                {getValues().mob_number}
                              </StyledSimpleCardCustom>
                            </>
                          )}
                        </SelectorWrapper>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="mb-4 mt-4">
                        <Label>{t("Department")}* </Label>
                        <SelectorWrapper>
                          {isEditable ? (
                            <>
                              <SelectorWrapper>
                                <Controller
                                  control={control}
                                  name="department"
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState: { error },
                                  }) => (
                                    <Input
                                      style={{ background: '#f3f3f3' }}
                                      placeholder={t("Human Resource")}
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      value={value}
                                      className={error ? 'invalid' : ''}
                                    />
                                  )}
                                />
                                <FormErrors
                                  errors={errors}
                                  formControlName="department"
                                />
                              </SelectorWrapper>
                            </>
                          ) : (
                            <>
                              <StyledSimpleCardCustom className="inputReadOnly">
                                {getValues().department}
                              </StyledSimpleCardCustom>
                            </>
                          )}
                        </SelectorWrapper>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </Col>
          </Row>
          {isEditable && (
            <Row>
              <ButtonGroup>
                <div lg="3">
                  {loading ? (
                    <Loader />
                  ) : (
                    <Button name={t("Save")} type="submit" light />
                  )}
                </div>
                <div lg="3">
                  <CancelAndExit
                    type="button"
                    className="cancelBtn"
                    onClick={onCancel}
                  >
                    {t("Cancel")}
                  </CancelAndExit>
                </div>
              </ButtonGroup>
            </Row>
          )}
        </form>
      )}
    </>
  );
};

export default SettingsOrganisationDetail;
