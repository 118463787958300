import { useState, useEffect, useRef, createRef } from "react";

import styled from "styled-components";

import Mainlayout from "../../layouts/mainLayout/mainlayout";
import Button from "../../components/button/button";
import InputGroup from "../../components/inputGroup/inputGroup";
// import Input from "../../components/input/input";

import { useNavigate, useLocation, Link } from "react-router-dom";
import { Colors } from "../../utilities/colors";
import {
  CreateBlog,
  allBlogList,
  fileUpload,
  updateBlog,
  viewBlog,
} from "../../services/apiCalls";
import { useForm, Controller } from "react-hook-form";
import ImageUploader from "./imageUpload";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import RichTextEditor from "react-rte";
import Loader from "../../components/loader/loader";
import { ReactComponent as CloudUploadIcon } from "../../assets/svg/cloudUpload.svg";
import { AspectRatio } from "react-aspect-ratio";
import "react-aspect-ratio/aspect-ratio.css";

const Uploader = styled.img`
  max-width: 500px;
  @media (max-width: 500px) {
    max-width: 280px;
  }
`;
const LabelText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;

const UploadWrapper = styled.div`
  displat: flex;
  max-width: 100%;
  text-align: center;
`;
const Input = styled.input`
  position: absolute;
  left: 0rem;
  right: 0rem;
  top: 0px;
  bottom: 0px;
  opacity: 0;
  cursor: pointer;
`;
const InputWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;
const FormWrapper = styled.div`
  background: ${Colors.light};
  border: 1px solid rgba(228, 228, 228, 0.26);
  box-sizing: border-box;
  border-radius: 32px;
  padding: 2.5rem;
  margin-top: 30px;
`;

const CardWrapper = styled.div`
  overflow: hidden;
  display: inline-block;
  position: relative;
  bottom: 0;
`;

const CustomButton = styled.button`
  width: 150px;
  background-color: white;
  color: #d04c8d;
  border-radius: 128px;
  outline: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding: 13px 20px;
  margin: 15px 0px;
  cursor: pointer;
  margin-left: 1rem;
  border: 1px solid ${Colors.pink};
  &:hover {
    background: ${Colors.pink};
    color: ${Colors.light};
  }
`;

export default function NewBlog() {
  const [request, setRequest] = useState({
    title: "",
    description: "",
    image: "",
    status: "public",
  });

  const [showImg, setShowImg] = useState("");
  const [loader, setLoader] = useState(false);
  const [duplicateMsg, SetDuplicateMsg] = useState(false);
  const [data, setData] = useState([]);
  const [inputOnchange, setInputOnchange] = useState(false);
  const { title, description } = request;
  const { t, i18n } = useTranslation();

  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    console.log(location);
    if (location.state) {
      getBlog(location.state.code);
    }
    blogList();
  }, [location.state]);

  const {
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      image: "",
      status: "public",
    },
    // resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const formVal = watch();

  const getBlog = async (code) => {
    setLoader(true);
    const { data, status } = await viewBlog(code);
    if (data.status == 1) {
      setLoader(true);
      const newDetails = {
        ...request,
        code: data.data.code,
        title: data.data.title,
        description: data.data.description,
        image: data.data.image,
        status: data.data.status,
      };
      if (data.data.image) {
        setLoader(false);
        setShowImg(data.data.image);
      }
      setRequest(newDetails);
      setValue("description", data.data.description);
    }
  };

  console.log("request", request);

  const inputChange = (e) => {
    const { name, value } = e.target;
    const newUserDetail = { ...request, [name]: value };
    setRequest(newUserDetail);
  };

  const pickImage = async (event) => {
    let minFileSize = (1024 * 1024) / 2; //500kb
    let maxFileSize = 1024 * 1024 * 5; //5mb

    if (event.target.files[0].size < minFileSize) {
      toast.error(t("Please upload the file size above 500 KB"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (event.target.files[0].size > maxFileSize) {
      toast.error(t("Your file must not exceeds 5 MB"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setLoader(true);
      const formdata = new FormData();
      formdata.append("obj_content", event.target.files[0]);
      const { data, status } = await fileUpload(formdata);
      if (status == 200) {
        setLoader(false);
        setShowImg(data.data.obj_content);
        const newUserDetail = { ...request, image: data.data.obj_content };
        setRequest(newUserDetail);
        toast.success(t("Image uploaded Successfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const AddBlogs = async () => {
    if (location.state) {
      if (request.image === "") {
        toast.error(t("please upload the image"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (request.title === "" || request.description === "") {
        toast.error(t("please fill all the fields"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (duplicateMsg) {
        toast.error(t("Title already taken!"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        const body = {
          code: location.state.code,
          ...request,
        };
        const { data, status } = await updateBlog(body);
        if (data.status == 1) {
          toast.success(t("Blog has been updated Successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/blogs");
        } else {
        }
      }
    } else {
      if (request.image === "") {
        toast.error(t("please upload the image"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (request.title === "" || request.description === "") {
        toast.error(t("please fill all the fields"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        const body = {
          ...request,
        };

        const { data, status } = await CreateBlog(body);
        if (data.status === 0) {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.success(t("Blog has been added Successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/blogs");
        }
      }
    }
  };

  const cancelBlog = () => {
    navigate("/blogs");
  };
  const blogList = async () => {
    let body = {
      search: "",
    };
    const { data, status } = await allBlogList(body);

    if (data?.status === 1) {
      setData(data.data);
    } else {
      setData([]);
    }
  };

  const OnInputBlur = () => {
    if (location.state) {
      if (
        location.state.code === request.code &&
        location.state.title === request.title
      ) {
        SetDuplicateMsg(false);
      } else {
        const result = data?.filter((item) => item.title === request.title);
        if (result.length > 0) {
          SetDuplicateMsg(true);
        } else {
          SetDuplicateMsg(false);
        }
      }
    } else {
      const result = data?.filter((item) => item.title === request.title);
      if (result.length > 0) {
        SetDuplicateMsg(true);
      } else {
        SetDuplicateMsg(false);
      }
    }
  };
  return (
    <Mainlayout>
      <div
        className="d-flex mb-5"
        style={{
          fontSize: "1.1rem",
          fontWeight: "400",
          marginLeft: "15px",
        }}
      >
        <Link
          to="/blogs"
          style={{
            textDecoration: "none",
            color: "#ACACAC",
            marginLeft: "15px",
          }}
        >
          Blogs &nbsp; {">"}
        </Link>
        <div className="mx-2" style={{ color: "#ACACAC" }}></div>
        <span style={{ textDecoration: "none", color: "#D04C8D" }}>
          {location.pathname == "/blogs/create-blog"
            ? t("Add new")
            : location.pathname == "/blogs/edit-blog"
            ? t("Edit blog")
            : ""}
        </span>
      </div>

      <UploadWrapper>
        <InputWrapper>
          <CardWrapper>
            <>
              {loader ? (
                <Loader />
              ) : showImg ? (
                <div className="upload-btn-wrapper">
                  <button className="upload-btn ">
                    <CloudUploadIcon />
                    <span style={{ position: "absolute", left: "3.2rem" }}>
                      {t("Upload")}
                    </span>
                  </button>
                  <Input
                    title=""
                    type="file"
                    name="file"
                    accept="image/x-png,image/jpeg"
                    onChange={pickImage}
                  />

                  <Uploader src={showImg} alt="uploader logo1" />
                </div>
              ) : (
                <>
                  <Input
                    type="file"
                    name="file"
                    accept="image/x-png,image/jpeg"
                    onChange={pickImage}
                  />
                  <ImageUploader />
                </>
              )}
            </>
          </CardWrapper>
        </InputWrapper>
      </UploadWrapper>
      <FormWrapper>
        <div style={{ marginBottom: "20px" }}>
          <InputGroup
            style={{ marginBottom: "0px !important" }}
            name={t("Title")}
            input={{
              placeholder: t("Type"),
              onChange: inputChange,
              onBlur: OnInputBlur,
              bg: Colors.blogTextBox,
              value: title,
              autoFocus: "autoFocus",
              key: "title",
              name: "title",
            }}
            err={duplicateMsg && "Title already exist"}
            type="text"
          />
        </div>

        <LabelText style={{ marginBottom: "0.5rem" }}>{t("Content")}</LabelText>

        <MyStatefulEditor
          style={{ display: "flex", height: "500px" }}
          value={description}
          onChange={(value) =>
            setRequest({
              ...request,
              description: value,
            })
          }
        />

        <Button width="150px" name={t("Submit")} onClick={AddBlogs} />
        <CustomButton onClick={cancelBlog}>{t("Cancel")}</CustomButton>
      </FormWrapper>
    </Mainlayout>
  );
}

const MyStatefulEditor = (props) => {
  const [value, setValue] = useState(
    RichTextEditor.createValueFromString(props.value, "html")
  );

  const editorRef = createRef();

  useEffect(() => {
    setValue(RichTextEditor.createValueFromString(props.value, "html"));
  }, [props.value]);

  const onSave = () => {
    const element = editorRef.current;

    setTimeout(() => {
      if (!element || !element.contains(document.activeElement)) {
        if (props.onChange) {
          props.onChange(value.toString("html"));
        }

        if (props.onBlur) {
          props.onBlur(value.toString("html"));
        }
      }
    });
  };

  return (
    <div ref={editorRef} style={{ height: "100%" }}>
      <RichTextEditor
        {...props}
        value={value}
        onChange={setValue}
        onBlur={onSave}
        rootStyle={{ height: "100%" }}
        className="rte"
        editorClassName="rte-editor"
        toolbarClassName="rte-toolbar"
      />
    </div>
  );
};
