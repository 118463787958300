import { ErrorMessage } from "@hookform/error-message";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const ErrorUnit = styled.div`
  color: red;
  margin: 0px;
  padding: 5px;
  font-weight: 500;
  &:before {
    display: inline;
    content: "⚠ ";
  }
`;

export default function FormErrors({ errors, formControlName }) {
  const { t, } = useTranslation();
  return (
    <ErrorMessage
      errors={errors}
      name={formControlName}
      render={({ messages }) => {
        return messages
          ? Object.entries(messages).map(([type, message]) => (
              <ErrorUnit key={type}>{t(message)}</ErrorUnit>
            ))
          : null;
      }}
    />
  );
}
