import moment from 'moment';


export const validateInputs = (obj) => {
  const values = Object.values(obj);
  const isValid = values.every((val) => val.length > 1);

  return isValid;
};



export const validEmailRegex = RegExp(
  /^(([^<>()\],;:\s@]+(\.[^<>()\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i
);
export const updatedValidEmailRegex = RegExp(
  /^([\w+-._]+@[\w-.]+\.[A-Za-z0-9]{2,10},?)+$/
);


export const checkBothPasswords = (pwdOne, pwdTwo) => {
  let errVal = true;
  if (pwdTwo.length > 0) {
    errVal = pwdOne === pwdTwo ? true : false;
  }
  return errVal;
};

export const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const debounce = (fn, delay) => {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      fn(...args);
    }, delay);
  };
};

export const dateToDateMonthInWordsYear = (date) => {
  return moment(date).format('DD MMMM YYYY');
};

export const isThisDatePastToday = (date) => {
  return moment(date).diff(moment(new Date(), 'days')) > 0 ? true : false;
};

export const filePathToFileName = (filePath) => {
  return filePath?.split('/').reverse()[0];
};

export const calculateAge = (birthDate) => {
  birthDate = new Date(birthDate);
  let today = new Date();

  var years = today.getFullYear() - birthDate.getFullYear();

  if (
    today.getMonth() < birthDate.getMonth() ||
    (today.getMonth() === birthDate.getMonth() &&
      today.getDate() < birthDate.getDate())
  ) {
    years--;
  }

  return years;
};

export const formLocation = (city, state, country) => {
  return [city, state, country].filter(Boolean).join(', ');
};

export const stringFormat = function (url, ...args) {
  let i = 0;
  console.log(
    url.replace(/{}/g, function () {
      return typeof args[i] != 'undefined' ? args[i++] : '';
    })
  );
  i = 0;
  return url.replace(/{}/g, function () {
    return typeof args[i] != 'undefined' ? args[i++] : '';
  });
};

export function isEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export const getLocationFormatted = (location) => {
  const addCommaIfNeeded = (value) => `${value}${value && ', '}`;
  return (
    <div>
      <div>{addCommaIfNeeded(location.address_line_one)}</div>
      <div>{addCommaIfNeeded(location.address_line_two)}</div>
      <div>{`${addCommaIfNeeded(location.city)}${addCommaIfNeeded(
        location.state
      )} ${addCommaIfNeeded(location.country)} ${location.postcode}`}</div>
    </div>
  );
};

export const downloadPdf = (file) => {
  fetch(file, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }).then((response) => response.blob().then(blob => {
      let filename = file;
      filename = filename.split("/");
      filename = filename[filename.length-1];
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
    }))
}

export const logsDateFormat=(inputDate)=>{
      var d = new Date(inputDate),
      month1 = '' + (d.getMonth() + 1),
      day1 = '' + d.getDate(),
      time = '' + d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric',second:'numeric', hour12: true }),
      year1= d.getFullYear();
      
      if (month1.length < 2) month1 = '0' + month1;
      if (day1.length < 2) day1 = '0' + day1;

      return [year1, month1, day1].join('-')+' '+time;
    }