import { useState, useEffect } from "react";

import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Colors } from "../../utilities/colors";
import MainLayout from "../../layouts/mainLayout/mainlayout";
import { useParams, Link } from "react-router-dom";
import { getCompanyData, onGettingCompanyInfo, getCompanyUserInfo } from "../../services/apiCalls";
import Loader from "../../components/loader/loader";
import { updateCompanyData } from "../companies/viewDetails/reducer";
import { fetchCompanyUserLog } from "./reducer";
import MyCompanyUserView from "../../components/myCompanyUserView/myCompanyUserView";
import SettingsOrganisationDetail from "../../components/settingsOrganisationDetailByUser/settingsOrganisationDetail";
import CompanyUserTimestamp from "../../components/companyUserTimestamp/companyUserTimestamp";
import { useCallback } from "react";

const SettingsWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 2rem;
  margin: 2rem 0;
  min-height: 90vh;
`;

export default function CompanyUserView() {
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState("");
  const [, setOrgUser] = useState("");
  const [, setCurrentUserEmail] = useState("");
  const [userData, setUserData] = useState('');

  const dispatch = useDispatch();
  const params = useParams();
  const id = params && params.id;
  const org = params && params.company;

  const getData = useCallback (async (code) => {
    const { data, } = await getCompanyData({
      code: code,
    });
    if (data && data.status === 1) {
      const newId = data.data.org_user.find(x => x.user_id === id).id;
      setLoading(false);
      dispatch(updateCompanyData(data.data));
      dispatch(fetchCompanyUserLog(newId));
    } else {
      setLoading(false);
    }
    getUserData(code);
  }, [dispatch, id])

  useEffect(() => {
    if (org) {
      getData(org);
      console.log(params);
      
    }
  }, [getData, org, params]);

  
  const getUserData = async (code) => {
    const { data, } = await onGettingCompanyInfo({ code: code });
    if (data && data.status === 1) {
      setCurrentUserEmail(data.data["org_email"]);
      setOrgUser(data.data["org_user"]);
      setUserInfo(data.data);
    }
  };
  const getCompanyUserData = useCallback (async () => {
    const { data, } = await getCompanyUserInfo(id);
    if (data && data.status === 1) {
      setUserData(data.data);
    }
  }, [id])

  useEffect(() => {
    getCompanyUserData(); 
  }, [org , getCompanyUserData])

  const { t, } = useTranslation();

  return (
    <MainLayout back={true}>
      <div role="presentation">
        <div
          className="d-flex mb-5"
          style={{ fontSize: "1.1rem", fontWeight: "400", marginLeft: "15px" }}
        >
          <Link
            to="/companiesusers"
            style={{ textDecoration: "none", color: "#ACACAC" }}
          >
            {t("Companies Users")} &nbsp; {">"}
          </Link>
          <div className="mx-2" style={{ color: "#ACACAC" }}></div>
          <span style={{ textDecoration: "none", color: "#D04C8D" }}>
            {t("Company User Details")}
          </span>
        </div>
      </div>
      <SettingsWrapper>
        {loading ? (
          <Loader />
        ) : (
          userInfo && (
            <>
              <MyCompanyUserView
                
                userData={userData}
              />

              <SettingsOrganisationDetail
               
                userData={userData}
              />
              <CompanyUserTimestamp />
            </>
          )
        )}
      </SettingsWrapper>
    </MainLayout>
  );
}
