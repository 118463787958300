import { useState } from "react";
import * as yup from "yup";
import { Message, SingleField, FormLabel } from "./companyUserListStyles";
import Button from "../../components/button/button";
import Loader from "../../components/loader/loader";
import { findIndex } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import TextArea from "../../components/description/description";
import ErrorField from "../../components/errorField/errorField";

import { useForm, Controller } from "react-hook-form";
import { companyUserAction } from "../../services/apiCalls";

export default function DeniedAlertBody({
  close,
  user_id,
  update_time,
  users,
  setUsers,
}) {
  const [syncing, setSyncing] = useState(false);
  const { t, } = useTranslation();

  const schema = yup.object().shape({
    comments: yup.string().required("Comments is a required field."),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  
  const handleDenied = async (values) => {
    const body = {
      id: user_id,
      status: "rejected",
      comments: values.comments,
      update_time: update_time,
    };

    
    setSyncing(true);
    const { data, status } = await companyUserAction(body);
    setSyncing(false);

    if (status === 200) {
      if (data.status === 1) {
        var tempUsers = [...users];
        var index = findIndex(tempUsers, { id: user_id });
        tempUsers[index].status = "rejected";
        setUsers(tempUsers);

        toast.success(t(data.message), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(t(data.message), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      
      close();
    }
  };

  return (
    <div style={{ padding: "0 40px 20px 40px" }}>
      <Message
        style={{
          textAlign: "center",
          fontSize: "26px",
          fontWeight: "bold",
          margin: "0px auto 10px auto",
        }}
      >
        {t("Are you sure you would like to deny (Newly added user)?")}
      </Message>

      <form onSubmit={handleSubmit(handleDenied)}>
        <div className="container">
          <SingleField style={{ marginBottom: 0 }}>
            <FormLabel>{t("Rejection Note")}</FormLabel>
            <Controller
              name="comments"
              control={control}
              render={({ field }) => (
                <TextArea
                  style={{ background: "#F3F3F3" }}
                  {...field}
                  rows="3"
                  placeholder={t("Enter Comment")}
                />
              )}
            />
            {errors.comments && <ErrorField err={t(errors.comments.message)} />}
          </SingleField>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            {syncing === false ? (
              <Button
                width="180px"
                name={t("Yes")}
                type="submit"
                style={{ marginRight: "30px" }}
                disabled={syncing}
              />
            ) : (
              <Loader />
            )}

            <Button
              width="180px"
              name={t("No")}
              type="button"
              color="#FFFFFF"
              onClick={() => {
                close();
                reset();
              }}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
