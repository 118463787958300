import { getLocationFormatted } from '../../../../utilities/commonFunctions';
import { ReactComponent as Trash } from '../../../../assets/svg/trash.svg';
import { ReactComponent as Pencil } from '../../../../assets/svg/pencil.svg';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const PencilButton = styled.button`
  background-color: transparent;
  border: 0px;
`;
const TrashButton = styled.button`
  background-color: transparent;
  border: 0px;
`;

const LocationType = styled.div`
  font-family: 'General Sans', sans-serif;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #696969;
  text-transform: capitalize;
`;

const LocationAddress = styled.div`
  font-family: 'General Sans', sans-serif;

  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202020;
`;

export default function UniversityLocationReadOnly({
  enableEdit,
  location,
  deleteAction,
}) {
  console.log('location', location.type === 'primary');
  const { t } = useTranslation();
  return (
    <Row>
      <Col xs="10">
        <LocationType>{t(location.type)}</LocationType>
        <LocationAddress>{getLocationFormatted(location)}</LocationAddress>
      </Col>
      <Col xs="2" className="d-flex justify-content-end">
        <PencilButton onClick={enableEdit.bind(this, location)}>
          <Pencil />
        </PencilButton>
        {location.type === 'secondary' ? (
          <TrashButton onClick={deleteAction.bind(this, location)}>
            <Trash />
          </TrashButton>
        ) : null}
      </Col>
    </Row>
  );
}
