import styled from 'styled-components';

import { Colors } from '../../utilities/colors';


const InputField = styled.textarea`
  background: ${(props) => (props.bg ? props.bg : Colors.inputBg)};
  border-radius: 29px;
  border: 0px;
  padding: 12px 20px;
  outline: none;
  width: -webkit-fill-available;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.dark};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${Colors.inputText};
  }
  :-ms-input-placeholder {
    color: ${Colors.inputText};
  }
`;


export default function TextArea(props) {
  return <InputField bg={props.bg} {...props} />;
}
