import { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Colors } from "../../utilities/colors";
import Logo from "../../assets/companylogosettings.png";
import Phone from "../../assets/icons/phone.png";
import Mail from "../../assets/icons/mail.png";
import {  companyAction } from "../../services/apiCalls";
import UpdateCompanyIntroSection from "../updateCompany/updateCompanyIntroSection";
import Button from "../../components/button/button";
import { Row, Col } from "react-bootstrap";
import AlertModalCompany from "../../components/alertModal/alertModalCompany";
import { useForm, Controller } from "react-hook-form";
import TextArea from "../../components/description/description";
import ErrorField from "../../components/errorField/errorField";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

const FlexWrapper = styled.div`
  display: flex;
`;
const SectionWrappper = styled(FlexWrapper)`
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const Image = styled.img`
  width: 210px;
  height: 210px;
  border-radius: 50%;
  margin-bottom: 20px;
`;
const ContentWrapper = styled.div`
  margin-left: 20px;
  width: 100%;
  > h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: ${Colors.labelText};
    margin: 0;
    margin-bottom: 5px;
    text-transform: capitalize;
  }
  > h {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    color: #525252;
  }
  > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.labelText};
  }
  > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.labelText};
  }
  @media (max-width: 500px) {
    margin-left: 0px;
  }
`;
const DividerLine = styled.hr`
  margin: 10px 0;
  border: 0.4px solid ${Colors.labelText};
`;

const LinkText = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: block;
  color: #d04c8d;
  text-transform: capitalize;
`;
const ImageWrapper = styled.div`
  text-align: center;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f8f8f8;
  border-radius: 54px;
  margin: 8px 0px;
  padding: 10px 20px;
  > p {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #525252;
    word-break: break-all;
    margin: 0;
  }
`;
const Message = styled.div`
  padding-left: 20px;
  font-size: 22px;
  padding-bottom: 5px;
`;
const Icon = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`;

const Label = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  color: ${(props) => (props.color ? props.color : Colors.labelText)};
  text-transform: ${(props) => (props.color ? "unset" : "capitalize")};
`;
const UserHelpText = styled(Label)`
  color: ${Colors.inputHighlightText};
  cursor: pointer;
  text-align: right;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
`;
const SingleField = styled.div`
  margin-bottom: 30px;
`;
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;
const HeaderSection = styled.div`
  display: grid;
  margin: 0;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.labelText};
    margin: 0;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export default function MyCompanyCard(props) {
  const navigate = useNavigate();
  const search = useLocation().search;
  const code = new URLSearchParams(search).get("code");
  const company = useSelector((state) => state.company.companyData);
  const [show, setShow] = useState(false);
  const [action, setAction] = useState("");
  const [, setCode2] = useState();
  const [updateTime, setUpdateTime] = useState();
  const [, setStatus] = useState(true);
  const { t, } = useTranslation();

  console.log(company, "haaaaaaaaaaaaaaaaa");
  const country =
    company.locations && company.locations.length > 0
      ? company.locations[0].country_abbr
      : "";
  const rgtr_address =
    company.locations && company.locations.length > 0
      ? company.locations[0].rgtr_address
      : "";
  const state =
    company.locations && company.locations.length > 0
      ? company.locations[0].state_abbr
      : "";
  const city =
    company.locations && company.locations.length > 0
      ? company.locations[0].city_abbr
      : "";
  const [open, setOpen] = useState(false);

  

  const onChangeContent = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const closeModal = () => {
    setShow(false);
  };

  const handleActionCall = (e, code, actionName, a) => {
    e.stopPropagation();
    setShow(true);
    setAction(actionName);
    setCode2(code);
    setUpdateTime(a);
  };

  const schema = yup.object().shape({
    comments: yup.string().required("Comments is a required field."),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const content = () => {
    return (
      <div style={{ padding: "0 40px 20px 40px" }}>
        <Message
          style={{
            textAlign: "center",
            fontSize: "26px",
            fontWeight: "bold",
            margin: "0px auto 10px auto",
          }}
        >
          {t("Are you sure you want to")} {action === "accepted" ? t("approve") : t("deny")}{" "}
          {t("this company ?")}
        </Message>
        {action !== "accepted" ? (
          <form onSubmit={handleSubmit(onRejectionSubmit)}>
            <div className="container">
              <SingleField style={{ marginBottom: 0 }}>
                <FormLabel>{t("Rejection Note")}</FormLabel>
                <Controller
                  name="comments"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      style={{ background: "#F3F3F3" }}
                      {...field}
                      rows="3"
                      placeholder={t("Enter Comment")}
                    />
                  )}
                />
                {errors.comments && (
                  <ErrorField err={t(errors.comments.message)} />
                )}
              </SingleField>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Button
                  width="180px"
                  name={t("Yes")}
                  type="submit"
                  style={{ marginRight: "30px" }}
                />
                <Button
                  width="180px"
                  type="button"
                  name={t("No")}
                  color="#FFFFFF"
                  onClick={() => {
                    setShow(false);
                    reset();
                  }}
                />
              </div>
            </div>
          </form>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <Button
              width="180px"
              name={t("Yes")}
              onClick={() => approveCompany()}
              style={{ marginRight: "30px" }}
            />
            <Button
              width="180px"
              name={t("No")}
              color="#FFFFFF"
              onClick={() => setShow(false)}
            />
          </div>
        )}
      </div>
    );
  };

  const onRejectionSubmit = async (values) => {
    const body = {
      code: code,
      status: action,
      comments: values.comments,
      update_time: updateTime,
      id: company.id
    };

    setStatus(true);

    const { data, status } = await companyAction(body);

    if (status === 200) {
      setStatus(false);
      setShow(false);
      if (data.status === 1) {
        setStatus(false);
        toast.success(
          action === "accepted"
            ? t("Company has been approved")
            : t("Company has been denied"),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        
      }
    }
  };

  const approveCompany = async () => {
    const body = {
      code: code,
      status: action,
      update_time: updateTime,
      id: company.id
    };

    setStatus(true);

    const { data, status } = await companyAction(body);

    if (status === 200) {
      setStatus(false);
      setShow(false);
      if (data.status === 1) {
        setStatus(false);
        toast.success(
          action === "accepted"
            ? t("Company has been approved")
            : t("Company has been denied"),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        
      }
    }
  };

  return (
    <SectionWrappper>
      <ImageWrapper className="col-md-4">
        <Image
          src={company && company.logo_path ? company.logo_path : Logo}
          alt="company logo"
        />
      </ImageWrapper>
      <ContentWrapper>
        <h3>{company && company.name}</h3>
        <p>
          {company && rgtr_address}, {city}, {state}
          <br />
          {country}
        </p>
        <HeaderSection>
          {company && <p>SSM : {company.ssm_number}</p>}
          {company.status === "rejected" || company.status === "accepted" ? ( 
            
            ""
          ) : (
            <UserHelpText onClick={onChangeContent}>{t("Edit")}</UserHelpText>
          )}
        </HeaderSection>

        <DividerLine />
        <p>{company.moto ? company && company.moto + "." : ""}</p>
        <br />

        <h>{t("Type Of Organization")}</h>
        <br />
        <LinkText>{company && company.type_of_org_abbr}</LinkText>
        <br />
        <h>{t("Sector")}</h>
        <LinkText>{company && company.sector_abbr}</LinkText>
        <br />
        {company.status === "rejected" ? (
          
          ""
        ) : (
          <ButtonWrapper>
            <Button
              width="40%"
              name={t("View Internships")}
              onClick={(e) => {
                navigate({
                  pathname: "/internships",
                  search: "?title=" + encodeURIComponent(company.name),
                });
                
              }}
            />
          </ButtonWrapper>
        )}
        {company && company.phone_number ? (
          <DetailsWrapper>
            <Icon src={Phone} />
            <p>{"9932029430328"}</p>
          </DetailsWrapper>
        ) : null}
        {company && company.org_email ? (
          <DetailsWrapper>
            <Icon src={Mail} />
            <p>{"teenshankar@gmail.com"}</p>
          </DetailsWrapper>
        ) : null}
        {company.status === "pending" ? (
          <Col lg="12" xl="8" md="12">
            <Row>
              <Col lg="4">
                <Button
                  width="100%"
                  name={t("Approve")}
                  onClick={(e) => {
                    handleActionCall(
                      e,
                      company.id,
                      "accepted",
                      company.update_time
                    );
                  }}
                />
              </Col>
              <Col lg="4">
                <Button
                  width="100%"
                  name={t("Deny")}
                  color="#FFFFFF"
                  onClick={(e) => {
                    handleActionCall(
                      e,
                      company.id,
                      "rejected",
                      company.update_time
                    );
                  }}
                />
              </Col>
            </Row>
          </Col>
        ) : (
          ""
        )}
      </ContentWrapper>
      <AlertModalCompany show={show} close={closeModal} content={content()} />
      {open && <UpdateCompanyIntroSection open={open} onClose={onCloseModal} />}
    </SectionWrappper>
  );
}
