import { Modal } from "react-bootstrap";

function AlertModalCompany(props) {
  return (
    <div>
      <Modal show={props.show} onHide={props.close} centered>
        <div style={{ padding: "40px 0 0 0" }}>
          <div style={{ textAlign: "center" }}>{props.content}</div>
        </div>
      </Modal>
    </div>
  );
}

export default AlertModalCompany;
