import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const messageReducer = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, action) => {
      return { message: action.payload };
    },
    clearMessage: () => {
      return { message: "" };
    },
  },
});


export const { setMessage, clearMessage } = messageReducer.actions;

export default messageReducer.reducer;
