import * as yup from 'yup';

export const schema = yup.object().shape({

  website: yup.string().url('Enter correct url!'),
  
  bsn_nature: yup.string().required('Nature of Business is required.'),
  bsn_address: yup.string().required('Address is required.'),
  bsn_country_code: yup.string().required('Postcode is required.'),
  bsn_country: yup.string().required('Country is required.'),
  bsn_state: yup.string().required('State is required.'),
  bsn_city: yup.string().required('City is required.'),
});

export const schemaObj = yup.object().shape({
  sector: yup.string().required(),
  type_of_org: yup.string().required(),
  org_email: yup.string().email('Email is required').required(),
  org_number: yup.string().required(),
});
