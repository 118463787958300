import { createSlice } from "@reduxjs/toolkit";

import { schemaNewObj } from "./staticContent";

const initialState = {
  contactNewData: schemaNewObj,
  isEdit: false,
};

export const newContactReducer = createSlice({
  name: "contact",
  initialState,
  reducers: {
    updateContactData: (state, action) => {
      state.contactNewData = action.payload;
    },
    updateEdit: (state, action) => {
      state.isEdit = action.payload;
    },
  },
});


export const { updateContactData, updateEdit } = newContactReducer.actions;

export default newContactReducer.reducer;
