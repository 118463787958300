import { stringFormat } from '../utilities/commonFunctions';
import { onApiCall } from './CommonApi';

class UniversityService {
  getUniversities(data) {
    return onApiCall({
      url: '/api/universities/?search=' + data.search,
      method: 'GET',
    });
  }
  getUniversityById(universityId) {
    return onApiCall({
      url: stringFormat('/api/universities/{}/', universityId),
      method: 'GET',
    });
  }
  createUniversity(universityId, universityData) {
    return onApiCall({
      url: stringFormat('/api/universities/{}/addresses/', universityId),
      method: 'POST',
      data: universityData,
    });
  }
  createNewUniversity(universityData) {
    return onApiCall({
      url: stringFormat('/api/universities/'),
      method: 'POST',
      data: universityData,
      isFileUpload: true,
    });
  }
  updateUniversity(universityId, universityData) {
    return onApiCall({
      url: stringFormat('/api/universities/{}/', universityId),
      method: 'PUT',
      data: universityData,
      isFileUpload: true,
    });
  }

  changeUniversityLogo(universityId, logo) {
    const formData = new FormData();

    formData.append('logo', logo, logo.name);

    return onApiCall({
      url: stringFormat('/api/universities/{}/change_logo/', universityId),
      method: 'POST',
      data: formData,
    });
  }

  getUniversityLocations(universityId) {
    return onApiCall({
      url: stringFormat('/api/universities/{}/addresses/', universityId),
      method: 'GET',
    });
  }
  getUniversityLocationById(universityId, addressId) {
    return onApiCall({
      url: stringFormat(
        '/api/universities/{}/addresses/{}/',
        universityId,
        addressId
      ),
      method: 'GET',
    });
  }
  updateUniversityLocation(universityId, addressId, universityLocationData) {
    return onApiCall({
      url: stringFormat(
        '/api/universities/{}/addresses/{}/',
        universityId,
        addressId
      ),
      method: 'PUT',
      data: universityLocationData,
    });
  }
  deleteUniversityLocation(universityId, addressId) {
    return onApiCall({
      url: stringFormat(
        '/api/universities/{}/addresses/{}/',
        universityId,
        addressId
      ),
      method: 'DELETE',
    });
  }
  deleteUniversity(universityId) {
    return onApiCall({
      url: stringFormat('/api/users/{}/', universityId),
      method: 'DELETE',
    });
  }
}

export default new UniversityService();
