import { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  DataTableWrapper,
  Heading,
  LoaderBackdrop,
  LoaderWrapper,
} from "../../pages/analyticalDashboardPage/commonStyles";
import { ReactComponent as Trash } from "../../assets/svg/trash.svg";
import { ReactComponent as Pencil } from "../../assets/svg/pencil.svg";
import { ReactComponent as GreenCircle } from "../../assets/svg/greenCircle.svg";
import { ReactComponent as RedCircle } from "../../assets/svg/redCircle.svg";
import Selector from "../select/select";
import { useDispatch, useSelector } from "react-redux";
import { updateModal } from "../../reducers/universityModalReducer";
import { ReactComponent as Loader } from "../../assets/svg/loader.svg";
import EventBus from "../../utilities/eventBus";
import userManagementService from "../../services/userManagement.service";
import { updateGlobalLoader } from "../../reducers/universityLoadingReducer";
import {
  resetAll,
  setPage,
  setPerPage,
  setRoles,
  setSearch,
  setSortDirection,
  setSortField,
  
} from "../../reducers/userManagementReducer";
import { getUsersByUniversity } from "../../actions/userManagement";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

const CustomHeading = styled(Heading)`
  font-family: "General Sans", sans-serif;
  color: #acacac;
  font-size: 20px;
`;

const TableButtons = styled.div`
  button {
    margin-right: 15px;
  }
`;
const PencilButton = styled.button`
  background-color: transparent;
  border: 0px;
  font-family: "General Sans", sans-serif;
`;
const TrashButton = styled.button`
  background-color: transparent;
  border: 0px;
`;
const DataTableLoaderWrapper = styled.div`
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  font-family: "General Sans", sans-serif;
  right: 0;
  bottom: 0;
  left: 0;
`;

const columnStyling = {
  fontFamily: "General Sans,sans-serif",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 18,
  lineHeight: "24px",
  color: "#202020",
  "@media (max-width: 990px)": {
    display: "flex",
    fontSize: 16,
    justifyContent: "flex-start",
    padding: "0.5rem 0 0 0.5rem",
  },
};
const columnStylingRole = {
  fontFamily: "General Sans,sans-serif",

  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 18,
  lineHeight: "24px",
  color: "#202020",
  "@media (max-width: 990px)": {
    display: "flex",
    fontSize: 16,
    justifyContent: "flex-start",
    padding: "0.5rem 0 0 0.5rem",
    color: "#d04c8d",
  },
};
const columnStylingstatus = {
  fontFamily: "General Sans,sans-serif",

  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 18,
  lineHeight: "24px",
  color: "#202020",
  "@media (max-width: 990px)": {
    display: "flex",
    justifyContent: "flex-start",
    padding: "0",
    fontSize: 16,
    width: "fit-content",
    backgroundColor: "#f3f3f3",
    margin: "8px",
    borderRadius: "123px",
    zIndex: "1",
  },
};

const actionButtonStyles = {
  "@media (max-width:990px)": {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-62px",
    alignItems: "center",
    zIndex: "0",
  },
};

function createUserTableTheme(backgroundColor) {
  createTheme("solarized", {
    divider: {
      default: backgroundColor || "#FFFFFF",
    },
  });
}

const dataTableLoader = (
  <DataTableLoaderWrapper>
    <LoaderBackdrop />
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  </DataTableLoaderWrapper>
);

function UserDataTable({
  setParamsData,
  paramsData,
  setModalMode,
  fromUserManagement = false,
  backgroundColor = "#FFFFFF",
  id,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const totalRows = useSelector((state) => state.userManagement.totalRows);
  const perPage = useSelector((state) => state.userManagement.perPage);
  const data = useSelector((state) => state.userManagement.data);
  const page = useSelector((state) => state.userManagement.page);

  const [custom, setCustom] = useState(Math.random());
  const [updateStatus, setUserStatus] = useState(0);
  const [userDeleteTempId, setUserDeleteTempId] = useState({});
  const [statusNew, statusTypeUpdate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [gridLoading, setGridLoading] = useState(true);
  const [countRef, setCountRef] = useState(Math.random());
  
  
  const { t } = useTranslation();

  useEffect(() => {
    createUserTableTheme();
  });

  const getUsersAction = useCallback (() => {
    setLoading(true);
    dispatch(getUsersByUniversity(id))
      .then(() => {
        setLoading(false);
        setGridLoading(false);
      });
    setUserDeleteTempId({});  
  }, [dispatch, id])

  const updateUserStatus = useCallback(() => {
    if (updateStatus !== 0) {
         userManagementService
          .updateUserStatusById(updateStatus, { activate:statusNew })
          .then(() => {
             setCustom(Math.random+""+updateStatus+""+statusNew);
          }); 
    }
  }, [statusNew, updateStatus])
   const deleteUser = useCallback (() => {
    if (Object.keys(userDeleteTempId).length !== 0) {
        userManagementService
        .deleteByUserUniversity(userDeleteTempId.id, userDeleteTempId.user_id)
        .then(() => {
          setCustom(Math.random+""+userDeleteTempId.id+""+userDeleteTempId.user_id);
        });
    }
  },[userDeleteTempId])
  useEffect(() => {
    getUsersAction();
  }, [paramsData,custom, getUsersAction]);

   useEffect(() => {
    updateUserStatus();
  }, [updateStatus,statusNew, updateUserStatus]);

  useEffect(() => {
    deleteUser();
  }, [userDeleteTempId, deleteUser]);


  useEffect(() => {
    EventBus.on("userSearchEvent", ({ search, roles }) => {
      dispatch(setSearch(search));
      dispatch(setRoles(roles));
    });

    EventBus.onStatusChange("userDeleteEvent", (data) => {
      setUserDeleteTempId(data);
    });

    EventBus.onStatusChange(
      "userActivateDeactivateEvent",
      ({ userId, activate }) => {
        setUserStatus(userId);
        statusTypeUpdate(activate);
      }
    );

    EventBus.on("userActivateDeactivateCancelEvent", () => {
      setCountRef(Math.random());
      
    });

    return () => {
      setCountRef(Math.random());
      setLoading(null);
      setGridLoading(null);
      EventBus.remove("userSearchEvent");
      EventBus.remove("userActivateDeactivateCancelEvent");
      EventBus.remove("userDeleteEvent");
      EventBus.remove("userActivateDeactivateEvent");
    };
  }, [dispatch]);

  useEffect(() => {
    if (window.location.href.indexOf("user-managment") === -1) {
      dispatch(resetAll());
      getUsersAction();
    }
  }, [location.pathname, getUsersAction , dispatch]);

  const handlePageChange = (page) => {
    dispatch(setPage(page));
    getUsersAction();
  };

  const handlePerRowsChange = (newPerPage, page) => {
    console.log("ssss===============");
    dispatch(setPage(page));
    dispatch(setPerPage(newPerPage));
    getUsersAction();
  };

  const handleSort = (column, sortDirection) => {
    
    const sortMap = {
      Name_asc: "full_name",
      Name_desc: "fullname",
      Email_asc: "email",
      Email_desc: "email",
    };
    dispatch(setSortField(sortMap[`${column.name}_${sortDirection}`]));
    dispatch(setSortDirection(sortDirection === "desc" ? "-" : ""));
    getUsersAction();
  };

  const onChange = (value, row) => {
    setModalMode(value.value === "1" ? "activate" : "deactivate");
    dispatch(updateModal({ modalIsOpen: true, cache: row.id }));
  };

  const deleteAction = (id, user_id) => {
    setModalMode("delete");
    dispatch(updateModal({ modalIsOpen: true, cache: { id, user_id } }));
  };
  const columns = [
    {
      name: t("Name"),
      selector: (row) => row.full_name,
      sortable: true,
      style: columnStyling,
      center: true,
    },
    {
      name: t("Email"),
      selector: (row) => row.email,
      sortable: true,
      style: columnStyling,
      center: true,
    },
    {
      name: t("Role"),
      selector: (row) => row.roles.map((role) => role.name).join(", "),
      sortable: false,
      style: columnStylingRole,
      center: true,
    },
    {
      name: t("Status"),
      selector: (row) => row.status,
      sortable: false,
      style: columnStylingstatus,
      center: true,
      cell: (row, index) => {
        if (!fromUserManagement) {
          return (
            <div>
              <span style={{ marginRight: 10 }}>
                {row.is_active === "1" ? <GreenCircle /> : <RedCircle />}
              </span>
              <span>{row.is_active === "1" ? t("Active") : t("Deactivated")}</span>
            </div>
          );
        }
        const CustomOption = (props) =>
          !props.isDisabled ? (
            <div
              {...props?.innerProps}
              style={{
                padding: 10,
                ...(props?.selectProps?.value?.value === props?.value
                  ? {
                      pointerEvents: "none",
                      cursor: "none",
                      backgroundColor: "lightgray",
                      opacity: 0.8,
                    }
                  : {}),
              }}
            >
              <span style={{ marginRight: 10 }}>
                {props.value === "1" ? <GreenCircle /> : <RedCircle />}
              </span>
              <span>{props.label}</span>
            </div>
          ) : null;
        const CustomInput = (props) => (
          <div
            style={{
              color: "inherit",
              gridArea: "1/1/2/3",
              marginLeft: "2px",
              marginRight: "2px",
              maxWidth: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              boxSizing: "border-box",
            }}
          >
            <span style={{ marginRight: 10 }}>
              {props.selectProps.value.value === "1" ? (
                <GreenCircle />
              ) : (
                <RedCircle />
              )}
            </span>
            <span>{props.selectProps.value.label}</span>
          </div>
        );

        const options = [
          { value: "1", label: "Active" },
          { value: "0", label: "Deactivated" },
        ];
        const selected = options.find((x) => x.value === row.is_active);
        return (
          <TableButtons>
            <Selector
              key={countRef}
              onChange={(value) => onChange(value, row)}
              className="custom-selector"
              defaultValue={selected}
              value={selected}
              components={{ Option: CustomOption, SingleValue: CustomInput }}
              userOptions={options}
              menuPlacement={
                index > Math.min(perPage, totalRows - (page - 1) * perPage) - 3
                  ? "top"
                  : "bottom"
              }
              backgroundColor="transparent !important"
              userCustomStyles={{
                control: (provided) => ({
                  ...provided,
                  padding: 5,
                  borderRadius: 40,
                  border: 0,
                  
                  boxShadow: "none",
                  backgroundColor: "transparent !important",
                }),
                menu: (provided) => ({
                  ...provided,
                  width: 150,
                }),
              }}
            ></Selector>
          </TableButtons>
        );
      },
    },
  ];
  if (fromUserManagement) {
    columns.push({
      name: t("Action"),
      sortable: false,
      center: true,
      style: actionButtonStyles,
      cell: (row) => (
        <TableButtons>
          <PencilButton
            onClick={() => {
              dispatch(updateGlobalLoader(true));
              setTimeout(() => navigate(`edit/${row.id}`), 500);
            }}
          >
            <Pencil />
          </PencilButton>
          <TrashButton>
            <Trash onClick={() => deleteAction(id, row.id)} />
          </TrashButton>
        </TableButtons>
      ),
    });
  }
  return (
    <DataTableWrapper>
      <DataTable
        columns={columns}
        data={data}
        theme="solarized"
        sortServer
        onSort={handleSort}
        progressPending={loading ? gridLoading : false}
        progressComponent={
          loading ? (
            <>
              <div
                style={{
                  minHeight: "586px",
                  backgroundColor: `${backgroundColor} !important`,
                }}
              >
                <DataTableLoaderWrapper>
                  <LoaderWrapper>
                    <Loader />
                  </LoaderWrapper>
                </DataTableLoaderWrapper>
              </div>
            </>
          ) : (
            <></>
          )
        }
        noDataComponent={
          <div
            style={{
              minHeight: "156px",
              backgroundColor: `${backgroundColor} !important`,
            }}
          >
            <DataTableLoaderWrapper>
              <LoaderWrapper>
                <CustomHeading>{t("No Results Found")}</CustomHeading>
              </LoaderWrapper>
            </DataTableLoaderWrapper>
          </div>
        }
        pagination={true}
        paginationServer={true}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
          {loading && typeof data !== "undefined" && dataTableLoader}
    </DataTableWrapper>
  );
}

export default UserDataTable;
