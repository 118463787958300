import { createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../reducers/messageReducer";
import { getError } from "../services/CommonApi";
import userManagementService from "../services/userManagement.service";

export const getUsers = createAsyncThunk("/api/users/", async (_, thunkAPI) => {
  try {
    const { search, page, perPage, sortField, sortDirection, roles } =
      thunkAPI.getState().userManagement;
    const params = {};
    let filtering = "";
    if (search) {
      params.search = search;
    }
    if (page) {
      params.page = page;
    }
    if (perPage) {
      params.page_size = perPage;
    }
    if (sortField) {
      params.ordering = `${sortDirection}${sortField}`;
    }
    if (roles && roles.length) {
      filtering = roles
        .split(",")
        .map((role) => `roles__name=${role}`)
        .join("&");
    }
    const usersResponse = await userManagementService.getUsers(
      params,
      filtering,
      null
    );
    if (usersResponse.data.results && usersResponse.data.results.length !== 0) {
      return { data: usersResponse.data };
    } else {
      return { data: [] };
    }
  } catch (error) {
    return thunkAPI.rejectWithValue();
  }
});

export const createUser = createAsyncThunk(
  `POST:/api/users/`,
  async (userData, thunkAPI) => {
    try {
      const { user, roles } = userData;
      const data = await userManagementService.createUser({
        user_list: user.map((x) => ({ name: x.name, email: x.email })),
        roles: roles.map((x) => x.value),
      });
      if (data && data.status === 200) {
        return true;
      } else {
        if (data && data.user_list && data.user_list.length) {
          const emailExistsList = data.user_list.map((_, index) => user[index]);
          return emailExistsList;
        }
        
      }
    } catch (error) {
      const message = getError(error);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createUserByUniversityId = createAsyncThunk(
  `POST:/api/universities/{}/users/`,
  async ({ userData, id }, thunkAPI) => {
    try {
      const { user, roles } = userData;

      const data = await userManagementService.createUserByUniversity(
        {
          user_list: user.map((x) => ({ name: x.name, email: x.email })),
          roles: roles.map((x) => x.value),
        },
        id
      );
      console.log(data);
      if (data && data.status === 200) {
        return { created: true };
      } else {
        if (data && data.user_list && data.user_list.length) {
          const emailExistsList = data.user_list.map((_, index) => user[index]);
          return emailExistsList;
        }
        return { created: false, data: data };
        
      }
    } catch (error) {
      const message = getError(error);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getUsersByUniversity = createAsyncThunk(
  "/api/universities/{}/users/",
  async (id, thunkAPI) => {
    try {
      const { search, page, perPage, sortField, sortDirection, roles } =
        thunkAPI.getState().userManagement;

      const params = {};
      let filtering = "";
      if (search) {
        params.search = search;
      }
      if (page) {
        params.page = page;
      }
      if (perPage) {
        params.page_size = perPage;
      }
      if (sortField) {
        params.ordering = `${sortDirection}${sortField}`;
      }
      if (roles && roles.length) {
        filtering = roles
          .split(",")
          .map((role) => `roles__name=${role}`)
          .join("&");
      }
      const usersResponse = await userManagementService.getUserByIdWithParams(
        id,
        params,
        filtering
      );
      if (usersResponse.data && usersResponse.data.length !== 0) {
      return { data: usersResponse.data };
      } else {
        return { data: [] };
      }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
