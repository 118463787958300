import { useEffect, useState } from "react";
import Card from "../../components/card/cardImage";

import styled from "styled-components";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { Image } from "react-bootstrap";
import { jobActionReview } from "../../services/apiCalls";
import { useTranslation } from "react-i18next";

import { Colors } from "../../utilities/colors";
import BussinessMan from "../../assets/icons/svg/businessman.svg";

import SalaryImg from "../../assets/icons/svg/salaryIcon.svg";
import Button from "../button/button";
import JobCardToggleBtn from "./jobCardToggleBtn";
import Duration from "../../assets/icons/svg/duration.svg";
import Remarks from "../../assets/icons/svg/remarks.svg";

import {
  isThisDatePastToday,
  dateToDateMonthInWordsYear,
} from "../../utilities/commonFunctions";
import FirstIcon from "../../assets/DemoIcon.png";
import Expired from "../../assets/icons/svg/expiredIcon.svg";
import Accepted from "../../assets/icons/svg/acceptedIcon.svg";
import Pending from "../../assets/icons/svg/pendingIcon.svg";
import Reject from "../../assets/icons/reject-48.png";
import PendingPayment from "../../assets/icons/pending-payment.png";

import moment from "moment";
const SalaryDiv = styled.div`
  display: flex;
  padding-right: 20px;
`;

const Salary = styled.p`
  align-self: center;
  font-size: 18px;
  font-weight: 500;
  padding-left: 5px;
`;

const Salary_Img = styled.img`
  width: 30px;
  height: 25px;
`;

const FlexDiv = styled.div`
  display: flex;
`;

const Division = styled.div`
  cursor: pointer;
  overflow-y: auto;
`;


const JobTitle = styled.h3`
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: ${Colors.dark};
  margin: 0;
  margin-left: 0.5em;
  cursor: pointer;
`;
const DateText = styled.p`
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  margin: 0;
  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const JobPostedDate = styled(DateText)`
  color: ${Colors.primaryText};
`;
const JobExpiredDate = styled(DateText)`
  color: #504f8c;
`;
const DateWrapper = styled(FlexDiv)`
  align-items: center;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const IconSingleSection = styled(FlexDiv)`
  align-items: center;
  margin-right: ${(props) => (props.margin ? props.margin : "0px")};
`;
const OtherText = styled.p`
  margin-left: 3px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #525252;
`;

const HeaderDiv = styled.div`
  margin-left: 5px;
  margin-top: 2vh;
  display: flex;
  justify-content: space-between;
  @media (max-width: 500px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const CompanyImage = styled.img`
  width: 58px;
  height: 42px;

  align-self: center;
  padding-left: 10px;
  padding-right: 5px;
  border-radius: 50%;
`;
const CompanyTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  align-items: center;
  display: flex;
`;


const DivLeft = styled.div`
  display: flex;
  align-items: center;
`;

const DiviPipeline = styled.span`
  border-right: 2px solid black;
  height: 22px;
  padding: 5px 5px 5px 5px;
`;

const CompanyDiv = styled.div`
  display: flex;
  width: fit-content;
  justify-content: space-between;
`;


const CardWrapper = styled.div`
  margin: 0 1.5rem 0;
  padding: 0.25rem 0;
  border-bottom: 0.6px solid rgb(188, 184, 184);
`;

const RequestedDate = styled.span`
  color: ${Colors.pink};
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  @media (max-width: 500px) {
    padding-top: 5px;
  }
`;

const Employment_img = styled.img`
  width: 30px;
  height: 25px;
`;

const RightDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
  align-items: center;
  @media (max-width: 500px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const VacancyDiv = styled.div`
  margin-left: 5px;
  margin-top: 1vh;
`;

const VacancyTextColor = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: rgb(82, 82, 82);
  font-size: 18px;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const EmployeeDiv = styled.div`
  display: flex;
  @media (max-width: 500px) {
  }
`;

const EmployeementType = styled.p`
  align-self: center;
  font-size: 18px;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 15px;
  margin-bottom: 1rem;
`;




const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin: 0 1.5rem;
`;

const Buttonwrapper = styled.div`
  display: flex;
  height: 20%;
  min-width: 15%;
  // justify-content: space-evenly;
  margin: 0px 10px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;
const VacancyWidth = styled.p`
  min-width: 150px;
`;
export const dateDifference = (date) => {
  let date1 = new Date(date);
  let date2 = new Date();
  return parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
};
export default function JobsCards({
  datas,
  pending,
  intern,
  onUpdate,
  domain,
  handleApprove,
  handleApproveReview,
  showToast,
  name,
  searchval
}) {
  
  const positionStartDuration = (created_at) => {
    let dateDifferences = dateDifference(created_at);
    if (dateDifferences === 1) {
      return dateDifferences + " " + t("day ago");
    } else if (dateDifferences > 1) {
      return dateDifferences + " " + t("days ago");
    } else if (dateDifferences === 0) {
      return t("Today");
    } else {
    }
  };
  const [, setImageSrc] = useState("");
  var {
    title,
    created_at,
    position_start_date,
    position_end_date,
    exp_start_date,
    exp_end_date,
    no_positions,
    min_compensation,
    max_compensation,
    intern_type,
    is_activate,
    status,
    id,
    organization_details,
    payment_status,
    update_time,
  } = datas;
  const navigate = useNavigate();
  

  const getDuration = (d1, d2) => {
    d2 = new Date(d2);
    d1 = new Date(d1);
    const date1 = moment(d1).format("DD/MM/YYYY");
    const date2 = moment(d2).format("DD/MM/YYYY");
    var startDate = new Date(
      date1.split("/")[2],
      date1.split("/")[1] - 1,
      date1.split("/")[0]
    );
    var endDate = new Date(
      date2.split("/")[2],
      date2.split("/")[1] - 1,
      date2.split("/")[0]
    );

    var diff = new Date(endDate - startDate);
    var diffResult = (diff.getFullYear() - 1970) * 12 + diff.getMonth();
    if (diffResult === 0) {
      return t("1 Month");
    } else {
      return `${diffResult} ` + t("Months");
    }
  };

  useEffect(() => {
    var checkDummyNEw = new Date();
    var expiredCheckingDate = new Date(position_end_date);

    if (
      status === "expired" ||
      expiredCheckingDate <= checkDummyNEw ||
      expiredCheckingDate === checkDummyNEw
    ) {

      
      setImageSrc(Expired);

    } else if (status === "pending") {
      setImageSrc(Pending);
    } else if (status === "accepted") {
      setImageSrc(Accepted);
    } else if (status === "review") {
      setImageSrc(Remarks);
    } else if (status === "rejected") {
      setImageSrc(Reject);
    } else if (status === "pending_payment") {
      setImageSrc(PendingPayment);
    } else {
    }
  }, [name.value, searchval, position_end_date, status]);

  const getIcon = () => {
    var checkDummyNEw = new Date();
    var expiredCheckingDate = new Date(position_end_date);

    if (
      status === "expired" ||
      expiredCheckingDate <= checkDummyNEw ||
      expiredCheckingDate === checkDummyNEw
    ) {
      return Expired;
    }
    else if (status === "pending") {
      return Pending;
    }
    else if (status === "accepted") {
      return Accepted
    }
    else if (status === "review") {
      return Remarks;
    }
    else if (status === "rejected") {
      return Reject;
    }
    else if (status === "pending_payment") {
      return PendingPayment;
    }
    else {
      return null;
    }
  }

  const onNavigate = () => {
    navigate({ pathname: "/internships/details", search: `?id=${datas.id}` });
  };

  

  const [, setShowEditIcon] = useState(true);

  useEffect(() => {
    if (status === "expired") setShowEditIcon(false);
    if (status === "accepted" && !isThisDatePastToday(position_start_date))
      setShowEditIcon(false);
  }, [position_start_date, status]);

  

  

  var date = moment(position_end_date);
  var now = moment();
  const isPostExpired = now > date;

  const makeActivateOrDeactivate = ($cks) => {
    var mainStatus = "";
    if ($cks === false) {
      mainStatus = "deactivate";
      
    } else {
      mainStatus = "activate";
      
    }
    const body = {
      id: datas.id,
      status: mainStatus,
      update_time,
    };
    const result = jobActionReview(body);
    showToast();
    if (result.status === 1) {
      toast.success(result.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  
  const { t, } = useTranslation();

  return (
    <>
      <Card size="100" overflow="auto" left="0">
        <Division>
          <CardWrapper onClick={() => onNavigate()}>
            <HeaderDiv>
              <DivLeft>
                <Image src={getIcon()} />
                <JobTitle>{title}</JobTitle> &nbsp;
                <DiviPipeline></DiviPipeline> &nbsp;
                <CompanyDiv>
                  <CompanyImage
                    src={
                      domain && organization_details?.logo_path
                        ? domain + organization_details?.logo_path
                        : FirstIcon
                    }
                  />{" "}
                  &nbsp;
                  <CompanyTitle>
                    {organization_details?.name ?? ""}
                  </CompanyTitle>
                </CompanyDiv>
              </DivLeft>
              {status === "accepted" &&
                !isPostExpired &&
                payment_status === "paid" ? (
                <JobCardToggleBtn
                  isActivate={is_activate}
                  onToggle={makeActivateOrDeactivate}
                  defaultChecked={is_activate}
                />
              ) : null}
            </HeaderDiv>

            <RequestedDate>{t("Requested")}: {created_at}</RequestedDate>
            <OtherText>{positionStartDuration(created_at)}</OtherText>
            <DateWrapper onClick={onNavigate}>
              <JobPostedDate>
                {status === "pending" ? t("Posted") : t("Posted")}:{" "}
                {position_start_date
                  ? dateToDateMonthInWordsYear(position_start_date)
                  : position_start_date}
              </JobPostedDate>
              {status === "pending" ? (
                ""
              ) : (
                <JobExpiredDate>
                  {t("Expires")}:{" "}
                  {position_end_date
                    ? dateToDateMonthInWordsYear(position_end_date)
                    : position_end_date}
                </JobExpiredDate>
              )}
            </DateWrapper>

            
            <VacancyDiv>
              <VacancyTextColor>
                <VacancyWidth>{t("Vacancies")}: {no_positions}</VacancyWidth>
                <RightDiv>
                  <EmployeeDiv>
                  {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <Employment_img src={BussinessMan} />
                    <EmployeementType>
                      {intern_type === "sip"
                        ? t("National Structured Internship Programme (MySIP)")
                        : t(intern_type)}
                    </EmployeementType>
                  </EmployeeDiv>
                  <SalaryDiv>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <Salary_Img src={SalaryImg} />
                    {payment_status === "unpaid" ?
                      <Salary>
                        0 RM
                      </Salary>
                      :
                      <Salary>
                        {min_compensation} MYR - {max_compensation} MYR
                      </Salary>
                    }

                  </SalaryDiv>
                  <IconSingleSection>
                    <OtherText>
                      <img src={Duration} alt="duration" />
                      &nbsp;{getDuration(exp_start_date, exp_end_date)}
                    </OtherText>
                  </IconSingleSection>
                </RightDiv>
              </VacancyTextColor>
            </VacancyDiv>
          </CardWrapper>
          {status === "pending" && isPostExpired === false ? (
            <>
              <ButtonDiv>
                <Buttonwrapper>
                  <Button
                    width="100%"
                    name={t("Approve")}
                    onClick={(e) => {
                      handleApprove(e, id, "accepted", update_time);
                    }}
                  />
                </Buttonwrapper>
                <Buttonwrapper>
                  <Button
                    width="100%"
                    onClick={(e) => {
                      handleApproveReview(e, id, "review", update_time);
                    }}
                    name={t("Send Review")}
                    color="#FFFFFF"
                  />
                </Buttonwrapper>

                <Buttonwrapper>
                  <Button
                    width="100%"
                    name={t("Deny")}
                    color="#FFFFFF"
                    onClick={(e) => {
                      handleApprove(e, id, "rejected", update_time);
                    }}
                  />
                </Buttonwrapper>
              </ButtonDiv>
            </>
          ) : null}
        </Division>
      </Card>
    </>
  );
}
