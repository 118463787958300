import styled from "styled-components";
import InternLogo from "../../assets/internLogo.png";
import { Colors } from "../../utilities/colors";
import Button from "../button/button";
import { Row, Col } from "react-bootstrap";
import InternIntroButton from "./internToggleButton";
import { useEffect, useState } from "react";
import JobCardToggleBtn from "../jobsCards/jobCardToggleBtn";
import { jobActionReview } from "../../services/apiCalls";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const FlexWrapper = styled.div`
  margin-bottom: 1.825rem;
`;


const CompanyName = styled.div`
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 7px;
  color: ${Colors.primaryText};
`;

const CompanyAddress = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: #525252;
`;
const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ImgContainer = styled.div`
  width: 150px;
  border-radius: 50%;
`;
const JobTitle = styled.div`
  font-weight: 500;
  font-size: 22px;
`;
const StyledImage = styled.img`
  width: 80%;
  height: 80%;
  border-radius: 50%;
`;

const TextContainer = styled.div`
  margin-top: 1rem;
  p3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #b3b3b3;
  }
`;

export default function InternIntroSection({
  data,
  org,
  handleApprove,
  handleDeny,
  handleApproveReview,
}) {
  const { domain, org_details, status, position_end_date, viewed_count, payment_status, is_activate, id, update_time } = data;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  var checkDummyNEw = new Date();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  var expiredCheckingDate = new Date(position_end_date);
  const { t, } = useTranslation();

  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    if (expiredCheckingDate <= checkDummyNEw || expiredCheckingDate === checkDummyNEw) {
      setIsExpired(true)
    }
  }, [checkDummyNEw, expiredCheckingDate])

  const makeActivateOrDeactivate = async ($cks) => {
    var mainStatus = "";
    if ($cks === false) {
      mainStatus = "deactivate";
      
    } else {
      mainStatus = "activate";
      
    }
    const body = {
      id: id,
      status: mainStatus,
      update_time,
    };
    const result = await jobActionReview(body);
    
    if (result.data.status === 1) {
      toast.success(t(result.data.message), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <FlexWrapper>
      <Row>
        <Col xl="2">
          <ImgContainer>
            <StyledImage
              src={
                domain && org_details?.logo_path
                  ? domain + org_details?.logo_path
                  : InternLogo
              }
            />
          </ImgContainer>
        </Col>
       
        <Col xl="10">
          <TextContainer>
            <FlexDiv>
              <JobTitle>
                {data && data.title}({org_details?.name ?? ""})
              </JobTitle>{" "}
              {status === "accepted" &&
                isExpired === false &&
                payment_status === "paid" ? (
                <JobCardToggleBtn
                  isActivate={is_activate}
                  onToggle={makeActivateOrDeactivate}
                  defaultChecked={is_activate}
                />
              ) : null}
            </FlexDiv>
            <Col>
              <p3>{viewed_count} {t("views")}</p3>

              

            </Col>
            {status === "pending" && isExpired === false ?
              
              (<Col lg="12" xl="8" md="12">
                <Row>
                  <Col lg="4">
                    <Button
                      width="100%"
                      name={t("Approve")}
                      onClick={(e) => {
                        handleApprove(
                          e,
                          data.id,
                          "accepted",
                          data.update_time
                        );
                      }}
                    />
                  </Col>
                  <Col lg="4">
                    <Button
                      onClick={(e) => {
                        handleApproveReview(
                          e,
                          data.id,
                          "review",
                          data.update_time
                        );
                      }}
                      width="100%"
                      name={t("Send Review")}
                      color="#FFFFFF"
                    />
                  </Col>

                  <Col lg="4">
                    <Button
                      width="100%"
                      name={t("Deny")}
                      color="#FFFFFF"
                      onClick={(e) => {
                        handleDeny(
                          e,
                          data.id,
                          "rejected",
                          data.update_time
                        );
                      }}
                    />
                    {data.status === "accepted" ? <InternIntroButton /> : ""}
                  </Col>
                </Row>
              </Col>
              ) : null}

            

            <CompanyName>{org && org?.name}</CompanyName>
            <CompanyAddress>📍{data.org_details?.country_abbr}</CompanyAddress>
          </TextContainer>
        </Col>
      </Row>
    </FlexWrapper>
  );
}
