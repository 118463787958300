import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import Button from "../../../components/button/button";
import Input from "../../../components/input/input";
import {
  AddUser,
  AddUserWrapper,
  DropdownTitle,
  Heading,
} from "../../analyticalDashboardPage/commonStyles";
import Selector from "../../../components/select/select";
import UserDataTable from "../../../components/universityUsersDataTable/userDataTable";
import { Controller, useForm } from "react-hook-form";
import { isEmpty } from "../../../utilities/commonFunctions";
import FormErrors from "../../../components/formErrors/formErrors";
import EventBus from "../../../utilities/eventBus";
import { useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const SearchColumn = styled(Col)`
  margin-top: 32px;
  input {
    font-family: General Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    ::placeholder {
      color: #bcbcbc;
    }
  }
`;

const ButtonColumn = styled(Col)`
  margin-top: 17px;
  button {
    padding: 11px 20px;
  }
`;
export default function UserLandingScreen({ setModalMode }) {
  const params = useParams();
  const [paramsData, setParamsData] = useState({});
  const id = params && params.id;
  const { t } = useTranslation();
  const masterdataRoles = useSelector(
    (state) => state.userManagement.masterdataRoles
  );
  const search = useSelector(
    (state) => state.userManagement.roles
  );
  console.log("============================",search);
  const {
    
    formState: { errors },
    control,
    handleSubmit,
    setValue,
    
  } = useForm({
    criteriaMode: "all",
  });

  const getOptions = () => {
    return [...(masterdataRoles ?? []), { value: 3, label: "All" }];
  };

  const historySelected=()=>{
    if (search !== "" && search !== null && search !== undefined) {
    setValue("roles",getOptions().filter((e)=>{
        return search.split(',').includes(e?.label);
    }));
    return getOptions().filter((e)=>{
        return search.split(',').includes(e?.label);
    })

    }else{
        setValue("roles",[{ value: 3, label: "All" }]);
        return [{ value: 3, label: "All" }];

    }
  }

  const onSubmit = (data) => {
    console.log("submitted data",data);
    if (!isEmpty(errors)) {
      return;
    }
    let roles = null;
    if (data.roles && Array.isArray(data.roles) && data.roles.length) {
      roles = data.roles.map((x) => x.label).join(",");
      if (data.roles.some((item) => item.label === "All")) {
        console.log(data, "optionsoptionsoptionsoptions");
        roles = "";
      }
    } else if (data.roles) {
      roles = data.roles.label;
    }
    console.log(roles);
    setParamsData({ search: data.search, roles: roles });
    EventBus.dispatch("userSearchEvent", { search: data.search, roles: roles });
  };

  const customOnChange = (selectedArray, onChangeCallback) => {
    
    onChangeCallback(selectedArray);
    
  };
 
  return (
    <>
      <div role="presentation">
        <div
          className="d-flex mb-5"
          style={{ fontSize: "1.1rem", fontWeight: "400", marginLeft: "10px" }}
        >
          <Link
            to="/universities"
            style={{ textDecoration: "none", color: "#ACACAC" }}
          >
            {t("Universities")}
          </Link>
          <div className="mx-2" style={{ color: "#ACACAC" }}>
            >
          </div>

          <Link
            to={"/universities/detail/" + id}
            style={{ textDecoration: "none", color: "#ACACAC" }}
          >
            {t("University Details")}
          </Link>

          <div className="mx-2" style={{ color: "#ACACAC" }}>
            >
          </div>
          <span style={{ textDecoration: "none", color: "#D04C8D" }}>
            {t("User Management")}
          </span>
        </div>
      </div>
      <Row>
        <Col>
          <Heading>{t("User Management")}</Heading>
        </Col>
      </Row>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <SearchColumn lg="6">
            <Controller
              control={control}
              name="search"
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  placeholder={t("Search")}
                  className={errors.name ? "invalid" : ""}
                  style={{ fontFamily: "General Sans, sans-serif" }}
                />
              )}
            />
            <FormErrors errors={errors} formControlName="search" />
          </SearchColumn>
          <Col lg="4">
            <DropdownTitle>{t("Role")}</DropdownTitle>
            <Controller
              control={control}
              name="roles"
              render={({ field: { onChange, onBlur, value } }) => (
                <Selector
                  isMulti={true}
                  userOptions={getOptions()}
                  value={value}
                  backgroundColor="#F3F3F3"
                  onChange={(event) => customOnChange(event, onChange)}
                  onBlur={onBlur}
                  className={errors.name ? "invalid" : ""}
                  defaultValue={historySelected}
                  
                />
              )}
            />
            <FormErrors errors={errors} formControlName="roles" />
          </Col>
          <ButtonColumn lg="2">
            <Button name={t("Search")} type="submit" />
          </ButtonColumn>
        </Row>
      </form>
      <Row>
        <Col>
          <AddUserWrapper>
            <AddUser to="./new">+ {t("Add User")}</AddUser>
          </AddUserWrapper>
        </Col>
      </Row>
      <Row>
        <Col>
          <UserDataTable
            id={id}
            setModalMode={setModalMode}
            fromUserManagement={true}
            paramsData={paramsData}
          />
        </Col>
      </Row>
    </>
  );
}
