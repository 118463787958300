import { NavLink } from "react-router-dom";
import { Colors } from "../../utilities/colors";

import Logo from "../../assets/icons/svg/mynextLogo.svg";
import styled from "styled-components";
import { ReactComponent as CompaniesusersIcon } from "../../assets/icons/svg/companyusers.svg";
import { ReactComponent as CompaniesIcon } from "../../assets/icons/svg/companies.svg";
import { ReactComponent as InternshipIcon } from "../../assets/icons/svg/internships.svg";
import { ReactComponent as UniversitiesIcon } from "../../assets/icons/svg/universities.svg";
import { ReactComponent as AnalyticalDashboardIcon } from "../../assets/icons/svg/analytical dashboard.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/svg/settings.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/svg/home.svg";
import { useTranslation } from "react-i18next";

const GradientCover = styled.div`
  position: absolute;
  top: 14vh;
  left: 0;
  right: 0;
  width: 300px;
  height: 90vh;
  min-height: 500px;
  background: #ffffff;
  border-radius: 0px 42px 42px 0px;
  z-index: 1000;
  margin-top: -80px;
  @media (min-width: 1024px) {
    display: none;
  }
`;
const LogoDiv = styled.div`
  width: auto;
  position: relative;
  height: 15%;
  margin-left: 10%;
  margin-top: 10%;
  margin-right: 10%;
`;

const LogoImage = styled.img`
  width: 90%;
`;

const Logotext = styled.span`
  @media (max-width: 1140px) {
    position: absolute;
    float: right;
    font-size: 25px;
    top: 4.5vh;
    right: 5vh;
    color: #d04d8b;
    font-weight: 500;
    font-size: 20px;
    color: #d04d8b;
    float: right;
    z-index: 1;
  }
`;

const MenuDiv = styled.div`
  width: 100%;
  height: auto;
  margin: 0px;
  display: flex;
  flex-direction: column;
`;

const MenuList = styled(NavLink)`
  display: flex;
  align-items: center;
  font-weight: 500;
  color: ${Colors.lightGray};
  justify-content: space-evenly;
  text-decoration: none;
  height: 50px;
  cursor: pointer;
  &:hover {
    color: ${Colors.pink};
    border-right: 5px solid ${Colors.pink};
  }

  &.active {
    color: ${Colors.pink};
    border-right: 5px solid ${Colors.pink};
  }
`;

const MenuSpanText = styled.p`
  width: 150px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;

const MenuSpanIcons = styled.span`
  width: auto;
`;

const menuItemsn = [
  {
    title: "Dashboard",
    icon: <HomeIcon />,
    url: "/",
    type: "admin",
  },
  {
    title: "Companies",
    icon: <CompaniesIcon />,
    url: "/companies",
    type: "admin",
  },
  {
    title: "Company Users",
    icon: <CompaniesusersIcon />,
    url: "/companiesuserslist",
    type: "admin",
  },
  {
    title: "Internships",
    icon: <InternshipIcon />,
    url: "/internships",
    type: "admin",
  },
  {
    title: "Universities",
    icon: <UniversitiesIcon />,
    url: "/universities",
    type: "admin",
  },
  {
    title: "Analytical Dashboard",
    icon: <AnalyticalDashboardIcon />,
    url: "/analyticaldashboard",
    type: "admin",
  },

  {
    title: "Settings",
    icon: <SettingsIcon />,
    url: "/settings",
    type: "admin",
  },
];

export default function Menus() {
  const { t } = useTranslation();
  return (
    <GradientCover>
      <LogoDiv>
        <LogoImage src={Logo} alt="my next logo" />
        <Logotext>ADMIN</Logotext>
      </LogoDiv>

      <MenuDiv>
        {menuItemsn.map((menuItem, i) => (
          <MenuList className="" key={i} to={menuItem.url}>
            <MenuSpanIcons>{menuItem.icon}</MenuSpanIcons>
            <MenuSpanText>{t(menuItem.title)}</MenuSpanText>
          </MenuList>
        ))}
      </MenuDiv>
    </GradientCover>
  );
}
