import CustomModal from '../../components/modal/CustomModal';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import * as Mui from '@material-ui/core';
import { GrClose } from 'react-icons/gr';
import { calculateAge } from '../../utilities/commonFunctions';
import { useTranslation } from "react-i18next";

const useStyles = Mui.makeStyles((theme) => ({
  avatarStyle: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));


const ProfileWrapper = styled.div`
  position: relative;
  width: 700px;
  @media (max-width: 767px) {
    width: 200px;
  }
`;

const ResumeName = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #6b6b6b;
`;

const ResumeNameTitle = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #202020;
  text-transform: capitalize;
`;

const HrLine = styled.hr`
  border: 0.6px solid #c4c4c4;
  margin: 10px auto;
`;

const DetailText = styled.p`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #d04c8d;
  margin-bottom: 0.5em;
  text-transform: capitalize;
`;

const Close = styled.div`
  // display: flex;
  // margin-right: 100px;
  margin-left:560px;
  justify-content: end;
  font-size: 1.5em;
  color: #000000;
  cursor: pointer;
`;

export default function ViewProfile(props) {
  const classes = useStyles();
  const {
    profile_pic,
    display_name,
    mob_number,
    gender,
    dob,
    city_abbr,
    state_abbr,
    email,
    nationality,
  } = props.data;

  const { t,  } = useTranslation();
  return (
    <CustomModal open={props.open}>
      
      <ProfileWrapper>
       
          <Close className="modal_header">
            <GrClose onClick={props.onClose} />
          </Close>
        
        <Row>
          <Col sm={3} md={3}>
            <Mui.Avatar src={profile_pic} className={classes.avatarStyle} />
          </Col>
          <Col>
            <ResumeNameTitle>{display_name}</ResumeNameTitle>
            <HrLine />
            <ResumeName>
              <Row>
                <Col sm={3}>
                  <DetailText>{t("Gender")}:</DetailText>
                </Col>
                <Col className="text-capitalize">{t(gender)}</Col>
              </Row>
            </ResumeName>
            <ResumeName>
              <Row>
                <Col sm={3}>
                  <DetailText>{t("Age")}:</DetailText>
                </Col>
                <Col>{calculateAge(dob)}</Col>
              </Row>
            </ResumeName>
            <ResumeName>
              <Row>
                <Col sm={3}>
                  <DetailText>{t("Location")}:</DetailText>
                </Col>
                <Col className="text-capitalize">
                  
                  {city_abbr}, {state_abbr}
                </Col>
              </Row>
            </ResumeName>
            <ResumeName>
              <Row>
                <Col sm={3}>
                  <DetailText>{t("E-mail Id")}:</DetailText>
                </Col>
                <Col>{email}</Col>
              </Row>
            </ResumeName>
            <ResumeName>
              <Row>
                <Col sm={3}>
                  <DetailText>{t("Mobile No.")}:</DetailText>
                </Col>
                <Col>{mob_number}</Col>
              </Row>
            </ResumeName>
            <ResumeName>
              <Row>
                <Col sm={3}>
                  <DetailText>{t("Nationality")}:</DetailText>
                </Col>
                <Col className="text-capitalize">{t(nationality)}</Col>
              </Row>
            </ResumeName>
          </Col>
        </Row>
      </ProfileWrapper>
      
    </CustomModal>
  );
}
