import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const newInternStaticObj = {
  heading: 'Add a New Internship',
  description:
    'Create a new Internship posting by filling in all the details. Posting will be reviewed by Talent Corp before being made public for students to apply.',
  formTitle: 'Internship Title*',
  formDescription: 'Internship Description',
  internTypes: ['General Internship', 'MySIP'],
  formInternType: 'Internship Type*',
  internStartDate: 'Expected Start Date*',
  internEndDate: 'Expected End Date*',
  internPositions: 'No. of positions*',
  uploadTitle: 'Upload More Documents',
  uploadFileText: 'Choose File',
  uploadNotes:
    'e.g., Further job descriptions, company profile, etc. The job viewer can download this',
  academicTitle: 'Academic Requirements',
  applicantDocumentTitle: 'Applicant Documents',
  scopeOfStudy: 'Scope of Study*',
  gpaText: 'Required GPA',
  degreeText: 'Minimum Degree Required*',
  gradeText: 'Grade Level(s)  you can select multiple',
  degrees: [
    'SKM 1',
    'SKM 2',
    'SKM 3',
    'SKM 4',
    'SKM 5',
    'Diploma',
    "Bachelor's Degree",
    "Master's Degree"
  ],
  grades: ['Year 1', 'Year 2', 'Year 3', 'Year 4', 'Other'],
  requiredDocs: [
    'All',
    'Resume',
    'Cover Letter',
    'Transcript',
    'Writing Sample',
  ],
  collectionTypes: [
    'Receive applications when submitted',
    'Receive appplications in a single package on a given date',
  ],
  addSkillText: 'Add Skills (Max 30)',
  hoursTitle: 'Hours & Compensation',
  workingHours: 'Working Hours/ Days',
  padiText: 'Paid/ Unpaid*',
  compensation: 'Compensation*',
  location: 'Location(s)',
  country: 'Country*',
  city: 'City*',
  state: 'State*',
  anotherLocation: '+ Add another location',
  postingDate: 'Posting Dates',
  postStartDate: 'Posting Start Date*',
  postEndDate: 'Posting End Date*',
  contactTitle: 'Posting Contact Person Information',
  contactPerson: 'Select Contact Person*',
  fullName: 'Full Name',
  email: 'E-mail Address',
  mobileNumber: 'Mobile Number',
  displayContact: 'Display contact information to students',
};

export const internFields = {
  title: '',
  description: '',
  intern_type: '',
  exp_start_date: '',
  exp_end_date: '',
  no_positions: '',
  req_gpa: '',
  min_degree: '',
  working_hours: '',
  payment_status: '',
  max_compensation: '',
  min_compensation: '',
  country: '',
  state: '',
  city: '',
  grade: '',
  file_paths: [],
  skills: [],
  scopes: [],
  documents: [],
  locations: [],
  position_start_date: '',
  position_end_date: '',
  contact_name: '',
  contact_email: '',
  contact_person: '',
  contact_mob_code: '',
  contact_mob: '',
  visibility_status: 0,
  collection_type: '',
  collection_radio_type: '',
  collection_date: '',
  re_email_address: '',
  optional_documents: '',
};

export const schema = yup.object().shape({
  title: yup.string().required('Internship title is required'),
  description: yup.string().required('Internship description is required'),
  intern_type: yup.string().required(),
  exp_start_date: yup.string().required('Expected start date is required'),
  exp_end_date: yup.string().required('Expected end date is required'),
  no_positions: yup
    .string()
    .required('No. of positions available is required')
    .test(
      'no_positions',
      'Minimum position should be greater than 0',
      (val) => val === undefined || val === null || val > 0
    ),
  req_gpa: yup
    .string()
    .required('Minimum CGPA is required')
    .test(
      'req_gpa',
      'For MySIP, Minimum required CGPA should be between 1.0 and 4.0',
      (val) => val === undefined || val === null || (val <= 4 && val >= 1)
    ),
  min_degree: yup.string().required('Minimum education level is required'),
  working_hours: yup
    .string()
    .required()
    .test(
      'working_hours',
      'Internship hours range should be between 1 and 24',
      (val) => val <= 24
    ),
  payment_status: yup.string().required('Payment status is a required field.'),
  
  max_compensation: yup
    .string()
    .required()
    .when('min_compensation', (min_compensation, schema) => {
      return schema.test({
        test: (max_compensation) =>
          !!min_compensation && max_compensation > min_compensation,
        message: 'Max should be > min',
      });
    }),
    min_compensation: yup
    .string()
    
    .when(["intern_type", "min_degree"], {
      is: (intern_type, min_degree) => intern_type === "sip" && (min_degree === "Bachelor's Degree" || min_degree === "Master's Degree"),
      then: yup
        .string()
        .required("Minimum compensation is required")
        .test(
          "min_compensation",
          "For MySIP, the minimum compensation for Bachelor's Degree and above is RM600",
          (val) => val >= 600
        ),
    })
    .when(["intern_type", "min_degree"], {
      is: (intern_type, min_degree) => intern_type === "sip" && (min_degree !== "Bachelor's Degree" || min_degree !== "Master's Degree"),
      then: yup
        .string()
        .required("Minimum compensation is required")
        .test(
          "min_compensation",
          "For MySIP, the minimum compensation for Diploma and below is RM500",
          (val) => val >= 500
        ),
    })
    .when("payment_status", {
      is: "paid",
      then: yup.string()
        .required("Minimum compensation is required")
        .test(
          "min_compensation",
          "Minimum Compensation cannot be 0",
          (val) => val >= 1
        ),
    }),
  position_start_date: yup.string().required('Post start date is required'),
  position_end_date: yup.string().required('Post expiration date is required'),
  contact_name: yup.string().required('Contact is required'),
  contact_email: yup.string().required('E-mail address is required'),
  collection_type: yup.string().required(),
  collection_radio_type: yup.string().required(),
  collection_date: yup.string().required(),
  re_email_address: yup.string().required(),
  
  contact_mob: yup
    .string()
    .required('Phone number is required field')
    .test('contact_mob', 'Phone number is not valid', (value) =>
      isValidPhoneNumber(value)
    ),
  grade: yup.string().required('Year of Study is required'),

  
  documents: yup
    .array(
      yup.object({
        doc_type: yup.string().required(),
      })
    )
    .test({
      message: 'Please select atleast one required document',
      test: (arr) => arr.length > 0,
    }),
  skills: yup
    .array(
      yup.object({
        skill: yup.string().required(),
      })
    )
    .test({
      message: 'For MySIP, at least 1 skill should be added',
      test: (arr) => arr.length > 0,
    }),
  file_paths: yup
    .array(
      yup.object({
        file_path: yup.string().required(),
      })
    )
    .test({
      message: 'Please upload one more file',
      test: (arr) => arr.length > 0,
    }),
  scopes: yup
    .array(
      yup.object({
        scope: yup.string().required(),
      })
    )
    .test({
      message: 'Please add atleast one scope',
      test: (arr) => arr.length > 0,
    }),
  locations: yup
    .array(
      yup.object({
        country: yup.string().required('Country is required'),
        state: yup.string().required('State is required'),
        city: yup.string().required('City is required'),
      })
    )
    .test({
      message: 'Please add any one location.',
      
      test: (arr) => arr.length > 0,
    }),
});
