import "./App.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { route } from "./routes";
import ScrollToTop from "./components/scrollToTop/scrollToTop";
import { getAdminUserInfo } from "./services/apiCalls";
import { updateAdminData } from "./pages/login/reducer";

function App() {
  useEffect(() => {
    getAdminUser();
  }, []);
  const dispatch = useDispatch();
  const getAdminUser = async () => {
    if (process.env.NODE_ENV === "production")
      console.log = function no_console() {};
    var { data, status } = await getAdminUserInfo();
    if (data && data.status === 1) {
      dispatch(updateAdminData(data.data));
    }
  };

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {route.map((x) => (
          <Route key={x.path} exact path={x.path} element={x.element} />
        ))}
      </Routes>
      <ToastContainer hideProgressBar={true} />
    </Router>
  );
}

export default App;
