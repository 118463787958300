import React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import styled from "styled-components";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useSelector } from 'react-redux';
import { logsDateFormat } from "../../utilities/commonFunctions";
import { useTranslation } from "react-i18next";

const SpanText = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #bcbcbc;
  text-transform:lowercase ;
`;
const TableData = styled.td`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 10px;
  color: #525252;
  text-transform: capitalize;
  text-align: center;
`;
const TableDataLeft = styled(TableData)`
  text-align: left;
`;



function Row(props){
    
    const {log} = props
    const [open, setOpen] = React.useState(false);
    const { t, } = useTranslation();

    

    return (
      <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableData style={{ textAlign: "center" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableData>
        <TableDataLeft component="th" scope="row">
          {t(log.change_type)} {/* */}
          <br />
          <SpanText>{log.change_agent}</SpanText>
        </TableDataLeft>
        <TableData >{log?.change_timestamp ? logsDateFormat(log.change_timestamp):'-'}</TableData>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 5 }}>
            <Typography variant="h6" gutterBottom component="div">
                  {t("History")}
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>{t("Field")}</strong></TableCell>
                      <TableCell align="center"><strong>{t("New Value")}</strong></TableCell>
                      <TableCell align="center"><strong>{t("Old Value")}</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {log.change_details.map((changeDetail) => (
                      <TableRow key={changeDetail.field}>
                        <TableCell component="th" scope="row" >
                            {changeDetail.field}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                            {changeDetail.new_value === ""
                             ? '-'
                             : changeDetail.new_value}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                            {changeDetail.old_value == null
                             ? '-'
                             : changeDetail.old_value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
      )
      
}

export default function TableTimestamp(props) { 
    const companyLog = useSelector((state) => state.companylog.companyLogData);
    const { t, } = useTranslation();
    
    return (
      <>
          <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
              <TableHead>
              <TableRow>
                  <TableCell />
                  <TableCell align="center"><strong>{t("Change")}</strong></TableCell>
                  <TableCell align="center"><strong>{t("Timestamp")}</strong></TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
                {companyLog.logs.map((log) => (
                    <Row key={log.change_type} log={log} />
                ))}
              </TableBody>
          </Table>
          </TableContainer>
      </>
      );
   
  }


