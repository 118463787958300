import React from 'react';
import MainLayout from '../../../../layouts/mainLayout/mainlayout';
import DoghnutChart from '../../../../components/analyticalDashboard/charts/doughnutChart.js';
import Button from '../../../../components/button/button';
import { Link, useParams } from 'react-router-dom';
import { onApiCall } from '../../../../services/CommonApi';
import { withTranslation } from 'react-i18next';

const texts = {
  'Ideas and Opportunities':
    'The Ideas and Opportunities domain contains the factors Spotting Opportunities, Creativity, Valuing ideas, Vision, and Ethical and Sustainable Thinking. Scoring high, moderate or low for this domain will depend on how the talents have scored for these factors.',
  Resources:
    'The Resources domain contains the factors Self-efficacy, Self-awareness, Financial and economic literacy, Mobilising others, and Mobilising Resources. Scoring high, moderate or low for this domain will depend on how the talents have scored for these factors.',
  'Into Action':
    'The Into Action domain contains the factors Motivation and perseverance, Working with others, Planning and management, Learning through Experience, Coping with uncertainty, Taking the initiative. Scoring high, moderate or low for this domain will depend on how the talents have scored for these factors.',
};

class EmployabilityDomains extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: {} };
  }

  getData() {
    onApiCall({
      url: `/api/dashboard/analytical/employability/`,
      method: 'GET',
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ data: res.data?.data?.results });
      }
    });
  }

  componentDidMount() {
    this.getData();
  }

  componentWillReceiveProps(newProps) {
    this.getData();
  }

  formatChartData(data) {
    const { t } = this.props;
    return {
      labels: [t('Low'), t('Moderate'), t('High')],
      datasets: [
        {
          data: [data.results.low, data.results.moderate, data.results.high],
          backgroundColor: ['#35acab', '#178b9b', '#75c5f2'],
        },
      ],
    };
  }

  render() {
    const { t } = this.props;
    return (
      <MainLayout>
        <div
          className="d-flex mb-5"
          style={{ fontSize: '1.1rem', fontWeight: '400' }}
        >
          <Link
            to="/analyticaldashboard"
            style={{ textDecoration: 'none', color: '#ACACAC' }}
          >
            {t("Analytical Dashboard")}
          </Link>

          <div className="mx-2" style={{ color: '#ACACAC' }}>
            {' '}
            >{' '}
          </div>
          <span
            to="/analyticaldashboard/employability"
            style={{ textDecoration: 'none', color: '#D04C8D' }}
          >
            {t("Employability")}
          </span>
        </div>
        <div className="row">
          {Object.keys(this.state.data).map((key, index) => (
            <div className="col-12 col-lg-6 mb-4" key={index}>
              <div
                className="p-4 text-center bg-white rounded shadow"
                style={{ minHeight: '100%' }}
              >
                <h3 className="mb-3" style={{ fontWeight: '300' }}>
                  {t(key)}
                </h3>

                <DoghnutChart
                  data={this.formatChartData(this.state.data[key])}
                />

                <p
                  className="text-secondary mt-4"
                  style={{ fontSize: '0.9rem' }}
                >
                  {t("Percentage of talents who have scored low, moderate or high in the ")}{t(key)} {this.props.params.id ? t('value') : t('domain')}
                </p>

                <p className="text-secondary mb-4">{t(texts[key])}</p>

                <Link
                  to={
                    '/analyticaldashboard/employability/' +
                    this.state.data[key].id +
                    '?name=' +
                    key
                  }
                >
                  <Button name={t("Factor Analysis")} />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </MainLayout>
    );
  }
}

const EmployabilityDomainsComponent = withTranslation()(EmployabilityDomains)

export default function EmployabilityDomainsComponentWrapper (props) {
  const params = useParams();

  return <EmployabilityDomainsComponent params={params} {...props} />;
}
