import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import styled from 'styled-components';
import { ReactComponent as Cross } from '../../assets/svg/cross.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updateModal } from '../../reducers/universityModalReducer';

const CloseIconWrapper = styled.span`
  right: 40px;
  position: absolute;
  right: 50px;
  top: 30px;
  color: #7c7474;
  opacity: 0.2;
  cursor: pointer;
  z-index: 1;
  :hover {
    opacity: 0.8;
  }
`;

export default function UniversityModal({ children, onClose = null }) {
  const dispatch = useDispatch();
  const modalIsOpen = useSelector((state) => state?.modal?.modalIsOpen);
  const onCloseAction = () => {
    if (onClose) {
      onClose();
    }
    dispatch(updateModal(false));
  };
  const closeButton = (
    <CloseIconWrapper>
      <Cross />
    </CloseIconWrapper>
  );
  return (
    <Modal
      open={modalIsOpen}
      closeIcon={closeButton}
      onClose={onCloseAction}
      center
      classNames={{
        closeButton: 'university',
        root: 'university',
        modal: 'university',
      }}
    >
      {children}
    </Modal>
  );
}
