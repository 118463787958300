import axios from "axios";
import Cookies from "js-cookie";

const httpClient = axios.create({
  
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 90000,
});


httpClient.interceptors.request.use(
  function (config) {
    
    const token = Cookies.get("token");

    if (token) {
      if (token) {
        config.headers.Authorization = `Token ${token}`;
      }
    }
    return config;
  },
  function (error) {
   
    return Promise.reject(error);
  }
);


httpClient.interceptors.response.use(
  function (response) {
    
    return response;
  },
  function (error) {
   
    if (error.response) {
      
      if (error.response.status === 401) {
        if (error.response?.data?.message === "session expired") {
          localStorage.removeItem("auth_token");
        }
      }
    } else if (error.request) {
      
    } else {
     
    }

    return Promise.reject(error);
  }
);

export default httpClient;
