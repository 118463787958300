import React, { useCallback, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import Input from "../../../components/input/input";
import Selector from "../../../components/select/select";
import { ReactComponent as Trash } from "../../../assets/svg/trash.svg";
import { Controller, useForm } from "react-hook-form";
import {
  ArrowBackWrapper,
  BackLink,
  DropdownTitle,
  DropdownWrapper,
  SelectorWrapper,
  UserHeading,
  UserSubHeading,
} from "../../analyticalDashboardPage/commonStyles";
import Button from "../../../components/button/button";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowBack } from "../../../assets/svg/arrowBack.svg";
import FormErrors from "../../../components/formErrors/formErrors";
import { isEmpty, updatedValidEmailRegex } from "../../../utilities/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { updateModal } from "../../../reducers/universityModalReducer";
import EventBus from "../../../utilities/eventBus";
import { updateGlobalLoader } from "../../../reducers/universityLoadingReducer";
import { cloneDeep } from "lodash";
import { createUserByUniversityId } from "../../../actions/userManagement";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const UserManagementSection = styled(Row)`
  margin-bottom: 20px;
  .fieldset {
    position: relative;
    padding-bottom: 1rem;
    border-bottom: 1px solid #cccccc;
  }
  .trash {
    margin-top: 10px;
  }
`;

const CancelAndExit = styled.button`
  border: 0px;
  background-color: transparent;
  font-family: "General Sans", sans-serif;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-decoration-line: underline;
  color: #504f8c;
  width: 100%;
  border-radius: 128px;
  outline: none;
  border: 0px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding: 13px 20px;
  margin: 15px 0;
  cursor: pointer;
  &.cancelBtn {
    border-radius: 128px;
    border: 1px solid #d04c8d;
    color: #d04c8d;
    text-decoration: none;
  }
  :hover {
    background: #d04c8d;
    color: white;
  }
`;

export const AddUser = styled.button`
  font-family: "General Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
  color: #d04c8d;
  border: 0px;
  background-color: transparent;
`;
const CreateCloseButton = styled.span`
  cursor: pointer;
  scale: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(0%, 50%);
`;

const CreateUserForm = styled.div`
  margin: 40px 0px;
`;

export default function CreateUser({ setModalMode }) {
  const navigate = useNavigate();
  const [indexes, setIndexes] = React.useState([0]);
  // const [checkEmail, setCheckEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(true);
  const [errorEmailMessage, setErrorEmailMessage] = React.useState([]);
  const [duplicateEmails, setDuplicateEmails] = React.useState("");
  
  const { t } = useTranslation();
  const {
   
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });
  const dispatch = useDispatch();
  const masterdataRoles = useSelector((state) => {
    var tempdata = [];
    state?.userManagement?.masterdataRoles?.map((x) => {
      tempdata.push(x);
      return x;
    });
    const newRoles = tempdata.filter((role) => role.label !== 'Admin').concat({
      id: 4, 
      label: 'Analyst',
    
    });
    return newRoles;
  });

  const navigateBack = useCallback(() => {
    dispatch(updateGlobalLoader(false));
    navigate(-1);
  }, [dispatch, navigate]);

  useEffect(() => {
    EventBus.on("userCreateEvent", (data) => {
      if (!isEmpty(errors)) {
        console.log("errors s there", errors);
        return;
      }
      dispatch(createUserByUniversityId({ userData: data.data, id: data.university_id }))
        .unwrap()
        .then((response) => {
          if (response.created === false) {
            setEmailError(false);
            const errorList = [];
            const userList = response?.data?.data.user_list;
            userList.map(element => {
              if (element && element.hasOwnProperty("email")) {
                errorList.push(element?.email[0])
              } else {
                errorList.push('')
              }
              return errorList;

            });
            setErrorEmailMessage(errorList);
            
            toast.error(response?.data?.data.user_list[0]["email"][0], {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            navigateBack();
          }
          
        })
        .catch((duplicateEmails) => {
          dispatch(updateGlobalLoader(false));
          setDuplicateEmails(duplicateEmails);
        });
    });

    return () => {
      EventBus.remove("userCreateEvent");
    };
  });

  const addUserAction = () => {
    setIndexes((prevIndexes) => [...prevIndexes, prevIndexes.length + 1]);
  };

  const removeUser = (index) => {
    setIndexes((prevIndexes) => [
      ...prevIndexes.filter((item) => item !== index).map((_, i) => i + 1),
    ]);
  };


  const duplicateUsers = (arry) => arry.filter((item, index) => arry.indexOf(item) !== index)

  const onSubmit = (data) => {
    setDuplicateEmails([]);
    if (!isEmpty(errors)) {
      console.log("errors a there", errors);
      return;
    }
    const userList = [];
    data.user.map(element => {
      userList.push(element.email.toLowerCase())
      return userList;
    });
    const duplicateUserList = duplicateUsers(userList);
    if (duplicateUserList.length > 0) {
      setEmailError(false);
      const errorList = [];
      data.user.map(element => {
        if (duplicateUserList.indexOf(element.email) >= 0) {
          errorList.push('The duplicate email exists')
        } else {
          errorList.push('')
        }
        return errorList;
      });
      setErrorEmailMessage(errorList);
      return;
    } else {
      setModalMode("create");
      dispatch(updateModal({ modalIsOpen: true, cache: cloneDeep(data) }));
    }
  };
  return (
    <>
      <Row>
        <Col>
          <ArrowBackWrapper onClick={() => navigate(-1)}>
            <ArrowBack />
            <BackLink>{t("Back")}</BackLink>
          </ArrowBackWrapper>
        </Col>
      </Row>
      <Row>
        <Col
          style={{ background: "white", padding: "30px", borderRadius: "10px" }}
        >
          
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <UserHeading>{t("Add User")}</UserHeading>
                <UserSubHeading>
                  {t("Use this functionality to give other members of your university the access to Mynext portal. A one-time password will be sent to the newly added user to initiate the sign-up process. You can add multiple users in a single go.")}
                </UserSubHeading>
                <CreateUserForm>
                  <DropdownWrapper md="6">
                    <DropdownTitle>{t("Role")}</DropdownTitle>
                    <SelectorWrapper>
                      <Controller
                        control={control}
                        name="roles"
                        rules={{ required: "Role is required" }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                          fieldState: { error },
                        }) => (
                          <Selector
                            isMulti={true}
                            backgroundColor="#F3F3F3"
                            name="roles"
                            userOptions={masterdataRoles}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            className={error ? "invalid" : ""}
                            placeholder={t("Select...")}
                          />
                        )}
                      />
                      <FormErrors errors={errors} formControlName="roles" />
                    </SelectorWrapper>
                  </DropdownWrapper>
                </CreateUserForm>
                <hr />
              </Col>
            </Row>
            <UserManagementSection>
              <Col>
                {indexes.map((index, i) => {
                  const fieldName = `user[${i}]`;
                  return (
                    <fieldset
                      name={fieldName}
                      key={fieldName}
                      className="fieldset"
                    >
                      <Row>
                        <DropdownWrapper md="5">
                          <DropdownTitle>{t("Name")}</DropdownTitle>
                          <SelectorWrapper>
                            <Controller
                              control={control}
                              name={`${fieldName}.name`}
                              rules={{ required: "Name is required" }}
                              render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState: { error },
                              }) => (
                                <Input
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  value={value}
                                  className={error ? "invalid" : ""}
                                  style={{ background: "rgb(243, 243, 243)" }}
                                  placeholder={t("Name")}
                                  maxLength="100"
                                />
                              )}
                            />
                            <FormErrors
                              errors={errors}
                              formControlName={`${fieldName}.name`}
                            />
                          </SelectorWrapper>
                        </DropdownWrapper>
                        <DropdownWrapper md="5">
                          <DropdownTitle>{t("Email")}</DropdownTitle>
                          <SelectorWrapper>
                            <Controller
                              control={control}
                              name={`${fieldName}.email`}
                              rules={{
                                required: "Email is required",
                                pattern: {
                                  custom: {
                                    isValid: (value) =>
                                      duplicateEmails.includes(value),
                                    message: "Email provided already exists.",
                                  },
                                  value: updatedValidEmailRegex,
                                  message: "Invalid email.",
                                },
                              }}
                              render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState: { error },
                              }) => (
                                <Input
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  value={value}
                                  type="email"
                                  className={
                                    emailError === false || error
                                      ? "invalid"
                                      : ""
                                  }
                                  style={{ background: "rgb(243, 243, 243)" }}
                                  placeholder={t("Email")}
                                  maxLength="100"
                                />
                              )}
                            />
                            
                            {emailError === false ? (
                              <div className="sc-fvxzrP jurmfn">
                                {errorEmailMessage[i]}
                              </div>
                            ) : (
                              ""
                            )}
                            
                            <FormErrors
                              errors={errors}
                              formControlName={`${fieldName}.email`}
                            />
                          </SelectorWrapper>
                        </DropdownWrapper>
                        <Col
                          md="2"
                          className="d-flex align-items-center justify-content-center trash"
                        >
                          {indexes.length > 1 && (
                            <CreateCloseButton onClick={() => removeUser(i)}>
                              <Trash />
                            </CreateCloseButton>
                          )}
                        </Col>
                      </Row>
                    </fieldset>
                  );
                })}
              </Col>
            </UserManagementSection>
            <Row>
              <Col>
                <AddUser onClick={addUserAction} type="button">
                  + {t("Add")}
                </AddUser>
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mb-4">
              <Col lg="3" md="5">
                <Button name={t("Create")} type="submit" style={{ width: "100%" }} />
              </Col>
              <Col lg="3" md="5">
                <CancelAndExit
                  type="button"
                  className="cancelBtn"
                  onClick={() => {
                    
                    dispatch(updateModal(false));
                    navigate(-1);
                    
                  }}
                >
                  {t("Cancel")}
                </CancelAndExit>
              </Col>
            </Row>
          </form>
          
        </Col>
      </Row>
    </>
  );
}
