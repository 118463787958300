import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  search: null,
  status: "all"
};

export const companyUsersFilter = createSlice({
  name: "companyUsersFilter",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    resetAllCompanyUsers: (state) => {
      state.search = null;
      state.status = "all";
    },
  }
});


export const {
  setSearch,
  setStatus,
  resetAllCompanyUsers
} = companyUsersFilter.actions;

export default companyUsersFilter.reducer;
