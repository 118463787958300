import ViewIcon from "../../assets/icons/svg/view.svg";
import JobIcon from "../../assets/icons/intern.png";
import SalaryIcon from "../../assets/icons/svg/salary.svg";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import { dateToDateMonthInWordsYear } from "../../utilities/commonFunctions";
import { useTranslation } from "react-i18next";

const FlexDiv = styled.div`
  display: flex;
`;


const ContentSection = styled.div`
  margin-left: 10px;
  width: calc(100% - 26px);
`;


const JobTitle = styled.h3`
  display: inline;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: ${Colors.dark};
  margin: 0;
`;

const DateText = styled.p`
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  margin: 0;
  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const JobPostedDate = styled(DateText)`
  color: ${Colors.primaryText};
`;
const JobExpiredDate = styled(DateText)`
  color: #504f8c;
`;
const DateWrapper = styled(FlexDiv)`
  align-items: center;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const OtherTextWrapper = styled(FlexDiv)`
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
const JobDescriptionSection = styled(FlexDiv)`
  align-items: center;
`;
const IconSingleSection = styled(FlexDiv)`
  align-items: center;
  margin-right: ${(props) => (props.margin ? props.margin : "0px")};
`;
const OtherText = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #525252;
`;

const JobsWrapper = styled.div`
  margin-bottom: 0.75em;
`;

const VacancyText = styled.span`
  display: inline;
  color: #b3b3b3;
  margin-left: 1em;
`;


export default function ApplicatantsHeader(props) {
  const {
    title,
    no_positions,
    intern_type,
    min_compensation,
    max_compensation,
    viewed_count,
    payment_status,
    position_start_date,
    position_end_date
  } = props?.data;

  const { t, } = useTranslation();
  return (
    <ContentSection>
      <JobsWrapper>
        <JobTitle>{title}</JobTitle>
        <VacancyText>
          <img src={ViewIcon} alt="view" /> {viewed_count} {t("views")}
        </VacancyText>
      </JobsWrapper>
      <DateWrapper>
        <JobPostedDate>
        {t("Posted")}: {dateToDateMonthInWordsYear(position_start_date)}
        </JobPostedDate>
        <JobExpiredDate>
          {t("Expires")}: {dateToDateMonthInWordsYear(position_end_date)}
        </JobExpiredDate>
      </DateWrapper>
      <OtherTextWrapper>
        <OtherText>{t("Vacancies")}: {no_positions}</OtherText>
        <JobDescriptionSection>
          <IconSingleSection margin="20px">
            <img src={JobIcon} alt="job" />
            <OtherText>{t(intern_type).toUpperCase()}
              
            </OtherText>
          </IconSingleSection>
          <IconSingleSection>
            <img src={SalaryIcon} alt="job" />
            <OtherText>
              {payment_status === "paid" ?
                min_compensation + " MYR - " + max_compensation + "MYR"
                : "0 MYR"}
            </OtherText>
          </IconSingleSection>
        </JobDescriptionSection>
      </OtherTextWrapper>
    </ContentSection>
  );
}
