import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Image, ImageWrapper, CancelAndExit } from "../../analyticalDashboardPage/commonStyles";
import universityLogo from "../../../assets/institute.jpg";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import UniversityPrimaryDetails from "../universityPrimaryDetails/universityPrimaryDetails";
import UniversityOverview from "../universityOverview/universityOverview";
import Loader from "../../../components/loader/loader";
import Button from "../../../components/button/button";
import { isEmpty } from "../../../utilities/commonFunctions";
import { toast } from "react-toastify";
import universityService from "../../../services/university.service";
import { useNavigate } from "react-router-dom";
import FormErrors from "../../../components/formErrors/formErrors";
import { useTranslation } from "react-i18next";

export default function UniversityHeader() {
  const navigate = useNavigate();
  const [isEditable, setIsEditable] = useState(true);
  const [loading,] = useState(false);
  const [universitiesFull, setUniversitiesFull] = useState([]);
  
  const [defaultValues,] = useState({
    name: "",
    shortName: "",
    motto: "",
    description: "",
    website: "",
    phone_number: "",
    yearFounded: "",
    noOfEmployees: "",
    logo: "",
  });
  
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Please Select University"),
    email: Yup.string()
      .email(
        "Please include an '@' in the email-address , Invalid email format"
      )
      .required("Required"),
    shortName: Yup.string()
      .max(50, "should be within 50 characters")
      .required("Short name is required"),
    motto: Yup.string()
      .max(255, "should be within 255 characters")
      .required("Motto is required"),
    description: Yup.string().required("Description is required"),
    
    website: Yup.string()
      .max(150, "should be within 150 characters")
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      )
      .required("Please enter website"),
    phone: Yup.string()
      .max(128, "should be within 128 characters")
      .required("Phone is required"),
    yearFounded: Yup.string()
      .length(4, "should be 4 characters long")
      .required("Year founded is required"),
    noOfEmployees: Yup.string()
      .nullable()
      .default(1)
      
      .min(1, "should be at least one")
      .max(1000000000, "should be less than 1,000,000,000")
     
      .required("University Population Size is required")
  });
  const totalForm = useForm({
    criteriaMode: "all",
    resolver: yupResolver(formSchema),
    defaultValues: defaultValues,
  });
  const {
    
    formState: { errors },
    handleSubmit,
    getValues,
    setError,
  } = totalForm;
  const [selectedImage, setSelectedImage] = useState();
  const { t,  } = useTranslation();

  const imageChange = (e) => {

    if(e.target.files[0].size <= 5000000){ 

    setIsEditable(true);
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
      console.log(e.target.files[0]);
    }

    }else{

      toast.error(t("File size exceeds maximum limit."));


    }



  };

  const getUniversityData = () => {
    const body = {
      name: "",
      search: "",
    };
    universityService
      .getUniversities({
        ...body,
      })
      .then((response) => {
        if (response && response.data && response.data.count > 0) {
          
          const dummyData = response.data.results;
          // eslint-disable-next-line array-callback-return
          dummyData.map((e) => {
            setUniversitiesFull((arr) => [...arr, e.universityId]);
          });
          
        } else {
          setUniversitiesFull([]);
        }
      });
  };

  useEffect(() => {
    getUniversityData();
  }, []);

  

  const onSubmit = (data) => {
    if (!isEmpty(errors)) {
      console.log("errors there", errors);
      
      return;
    }
    
    const form = new FormData();
    form.append("name", data.univ_name);
    form.append("short_name", data.shortName);
    form.append("motto", data.motto);
    form.append("description", data.description);
    form.append("url", data.website);
    form.append("email", data.email);
    form.append("phone_number", data.phone);
    form.append("universityId", data.name);
    console.log(data.phone);
    form.append("year_founded", data.yearFounded);
    form.append("no_of_employees", data.noOfEmployees);
    console.log("before", selectedImage);
    if (selectedImage !== "" && selectedImage !== undefined) {
      console.log("before", selectedImage);
      form.append("logo", selectedImage);
    }

    universityService.createNewUniversity(form).then((response) => {
      if (response.status === 201) {
        toast.success(t("University created successfully!"));
        navigate("/universities");
      } else {
        console.log(response, "errors response");
        
        if (response?.data?.phone_number) {
          setError("phone", {
            type: "server",
            message: response?.data?.phone_number[0],
          });
          toast.error(response?.data?.phone_number[0]);
        }
        if (response?.data?.name) {
          setError("name", {
            type: "server",
            message: response?.data?.name[0],
          });
          toast.error(response?.data?.name[0]);
        }
        if (response?.data?.url) {
          setError("website", {
            type: "server",
            message: response?.data?.url[0],
          });
          toast.error(response?.data?.url[0]);
        }
        
      }
    });
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ background: "white", padding: "50px", borderRadius: "10px" }}
      >
        <Row style={{ display: "flex", flexWrap: "wrap" }}>
          <div className="d-flex" style={{ minWidth: "200px", width: "20%" }}>
            <ImageWrapper>
              {selectedImage ? (
                <Image
                  src={URL.createObjectURL(selectedImage)}
                  alt="university-logo"
                />
              ) : getValues().avatar ? (
                <Image src={getValues().avatar} alt="university-logo" />
              ) : (
                <Image src={universityLogo} alt="university-logo" />
              )}

              <div
                className="changeImage"
                style={{
                  fontFamily: "General Sans ,sans-serif",
                  background: "white",
                }}
              >
                <input
                  accept="image/x-png,image/jpeg"
                  type="file"
                  className="position-absolute opacity-0"
                  onChange={imageChange}
                />
                {t("Change Logo")}
              </div>
              <p style={{ fontSize: "12px", color: "gray" }}>
                {t("The maximum image size limit is 5 MB")}
              </p>
              <FormErrors errors={t(errors)} formControlName="logo" />
            </ImageWrapper>
          </div>
          <Col style={{ width: "80%", minWidth: "400px" }}>
            <UniversityPrimaryDetails
              totalForm={totalForm}
              isEditable={isEditable}
              datas={universitiesFull}
            />
          </Col>
        </Row>
       
        <Row>
          <Col>
            <UniversityOverview
              totalForm={totalForm}
              setIsEditable={setIsEditable}
              isEditable={isEditable}
            />
          </Col>
        </Row>
       
        {isEditable && (
          <Row>
            <Col>
              <Container className="">
                <Row className="d-flex justify-content-between mt-4">
                  <Col lg="3">
                    {loading ? (
                      <Loader />
                    ) : (
                      <Button
                        name={t("Save")}
                        type="submit"
                        style={{ width: "100%" }}
                      />
                    )}
                  </Col>

                  <Col lg="3">
                    <CancelAndExit
                      type="button"
                      className="cancelBtn"
                      onClick={() => {
                        navigate("/universities");
                      }}
                    >
                      {t("Cancel")}
                    </CancelAndExit>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        )}
      </form>
    </>
  );
}
