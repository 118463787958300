import React from 'react';
import MainLayout from '../../../../layouts/mainLayout/mainlayout';
import DoghnutChart from '../../../../components/analyticalDashboard/charts/doughnutChart.js';
import Button from '../../../../components/button/button';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { onApiCall } from '../../../../services/CommonApi';
import { withTranslation } from 'react-i18next';

const texts = {
  'Intellectual stimulation':
    'It will be important for talents with high Intellectual Stimulation to look for roles that enable them to use their intellect to achieve results.',
  Recognition:
    'It will be important for talents with high Recognition values to work in an environment where they will be able to gain recognition or status for their contribution.',
  Achievement:
    'It is important for talents that scored high in Achievement that they are in roles where they can gain a sense of achievement through setting goals, working hard and achieving results.',
  Independence:
    'It will be important for talents with high Independence score to work in a role with a high level of independence and autonomy.',
  Variety:
    'Rating Variety high may come from having a role that involves a range of diverse tasks, settings, client groups or from learning new things.',
  Security:
    'It is important for talents with high Security score to have a role that provides them with security through a permanent contract.',
  'Way of Life':
    'It is important for talents that scored high in Way of Life to you to have a job that allows them to lead the type of lifestyle that they choose.',
  Surroundings:
    'It is very important for talents with high Surroundings scores that they work in an environment which is pleasant and suited to your preferences.',
  'Economic Return':
    'It is important for talents that scored high on Economic Return that their job pays well and provides them with the opportunity to have a good standard of living.',
  'Supervisory Relationship':
    'It is very important for talents that scored high in Supervisory Relationships to have a good relationship with their line manager or supervisor.',
  Associates:
    'It is very important for talents with a high Associates value score to work as part of a team, and they thrive when working with others towards a common goal.',
  Belonging:
    'It is very important for talents that score high on Belonging to have a sense of belonging to the organisation that they work for.',
  Family:
    'It is important for talents with high Family value scores to have a career that their family would approve of and be proud of and that would bring benefits to their family as well as themselves.',
  Aesthetic:
    'It is very important for talents with high Aesthetic scores to be involved in work that creates beauty, designs beautiful products, or creates beautiful spaces or landscapes for people to enjoy.',
  Creativity:
    'Talents with high Creative value scores work best when they are free to be creative. This may involve solving difficult problems, creating new products, looking at an issue from different angles or creating innovative solutions.',
  Leadership:
    'It is very important for talents that score high on Leadership to have opportunities to take on a leadership role.',
  Altruism:
    'It is important for talents with high Altruism value scores to contribute to the welfare of others.',
  'Protecting the Planet':
    'It is very important for talents that score high on Protecting the Planet that they work for an organisation which takes its responsibility for the planet seriously.',
};

class WorkValuesValues extends React.Component {
  constructor(props) {
    super(props);

    this.state = { data: {} };
  }

  getData() {
    onApiCall({
      url: `/api/dashboard/analytical/work-values/${this.props.params.id}/`,
      method: 'GET',
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ data: res.data?.data?.results });
      }
    });
  }

  componentDidMount() {
    this.getData();
  }

  componentWillReceiveProps(newProps) {
    this.getData();
  }

  formatChartData(data) {
    const { t } = this.props;
    return {
      labels: [t('Low'), t('Moderate'), t('High')],
      datasets: [
        {
          data: [data.results.low, data.results.moderate, data.results.high],
          backgroundColor: ['#65b920', '#b4e056', '#eef359'],
        },
      ],
    };
  }

  render() {
    const { t } = this.props;
    return (
      <MainLayout>
        <div
          className="d-flex mb-5"
          style={{ fontSize: '1.1rem', fontWeight: '400' }}
        >
          <Link
            to="/analyticaldashboard"
            style={{ textDecoration: 'none', color: '#ACACAC' }}
          >
            {t("Analytical Dashboard")}
          </Link>
          <div className="mx-2" style={{ color: '#ACACAC' }}>
            {' '}
            >{' '}
          </div>
          <Link
            to="/analyticaldashboard/work-values"
            style={{ textDecoration: 'none', color: '#ACACAC' }}
          >
            {t("Work Values")}
          </Link>
          <div className="mx-2" style={{ color: '#ACACAC' }}>
            {' '}
            >{' '}
          </div>
          <span style={{ textDecoration: 'none', color: '#D04C8D' }}>
            {t(this.props.searchParams.get('name'))}
          </span>
        </div>
        <div className="row">
          {Object.keys(this.state.data).map((key, index) => (
            <div className="col-12 col-lg-6 mb-4" key={index}>
              <div
                className="p-4 text-center bg-white rounded shadow"
                style={{ minHeight: '100%' }}
              >
                <h3 className="mb-3" style={{ fontWeight: '300' }}>
                  {t(key)}
                </h3>

                <DoghnutChart
                  data={this.formatChartData(this.state.data[key])}
                />

                <p
                  className="text-secondary mt-4"
                  style={{ fontSize: '0.9rem' }}
                >
                  {t("Percentage of talents who have scored low, moderate or high in the ")}{t(key)} {this.props.params.id ? t('value') : t('domain')}
                </p>

                <p className="text-secondary mt-4">{t(texts[key])}</p>

                {!this.props.params.id && (
                  <Link
                    width="150px"
                    to={
                      '/analyticaldashboard/work-values/' +
                      this.state.data[key].id
                    }
                  >
                    <Button width="150px" name={t("View Details")} />
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>
      </MainLayout>
    );
  }
}

const WorkValuesValuesComponent = withTranslation()(WorkValuesValues)

export default function WorkValuesValuesComponentWrapper (props) {
  const params = useParams();
  const [searchParams,] = useSearchParams();

  return (
    <WorkValuesValuesComponent params={params} searchParams={searchParams} {...props} />
  );
}
