import styled from 'styled-components';
import { Colors } from '../../utilities/colors';

export default function cardImage({
  size,
  count,
  discription,
  children,
  height,
  overflow,
  left,
  right,
  mb,
  bg,
  bs,
  padding,
  subText,
}) {
  const CardWrapper = styled.div`
    display:flex
    overflow: hidden;
    width: ${size}%;
    align-items:center;
    height: ${height ? height : '100%'};
    background: ${bg ? bg : '#ffffff'};
    font-family: Quicksand, arial, sans-serif;
    box-shadow: ${
      bs ? bs : '0 0 1px rgba(0, 0, 0, 0.1), 0 0px 10px rgba(0, 0, 0, 0.08);'
    };
    position:relative;
    border-radius: 10px;
    overflow-y: ${overflow ? 'scroll' : 'none'};
    margin-left: ${left ? `${left}vh` : '0px'};
   
    margin-bottom: ${mb ? mb : '20px'};
    padding:${padding ? padding : 'none'};
    // padding: 0 20px;
    @media (max-width:500px){
      width: 100%;
      align-items: center;
      padding:5px;
    }
  `;

  const Counts = styled.label`
    font-weight: 500;
    font-size: 74px;
    display: flex;
    margin-top: 20px;
    justify-content: center;
    line-height: 50px;
    color: ${Colors.primaryText};
    @media (max-width: 500px) {
      display: block;
      text-align: center;
    }
  `;

  const DescriptionOne = styled.div`
    color: ${Colors.expiredDateColor};
    font-size: 24px;
    display: flex;
    justify-content: center;
    width: 100%;
    @media (max-width: 500px) {
      width: 100%;
    }
  `;

  const DescriptionLayer = styled.div`
    position: absolute;
    line-height: 24px;
    margin-top: 4rem;
    @media (max-width: 500px) {
      margin: 0 auto;
    }
  `;
  const CardText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  

  return (
    <CardWrapper>
      {count && <Counts>{count}</Counts>}
      {discription && (
        <CardText>
          <DescriptionLayer>
            <DescriptionOne>{discription}</DescriptionOne>
            <DescriptionOne>{subText}</DescriptionOne>
          </DescriptionLayer>
        </CardText>
      )}

      {children}
    </CardWrapper>
  );
}
