import { createSlice } from "@reduxjs/toolkit";

import { initialObj } from "./staticContent";

const initialState = {
  locationData: initialObj,
  isEdit:false
};

export const newLocationReducer = createSlice({
  name: "location",
  initialState,
  reducers: {
    updateLocationData: (state, action) => {
      state.locationData = action.payload;
    },
    updateEdit: (state, action) => {
      state.isEdit = action.payload;
    },
  },
});


export const { updateLocationData,updateEdit } = newLocationReducer.actions;

export default newLocationReducer.reducer;
