import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Mainlayout from "../../layouts/mainLayout/mainlayout";
import { Colors } from "../../utilities/colors";
import InputGroup from "../../components/inputGroup/inputGroup";
import Button from "../../components/button/button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/loader/loader";
import InfiniteScroll from "react-infinite-scroll-component";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";


import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import TextArea from "../../components/description/description";
import ErrorField from "../../components/errorField/errorField";
import { Row, Col } from "react-bootstrap";
import PendingPage from "./pendingpage.js";
import { ReactComponent as AcceptedIcon } from "../../assets/icons/svg/acceptedIcon.svg";
import { ReactComponent as PendingIcon } from "../../assets/icons/svg/pendingIcon.svg";
import { ReactComponent as AllIcon } from "../../assets/icons/svg/all.svg";
import { ReactComponent as Expired } from "../../assets/icons/svg/expiredIcon.svg";
import { ReactComponent as Trash } from "../../assets/icons/svg/trash.svg";
import {
  companyAction,
  companyStatusApi,
  statusDropdownCompany,
  getAdminUserInfo,
} from "../../services/apiCalls";
import {
  resetAllCompanies,
  setStatus as setFilterStatus
} from "./viewDetails/companyFilter";
import AlertModalCompany from "../../components/alertModal/alertModalCompany";
import { useDispatch, useSelector } from "react-redux";


const LabelText = styled.label`
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;
`;


const LightText = styled(LabelText)`
  color: ${Colors.black};
  margin-left: 0vh;
`;


const Message = styled.div`
  padding-left: 20px;
  font-size: 22px;
  padding-bottom: 5px;
`;


const SingleField = styled.div`
  margin-bottom: 30px;
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;

const CompanyHead = styled.div``;

const DropdownWrapper = styled.div`
  margin-top: 2rem;
  font-weight: 500;
  font-size: 16px;
`;


const DivWrapper = styled.div``;


export default function Companies() {
  const [, setPendingpage] = useState(false);
  const [datas, setData] = useState([]);
  const [period, ] = useState({
    start_date: "",
    end_date: "",
  });
  const [clear,] = useState(false);
  const [search, setSearch] = useState("");
  
  const [name, setName] = useState("all");
  const [qty, setQty] = useState([]);
  const [status, setStatus] = useState(true);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState();
  const [show, setShow] = useState(false);
  const [action, setAction] = useState("");
  const [approve, setApprove] = useState(false);
  const [code, setCode] = useState();
  const [id, setId] = useState();
  const [data, setDatacmd] = useState();
  const [, setOptionValue] = useState();
  const [updateTime, setUpdateTime] = useState();
  const [showOne, setShowNewOne] = useState(false);
  const dispatch = useDispatch();
  const { t, } = useTranslation();
  let navigate = useNavigate();
  const statusFilter = useSelector((state) => state.companyFilter.status);
  const [selectOptions, setSelectOptions] = useState(statusFilter ? statusFilter:"all");

  useEffect(() => {
      return ()=>{
      if (window.location.href.indexOf("companies") === -1 && window.location.href.indexOf("company-detail") === -1 && window.location.href.indexOf("companiesuserslist") === -1) {
       dispatch(resetAllCompanies());
      }
      }
  }, [dispatch]);

  const getDatas = useCallback (async () => {
    var result1 = await getAdminUserInfo();
    if (result1.status === "401") {
      navigate("/login");
    } else {
      setShowNewOne(true);
    }
  }, [navigate])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const options = [
    {
      value: "all",
      label: t("All"),
      customAbbreviation: qty ? qty.all : null,
      icon: <AllIcon />,
    },
    {
      value: "accepted",
      label: t("Approved"),
      customAbbreviation: qty ? qty.accepted : null,
      icon: <AcceptedIcon />,
    },
    {
      value: "pending",
      label: t("Pending"),
      customAbbreviation: qty ? qty.pending : null,
      icon: <PendingIcon />,
    },
    {
      value: "rejected",
      label: t("Denied"),
      customAbbreviation: qty ? qty.rejected : null,
      icon: <Trash />,
    },
    
    {
      value: "review",
      label: "Sent back for Review",
      customAbbreviation: qty ? qty.review : null,
      icon: <Expired />,
    },
  ];

  const selectedOption=()=>{
    return options.filter((e)=>{
      return e.value === statusFilter;
    });
  }

  const selectState = useCallback (() => {
    if (selectOptions === "pending") {
      setPendingpage(true);
      setName("Pending");
    } else if (selectOptions === "accepted") {
      setPendingpage(true);
      setName("Approved");
    } else if (selectOptions === "rejected") {
      setPendingpage(true);
      setName("Denied");
    } else if (selectOptions === "review") {
      setPendingpage(true);
      setName("Sent back for Review");
    } else {
      setPendingpage(true);
      setName("All");
    }
  }, [selectOptions])

  useEffect(() => {
    selectState();
    setOptionValue(options[0].value);
  }, [selectOptions, options, selectState]);
  useEffect(() => {
    getDatas();
    statusDropdownApi();
    
    setSelectOptions(statusFilter ? statusFilter:"all");
  }, [getDatas, statusFilter]);

  
  const stylesq = {
    control: (base, state) => ({
      ...base,
      height: "45px",
      padding: "5.5px 5px",
      transition: "none",
      textTransform: "capitalize",
      width: "100%",
      zIndex: "100",
      background: `${Colors.light}`,
      border: "0px",
      outline: "none",
      boxShadow: " inset 0px 0px 4px rgba(0, 0, 0, 0)",
      borderRadius: "128px",
      "&:hover": {
        boxShadow: "0 0 0 1px #ff60b1",
        borderColor: "#ff60b1",
      },
    }),
    menu: (base) => ({
      ...base,
      marginTop: "0px",
      outline: "none",
      zIndex: "3",
      textTransform: "capitalize",
      border: "0px solid white",
      transition: "none",
      minWidth: "234px",
    }),
    menuList: (base) => ({
      ...base,
      color: "#575757;",
      fontSize: "12px",
     
      textTransform: "capitalize",
    }),
    option: (provided, state) => ({
      ...provided,
      width: "100%",
      height: "100%",
      fontSize: `14px`,
      
      fontWeight: `500`,
      backgroundColor: state.isSelected ? `#D04C8D` : `transparent`,
      color: state.isSelected ? `white` : `#202020`,
      "&:hover": {
        backgroundColor: state.isSelected ? `#D04C8D` : `#FFE9F4`,
        Color: `#D04C8D`,
      },
    }),
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        color: `#575757; !important`,
      },
    }),
  };
  

  const formatOptionLabel = ({ value, label, customAbbreviation, icon }) => (
    <>
      <Row>
        <Col xs={2}>{icon}</Col>
        <Col xs={7}>{label}</Col>
        <Col xs={3} className="text-end">
          {customAbbreviation}
        </Col>
      </Row>
    </>
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions, clear]);

  const compo = {
    IndicatorSeparator: () => null,
    dropdownIndicator: () => null,
  };
  
  const handleSelect = (value) => {
    setSelectOptions(value.value);
    dispatch(setFilterStatus(value.value));
    setData([]);
    setPage(0);
  };
  const handleSearch = (e) => {
    console.log(e.target.value);
    setSearch(e.target.value);
    setTotal();
    setPage(0);
    setData([]);
    setApprove(!approve);
  };

  const schema = yup.object().shape({
    comments: yup.string().required("Comments is a required field."),
  });
  const {
    control,
    handleSubmit,
    
    formState: { errors, },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const content = () => {
    return (
      <>
      {
        (action=='review')?<div>
          <form onSubmit={handleSubmit(onReviewSubmit)}>
            <div className="container">
              <SingleField style={{ marginBottom: 0 }}>
                <FormLabel>{t("Comments")}</FormLabel>
                <Controller
                  name="comments"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      style={{ background: "#F3F3F3" }}
                      {...field}
                      rows="3"
                      placeholder={t("Enter Comment")}
                    />
                  )}
                />
                {errors.comments && (
                  <ErrorField err={t(errors.comments.message)} />
                )}
              </SingleField>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Button
                  width="180px"
                  name={t("Save")}
                  type="submit"
                  style={{ marginRight: "30px" }}
                />
                <Button
                  width="180px"
                  type="button"
                  name={t("Cancel")}
                  color="#FFFFFF"
                  onClick={() => {
                    setShow(false);
                    reset();
                  }}
                />
              </div>
            </div>
          </form>
          {
            (data)?<table style={{background:"#F84B9C",overflow: "hidden",margin: "20px",width: "90%"}}>
            <thead style={{background:"#F84B9C"}}>
              <tr >
                  <th style={{color:"white"}}>Comments</th>
                  <th style={{color:"white"}}>Created</th>
              </tr>
            </thead>
            <tbody style={{paddingBottom:"25px"}}>
              {data.length> 0 ? 
              data.map((d,index)=>
              <>
                  <tr key={index} style={{background:"white"}}>
              <td>{d.comment}</td>
              <td>{d.date}</td>
              </tr>
              </>
              ) : null
              }
              
              
            </tbody>        
          </table>  :""
          }
            
    </div>:<div style={{ padding: "0 40px 20px 40px" }}>
        <Message
          style={{
            textAlign: "center",
            fontSize: "26px",
            fontWeight: "bold",
            margin: "0px auto 10px auto",
          }}
        >
          {t("Are you sure you want to")} {action === "accepted" ? t("approve") : t("deny")}{" "}
          {t("this company ?")}
        </Message>
        {action !== "accepted" ? (
          <form onSubmit={handleSubmit(onRejectionSubmit)}>
            <div className="container">
              <SingleField style={{ marginBottom: 0 }}>
                <FormLabel>{t("Rejection Note")}</FormLabel>
                <Controller
                  name="comments"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      style={{ background: "#F3F3F3" }}
                      {...field}
                      rows="3"
                      placeholder={t("Enter Comment")}
                    />
                  )}
                />
                {errors.comments && (
                  <ErrorField err={t(errors.comments.message)} />
                )}
              </SingleField>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Button
                  width="180px"
                  name={t("Yes")}
                  type="submit"
                  style={{ marginRight: "30px" }}
                />
                <Button
                  width="180px"
                  type="button"
                  name={t("No")}
                  color="#FFFFFF"
                  onClick={() => {
                    setShow(false);
                    reset();
                  }}
                />
              </div>
            </div>
          </form>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <Button
              width="180px"
              name={t("Yes")}
              onClick={() => approveCompany()}
              style={{ marginRight: "30px" }}
            />
            <Button
              width="180px"
              name={t("No")}
              color="#FFFFFF"
              onClick={() => setShow(false)}
            />
          </div>
        )}
      </div>
      }
      </>
      
    );
  };

  const handleActionCall = (e, code, actionName, a,id,cmd) => {
    e.stopPropagation();
    setShow(true);
    setAction(actionName);
    setCode(code);
    setUpdateTime(a);
    setId(id)
    if(cmd){
      try {
        setDatacmd(JSON.parse(cmd))
      } catch (error) {
        console.log(error)
      }
      
    }
  };
  const onRejectionSubmit = async (values) => {
    const body = {
      code: code,
      status: action,
      comments: values.comments,
      update_time: updateTime,
      id:id
    };
    console.log(body)

    setStatus(true);

    const { data, status } = await companyAction(body);

    if (status === 200) {
      setStatus(false);
      setShow(false);
      if (data.status === 1) {
        setStatus(false);
        toast.success(
          action === "accepted"
            ? t("Company has been approved")
            : t("Company has been denied"),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setFun();
      }
    }
  };

  const onReviewSubmit = async (values) => {
    const body = {
      code: code,
      status: action,
      comments: values.comments,
      update_time: updateTime,
      id:id
    };
    console.log(body)

    setStatus(true);

    const { data, status } = await companyAction(body);

    if (status === 200) {
      setStatus(false);
      setShow(false);
      if (data.status === 1) {
        setStatus(false);
        toast.success(
          "Company  review has been sent.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setFun();
      }
    }
  };

  const approveCompany = async () => {
    const body = {
      code: code,
      status: action,
      update_time: updateTime,
      id:id
    };

    setStatus(true);

    const { data, status } = await companyAction(body);

    if (status === 200) {
      setStatus(false);
      setShow(false);
      if (data.status === 1) {
        setStatus(false);
        toast.success(
          action === "accepted"
            ? t("Company has been approved")
            : t("Company has been denied"),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setFun();
      }
    }
  };

  const closeModal = () => {
    setShow(false);
  };

  
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approve]);
  const setFun = () => {
    setTotal();
    setPage(0);
    setData([]);
    setApprove(!approve);
    statusDropdownApi();
  };

  const fetchData = (a) => {
    
    if (total) {
     
      const count = page + 1;
      setPage(count);
      if (count <= total) {
        
        statusAPiCall(count);
       
      }
    } else {
      
      const count = page + 1;
      setPage(count);
      statusAPiCall(count);
    }
  };
  const statusAPiCall = async (count) => {
    const body = {
      page_number: count ? count : page,
      count_per_page: 10,
      search: search,
      filters: {
        status: selectOptions,
        
        ...(period.start_date && period.end_date
          ? { period: { ...period } }
          : { period: {} }),
      },
    };
    if (count === 1) {
      setStatus(true);
    }

    const { data, status } = await companyStatusApi(body);
    if (status === 200) {
      setStatus(false);
      
      setData([...datas, ...data.data]);
     
      setTotal(data.pagination.total_pages);
    }
  };

  const statusDropdownApi = async () => {
    const { data, status } = await statusDropdownCompany();
    if (status === 200) {
      if (data.status === 1) {
        setQty(data.data);
      }
    }
  };
  

  return (
    <Mainlayout>
      {showOne ? (
        <>
          <CompanyHead>
            
            <LightText> {t("Manage Companies")} </LightText>
            <ReactTooltip
              id="search"
              globalEventOff="focusin"
              place="top"
              effect="solid"
            >
              {t("Search Organization by name")}
            </ReactTooltip>
           
            <Row>
              <Col lg="8" xl="8">
                <InputGroup
                  dataId="search"
                  input={{
                    placeholder: t("Search Keywords for Company Name"),
                    name: "search",
                    onChange: handleSearch,
                    value: search,
                  }}
                  type="text"
                />
              </Col>
              <Col lg="4" xl="4" style={{ height: "100px" }}>
                <DropdownWrapper>
                  <Select
                    
                    formatOptionLabel={formatOptionLabel}
                    options={options}
                    components={compo}
                    styles={stylesq}
                    onChange={handleSelect}
                    defaultValue={statusFilter && statusFilter !== null && statusFilter !== "" ? selectedOption:options[t(0)]}
                    val={selectOptions ? selectOptions : options[0]}
                  />
                </DropdownWrapper>
              </Col>
            </Row>
            <DivWrapper>
              <InfiniteScroll
                dataLength={datas.length}
                next={fetchData}
                hasMore={true}
                loader={page === 1 ? "" : page <= total ? <Loader /> : ""}
                scrollThreshold={0.5}
              >
                <PendingPage
                  name={selectOptions}
                  title={name}
                  value={datas}
                  handleApprove={handleActionCall}
                  loading={status}
                />
              </InfiniteScroll>
            </DivWrapper>
          </CompanyHead>
          <AlertModalCompany
            show={show}
            close={closeModal}
            content={content()}
          />
        </>
      ) : (
        <Loader />
      )}
    </Mainlayout>
  );
}
