import React from "react";
import MainLayout from "../../../../layouts/mainLayout/mainlayout";
import DoghnutChart from "../../../../components/analyticalDashboard/charts/doughnutChart.js";
import Button from "../../../../components/button/button";
import { Link, useParams } from "react-router-dom";
import { onApiCall } from "../../../../services/CommonApi";
import { withTranslation } from 'react-i18next';

const texts = {
  Mastery:
    "The Mastery domain contains the values Intellectual stimulation, Recognition, Achievement, Independence. Scoring high, moderate or low for this domain will depend on how the talents have scored for these values.",
  "Quality of Life":
    "The Quality of Life domain contains the values Variety, Security, Way of Life, Surroundings, and Economic Return. Scoring high, moderate or low for this domain will depend on how the talents have scored for these values.",
  Fellowship:
    "The Fellowship domain contains the values Altruism, Supervisory Relationship, Associates, Belonging, and Family. Scoring high, moderate or low for this domain will depend on how the talents have scored for these values.",
  "Creating Value":
    "The Creating Value domain contains the values Aesthetic, Creativity, Leadership, and Protecting the Planet. Scoring high, moderate or low for this domain will depend on how the talents have scored for these values.",
};

class WorkValues extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: {} };
  }

  getData() {
    onApiCall({
      url: `/api/dashboard/analytical/work-values/`,
      method: "GET",
    }).then((res) => {
      if ((res.status = 200)) {
        this.setState({ data: res.data?.data?.results });
      }
    });
  }

  componentDidMount() {
    this.getData();
  }

  componentWillReceiveProps(newProps) {
    this.getData();
  }

  formatChartData(data) {
    const { t } = this.props;
    return {
      labels: [t("Low"), t("Moderate"), t("High")],
      datasets: [
        {
          data: [data.results.low, data.results.moderate, data.results.high],
          backgroundColor: ["#65b920", "#b4e056", "#eef359"],
        },
      ],
    };
  }

  render() {
    const { t } = this.props;
    return (
      <MainLayout>
        <div
          className="d-flex mb-5"
          style={{ fontSize: "1.1rem", fontWeight: "400" }}
        >
          <Link
            to="/analyticaldashboard"
            style={{ textDecoration: "none", color: "#ACACAC" }}
          >
            {t("Analytical Dashboard")}
          </Link>

          <div className="mx-2" style={{ color: "#ACACAC" }}>
            {" >"}
          </div>

          <span
            to="/analyticaldashboard/work-values"
            style={{ textDecoration: "none", color: "#D04C8D" }}
          >
            {t("Work Values")}
          </span>
        </div>

        <div className="row">
          {Object.keys(this.state.data).map((key, index) => (
            <div className="col-12 col-lg-6 mb-4" key={index}>
              <div
                className="p-4 text-center bg-white rounded shadow"
                style={{ minHeight: "100%" }}
              >
                <h3 className="mb-3" style={{ fontWeight: "300" }}>
                  {t(key)}
                </h3>

                <DoghnutChart
                  data={this.formatChartData(this.state.data[key])}
                />

                <p
                  className="text-secondary mt-4"
                  style={{ fontSize: "0.9rem" }}
                >
                  {t("Percentage of talents who have scored low, moderate or high in the ")} {t(key)} {this.props.params.id ? t("value") : t("domain")}
                </p>

                <p className="text-secondary mt-4">{t(texts[key])}</p>

                {!this.props.params.id && (
                  <Link
                    to={
                      "/analyticaldashboard/work-values/" +
                      this.state.data[key].id +
                      "?name=" +
                      key
                    }
                  >
                    <Button name={t("Value Analysis")} />
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>
      </MainLayout>
    );
  }
}
const WorkValuesComponent = withTranslation()(WorkValues)

export default function WorkValuesComponentWrapper (props) {
  const params = useParams();

  return <WorkValuesComponent params={params} {...props} />;
}
