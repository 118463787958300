import { useState, useEffect } from 'react';

import styled from 'styled-components';
import { Colors } from '../../utilities/colors';
import Logo from '../../assets/companylogosettings.png';
import { useSelector, useDispatch } from 'react-redux';
import { Label, SelectorWrapper, StyledSimpleCardCustom, CancelAndExit } from '../../pages/analyticalDashboardPage/commonStyles';
import { onUpdateCompanyDatas, getCompanyData, changeAvatar, getAdminData } from '../../services/apiCalls';
import { updateSetting } from './reducer';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import Input from '../input/input';
import Button from '../button/button';
import { changeAdminInfo, getAdminUserInfo } from '../../services/apiCalls';
import { toast } from 'react-toastify';
import ChangePasswordFormErrors from '../settingsChangePassword/changePasswordFormErrors';
import { useTranslation } from "react-i18next";


const FlexWrapper = styled.div`
  display: flex;
`;
const SectionWrappper = styled(FlexWrapper)`
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const Image = styled.img`
  width: 217px;
  height: 217px;
  border-radius: 50%;
`;
const ContentWrapper = styled.div`
  margin-left: 30px;
  width: 100%;
  > h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: ${Colors.labelText};
    margin: 0;
    margin-bottom: 5px;
    text-transform: capitalize;
  }
  > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.labelText};
    margin: 0;
    text-transform: capitalize;
  }
  @media (max-width: 500px) {
    margin-left: 0px;
  }
`;
const DividerLine = styled.hr`
  margin: 10px 0;
  border: 0.4px solid ${Colors.labelText};
`;

const LinkText = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: block;
  color: #d04c8d;
`;
const ImageWrapper = styled.div`
  text-align: center;
`;
const Uploader = styled.div`
  position: relative;
  > input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
`;
const SectionDivisionWrapper = styled.div`
  display: flex;
  padding: 1rem 1.5rem 2rem 1.5rem;
  flex-direction: column;
  background: #fdfdfd;
  border: 1px solid rgba(228, 228, 228, 0.26);
  box-sizing: border-box;
  border-radius: 5px;
  margin: 30px 0;
`;
const FiledWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
`;


const CompanyAddress = styled.span`
  font-family: 'General Sans', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: rgb(32, 32, 32);
`;

const UnderLineText = styled.label`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
  color: ${Colors.primaryText};
  cursor: pointer;
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.4px solid ${Colors.labelText};
  padding: 12px 0;
  margin-bottom: 25px;
`;

const schema = yup.object().shape({
  mob_number: yup.string().required(),
  email: yup.string().email('Entered email id is invalid').required(),
  department: yup.string().required(),
  first_name: yup.string().required(),
  name: yup.string().required("Name is a required field."),
});

export default function MyCompanyIntro(props) {
  const [, setLoading] = useState(false);
  const org = useSelector((state) => state.org?.orgData);
  const dispatch = useDispatch();
  const [admin_data, setAdminData] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [name, setName] = useState(props.admin_data.data.name);
  const [nameTop, setNameTop] = useState(props.admin_data.data.name);
  const { t } = useTranslation();

  const {
    control,
    reset,
    formState: { errors },
  } = useForm({
    
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });
  
  const uploadFile = async (event) => {

    if(event.target.files[0].size <= 5000000){ 

      const formdata = new FormData();
      formdata.append(
        'profile_picture',
        event.target.files[0],
        event.target.files[0].name
      );
      const { data, status } = await changeAvatar(formdata);
      if (status === 200) {
        onUploaded(data)
        getAdminAllData()
      };

    }else{

      toast.error(t("File size exceeds maximum limit."));

    }


  };

  const getData = async () => {
    const { data, } = await getCompanyData({
      code: org?.additional_info?.org_code,
    });
    if (data && data.status === 1) {
      setLoading(false);
      dispatch(updateSetting(data.data));
    } else {
      setLoading(false);
    }
  };
  const getAdminAllData = async () => {
    const { data, } = await getAdminData();
    if (data && data.status === true) {
      setAdminData(data.data)
    }
  };
  const onUploaded = async (uploadData) => {
    const { status, } = uploadData;
    dispatch(updateSetting(uploadData.data));
    if (status === 1) {
      let obj = { logo_path: uploadData.data.obj_content };
      const { data, } = await onUpdateCompanyDatas({
        code: org?.additional_info?.org_code,
        data: { ...obj },
      });
      if (data && data.status === 1) {
        getAdminAllData();
        getData();
      }
    }
  };
  const onSubmit = async () => {
    
    if (name !== '') {
      reset();
      const { data } = await changeAdminInfo({
      name
      });
    if (data && data.status === 1) {
      toast.success(t(data.message), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsEditable(false);
      getDatas();
      dispatch(updateSetting(data.data));
      setNameTop(name);
      
    }
    }
    
  };
  const onCancel = () => {
    
    setIsEditable(false);
    setName(props.admin_data.data.name)
    
    reset();
  };

  useEffect(() => {
    getAdminAllData()
  }, [])
  const getDatas = async () => {
    var result1 = await getAdminUserInfo();
    setAdminData(result1.data.data)
  };
  return (
    <>
      <SectionWrappper>
        <ImageWrapper>
          <Image
            src={
              admin_data && admin_data.profile_picture
                ? admin_data.profile_picture
                : Logo
            }
            alt="company logo"
          />
          <Uploader>
            <LinkText>{t("Change Profile Photo")}</LinkText>
            <input
              accept="image/x-png,image/jpeg"
              type="file"
              onChange={uploadFile}
            />
          </Uploader>
          <p style={{ fontSize: '12px', color: 'gray' }}>{t("The maximum image size limit is 5 MB")}</p>
        </ImageWrapper>
        <ContentWrapper>
          <h3>{nameTop}</h3>
          {/* <p>companyName</p>
        <p>Country</p> */}
          <DividerLine />
          {/* <p>job title</p> */}
          {/* <InputGridWrapper>
          <Input disabled placeholder={`📞 `} />

          <Input disabled placeholder={`✉ `} />
        </InputGridWrapper> */}
        </ContentWrapper>
      </SectionWrappper>

      <SectionDivisionWrapper>
        <HeaderSection>
          <CompanyAddress>{t("Profile Information")}</CompanyAddress>

          
          {!isEditable && (
            <UnderLineText
              className="changeText"
              onClick={() => setIsEditable(true)}
            >
              {t("Edit")}
            </UnderLineText>
          )}
        </HeaderSection>
        <FiledWrapper>
          <Col lg="6">
            <div className="mb-4 mt-4">
              <Label>{t("Name")}*</Label>
              <SelectorWrapper>
                {isEditable ? (
                  <>
                    <SelectorWrapper>
                      <Controller
                        control={control}
                        name="name"
                        render={({
                          field: { onChange, onBlur, value, ref },
                          fieldState: { error },
                        }) => (
                          <Input
                            style={{ background: '#f3f3f3', width: '180%' }}
                            placeholder={t("Name")}
                            onChange={(e) => setName(e.target.value)}
                            onBlur={onBlur}
                            value={name}
                            className={name === "" ? 'invalid' : ''}
                            inputRef={ref}
                          />
                        )}
                      />
                       {name === "" ? <ChangePasswordFormErrors errors={t(errors)} formControlName="name" />:<></>}
                    </SelectorWrapper>
                  </>
                ) : (
                  <>
                    <StyledSimpleCardCustom className="inputReadOnly">
                      
                      {name}
                    </StyledSimpleCardCustom>
                  </>
                )}
              </SelectorWrapper>
            </div>
          </Col>

          <Col lg="6">
            <div className="mb-4 mt-4">
              <Label>{t("Email")}*</Label>
              <SelectorWrapper>
                
                <StyledSimpleCardCustom className="inputReadOnly">
                  
                  {props.admin_data.data.email}
                </StyledSimpleCardCustom>
                
              </SelectorWrapper>
            </div>
          </Col>

          
        </FiledWrapper>
      </SectionDivisionWrapper>
      {
        isEditable && (
          <ButtonGroup>
            <div lg="3">
              <Button
                style={{
                  margin: 0,
                  width: '200px',
                  padding: '7%',
                  borderColor: '#D04C8D',
                }}
                name={t("Confirm")}
                onClick={onSubmit}
              />
            </div>
            <div lg="3">
              <CancelAndExit
                style={{ width: '200px', margin: "0px", marginLeft: "10px" }}
                type="button"
                className="cancelBtn"
                onClick={onCancel}
              >
                {t("Cancel")}
              </CancelAndExit>
            </div>
          </ButtonGroup>
        )
      }
    </>
  );
}
