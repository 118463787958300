import React from 'react';
import Chart from 'chart.js/auto';



class HorizontalBarChart extends React.Component {

  constructor(props) {
      super(props);
      this.chartRef = React.createRef();
      this.chart = null;
  }

  makeChart() {

    const ctx = this.chartRef.current.getContext("2d");

    this.chart = new Chart(ctx, {
          type: 'bar',
          data: this.props.data,
          options: {
            indexAxis: 'y',
            responsive: true,
            maintainAspectRatio: true,
            showScale: false,
            plugins: {
              legend: {
                display: false
              },
          },
          scales: {
            y: {
            beginAtZero: true,
            ticks : {
              autoSkip: false
              }
            },
          },
          
          
        },

    });

  }

  componentDidMount() {

    this.makeChart();

    window.scrollTo(0,0)

  }

  componentWillReceiveProps(nextProps) {

    this.chart.data = nextProps.data

    this.chart.update();

  }

  render() {

    return (
          <canvas className="mx-auto w-100 custom-canva-css" style={{ height : '100%', minHeight : '600px' }} ref={this.chartRef}/>
    );

  }

}
export default HorizontalBarChart;
