import React from 'react';
import MainLayout from '../../../../layouts/mainLayout/mainlayout';
import DoghnutChart from '../../../../components/analyticalDashboard/charts/doughnutChart.js';
import { Link, useParams } from 'react-router-dom';
import { onApiCall } from '../../../../services/CommonApi';
import { withTranslation } from 'react-i18next';

const texts = {
  'Emotional Stability':
    'Emotional Control relates talents’ ability to keep their emotions steady and under control in response to life experiences.',
  Extraversion:
    'Extraverts relate more strongly to their external world than their internal world.',
  'Openness to Experience':
    'Talents that are high in Openness are usually open to new ideas and new experiences.',
  Agreeableness:
    'High Agreeableness means talents are motivated to promote cooperation and harmony with others.',
  Conscientiousness:
    'Being high in Conscientiousness means talents are strong in controlling and regulating their life and work.',
};

class FiveFactorValues extends React.Component {
  constructor(props) {
    super(props);

    this.state = { data: {} };
  }

  getData() {
    onApiCall({
      url: `/api/dashboard/analytical/five-factor/6/`,
      method: 'GET',
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ data: res.data?.data?.results });
      }
    });
  }

  componentDidMount() {
    this.getData();
  }

  componentWillReceiveProps(newProps) {
    this.getData();
  }

  formatChartData(data) {
    const { t } = this.props;
    return {
      labels: [t('Low'), t('Moderate'), t('High')],
      datasets: [
        {
          data: [data.results.low, data.results.moderate, data.results.high],
          backgroundColor: ['#9c1b58', '#ff92ca', '#97b6ff'],
        },
      ],
    };
  }

  render() {
    const { t } = this.props;
    return (
      <MainLayout>
        <div
          className="d-flex mb-5"
          style={{ fontSize: '1.1rem', fontWeight: '400' }}
        >
          <Link
            to="/analyticaldashboard"
            style={{ textDecoration: 'none', color: '#ACACAC' }}
          >
            {t("Analytical Dashboard")}
          </Link>

          <div className="mx-2" style={{ color: '#ACACAC' }}>
            {' '}
            >{' '}
          </div>
          <span
            to="/analyticaldashboard/work-values"
            style={{ textDecoration: 'none', color: '#D04C8D' }}
          >
            {t("Personality & Motivation")}
          </span>
        </div>
        <div className="row">
          {Object.keys(this.state.data).map((key, index) => (
            <div className="col-12 col-lg-6 mb-4" key={index}>
              <div
                className="p-4 text-center bg-white rounded shadow"
                style={{ minHeight: '100%' }}
              >
                <h3 className="mb-3" style={{ fontWeight: '300' }}>
                  {t(key)}
                </h3>

                <DoghnutChart
                  data={this.formatChartData(this.state.data[key])}
                />

                <p className="text-secondary mt-4">{t(texts[key])}</p>
              </div>
            </div>
          ))}
        </div>
      </MainLayout>
    );
  }
}

const FiveFactorValuesComponent = withTranslation()(FiveFactorValues)

export default function FiveFactorValuesComponentWrapper (props) {
  const params = useParams();

  return <FiveFactorValuesComponent params={params} {...props} />;
}
