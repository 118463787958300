import styled from "styled-components";

import { Colors } from "../../../src/utilities/colors";

const CustomBtn = styled.button`
  width: ${(props) => props.width};
  background: ${(props) =>
    props.color
      ? props.color
      : "linear-gradient(90deg, #FA4AA1 0%, #504F8C 100%)"};
  border-radius: 128px;
  outline: none;
  border: ${(props) => (props.color ? `1px solid ${Colors.pink}` : "0px")};
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => (props.color ? Colors.pink : Colors.light)};
  padding: 13px 20px;
  margin: 15px 0;
  cursor: pointer;
  margin-right: ${(props) => (props.right ? props.right : "0px")};
  &:hover {
    background: ${(props) =>
      props.name === "Deny" ||
      props.name === "Reset" ||
      props.name === "No" ||
      props.name === "Send Review"
        ? Colors.pink
        : " linear-gradient(90deg, #504F8C 0%, #FA4AA1 100%);"};
    color: ${(props) =>
      props.name === "Deny" ||
      props.name === "Reset" ||
      props.name === "Cancel" ||
      props.name === "No" ||
      props.name === "Send Review"
        ? Colors.light
        : ""};
  }
  // @media (max-width: 500px) {
  //   width: 100%;
  // }
`;

export default function Button(props) {
  return (
    <CustomBtn right={props.right} {...props}>
      {props.name}
    </CustomBtn>
  );
}
