import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Colors } from '../../utilities/colors';
import { useTranslation } from "react-i18next";
import DeleteIcon from '../../assets/icons/svg/trash.svg';
import EditIcon from '../../assets/icons/svg/pencil.svg';
import AddNewLocation from '../addNewLocation/addNewLocation';
import { updateCompanyData } from '../../pages/companies/viewDetails/reducer';
import {
  onDeleteCompanyLocation,
  getCompanyData,
  onViewCompanyLocation,
} from '../../services/apiCalls';
import { updateEdit, updateLocationData } from '../addNewLocation/reducer';
import { initialObj } from '../addNewLocation/staticContent';
import AlertModal from '../alertModal/alertModal';
import Button from '../button/button';
const FlexWrapper = styled.div`
  display: flex;
`;
const SectionWrapper = styled.div`
  background: #fdfdfd;
  border: 1px solid rgba(228, 228, 228, 0.26);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 25px;
  margin: 30px 0;
`;
const HeaderSection = styled(FlexWrapper)`
  align-items: center;
  border-bottom: 0.3px solid ${Colors.labelText};
  padding-bottom: 10px;
  > h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.primaryText};
    margin: 0;
  }
`;
const StatusSection = styled(FlexWrapper)`
  align-items: center;
  margin-left: 15px;
  > p {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #8f8f8f;
    margin: 0 8px;
  }
`;
const DividerLine = styled.hr`
  border: 0.4px solid rgba(32, 32, 32, 0.22);
`;
const LocationSection = styled(FlexWrapper)`
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`;
const SingleSection = styled.div`
  > h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #696969;
    margin: 0;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
  > p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
    color: #202020;
    text-transform: capitalize;
  }
`;
const Icon = styled.img`
  margin: 0px 10px;
  cursor: pointer;
  display: block;
`;
const AddButton = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  color: #d04c8d;
`;
const Message = styled.div`
  padding-left: 20px;
  font-size: 22px;
  padding-bottom: 5px;
`;
export default function Location() {
  const dispatch = useDispatch();

  const company = useSelector((state) => state.company.companyData);
  
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('code');
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState(false);
  const closeModal = () => {
    setShow(false);
  };

  const [isPrimaryLocation, setIsPrimayLocation] = useState(false);
  const { t, } = useTranslation();

  useEffect(() => {
    for (var i = 0; i < company?.locations?.length; i++) {
      if (company.locations[i]?.status === 'primary') {
        setIsPrimayLocation(true);
        break;
      }
     
      if (company.locations.length === i + 1) {
        setIsPrimayLocation(false);
      }
    }
  }, [company.locations]);

  const content = () => {
    return (
      <>
        <Message>{t("Are you sure you want to delete this location?")}</Message>
        <Row>
          <Col md="6" />
          <Col
            md="12"
            className="d-flex justify-content-center align-items-center"
          >
            <Button
              width="100px"
              name={t("Yes")}
              onClick={onDelete}
            />
            <Button
              secondary
              color="#FFFFFF"
              name={t("No")}
              width="100px"
              style={{
                marginLeft: "10px",
              }}
              variant="outlined"
              onClick={closeModal}
            >
            </Button>
          </Col>
        </Row>
      </>
    );
  };
  const onAdd = () => {
    dispatch(updateEdit(false));
    dispatch(updateLocationData(initialObj));
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const onEdit = async (id) => {
    const { data } = await onViewCompanyLocation({
      id: id,
    });
    if (data && data.status === 1) {
      dispatch(updateLocationData(data.data));
      dispatch(updateEdit(true));
      setOpen(true);
    }
  };
  const deleteModal = (id) => {
    setShow(true);
    setId(id);
  };
  const onDelete = async () => {
    const { data, } = await onDeleteCompanyLocation({ id: id });
    closeModal();
    if (data && data.status === 1) {
      toast.success(t('Location Successfully Deleted'), {
        position: toast.POSITION.TOP_RIGHT,
      });
      getData();
    }
  };

  const getData = async () => {
    const { data, } = await getCompanyData({
      code: code,
    });
    if (data && data.status === 1) {
      dispatch(updateCompanyData(data.data));
    }
  };

  return (
    <SectionWrapper>
      <HeaderSection>
        <h3>{t("Location")}</h3>
        <StatusSection>
          
        </StatusSection>
      </HeaderSection>
      {company && company.locations ? (
        company.locations.map((location, index) => (
          <>
            {location.status.toLowerCase() === "primary" ?
              <LocationSection key={location + index}>
                <SingleSection>
                  <h3>{t(location.status)}</h3>
                  <p>
                    {location.rgtr_address}, {location.city_abbr}
                  </p>
                  <p>
                    {location.state_abbr}, {location.country_abbr}
                  </p>
                </SingleSection>
                <FlexWrapper>
                  <Icon onClick={() => onEdit(location.id)} src={EditIcon} />
                  {location.status.toLowerCase() === "primary" ? (
                    ""
                  ) : (
                    <Icon
                      onClick={() => deleteModal(location.id)}
                      src={DeleteIcon}
                    />
                  )}
                </FlexWrapper>
              </LocationSection>
              :
              ""
            }
          </>
        ))
      ) : (
        <p><p className="noDataFound">{t("No data found")}</p></p>
      )}
      {company && company.locations ? (
        company.locations.map((location, index) => (
          <>
            {location.status.toLowerCase() === "secondary" ?
              <LocationSection key={location + index}>
                <SingleSection>
                  <h3>{t(location.status)}</h3>
                  <p>
                    {location.rgtr_address}, {location.city_abbr}
                  </p>
                  <p>
                    {location.state_abbr}, {location.country_abbr}
                  </p>
                </SingleSection>
                <FlexWrapper>
                  <Icon onClick={() => onEdit(location.id)} src={EditIcon} />
                  {location.status.toLowerCase() === "primary" ? (
                    ""
                  ) : (
                    <Icon
                      onClick={() => deleteModal(location.id)}
                      src={DeleteIcon}
                    />
                  )}
                </FlexWrapper>
              </LocationSection>
              :
              ""
            }
          </>
        ))
      ) : (
        <p><p className="noDataFound">{t("No data found")}</p></p>
      )}
      <DividerLine />
      {company.status === "rejected" ?
        ""
        :
        <>
          {!isPrimaryLocation || company?.locations?.length < 2 ? (
            <AddButton onClick={onAdd}>
              {!isPrimaryLocation ? t('+ Add location') : t('+ Add secondary location')}
            </AddButton>
          ) : (
            ''
          )}
        </>
      }
      {open && (
        <AddNewLocation
          open={open}
          onClose={onCloseModal}
          isPrimaryLocation={isPrimaryLocation}
        />
      )}
      <AlertModal show={show} close={closeModal} content={content()} />
    </SectionWrapper>
  );
}
