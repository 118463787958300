import { useSelector } from "react-redux";
import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Mainlayout from "../../layouts/mainLayout/mainlayout";
import { Colors } from "../../utilities/colors";
import HomeImage from "../../assets/Admin.png";
import { useNavigate } from "react-router-dom";
import { dashBoardApi, getAdminUserInfo } from "../../services/apiCalls";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const LabelText = styled.label`
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;
`;
const LightText = styled(LabelText)`
  color: ${Colors.black};
`;

const LightDiv = styled.div`
  align-self: center;
  margin-bottom: 1rem;
`;

const Card = styled.div`
  background-color: white;
  border-radius: 25px;
  padding: 25px;
  .imageDiv {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    justify-content: flex-start;
    @media (max-width: 1200px) {
      justify-content: center;
    }
  }
`;
const SummaryCard = styled.div`
  background-color: #fffafa;
  border-radius: 10px;
  padding: 50px;
  display: flex;
  align-items: center;
  margin: 10px;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: column;
`;
const ImageWrapper = styled.div`
  height: auto;
  max-width: 100%;
  max-height: 100%;
  transform: rotate(-18deg);
  @media (max-width: 1200px) {
    margin-left: unset;
  }
`;
const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const Counts = styled.span`
  font-weight: 500;
  font-size: 74px;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  line-height: 50px;
  color: ${Colors.primaryText};
  @media (max-width: 500px) {
    display: block;
    text-align: center;
  }
`;

const DescriptionOne = styled.span`
  color: ${Colors.expiredDateColor};
  font-size: 24px;
  display: flex;
  justify-content: center;
  width: 100%;
  line-height: 25px;
  font-weight: 500;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const DescriptionLayer = styled.div`
  margin-top: 1rem;
`;

const ImageDiv = styled.div``;

export default function Mainpage() {
  const [data, setData] = useState([]);
  const [value, setValue] = useState([]);
  const [, setShowNewOne] = useState(false);
  let navigate = useNavigate();
  const { t } = useTranslation();

  const statusDropdownApi = useCallback(async () => {
    const { data, status } = await dashBoardApi();
    if (status === 200) {
      if (data.status === 1) {
        console.log(data);
        setData(data.data);
      }
    }
  }, []);

  const getDataOfUser = useCallback(async () => {
    const { data, status } = await getAdminUserInfo();
    if (status === "401") {
      navigate("/login");
    } else {
      setShowNewOne(true);
    }
    if (status === 200) {
      if (data?.status === 1) {
        console.log(data);
        setValue(data?.data);
      }
    }
  }, [navigate]);

  useEffect(() => {
    statusDropdownApi();
    getDataOfUser();
  }, [statusDropdownApi, getDataOfUser]);

  const getTodayPart = () => {
    var today = new Date();
    var curHr = today.getHours();
    if (curHr < 12) {
      return "Good Morning";
    } else if (curHr < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };
  return (
    <Mainlayout>
      <Row>
        <Col>
          <LightDiv>
            <LightText>
              {t(getTodayPart())}, {value.name}{" "}
            </LightText>
          </LightDiv>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Row>
              <Col xl="5" lg="12" className="imageDiv">
                <ImageDiv>
                  <ImageWrapper>
                    <Image src={HomeImage} alt="welcome-logo" />
                  </ImageWrapper>
                </ImageDiv>
              </Col>
              <Col xl="7" lg="12">
                <Row>
                  <Col sm="6">
                    <SummaryCard>
                      <Counts>{data.talent ? data.talent : "0"}</Counts>
                      <DescriptionLayer>
                        <DescriptionOne>{t("Students")}</DescriptionOne>
                        <DescriptionOne>{t("Registered")}</DescriptionOne>
                      </DescriptionLayer>
                    </SummaryCard>
                  </Col>
                  <Col sm="6">
                    <SummaryCard>
                      <Counts>{data.university ? data.university : "0"}</Counts>
                      <DescriptionLayer>
                        <DescriptionOne>{t("Universities")}</DescriptionOne>
                        <DescriptionOne>{t("Registered")}</DescriptionOne>
                      </DescriptionLayer>
                    </SummaryCard>
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <SummaryCard>
                      <Counts>{data.company ? data.company : "0"}</Counts>
                      <DescriptionLayer>
                        <DescriptionOne>{t("Companies")}</DescriptionOne>
                        <DescriptionOne>{t("Registered")}</DescriptionOne>
                      </DescriptionLayer>
                    </SummaryCard>
                  </Col>
                  <Col sm="6">
                    <SummaryCard>
                      <Counts>{data.pending ? data.pending : "0"}</Counts>
                      <DescriptionLayer>
                        <DescriptionOne>{t("Pending1")}</DescriptionOne>
                        <DescriptionOne>{t("Verifications")}</DescriptionOne>
                      </DescriptionLayer>
                    </SummaryCard>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Mainlayout>
  );
}
