import { useState, useEffect } from "react";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { Colors } from "../../utilities/colors";
import EyeIconGray from "../../assets/svg/view_eye_gray.svg";
import FooterAddress from "../footeraddress/footeraddress";
import DeleteImage from "../../assets/icons/svg/trash.svg";
import EditImage from "../../assets/icons/svg/pencil.svg";
import GreenIcon from "../../assets/svg/view_eye.svg";
import { toast } from "react-toastify";
import { blogStatusUpdate, deleteBlog } from "../../services/apiCalls";
import Button from "../button/button";
import { Row, Col } from "react-bootstrap";
import AlertModal from "../alertModal/alertModal";
import Dropdown from "../dropdown/dropdown";
import { Label } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const BlogCardWrapper = styled(FlexWrapper)`
  background: #fdfdfd;
  border: 1px solid rgba(228, 228, 228, 0.26);
  box-sizing: border-box;
  border-radius: 30px;
  padding: 20px;
  justify-content: space-between;
  margin-bottom: 20px;
  &:last-child() {
    margin-bottom: 0px;
  }
`;
const Footer = styled.div`
  margin-top: 100px;
`;
const BlogCardText = styled.p`
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  line-height: 24px;
  color: ${Colors.primaryText};
`;
const StatusSelect = styled.select`
  width: 100%;
  height: 32px;
  background: white;
  color: gray;
  font-size: 14px;
  border: none;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

const BlogStatusWrapper = styled(FlexWrapper)``;
const DeleteImg = styled.img`
  height: 20px;
  cursor: pointer;
`;
const EditImg = styled.img`
  height: 20px;
  cursor: pointer;
`;
const Message = styled.div`
  padding-left: 20px;
  font-size: 22px;
  padding-bottom: 5px;
`;

export default function BlogCard(props) {
  const [data, setData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [blogIdOnDelete, setBlogIdOnDelete] = useState();
  const [iconPrivate, SetIconPrivate] = useState(false);

  const closeModal = () => {
    setShowDeleteModal(false);
  };

  let navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (props.value) {
      setData(props.value);
    }
  }, [props]);

  const editBlog = (id, code, title) => {
    navigate(
      { pathname: "/blogs/edit-blog", search: `?id=${id}` },
      { state: { code: code, title: title } }
    );
  };

  const viewBlog = (id, code) => {
    navigate(
      { pathname: "/blogs/view-blog", search: `?id=${id}` },
      { state: { code: code } }
    );
  };

  const content = () => {
    return (
      <>
        <Message>{t("Are you sure you want to delete this Blog")}</Message>
        <Row>
          <Col md="6" />
          <Col
            md="12"
            className="d-flex justify-content-center align-items-center"
          >
            <Button
              style={{ margin: "20px 20px 30px 0px" }}
              width="100px"
              name={t("Yes")}
              onClick={onDeleteBlog}
            />
            <Button
              width="100px"
              name={t("No")}
              color="#FFFFFF"
              style={{ margin: "20px 20px 30px 0px" }}
              onClick={hideDeleteModal}
            />
          </Col>
        </Row>
      </>
    );
  };

  const OpenDeleteModal = (val) => {
    setShowDeleteModal(true);
    setBlogIdOnDelete(val);
  };

  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const onDeleteBlog = async () => {
    const { data, status } = await deleteBlog(blogIdOnDelete);

    if (data && data.status === 1) {
      toast.success(t("Blog Deleted Successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      props.blogList();
    }
  };

  const statusUpdate = async (e, code, value) => {
    const { data, status } = await blogStatusUpdate(code, value);
    if (data?.status == 1) {
      props.blogList();
    }
  };

  return (
    <>
      {data.map((item, index) => {
        return (
          <BlogCardWrapper key={item.id}>
            <BlogCardText>{item.title}</BlogCardText>

            <BlogStatusWrapper>
              <Dropdown
                val={item.status}
                label={item.status}
                change={(e) => {
                  statusUpdate(e, item.code, e.value);
                }}
                options={[
                  { label: "Public", value: "public" },
                  { label: "Private", value: "private" },
                ]}
              />

              <img
                style={{ margin: "0 5px", cursor: "pointer" }}
                alt="eye icon"
                src={item.status === "private" ? EyeIconGray : GreenIcon}
                onClick={() => {
                  viewBlog(item.id, item.code);
                }}
                title={t("view")}
              />
              <img
                alt="EditImage"
                style={{ margin: "0 5px", cursor: "pointer" }}
                src={EditImage}
                onClick={() => {
                  editBlog(item.id, item.code, item.title);
                }}
                title={t("edit")}
              />
              <img
                title={t("delete")}
                alt="DeleteImage"
                style={{ margin: "0 5px", cursor: "pointer" }}
                src={DeleteImage}
                onClick={() => {
                  OpenDeleteModal(item.code);
                }}
              />
            </BlogStatusWrapper>
          </BlogCardWrapper>
        );
      })}

      <AlertModal
        show={showDeleteModal}
        close={closeModal}
        content={content()}
      />
    </>
  );
}
