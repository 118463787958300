import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import universityService from "../../../services/university.service";
import {
  CardPadded39Darker,
  SectionTitle,
  UserManagementModalContainer,
  UserModal,
} from "../../analyticalDashboardPage/commonStyles";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import UniversityLocationReadOnly from "./universityLocationReadOnly/universityLocationReadOnly";
import UniversityLocationNewEdit from "./universityLocationNewEdit/universityLocationNewEdit";
import UniversityModal from "../../../components/universityModal/universityModal";
import ConfirmUser from "../../../assets/confirmUser.png";
import Warning from "../../../assets/warning.png";
import Button from "../../../components/button/button";
import EventBus from "../../../utilities/eventBus";
import { updateModal } from "../../../reducers/universityModalReducer";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AddLocation = styled.div`
  font-family: "General Sans", sans-serif;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #d04c8d;
  cursor: pointer;
`;

export default function UniversityLocationSummary() {
  const modalCache = useSelector((state) => state?.modal?.cache);
  const [modalMode, setModalMode] = useState("");
  const [universityLocations, setUniversityLocations] = useState([]);
  const [addressId, setAddressId] = useState(null);
  const [isEditable, setIsEditable] = useState("");
  const dispatch = useDispatch();
  const params = useParams();
  const id = params && params.id;
  const { t } = useTranslation();
  const getUniversityLocations = useCallback(() => {
    universityService.getUniversityLocations(id).then((response) => {
      const foundPrimaryLocation = response.data?.find(
        (item) => item.type === "Primary"
      );
      if (!foundPrimaryLocation) {
        response.data.push({
          type: "Primary",
          doesNotExist: true,
        });
      }
      EventBus.dispatch(
        "updatePrimaryLocationToUniversityHeader",
        foundPrimaryLocation
      );
      if (!response.data.find((item) => item.type === "Secondary")) {
        response.data.push({
          type: "Secondary",
          doesNotExist: true,
        });
      }
      setUniversityLocations(
        response.data
          .map((location) => {
            location.isEditable = false;
            return location;
          })
          .sort((a, b) => {
            if (a.type > b.type) {
              return 1;
            }
            if (a.type < b.type) {
              return -1;
            }
            return 0;
          })
      );
    });
  }, [id]);

  useEffect(() => {
    getUniversityLocations();
  }, [getUniversityLocations]);

  const enableEdit = (location) => {
    setIsEditable(location.type);
    setAddressId(location.id);
  };

  const deleteAction = (location) => {
    setModalMode("delete");
    dispatch(updateModal({ modalIsOpen: true, cache: location.id }));
  };

  const confirmDeleteAction = () => {
    universityService.deleteUniversityLocation(id, modalCache).then(() => {
      getUniversityLocations();
    });
  };

  const getModalContent = () => {
    switch (modalMode) {
      case "delete":
        return (
          <UserModal>
            <div className="modal-wrapper rotated">
              <img
                src={ConfirmUser}
                alt="delete university address"
                className="modal-image rotated"
              />
            </div>
            <div className="modal-title">{t("Delete Address")}</div>
            <div className="modal-description">
            {t("Are you sure you want to delete the address? This address will be deleted from the system.")}
            </div>
            <div className="modal-buttons">
              <Button
                name={t("Yes")}
                onClick={() => {
                  confirmDeleteAction();
                  dispatch(updateModal(false));
                }}
              />
              <Button
                name={t("No")}
                className="inverted"
                onClick={() => {
                  dispatch(updateModal(false));
                }}
                color="#fff"
              />
            </div>
          </UserModal>
        );
      case "update":
        return (
          <UserModal>
            <div className="modal-wrapper">
              <img
                src={ConfirmUser}
                alt="update university address"
                className="modal-image"
              />
            </div>
            <div className="modal-title">{t("Update Address")}</div>
            <div className="modal-description">
              {t("Are you sure you want to update the address?")}
            </div>
            <div className="modal-buttons">
              <Button
                name={t("Yes")}
                onClick={() => {
                  dispatch(updateModal(false));
                  EventBus.dispatch("locationCreateUpdateEvent", modalCache);
                }}
              />
              <Button
                name={t("No")}
                className="inverted"
                onClick={() => dispatch(updateModal(false))}
                color="#fff"
              />
            </div>
          </UserModal>
        );
      case "create":
        return (
          <UserModal>
            <div className="modal-wrapper">
              <img
                src={ConfirmUser}
                alt="create university location"
                className="modal-image"
              />
            </div>
            <div className="modal-title">{t("Create Address")}</div>
            <div className="modal-description">
              {t("Are you sure you want to create the address?")}
            </div>
            <div className="modal-buttons">
              <Button
                name={t("Yes")}
                onClick={() => {
                  dispatch(updateModal(false));
                  EventBus.dispatch("locationCreateUpdateEvent", modalCache);
                }}
              />
              <Button
                name={t("No")}
                className="inverted"
                onClick={() => dispatch(updateModal(false))}
                color="#fff"
              />
            </div>
          </UserModal>
        );
      case "unsavedChanges":
        return (
          <UserModal>
            <div className="modal-wrapper fixed">
              <img
                src={Warning}
                alt="unsaved changes"
                className="modal-image fixed"
              />
            </div>
            <div className="modal-title">{t("Unsaved Changes")}</div>
            <div className="modal-description">
              {t("Are you sure you want to discard unsaved changes?")}
            </div>
            <div className="modal-buttons">
              <Button
                name={t("Yes")}
                onClick={() => {
                  setAddressId(null);
                  setIsEditable(false);
                  dispatch(updateModal(false));
                }}
              />
              <Button
                name={t("No")}
                className="inverted"
                onClick={() => dispatch(updateModal(false))}
                color="#fff"
              />
            </div>
          </UserModal>
        );
      default:
        break;
    }
  };

  return (
    <>
      <CardPadded39Darker className="mt-5">
        <Row>
          <Col>
            <Row>
              <SectionTitle>{t("Location")}</SectionTitle>
            </Row>
            <Row>
              <Col xs="12">
                <hr />
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                {universityLocations.map((location, index) => {
                  return (
                    <div key={index}>
                      {location.doesNotExist && isEditable !== location.type ? (
                        universityLocations?.length < 4 && index === 0 ? (
                          <AddLocation
                            onClick={enableEdit.bind(this, location)}
                          >
                            + {t("Add Location")}
                          </AddLocation>
                        ) : (
                          <></>
                        )
                      ) : isEditable === location.type ? (
                        <UniversityLocationNewEdit
                          setModalMode={setModalMode}
                          addressId={addressId}
                          setIsEditable={setIsEditable}
                          type={location.type}
                          getUniversityLocations={getUniversityLocations}
                          universityLocations={universityLocations}
                        />
                      ) : (
                        <>
                          <UniversityLocationReadOnly
                            setModalMode={setModalMode}
                            deleteAction={deleteAction}
                            enableEdit={enableEdit}
                            location={location}
                          />
                        </>
                      )}
                     
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Col>
        </Row>
      </CardPadded39Darker>

      <UniversityModal>
        <UserManagementModalContainer>
          {getModalContent()}
        </UserManagementModalContainer>
      </UniversityModal>
    </>
  );
}
