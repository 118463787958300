import { NavLink } from 'react-router-dom';

import styled from 'styled-components';
import { menuItems } from '../../menuItems';
import Logo from '../../assets/Logo.png';
import { Colors } from '../../utilities/colors';




const LayoutWrapper = styled.section`
  background: #ffffff;

  height: 90% !important;
  display: flex;
  width: 100%;
  @media (max-width: 1024px) {
    display: block;
  }
`;
const ImageSection = styled.div`
  background: #ffffff;
  height: 20%;
  min-width: 30%;
`;

const CoverImageWrapper = styled.div`
  position: relative;
  line-height: 0px;
`;

const CoverDivWrapper = styled.div`
  width: 20%;
  min-width: 20%;
  position: relative;
  height: 100% !important;
  line-height: 0px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const Image = styled.img`
  height: 90vh;
  width: 87%;
  @media (max-width: 1024px) {
    width: 100%;
    display:none;
  }
`;

const PageContentSection = styled.div`
  overflow-y: auto;
  // flex-grow: 2;
  padding: 1rem 4rem;
  backgroung-color: #e5e5e5;
  &::-webkit-scrollbar {
    width: 0px;
  }
  @media (max-width: 1090px) {
    padding: 1rem 2rem;
  }
`;
const GradientCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0px;
  right: 0;
  background: #ffffff;
  border-radius: 0px 42px 42px 0px;
  border-right: 1px solid #acacac;
`;
const LogoDiv = styled.div`
  width: auto;
  height: 15%;
  margin-left: 10%;
  margin-top: 10%;
  margin-right: 10%;
`;

const LogoImage = styled.img`
  width: 90%;
`;

const Logotext = styled.span`
  position: relative;
  float: right;
  font-size: 25px;
  width: 50%;
  color: #d04d8b;
  font-weight: 500;
  @media (max-width: 1140px) {
    font-size: 20px;
    color: #d04d8b;
    float: right;
  }
`;

const MenuDiv = styled.div`
  width: 100%;
  height: auto;
  margin: 0px;
  display: flex;
  flex-direction: column;
`;

const MenuList = styled(NavLink)`
  display: flex;
  align-items: center;
  font-weight: 500;
  color: ${Colors.lightGray};
  justify-content: space-evenly;
  text-decoration: none;
  height: 50px;
  cursor: pointer;
  &:hover {
    color: ${Colors.pink};
    border-right: 5px solid ${Colors.pink};
  }

  &.active {
    color: ${Colors.pink};
    border-right: 5px solid ${Colors.pink};
  }
`;

const MenuSpanText = styled.p`
  width: 150px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;

const MenuSpanIcons = styled.span`
  width: auto;
`;



export default function UserCreationLayout({ children, coverImg }) {
  return (
    <LayoutWrapper>
      {coverImg ? (
        <ImageSection>
          <CoverImageWrapper>
            <Image src={coverImg} alt="Cover image" />
          </CoverImageWrapper>
        </ImageSection>
      ) : (
        <CoverDivWrapper>
          <GradientCover>
            
            <LogoDiv>
              <LogoImage src={Logo} />
              <Logotext>ADMIN</Logotext>
            </LogoDiv>
            
            <MenuDiv>
              {menuItems.map((menuItem, i) => (
                <MenuList key={i} to={menuItem.url}>
                  <MenuSpanIcons>{menuItem.icon}</MenuSpanIcons>
                  <MenuSpanText>{menuItem.title}</MenuSpanText>
                </MenuList>
              ))}
            </MenuDiv>
          </GradientCover>
        </CoverDivWrapper>
      )}
      <PageContentSection>{children}</PageContentSection>
    </LayoutWrapper>
  );
}
