import styled from "styled-components";

import { Colors } from "../../utilities/colors";
import DeleteIcon from "../../assets/icons/activeDelete.png";

const SectionDivisionWrapper = styled.div`
  padding: 1rem 1.5rem 1rem 1.5rem;
  background: #fdfdfd;
  border: 1px solid rgba(228, 228, 228, 0.26);
  box-sizing: border-box;
  border-radius: 5px;
  margin: 30px 0;
`;
const TableTop = styled.table`
  overflow-x: scroll;
`;
const TableHead = styled.th`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  padding: 15px 0;
`;
const TableData = styled.td`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding: 15px 0 !important;
  color: #525252;
  text-transform: capitalize;
`;

const Icon = styled.img`
  margin: 0px 5px;
`;

export default function LocationTable(props) {
  return (
    <SectionDivisionWrapper>
      {props?.data?.length > 0 ? (
        <TableTop>
          <thead>
            <tr>
              <TableHead>Country</TableHead>
              <TableHead>State</TableHead>
              <TableHead>City</TableHead>
              <TableHead></TableHead>
            </tr>
          </thead>
          <tbody>
            {props.data.map((x, index) => (
              <tr key={index}>
                <TableData>{x.country}</TableData>
                <TableData>{x.state}</TableData>
                <TableData>{x.city}</TableData>
                <TableData>
                  <Icon onClick={() => props.delete(index)} src={DeleteIcon} />
                </TableData>
              </tr>
            ))}
          </tbody>
        </TableTop>
      ) : (
        <TableTop style={{ display: "flex", justifyContent: "center" }}>
          <p className="noDataFound">No data found</p>
        </TableTop>
      )}
    </SectionDivisionWrapper>
  );
}
