import styled from 'styled-components';

import { Colors } from '../../../utilities/colors';


const CustomBtn = styled.button`
  width: 100%;
  background: linear-gradient(90deg, #fa4aa1 0%, #504f8c 100%);
  border-radius: 128px;
  outline: none;
  border: 0px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${Colors.light};
  padding: 13px 20px;
  margin: 15px 0;
  cursor: pointer;
`;


export default function Button(props) {
  return <CustomBtn {...props}>{props.name}</CustomBtn>;
}
