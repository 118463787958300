import React from 'react';
import MainLayout from '../../../../layouts/mainLayout/mainlayout';
import DoghnutChart from '../../../../components/analyticalDashboard/charts/doughnutChart.js';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { onApiCall } from '../../../../services/CommonApi';
import { withTranslation } from 'react-i18next';

const texts = {
  'Critical Thinking': [
    'High critical thinking skills position one well to access exciting opportunities in the world of work for the future.',
    'Being able to solve problems using logical thinking, obtaining relevant information and having an awareness of the impact of cognitive biases is important in the modern workplace. Weighing up the advantages and disadvantages of a decision or course of action is also an important skill.',
  ],
  'Planning and Ways of Working': [
    'High planning and ways of working skills position one well to access exciting opportunities in the world of work for the future.',
    'Being able to plan and prioritise one’s work efficiently to ensure its completion is important in the modern workplace. When starting a project, creating a plan to ensure that one stays on track, yet being flexible to switch tasks if required is also an important skill.',
  ],
  Communication: [
    'High communication skills position one well to access exciting opportunities in the world of work for the future.',
    'Being able to understand key messages and communicate them to others in ways that they can easily understand, listen well and understand the views of others are all important skills in the workplace. Asking the right questions so that one has all the information they need when starting a new piece of work is also important. Being able to speak in public could be an important skill to acquire.',
  ],
  'Mental Flexibility': [
    'High mental flexibility skills position one well to access exciting opportunities in the world of work for the future.',
    'Being able to use creativity and imagination to help solve problems or come up with new ideas, to use learning from one area and apply it to another area for positive results, to see a situation from many perspectives and to be open to new learning are all important skills in the workplace. To use these effectively, one should easily adapt to change.',
  ],
  'Mobilising Systems': [
    'High mobilising systems skills position one well to access exciting opportunities in the world of work for the future.',
    'Being able to be a role model of positive behaviour, negotiating with others so that everyone feels that they have gained a positive result and creating an inspiring vision around a project or idea to build motivation and engagement in others are all important skills in the modern workplace. Gaining the information required to understand how organisations work and what is required of you is also significant.',
  ],
  'Developing Relationships': [
    'High developing relationships skills position one well to access exciting opportunities in the world of work for the future.',
    'Being able to have empathy for others by putting yourself in their position to understand their point of view, build trusting relationship with others by being open, honest and supportive and demonstrate humility in your interactions with others are important skills in the workplace. Being sociable opens the way to building relationships with work colleagues or clients.',
  ],
  'Effective Teamwork': [
    'High effective teamwork skills position one well to access exciting opportunities in the world of work for the future.',
    'Being able to work with a diverse group of people towards a common goal and ensure that they feel included and their views are heard is important in the workplace today. Getting to know people as individuals, understanding what motivates them and supporting them to resolve conflicts are significant skills for the workplace.',
  ],
  'Coaching Others': [
    'High coaching others skills position one well to access exciting opportunities in the world of work for the future.',
    'Being able to help others think about what they want to achieve, to empower them by helping them focus on their strengths, to help set goals for themselves and to facilitate the development of their skills and knowledge are all important coaching skills needed in the workplace today.',
  ],
  'Self-Awareness': [
    'High self-awareness skills position one well to access exciting opportunities in the world of work for the future.',
    'Being able to understand your emotions, how they impact you and knowing how to calm yourself down when you are feeling stressed is important for your health, wellbeing, and your performance at work.',
  ],
  'Self-Management': [
    'High self-management skills position one well to access exciting opportunities in the world of work for the future.',
    'Being able to look after your wellbeing, motivate yourself and make progress towards your goals is important for your ability to secure employment and progress in your career.',
  ],
  Entrepreneurship: [
    'High entrepreneurship skills position one well to access exciting opportunities in the world of work for the future.',
    'Being able to take appropriate risks, innovate, adapt to change and apply positive energy towards your work is important for your ability to secure employment, progress in your career and stay ahead of the competition.',
  ],
  'Goal Achievement': [
    'High goal achievement skills position one well to access exciting opportunities in the world of work for the future.',
    'Being able to take responsibility for setting and working towards your goals, persevering, especially during periods of uncertainty and committing to your professional development is important for your ability to secure employment and progress in your career.',
  ],
  'Digital Fluency': [
    'High digital fluency skills position one well to access exciting opportunities in the world of work for the future.',
    'Being able to use technology and interact professionally in the digital world are important skills to some extent, in all careers in the future of work.',
  ],
  'Software Use': [
    'High software use skills position one well to access exciting opportunities in the world of work for the future.',
    'Being able to learn about, adapt to and integrate new software into your work, are important skills to some extent, in all careers in the future of work.',
  ],
  'Software Development': [
    'High software development skills position one well to access exciting opportunities in the world of work for the future.',
    'Being able to develop digital systems, understand and develop computer code and create digital programmes to solve problems are valuable assets if you see digital skills as a core element of your chosen career.',
  ],
  'Understanding Digital Systems': [
    'High understanding digital systems skills position one well to access exciting opportunities in the world of work for the future.',
    'Being able to use, understand and develop digital systems, and use data to gain insights are valuable assets if you see digital skills as a core element of your chosen career.',
  ],
};

class FutureOfWorkValues extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: {} };
  }

  getData() {
    onApiCall({
      url: `/api/dashboard/analytical/century-skills/${this.props.params.id}/`,
      method: 'GET',
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ data: res?.data?.data?.results });
      }
    });
  }

  componentDidMount() {
    this.getData();
  }

  componentWillReceiveProps(newProps) {
    this.getData();
  }

  formatChartData(data) {
    const { t } = this.props;
    return {
      labels: [t('Low'), t('Moderate'), t('High')],
      datasets: [
        {
          data: [data.results.low, data.results.moderate, data.results.high],
          backgroundColor: ['#366eb6', '#7db8ff', '#b69036'],
        },
      ],
    };
  }

  render() {
    const { t } = this.props;
    return (
      <MainLayout>
        <div
          className="d-flex mb-5"
          style={{ fontSize: '1.1rem', fontWeight: '400' }}
        >
          <Link
            to="/analyticaldashboard"
            style={{ textDecoration: 'none', color: '#ACACAC' }}
          >
            {t("Analytical Dashboard")}
          </Link>

          <div className="mx-2" style={{ color: '#ACACAC' }}>
            {' >'}
          </div>

          <Link
            to="/analyticaldashboard/21-century-skills"
            style={{ textDecoration: 'none', color: '#ACACAC' }}
          >
            {t("The Future of Work")}
          </Link>

          <div className="mx-2" style={{ color: '#ACACAC' }}>
            {' >'}
          </div>

          <span style={{ textDecoration: 'none', color: '#D04C8D' }}>
            {t(this.props.searchParams.get('name'))}
          </span>
        </div>

        <div className="row">
          {Object.keys(this.state.data).map((key, index) => (
            <div className="col-12 col-lg-6 mb-4" key={index}>
              <div
                className="p-4 text-center bg-white rounded shadow"
                style={{ minHeight: '100%' }}
              >
                <h3 className="mb-3" style={{ fontWeight: '300' }}>
                  {t(key)}
                </h3>

                <DoghnutChart
                  data={this.formatChartData(this.state.data[key])}
                />

                <p
                  className="text-secondary mt-4 mb-4"
                  style={{ fontSize: '0.9rem' }}
                >
                  {t("Percentage of talents who have scored low, moderate or high in the ")}{t(key)} {this.props.params.id ? t('value') : t('domain')}
                </p>

                <p className="text-secondary mb-4">{t(texts[key][0])}</p>

                <p className="text-secondary mb-4">{t(texts[key][1])}</p>
              </div>
            </div>
          ))}
        </div>
      </MainLayout>
    );
  }
}

const FutureOfWorkValuesComponent = withTranslation()(FutureOfWorkValues)

export default function FutureOfWorkValuesComponentWrapper (props) {
  const params = useParams();
  const [searchParams,] = useSearchParams();

  return (
    <FutureOfWorkValuesComponent
      params={params}
      searchParams={searchParams}
      {...props}
    />
  );
}
