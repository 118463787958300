import { configureStore } from "@reduxjs/toolkit";

import myCompanyReducer from "./pages/companies/viewDetails/reducer";
import newLocationReducer from "./components/addNewLocation/reducer";
import messageReducer from "./reducers/messageReducer";
import universityLoadingReducer from "./reducers/universityLoadingReducer";
import universityModalReducer from "./reducers/universityModalReducer";
import universityReducer from "./reducers/universityReducer";
import userManagementReducer from "./reducers/userManagementReducer";
import newContactPersonReducer from "./components/AddContact/reducer";
import adminDataReducer from "./pages/login/reducer";
import mySettings from "./components/myCompanyIntro/reducer";
import myCompanyLogReducer from "./pages/companies/viewDetails/companyLogReducer";
import myCompanyUserLogReducer from "./pages/companyUsersView/reducer";
import myInternshipLogReducer from "./pages/viewDetails/reducer";
import companyFilter from "./pages/companies/viewDetails/companyFilter";
import companyUsersFilter from "./pages/companiesusers/companyUsersFilter";
import internshipFilter from "./pages/internship/internshipFilter";
// import authReducer from "./reducers/authReducer";

export const store = configureStore({
  reducer: {
    // authReducer: authReducer,
    company: myCompanyReducer,
    location: newLocationReducer,
    modal: universityModalReducer,
    loader: universityLoadingReducer,
    message: messageReducer,
    userManagement: userManagementReducer,
    university: universityReducer,
    contact: newContactPersonReducer,
    adminDataReducer: adminDataReducer,
    mySettings: mySettings,
    companylog: myCompanyLogReducer,
    companyUserLog: myCompanyUserLogReducer,
    internshipLog: myInternshipLogReducer,
    companyFilter: companyFilter,
    companyUsersFilter: companyUsersFilter,
    internshipFilter: internshipFilter,
  },
});
