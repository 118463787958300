import { useState, useEffect } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import { Colors } from '../../utilities/colors';
import UpdateCompany from '../updateCompanyNew/updateCompany';

const FlexWrapper = styled.div`
  display: flex;
`;
const SectionWrapper = styled.div`
  background: #fdfdfd;
  border: 1px solid rgba(228, 228, 228, 0.26);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 25px;
  margin: 30px 0;
`;
const HeaderSection = styled(FlexWrapper)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  border-bottom: 0.3px solid ${Colors.labelText};
  padding-bottom: 10px;
  > h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.primaryText};
    margin: 0;
  }
`;
const StatusSection = styled(FlexWrapper)`
  align-items: center;
  > p {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #8f8f8f;
    margin: 0 8px;
  }
  > h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.pink};
    margin-right: 10px;
  }
`;
const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  margin: 20px 0;
`;
const Header = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  color: #525252;
`;
const Label = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => (props.color ? props.color : Colors.labelText)};
  text-transform: ${(props) => (props.color ? 'unset' : 'capitalize')};
  word-break: break-word;
`;

const CompanyDetailSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 43px;
  margin-bottom: 43px;
  > p {
    font-size: 16px;
    line-height: 22px;
    color: #202020;
  }
`;
const SingleSection = styled.div`
  justify-self: ${(props) => (props.position ? props.position : 'initial')};
  margin-right: ${(props) => (props.margin ? props.margin : '')};
  width: 200px;
`;
const SingleSectionElement = styled.div`
  width: 75%;
  justify-self: ${(props) => (props.position ? props.position : 'initial')};
  margin-right: ${(props) => (props.margin ? props.margin : '')};
`;

const UserHelpText = styled(Label)`
  color: ${Colors.inputHighlightText};
  cursor: pointer;
  text-align: right;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
`;
export default function Overview() {
  const company = useSelector((state) => state.company.companyData);
  

  const [open, setOpen] = useState(false);

  const onChangeContent = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const [temp_website, setTempWebsite] = useState('');

  useEffect(() => {
    if (company && company.website) {
      if (company.website.includes("https://")) {
        setTempWebsite(company.website)
      } else {
        setTempWebsite("https://" + company.website)
      }
    }
  }, [company])

  const { t, } = useTranslation();
  return (
    <SectionWrapper>
      <HeaderSection>
        <StatusSection>
          <h3>{t("Overview")}</h3>
          
        </StatusSection>
        {company.status === "rejected" ?
          
          ""
          :
          <UserHelpText onClick={onChangeContent}>{t("Edit")}</UserHelpText>
        }
      </HeaderSection>
      <Description dangerouslySetInnerHTML={{ __html: company.overview }}>
       
      </Description>
      <CompanyDetailSection>
        <SingleSection>
          <Header>{t("Website")}</Header>
          <a href={temp_website} target="_blank" rel="noopener noreferrer">
            <Label color="#1B9DC6">{company && company.website}</Label>
          </a>
        </SingleSection>
        <SingleSection margin="20px">
          <Header>{t("SSM Registration Date")}</Header>
          <Label>{company && company.ssm_rgtr_date}</Label>
        </SingleSection>
        <SingleSection>
          <Header>{t("Company Size")}</Header>
          <Label>
            {company && company.org_size ? company.org_size : '0'} {t("Employees")}
          </Label>
        </SingleSection>

        
      </CompanyDetailSection>
      <SingleSectionElement>
        <Header>{t("Business Address")}</Header>
        <Label>{company && company.bsn_address}, {company && company.bsn_city_abbr}, {company && company.bsn_state_abbr}, {" "}
          {company && company.bsn_country_abbr}, {company && company.bsn_country_code}</Label>
      </SingleSectionElement>
      <SingleSectionElement>
        <SingleSection style={{marginTop:'25px'}}>
          <Header>{t("Nature of Business")}</Header>
          <Label>{company && company.bsn_nature}</Label>
        </SingleSection>
      </SingleSectionElement>

      {open && <UpdateCompany open={open} onClose={onCloseModal} />}
    </SectionWrapper>
  );
}
