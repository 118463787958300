import React from 'react';
import MainLayout from '../../../../layouts/mainLayout/mainlayout';
import DoghnutChart from '../../../../components/analyticalDashboard/charts/doughnutChart.js';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { onApiCall } from '../../../../services/CommonApi';
import { withTranslation } from 'react-i18next';

const texts = {
  'Spotting Opportunities':
    'Talents with high scores for this factor are good at spotting opportunities, which other people may not see, bringing benefits to themselves and others. They have the ability to create opportunities and to problem-solve in order to improve their performance in school or work.',
  Creativity:
    'Talents with high scores for this factor are good at brain storming to generate new ideas and finding ways to meet challenges at school or work. They have the ability to persuade people to get involved in projects.',
  'Valuing Ideas':
    'Talents with high scores for this factor are good at estimating the value of ideas and finding solutions to problems in their work.',
  Vision:
    'Talents with high scores for this factor are good at building a vision of the future that inspires other people and reflects the future in which theu would like to live. They are also able to understand what decisions need to be made to achieve that vision for their lives.',
  'Ethical and Sustainable Thinking':
    'Talents with high scores for this factor know why integrity and ethical behaviour is important. Their own behaviour reflects this and they take responsibility to promote and advance ethical practices in work.',
  'Self Awareness':
    'Talents with high scores for this factor are good at knowing their own abilities. They can deliver presentations well and weigh the value of different options when decision making.',
  'Self Efficacy':
    'Talents with high scores for this factor are good at recognising and understanding when mistakes have been made when trying something new. This means that theu are able to learn from their mistakes, while completing their work. They are able to push through difficulties to complete the given task.',
  'Financial and Economic Literacy':
    'Talents with high scores for this factor are good at developing budgets for projects and evaluating the costs and benefits of new ideas. They can also develop financial plans for long term projects.',
  'Mobilising Others':
    'Talents with high scores for this factor are good at inspiring and motivating others to support and contribute to projects in which they are involved. They are also able to build a balanced team, by recognising the skills and weaknesses of individual team members.',
  'Mobilising Resources':
    'Talents with high scores for this factor are good at planning to use and gather the resources they need to complete their work. They can identify extra resources needed to overcome challenges found during the course of a project. They are able to find sponsors for a project and then manage the budget successfully.',
  'Motivation and Perseverance':
    'Talents with high scores for this factor are motivated and work hard to achieve their goals. When facing challenges they will work even harder to ensure their goals are met. Being aware of their strengths enables them to make progress at work.',
  'Working with Others':
    'Talents with high scores for this factor are able to form teams and work with networks of people in order to meet the needs of a project. They are good team players and have confidence in their ability to work with diverse individuals and groups in order to achieve a common goal.',
  'Planning and Management':
    'Talents with high scores for this factor are able to plan projects, define priorities and give achievable milestones for progress. They can set clear goals at the beginning of a project, which they aim to achieve and are able to maximise the value of ideas they have encountered.',
  'Learning Through Experience':
    'Talents with high scores for this factor are able to improve, reflect and identify areas of learning when they have completed a project. They find ways of improving themselves by reflecting on what they have learnt from projects. They learn from experience by reflecting on mistakes and successes after completing a project. They can also identify precise areas of new learning.',
  'Coping with Uncertainty Ambiguity and Risk':
    'Talents with high scores for this factor are able to use their best judgment and the available information to make decisions, especially when the situation is ambiguous or uncertain. They are also adaptable when circumstances change and can change their plans in the course of completing their work. Alongside this, they have a clear idea of the goals and targets they aim to achieve.',
  'Taking the Initiative':
    'Talents with high scores for this factor are able to take responsibility in meeting challenges at work and in the past they have initiated action to make improvements in school or work. They have also used different types of resources effectively to achieve their goals to bring change.',
};

class EmployabilityValues extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: {} };
  }

  getData() {
    onApiCall({
      url: `/api/dashboard/analytical/employability/${this.props.params.id}/`,
      method: 'GET',
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ data: res.data?.data?.results });
      }
    });
  }

  componentDidMount() {
    this.getData();
  }

  componentWillReceiveProps(newProps) {
    this.getData();
  }

  formatChartData(data) {
    const { t } = this.props;
    return {
      labels: [t('Low'), t('Moderate'), t('High')],
      datasets: [
        {
          data: [data.results.low, data.results.moderate, data.results.high],
          backgroundColor: ['#35acab', '#178b9b', '#75c5f2'],
        },
      ],
    };
  }

  render() {
    const { t } = this.props;
    return (
      <MainLayout>
        <div
          className="d-flex mb-5"
          style={{ fontSize: '1.1rem', fontWeight: '400' }}
        >
          <Link
            to="/analyticaldashboard"
            style={{ textDecoration: 'none', color: '#ACACAC' }}
          >
            {t("Analytical Dashboard")}
          </Link>

          <div className="mx-2" style={{ color: '#ACACAC' }}>
            {' > '}
          </div>
          <Link
            to="/analyticaldashboard/employability"
            style={{ textDecoration: 'none', color: '#ACACAC' }}
          >
            {t("Employability")}
          </Link>
          <div className="mx-1" style={{ color: '#ACACAC' }}>
          {' > '}
          </div>

          <span style={{ textDecoration: 'none', color: '#D04C8D' }}>
            {t(this.props.searchParams.get('name'))}
          </span>
        </div>
        <div className="row">
          {Object.keys(this.state.data).map((key, index) => (
            <div className="col-12 col-lg-6 mb-4" key={index}>
              <div
                className="p-4 text-center bg-white rounded shadow"
                style={{ minHeight: '100%' }}
              >
                <h3 className="mb-3" style={{ fontWeight: '300' }}>
                  {t(key)}
                </h3>

                <DoghnutChart
                  data={this.formatChartData(this.state.data[key])}
                />

                <p
                  className="text-secondary mt-4 mb-4"
                  style={{ fontSize: '0.9rem' }}
                >
                  {t("Percentage of talents who have scored low, moderate or high in the ")}{t(key)} {this.props.params.id ? t('value') : t('domain')}
                </p>

                <p className="text-secondary mb-4">{t(texts[key])}</p>
              </div>
            </div>
          ))}
        </div>
      </MainLayout>
    );
  }
}

const EmployabilityValuesComponent = withTranslation()(EmployabilityValues)

export default function EmployabilityValuesComponentWrapper (props) {
  const params = useParams();
  const [searchParams,] = useSearchParams();

  return (
    <EmployabilityValuesComponent
      params={params}
      searchParams={searchParams}
      {...props}
    />
  );
}
