import { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Button from "../button/button";
import { Row, Col } from "react-bootstrap";
import { Colors } from "../../utilities/colors";
import { toast } from "react-toastify";
import { ReactComponent as EmailIcon } from "../../assets/icons/svg/email.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/svg/phone.svg";
import { StyledSimpleCardCustom } from "../button/commonStyles";
import { onUserProfilePicUpdate } from "../../services/apiCalls";

import { useParams } from "react-router-dom";
import AlertModalCompany from "../alertModal/alertModalCompany";
import {
  companyUserAction
} from "../../services/apiCalls";
import { useForm, Controller } from "react-hook-form";
import TextArea from "../../components/description/description";
import ErrorField from "../../components/errorField/errorField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

const FlexWrapper = styled.div`
  display: flex;
`;
const SectionWrappper = styled(FlexWrapper)`
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const Image = styled.img`
  width: 217px;
  height: 217px;
  border-radius: 50%;
`;
const ContentWrapper = styled.div`
  margin-left: 30px;
  width: 100%;
  > h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: ${Colors.labelText};
    margin: 0;
    margin-bottom: 5px;
    text-transform: capitalize;
  }
  > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.labelText};
    margin: 0;
    text-transform: capitalize;
  }
  @media (max-width: 500px) {
    margin-left: 0px;
  }
`;
const DividerLine = styled.hr`
  margin: 10px 0;
  border: 0.4px solid ${Colors.labelText};
`;

const Message = styled.div`
  padding-left: 20px;
  font-size: 22px;
  padding-bottom: 5px;
`;
const LinkText = styled.label`
  font-family: inherit !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: block;
  color: #d04c8d;
`;
const ImageWrapper = styled.div`
  text-align: center;
`;
const Uploader = styled.div`
  position: relative;
  > input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
`;
const SingleField = styled.div`
  margin-bottom: 30px;
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;
export default function MyCompanyUserView(props) {
  const company = useSelector((state) => state.company.companyData);
  const org = useSelector((state) => state?.org?.orgData);
  
  const [selectedImage, setSelectedImage] = useState(
    props.userData.profile_picture
  );
 
  const params = useParams();
  const ids = params && params.id;
  const company_id = params && params.company;
  const [show, setShow] = useState(false);
  const [action, setAction] = useState("");
  const [code, setCode] = useState();
  const [updateTime, setUpdateTime] = useState("");
  const [, setStatus] = useState(true);
  const { t, } = useTranslation();

  const schema = yup.object().shape({
    comments: yup.string().required("Comments is a required field."),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    
    resolver: yupResolver(schema),
    
  });

  const uploadFile = async (event) => {
   
    const formdata = new FormData();

    let maxFileSize = 1024 * 1024 * 5; 

    if (event.target.files[0].size > maxFileSize) {
      toast.error(
        t("File size exceeds maximum limit."),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else {
      formdata.append("profile_picture", event.target.files[0]);
      formdata.append("update_time", company?.update_time);
      
      const { data, status } = await onUserProfilePicUpdate({
        code: company_id,
        data: formdata,
        ids: ids,
      });

      console.log(data, status);
      if (data && data.status === 1) {
        setSelectedImage(data.data.profile_picture);
       
      }
    }
  };

  

  

  const handleActionCall = (e, code, actionName, a) => {
    setShow(true);
    setAction(actionName);
    setCode(code);
    setUpdateTime(a);
  };

  const content = () => {
    console.log(action, "=========");
    var ds = false;
    if (action === "rejected") {
      ds = true;
    }
    return (
      <div style={{ padding: "0 40px 20px 40px" }}>
        <Message
          style={{
            textAlign: "center",
            fontSize: "26px",
            fontWeight: "bold",
            margin: "0px auto 10px auto",
          }}
        >
          {ds === true
            ? t("Are you sure you would like to deny (Newly added user)?")
            : t("Are you sure you want to")}{" "}
          {ds === false ? (action === "accepted" ? t("approve") : t("deny")) : ""}{" "}
          {ds === false ? t("this company user ?") : ""}
        </Message>
        {action !== "accepted" ? (
          <form onSubmit={handleSubmit(onRejectionSubmit)}>
            <div className="container">
              <SingleField style={{ marginBottom: 0 }}>
                <FormLabel>{t("Rejection Note")}</FormLabel>
                <Controller
                  name="comments"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      style={{ background: "#F3F3F3" }}
                      {...field}
                      rows="3"
                      placeholder={t("Enter Comment")}
                    />
                  )}
                />
                {errors.comments && (
                  <ErrorField err={t(errors.comments.message)} />
                )}
              </SingleField>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Button
                  width="180px"
                  name={t("Yes")}
                  type="submit"
                  style={{ marginRight: "30px" }}
                  
                />
                <Button
                  width="180px"
                  name={t("No")}
                  type="button"
                  color="#FFFFFF"
                  onClick={() => {
                    setShow(false);
                    reset();
                  }}
                />
              </div>
            </div>
          </form>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <Button
              width="180px"
              name={t("Yes")}
              onClick={() => approveCompanyUser()}
              style={{ marginRight: "30px" }}
            />
            <Button
              width="180px"
              name={t("No")}
              color="#FFFFFF"
              onClick={() => {
                setShow(false);
                reset();
              }}
            />
          </div>
        )}
      </div>
    );
  };
  const onRejectionSubmit = async (values) => {
    const body = {
      id: code,
      status: action,
      comments: values.comments,
      update_time: updateTime,
    };

    setStatus(true);
    const { data, status } = await companyUserAction(body);

    if (status === 200) {
      setStatus(false);
      setShow(false);
      if (data.status === 1) {
        setStatus(false);
        toast.success(
          action === "accepted"
            ? t("Company user approved successfully")
            : t("Company user denied successfully"),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        
      } else {
        toast.error(data.message);
      }
    }
  };

  const approveCompanyUser = async () => {
    const body = {
      id: code,
      status: action,
      update_time: updateTime,
    };

    setStatus(true);
    const { data, status } = await companyUserAction(body);

    if (status === 200) {
      setStatus(false);
      setShow(false);
      if (data.status === 1) {
        setStatus(false);
        toast.success(
          action === "accepted"
            ? t("Company user approved successfully")
            : t("Company user denied successfully"),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        
      } else {
        toast.error(t(data.message));
      }
    }
  };
  const closeModal = () => {
    setShow(false);
  };
  return (
    <SectionWrappper>
      <ImageWrapper>
        <Image
          
          src={selectedImage}
          alt="company logo"
        />
        
        <Uploader>
          <LinkText style={{ "font-family": "inherit!important" }}>
            {t("Change Profile Photo")}
          </LinkText>
          <input
            onChange={uploadFile}
            accept="image/x-png,image/jpeg"
            type="file"
          />
        </Uploader>
        
        <p style={{ fontSize: "12px", color: "gray" }}>
          {t("The maximum image size limit is 5 MB")}
        </p>
      </ImageWrapper>

      <ContentWrapper>
        <h3>
          {props.userData && props.userData.first_name}{" "}
          {props.userData && props.userData.last_name}(
          {props.userData && props.userData.org_name})
        </h3>

        <p>{t("Location")}: {props.userData && props.userData.org_country}</p>
        <DividerLine />
        <p>{props.userData && props.userData.job_title}</p>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          {company && company.contact_number ? (
            <StyledSimpleCardCustom style={{ margin: 0, marginRight: "20px" }}>
              <PhoneIcon />
              {`${company && company.contact_number}`}
            </StyledSimpleCardCustom>
          ) : (
            ""
          )}
          {org && org?.email ? (
            <StyledSimpleCardCustom style={{ margin: 0 }}>
              <EmailIcon />
              {`${org && org?.email}`}
            </StyledSimpleCardCustom>
          ) : (
            ""
          )}
          {props.userData && props.userData.status === "pending" ? (
            <Col lg="12" xl="8" md="12">
              <Row>
                <Col lg="4">
                  <Button
                    width="100%"
                    name={t("Approve")}
                    onClick={(e) => {
                      handleActionCall(
                        e,
                        ids,
                        "accepted",
                        props.userData.update_time
                      );
                    }}
                  />
                </Col>

                <Col lg="4">
                  <Button
                    width="100%"
                    name={t("Deny")}
                    color="#FFFFFF"
                    onClick={(e) => {
                      handleActionCall(
                        e,
                        ids,
                        "rejected",
                        props.userData.update_time
                      );
                    }}
                  />
                </Col>
              </Row>
            </Col>
          ) : null}
        </div>
        <AlertModalCompany show={show} close={closeModal} content={content()} />
      </ContentWrapper>
    </SectionWrappper>
  );
}
