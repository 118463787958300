import { stringFormat } from "../utilities/commonFunctions";
import { onApiCall } from "./CommonApi";
import masterdataService from "./masterdata.service";

class UserManagementService {
  getMasterdata() {
    return masterdataService.getRoles().then((response) => {
      return response?.data?.map((x) => ({ value: x.id, label: x.name }));
    });
  }

  getUsers(params, filtering, controller) {
    return onApiCall({
      url: `"/api/users/"?${filtering}`,
      method: "GET",
      params: params,
      controller,
    });
  }
  getUserById(userId) {
    return onApiCall({
      url: stringFormat("/api/universities/{}/users/", userId),
      method: "GET",
    });
  }
  getUserByIdWithParams(userId, params,filtering) {
    return onApiCall({
      url: stringFormat(`/api/universities/{}/users/?${filtering}`, userId),
      method: "GET",
      params: params,
    });
  }
  createUser(userData) {
    return onApiCall({
      url: "/api/users/",
      method: "POST",
      data: userData,
    });
  }
  createUserByUniversity(userData, id) {
    return onApiCall({
      url: stringFormat("/api/universities/{}/users/", id),
      method: "POST",
      data: userData,
    });
  }
  updateUser(userId, userData) {
    return onApiCall({
      url: stringFormat("/api/users/{}/", userId),
      method: "PUT",
      data: userData,
    });
  }
  updateUserWithUniversityId(id, userId, userData) {
    return onApiCall({
      url: stringFormat("/api/universities/{}/users/{}/", id, userId),
      method: "PUT",
      data: userData,
    });
  }
  getUserByIdWithUniversityId(id, userId) {
    return onApiCall({
      url: stringFormat("/api/universities/{}/users/{}/", id, userId),
      method: "GET",
    });
  }
  deleteUser(userId) {
    return onApiCall({
      url: stringFormat("/api/users/{}/", userId),
      method: "DELETE",
    });
  }
  updateUserStatusById(userId, userData) {
    return onApiCall({
      url: stringFormat("/api/users/{}/status/", userId),
      method: "POST",
      data: userData,
    });
  }
  deleteByUserUniversity(id, userId) {
    return onApiCall({
      url: stringFormat("/api/universities/{}/users/{}/", id, userId),
      method: "DELETE",
    });
  }
}

export default new UserManagementService();
