import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getOrganizationUserHistory } from "../../services/apiCalls";

const initialState = {
    companyUserLogData: {
        status: 0,
        logs:[]
    },
  };

export const myCompanyUserLogReducer = createSlice({
    name: "companyuserlog",
    initialState,
    extraReducers : builder => {
        builder.addCase(fetchCompanyUserLog.fulfilled, (state,action) => {
            state.companyUserLogData = action.payload;
        })
    } 
  })
  
  export const fetchCompanyUserLog = createAsyncThunk(
    'companyuserlog/fetchCompanyUserLog', 
    async (newId)=>{
        const response = await getOrganizationUserHistory({id:newId})
        return response.data;
    }
  )

export default myCompanyUserLogReducer.reducer;