import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Colors } from "../../../utilities/colors";
import Overview from "../../../components/overviewNew/overview";
import CompanyContactPerson from "../../../components/companyContactPersonNew/companyContactPerson";
import Location from "../../../components/addLocationNew/addLocation";
import { getCompanyData } from "../../../services/apiCalls";
import Loader from "../../../components/loader/loader";
import { updateCompanyData } from "./reducer";
import MyCompanyCard from "../../../components/myCompanyCardNew/myCompanyCard";
import MainLayout from "../../../layouts/mainLayout/mainlayout";
import { Link } from "react-router-dom";
import {fetchCompanyLog} from "./companyLogReducer";
import Timestamp from "../../../components/companyTimestamp/companyTimestamp";
import {
  resetAllCompanies,
} from "./../viewDetails/companyFilter";
import { useTranslation } from "react-i18next";

const PageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 2rem;
  margin: 2rem 0;
  min-height: 90vh;
  @media (max-width: 500px) {
    padding: 0.5rem;
    margin: 0.5rem 0;
  }
`;

export default function MyCompany() {
  const [loading, setLoading] = useState(true);
  const [loadichekng, setloadichekng] = useState(false);
  const dispatch = useDispatch();
  const search = useLocation().search;
  const code = new URLSearchParams(search).get("code");
  const id = new URLSearchParams(search).get("id");
  const navigate = useNavigate();
  const { t, } = useTranslation();

  const getData = useCallback (async (code) => {
    
    const { data, } = await getCompanyData({
      code: code,
    });

    if (data && data.status === 1) {
      setLoading(false);
      dispatch(updateCompanyData(data.data));
    } else {
      navigate("/companies");
      setLoading(false);
    }

  }, [ dispatch, navigate])

  const getLogData = useCallback ((id) => {

    dispatch(fetchCompanyLog(id));
    setLoading(false);

}, [ dispatch])

  useEffect(() => {

    if (code) {
      getData(code);
      getLogData(id);
    } else {
      navigate("/companies");
    }
  }, [loadichekng, getData, getLogData, navigate, id, code]);

   useEffect(() => {
      return ()=>{
      if (window.location.href.indexOf("companiesuserslist") === 22 || (window.location.href.indexOf("companies") === -1 && window.location.href.indexOf("company-detail") === -1 && window.location.href.indexOf("companiesuserslist") === -1)) {
       dispatch(resetAllCompanies());
      }
      }
  }, [dispatch]);
  

  const changeLoadingChecking = async () => {
    setloadichekng(true);
  };
  

  return (
    <MainLayout>
      <div role="presentation">
        <div
          className="d-flex mb-5"
          style={{ fontSize: "1.1rem", fontWeight: "400", marginLeft: "15px" }}
        >
          <Link
            to="/companies"
            style={{ textDecoration: "none", color: "#ACACAC" }}
          >
            {t("Companies")} &nbsp; {">"}
          </Link>
          <div className="mx-2" style={{ color: "#ACACAC" }}></div>
          <span style={{ textDecoration: "none", color: "#D04C8D" }}>
            {t("Company Details")}
          </span>
        </div>
      </div>
      <PageWrapper>
        {loading ? (
          <Loader />
        ) : (
          <>
            <MyCompanyCard />
            <Overview />
            <CompanyContactPerson loadichekng={changeLoadingChecking} />
            <Location />
            <Timestamp />
          </>
        )}
      </PageWrapper>
    </MainLayout>
  );
}