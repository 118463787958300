import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../components/button/button';
import Input from '../../../components/input/input';
import {
  DropdownTitle,
  DropdownWrapper,
  SelectorWrapper,
  UserHeading,
  UserSubHeading,
  CancelAndExit,
  CreateUserForm,
  ArrowBackWrapper,
  BackLink,
} from '../../analyticalDashboardPage/commonStyles';
import Selector from '../../../components/select/select';
import { ReactComponent as ArrowBack } from '../../../assets/svg/arrowBack.svg';
import FormErrors from '../../../components/formErrors/formErrors';
import { isEmpty } from '../../../utilities/commonFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { updateModal } from '../../../reducers/universityModalReducer';
import userManagementService from '../../../services/userManagement.service';
import { updateGlobalLoader } from '../../../reducers/universityLoadingReducer';
import { Controller, useForm } from 'react-hook-form';
import EventBus from '../../../utilities/eventBus';
import { useTranslation } from "react-i18next";

const UserHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ReadOnly = styled.span`
  width: 100%;
  padding: 11px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .value {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #202020;
    margin-right: 100px;
  }
  .edit {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    color: #d04c8d;
    cursor: pointer;
  }
`;

export default function UpdateUser({ setModalMode }) {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(params, '==============================================');
  const id = params && params.id;
  const user_id = params && params.user_id;
  const { t } = useTranslation();
  const {
    
    formState: { errors },
    control,
    handleSubmit,
    formState: { touchedFields },
    getValues,
    reset,
  } = useForm({
    criteriaMode: 'all',
  });
  const [fullNameEditable, setFullNameEditable] = useState(false);
  const masterdataRoles = useSelector((state) => {
    var tempdata = [];
    state.userManagement.masterdataRoles.map((x) => {
      if (x.label !== 'University Admin') {
        tempdata.push(x);
      }
      return null;
    });
    return tempdata;
  });
  
  const navigateBack = useCallback(() => {
    dispatch(updateGlobalLoader(false));
   
    navigate(-1);
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(updateGlobalLoader(false));
    EventBus.on('userUpdateEvent', (data) => {
      if (!isEmpty(errors)) {
        console.log('errors there', errors);
        return;
      }
      const { full_name, roles } = data;
      userManagementService
        .updateUserWithUniversityId(id, user_id, {
          user_list: { name: full_name },
          roles: roles.map((x) => x.value),
        })
        .then(navigateBack)
        .catch(() => navigateBack);
    });

    return () => {
      EventBus.remove('userUpdateEvent');
    };
  });

  useEffect(() => {
    if (user_id) {
      userManagementService
        .getUserByIdWithUniversityId(id, user_id)
        .then(({ data: response }) => {
          reset({
            full_name: response.full_name,
            email: response.email,
            roles: response.roles.map((x) => ({ value: x.id, label: x.name })),
          });
          dispatch(updateGlobalLoader(false));
        })
        .catch(() => navigateBack);
    } else {
      navigateBack();
    }
  }, [id, navigateBack, dispatch, reset, user_id]);

  const onSubmit = (data) => {
    if (!isEmpty(errors)) {
      console.log('errors there', errors);
      return;
    }
    setModalMode('update');
    dispatch(updateModal({ modalIsOpen: true, cache: data }));
  };
  return (
    <>
      <Row>
        <Col style={{ padding: "0px" }}>
          <ArrowBackWrapper onClick={navigateBack}>
            <ArrowBack />
            <BackLink>{t("Back")}</BackLink>
          </ArrowBackWrapper>
        </Col>
      </Row>
      <Row>
        <Col
          style={{ background: "white", padding: "39px", borderRadius: "10px" }}
        >
          
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <UserHeadingWrapper>
                  <UserHeading>{t("User Management")}</UserHeading>
                </UserHeadingWrapper>
                <UserSubHeading>
                  
                  {t("Edit or add users in your organization with user specific roles to give them access to different mynext functionalities.")}
                </UserSubHeading>
                <CreateUserForm>
                  <DropdownWrapper md="6">
                    <DropdownTitle>{t("Name")}</DropdownTitle>
                    <SelectorWrapper>
                      {fullNameEditable ? (
                        <>
                          <Controller
                            control={control}
                            name="full_name"
                            rules={{ required: 'Name is required' }}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Input
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                className={errors.name ? 'invalid' : ''}
                                maxLength="100"
                              />
                            )}
                          />
                          <FormErrors
                            errors={errors}
                            formControlName="full_name"
                          />
                        </>
                      ) : (
                        <ReadOnly>
                          <span className="value">{getValues().full_name}</span>
                          <span
                            className="edit"
                            onClick={() => setFullNameEditable(true)}
                          >
                            {t("Edit")}
                          </span>
                        </ReadOnly>
                      )}
                    </SelectorWrapper>
                  </DropdownWrapper>
                  <DropdownWrapper md="6">
                    <DropdownTitle>{t("Email")}</DropdownTitle>
                    <SelectorWrapper>
                      <>
                        <Controller
                          control={control}
                          name="email"
                          rules={{ required: 'Email is required' }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Input
                              onChange={onChange}
                              onBlur={onBlur}
                              disabled
                              value={value}
                              className={errors.name ? 'invalid' : ''}
                              maxLength="100"
                            />
                          )}
                        />
                        <FormErrors errors={errors} formControlName="email" />
                      </>
                    </SelectorWrapper>
                  </DropdownWrapper>
                  <DropdownWrapper md="6">
                    <DropdownTitle>{t("Role")}</DropdownTitle>
                    <SelectorWrapper>
                      <Controller
                        control={control}
                        name="roles"
                        rules={{ required: 'Role is required' }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Selector
                            isMulti={true}
                            backgroundColor="#F3F3F3"
                            name="roles"
                            userOptions={masterdataRoles}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            className={errors.role ? 'invalid' : ''}
                          />
                        )}
                      />
                      <FormErrors errors={errors} formControlName="roles" />
                    </SelectorWrapper>
                  </DropdownWrapper>
                </CreateUserForm>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col lg="3" md="4">
                <Button name={t("Update")} type="submit" style={{ width: "80%" }} />
              </Col>
              <Col lg="3" md="4">
                <CancelAndExit
                  type="button"
                  style={{ width: "80%" }}
                  onClick={() => {
                    console.log(touchedFields);
                    if (!isEmpty(touchedFields)) {
                      setModalMode('unsavedChanges');
                      dispatch(updateModal(true));
                    } else {
                      dispatch(updateModal(false));
                      navigate(-1);
                    }
                  }}
                >
                  {t("Cancel")}
                </CancelAndExit>
              </Col>
              <Col className="d-md-none d-lg-block"></Col>
            </Row>
          </form>
          
        </Col>
      </Row>
    </>
  );
}
