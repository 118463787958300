import React from 'react';
import MainLayout from '../../../../layouts/mainLayout/mainlayout';
import DoghnutChart from '../../../../components/analyticalDashboard/charts/doughnutChart.js';
import { Link, useParams } from 'react-router-dom';
import { onApiCall } from '../../../../services/CommonApi';
import { withTranslation } from 'react-i18next';

class FutureOfWork extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: {}, totalsData: {} };
  }

  getData() {
    onApiCall({
      url: `/api/dashboard/analytical/english-test/15/`,
      method: 'GET',
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ data: res.data?.data?.results });
      }
    });
  }

  getDataTotals() {
    onApiCall({
      url: `/api/dashboard/analytical/english-test/`,
      method: 'GET',
    }).then((res) => {
      if (res.status === 200) {
        this.setState({
          totalsData: this.formatChartDataTotals(res.data?.data?.results),
        });
      }
    });
  }

  componentDidMount() {
    this.getData();

    this.getDataTotals();
  }

  componentWillReceiveProps(newProps) {
    this.getData();

    this.getDataTotals();
  }

  formatChartData(data) {
    const { t } = this.props;
    return {
      labels: [t('Level 1'), t('Level 2'), t('Level 3'), t('Level 4'), t('Level 5')],
      datasets: [
        {
          data: [
            data.results.level1,
            data.results.level2,
            data.results.level3,
            data.results.level4,
            data.results.level5,
          ],
          backgroundColor: [
            '#f2bf26',
            '#26d9f2',
            '#37bed1',
            '#238bc6',
            '#004383',
          ],
        },
      ],
    };
  }

  formatChartDataTotals(data) {
    const { t } = this.props;
    return {
      labels: [t('Level 1'), t('Level 2'), t('Level 3'), t('Level 4'), t('Level 5')],
      datasets: [
        {
          data: [
            data['General'].results.level1,
            data['General'].results.level2,
            data['General'].results.level3,
            data['General'].results.level4,
            data['General'].results.level5,
          ],
          backgroundColor: [
            '#f2bf26',
            '#26d9f2',
            '#37bed1',
            '#238bc6',
            '#004383',
          ],
        },
      ],
    };
  }

  render() {
    const { t } = this.props;
    return (
      <MainLayout>
        <div
          className="d-flex mb-5"
          style={{ fontSize: '1.1rem', fontWeight: '400' }}
        >
          <Link
            to="/analyticaldashboard"
            style={{ textDecoration: 'none', color: '#ACACAC' }}
          >
            {t("Analytical Dashboard")}
          </Link>

          <div className="mx-2" style={{ color: '#ACACAC' }}>
            {' '}
            >{' '}
          </div>

          <span style={{ textDecoration: 'none', color: '#D04C8D' }}>
          {t("English Proficiency")}
          </span>
        </div>

        <div className="row">
          <div className="col-12 mb-4 col-lg-6">
            <div
              className="p-4 text-center bg-white rounded shadow"
              style={{ minHeight: '100%' }}
            >
              <h3 className="mb-3" style={{ fontWeight: '300' }}>
              {t("Total English Proficiency Level")}
              </h3>

              <DoghnutChart
                style={{ width: '100%', height: '500px' }}
                data={this.state.totalsData}
              />

              <div className="mb-5 mt-5 text-start">
                <h5 className="text-secondary">{t("LEVEL 1")}</h5>

                <p className="text-secondary">
                  {t("Talents who have demonstrated basic knowledge of English Grammar and/or basic understanding of a text in English.")}
                </p>
              </div>

              <div className="mb-5 text-start">
                <h5 className="text-secondary">{t("LEVEL 2")}</h5>

                <p className="text-secondary">
                  {t("Talents who have demonstrated average knowledge of English Grammar and/or average understanding of a text in English.")}
                </p>
              </div>

              <div className="mb-5 text-start">
                <h5 className="text-secondary">{t("LEVEL 3")}</h5>

                <p className="text-secondary">
                  {t("Talents who have demonstrated above average knowledge of English Grammar and/or above average understanding of a text in English.")}
                </p>
              </div>

              <div className="text-start mb-5">
                <h5 className="text-secondary">{t("LEVEL 4")}</h5>

                <p className="text-secondary">
                  {t("Talents who have demonstrated good knowledge of English Grammar and/or good understanding of a text in English.")}
                </p>
              </div>

              <div className="text-start">
                <h5 className="text-secondary">{t("LEVEL 5")}</h5>

                <p className="text-secondary">
                  {t("Talents who have demonstrated excellent knowledge of English Grammar and/or excellent understanding of a text in English.")}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 mb-4 col-lg-6 mb-lg-0">
            <div className="row">
              {Object.keys(this.state.data).map((key, index) => (
                <div className="col-12 mb-4" key={index}>
                  <div
                    className="p-4 text-center bg-white rounded shadow"
                    style={{ minHeight: '100%' }}
                  >
                    <h3 className="mb-3" style={{ fontWeight: '300' }}>
                      {t(key)}
                    </h3>

                    <DoghnutChart
                      data={this.formatChartData(this.state.data[key])}
                    />

                    <p
                      className="text-secondary mt-4"
                      style={{ fontSize: '0.9rem' }}
                    >
                      {t("Percentage of talents who have scored Level 1, Level 2, Level 3, Level 4 or Level 5 in the ")}{t(key)}{' '}
                      {this.props.params.id ? t('value') : t('domain')}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

const FutureOfWorkComponent = withTranslation()(FutureOfWork)

export default function FutureOfWorkComponentWrapper (props) {
  const params = useParams();

  return <FutureOfWorkComponent params={params} {...props} />;
}
