import { GrClose } from "react-icons/gr";
import { Modal } from "react-bootstrap";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import Button from "../../../components/button/button";
import { useEffect, useState } from "react";
import {
  createStudyProgram as createStudyProgramAPI,
  updateStudyProgram as updateStudyProgramAPI,
  deleteStudyProgram as deleteStudyProgramAPI,
  getUniversityDropdownList,
  getCampusDropwdownList,
  getFacultyDropdownList,
} from "../../../services/apiCalls";
import { useTranslation } from "react-i18next";

import Dropdown from "../components/dropdown";

const AddStudyProgramModalWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 30px;
  margin-right: 30px;
`;

function CreateStudyProgramModal(props) {
  const [name, setName] = useState("");
  const [error, setError] = useState({});

  const [facultyList, setFacultyList] = useState([]);
  const [facultySelect, setFacultySelect] = useState(null);
  const [campusList, setCampusList] = useState([]);
  const [campusSelect, setCampusSelect] = useState(null);
  const [universityList, setUniversityList] = useState([]);
  const [univerisitySelect, setUniversitySelect] = useState(null);
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  const createStudyProgram = async () => {
    if (name === "") {
      setError((prevState) => ({
        ...prevState,
        name: "Field name cannot be blank.",
      }));
    }
    if (campusSelect === null) {
      setError((prevState) => ({
        ...prevState,
        campus: "Campus is required field.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        campus: "",
      }));
    }

    if (facultySelect === null) {
      setError((prevState) => ({
        ...prevState,
        faculty: "Faculty is required field.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        faculty: "",
      }));
    }

    if (univerisitySelect === null) {
      setError((prevState) => ({
        ...prevState,
        university: "University is required field.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        university: "",
      }));
    }

    if (
      name !== "" &&
      campusSelect != null &&
      facultySelect != null &&
      univerisitySelect != null
    ) {
      const { data, status } = await createStudyProgramAPI({
        Name: name,
        DepartmentId: facultySelect.value,
      });

      if (status !== 201 && status !== 200) {
        var message;
        if (data.message) {
          message = data.message;
        } else {
          message = "Error. Please try again or contact the administrator.";
        }
        setError((prevState) => ({
          ...prevState,
          name: message,
        }));
      } else {
        props.close();
      }
    }
  };

  const handleChange = (e) => {
    setError((prevState) => ({
      ...prevState,
      name: "",
    }));
  };

  const handleFacultySelect = (e) => {
    setFacultySelect(e);
    setError((prevState) => ({
      ...prevState,
      faculty: "",
    }));
  };

  const handleCampusSelect = (e) => {
    setCampusSelect(e);
    getFacultyDropdown(e.value);
    setFacultySelect(null);
    setError((prevState) => ({
      ...prevState,
      campus: "",
    }));
  };

  const handleUniversitySelect = (e) => {
    setUniversitySelect(e);
    getCampusDropdown(e.value);
    setCampusSelect(null);
    setFacultySelect(null);
    setError((prevState) => ({
      ...prevState,
      university: "",
    }));
  };

  useEffect(() => {
    getUniversityDropdown();
  }, []);

  const getFacultyDropdown = async (Id) => {
    const { data } = await getFacultyDropdownList({
      CollegeId: Id,
    });

    var facultiesList = [];
    for (var key in data.data) {
      var facultyCheck = data.data[key];
      facultiesList.push({ value: facultyCheck.Id, label: facultyCheck.Name });
    }
    setFacultyList(facultiesList);
    setLoading(false);
  };

  const getCampusDropdown = async (Id) => {
    const { data } = await getCampusDropwdownList({
      UniversityId: Id,
    });

    var campusesList = [];
    for (var key in data.data) {
      var campusCheck = data.data[key];
      campusesList.push({ value: campusCheck.Id, label: campusCheck.Name });
    }
    setCampusList(campusesList);
    setLoading(false);
  };

  const getUniversityDropdown = async () => {
    const { data } = await getUniversityDropdownList({});

    var uniList = [];
    for (var key in data.data) {
      var uni = data.data[key];
      uniList.push({ value: uni.Id, label: uni.Name });
    }
    setUniversityList(uniList);
    setLoading(false);
  };

  return (
    <div>
      <style type="text/css">
        {`
    @media (min-width: 576px){
    .modal-dialog {
        max-width: 671px;
        margin: 1.75rem auto;
    }
    }
        `}
      </style>
      <Modal show={props.show} onHide={props.close} centered>
        <div className="post-modal mx-2">
          <div
            className=" d-flex justify-content-end align-items-center pt-3 pe-3 modal_header"
            style={{ cursor: "pointer" }}
          >
            <GrClose onClick={props.close} />
          </div>
          <div>
            <AddStudyProgramModalWrapper>
              <Row style={{ marginBottom: "20px", marginRight: "20px" }}>
                <h3>{t("Add Study Program")}</h3>
              </Row>

              <Container>
                <Row style={{ marginBottom: "20px", marginRight: "20px" }}>
                  <Col md="4">
                    <h6 style={{ marginTop: "12px" }}>
                      {t("University Name")}:{" "}
                    </h6>
                  </Col>
                  <Col md="8">
                    <Dropdown
                      options={universityList}
                      placeholder={t("Enter University")}
                      minWidth="auto"
                      showLoader={loading}
                      change={(e) => handleUniversitySelect(e)}
                    />
                    <div>
                      {error.university !== "" && (
                        <span style={{ color: "red" }}>
                          {t(error.university)}
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row style={{ marginBottom: "20px", marginRight: "20px" }}>
                  <Col md="4">
                    <h6 style={{ marginTop: "12px" }}>{t("Campus Name")}: </h6>
                  </Col>

                  <Col md="8">
                    <Dropdown
                      options={campusList}
                      placeholder={t("Enter Campus")}
                      minWidth="auto"
                      showLoader={loading}
                      value={campusSelect}
                      change={(e) => handleCampusSelect(e)}
                    />
                    <div>
                      {error.campus !== "" && (
                        <span style={{ color: "red" }}>{t(error.campus)}</span>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row style={{ marginBottom: "20px", marginRight: "20px" }}>
                  <Col md="4">
                    <h6 style={{ marginTop: "12px" }}>{t("Faculty Name")}: </h6>
                  </Col>

                  <Col md="8">
                    <Dropdown
                      options={facultyList}
                      placeholder={t("Enter Faculty")}
                      minWidth="auto"
                      showLoader={loading}
                      value={facultySelect}
                      change={(e) => handleFacultySelect(e)}
                    />
                    <div>
                      {error.faculty !== "" && (
                        <span style={{ color: "red" }}>{t(error.faculty)}</span>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row style={{ marginBottom: "20px", marginRight: "20px" }}>
                  <Col md="4">
                    <label className="pe-3" name="study-program-name">
                      <h6>{t("Study Program Name")}: </h6>
                    </label>
                    {"  "}
                  </Col>
                  <Col md="8">
                    <input
                      style={{ width: "70%" }}
                      type="text"
                      name="study-program-name"
                      value={name}
                      onInput={(e) => setName(e.target.value)}
                      onChange={handleChange}
                    />
                    <div>
                      {error.name !== "" && (
                        <span style={{ color: "red" }}>{t(error.name)}</span>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>

              <Row>
                <span>
                  <Button
                    name={t("Submit")}
                    onClick={createStudyProgram}
                    style={{
                      marginRight: "10px",
                      minWidth: "100px",
                      fontSize: "18px",
                      borderRadius: "24px",
                      padding: "8px 8px 8px 8px",
                    }}
                  />
                  <Button
                    name={t("Cancel")}
                    onClick={props.close}
                    style={{
                      marginRight: "10px",
                      minWidth: "100px",
                      fontSize: "18px",
                      borderRadius: "24px",
                      padding: "8px 8px 8px 8px",
                    }}
                  />
                </span>
              </Row>
            </AddStudyProgramModalWrapper>
          </div>
        </div>
      </Modal>
    </div>
  );
}

function EditStudyProgramModal(props) {
  const [name, setName] = useState("");
  const [error, setError] = useState({});
  const { t } = useTranslation();

  const updateStudyProgram = async () => {
    if (name === "") {
      setError((prevState) => ({
        ...prevState,
        name: "Field name cannot be blank.",
      }));
    } else {
      const { data, status } = await updateStudyProgramAPI(
        props.studyProgram.Id,
        { Name: name }
      );

      if (status !== 201 && status !== 200) {
        var message;
        if (data.message) {
          message = data.message;
        } else {
          message = "Error. Please try again or contact the administrator.";
        }
        setError((prevState) => ({
          ...prevState,
          name: message,
        }));
      } else {
        props.close();
        props.refresh();
      }
    }
  };

  const handleChange = (e) => {
    setError((prevState) => ({
      ...prevState,
      name: "",
    }));
  };

  return (
    <div>
      <style type="text/css">
        {`
  @media (min-width: 576px){
  .modal-dialog {
      max-width: 671px;
      margin: 1.75rem auto;
  }
  }
      `}
      </style>
      <Modal show={props.show} onHide={props.close} centered>
        <div className="post-modal mx-2">
          <div
            className=" d-flex justify-content-end align-items-center pt-3 pe-3 modal_header"
            style={{ cursor: "pointer" }}
          >
            <GrClose onClick={props.close} />
          </div>
          <div>
            <AddStudyProgramModalWrapper>
              <Row>
                <h3>
                  {t("Edit")} <b>{props.studyProgram.Name}</b>
                </h3>
              </Row>
              <Row>
                <span>
                  <label className="pe-3" name="university-name">
                    <h6>{t("New Name")}: </h6>
                  </label>
                  {"  "}
                  <input
                    style={{ width: "70%" }}
                    type="text"
                    name="university-name"
                    value={name}
                    onInput={(e) => setName(e.target.value)}
                    onChange={handleChange}
                  />
                  <div>
                    {error.name !== "" && (
                      <span style={{ color: "red" }}>{t(error.name)}</span>
                    )}
                  </div>
                </span>
              </Row>
              <Row>
                <span>
                  <Button
                    name={t("Submit")}
                    onClick={updateStudyProgram}
                    style={{
                      marginRight: "10px",
                      minWidth: "100px",
                      fontSize: "18px",
                      borderRadius: "24px",
                      padding: "8px 8px 8px 8px",
                    }}
                  />
                  <Button
                    name={t("Cancel")}
                    onClick={props.close}
                    style={{
                      marginRight: "10px",
                      minWidth: "100px",
                      fontSize: "18px",
                      borderRadius: "24px",
                      padding: "8px 8px 8px 8px",
                    }}
                  />
                </span>
              </Row>
            </AddStudyProgramModalWrapper>
          </div>
        </div>
      </Modal>
    </div>
  );
}

function DeleteStudyProgramModal(props) {
  const { t } = useTranslation();

  const deleteStudyProgram = async () => {
    const {data, status} = await deleteStudyProgramAPI(props.studyProgram.Id)
    props.refresh();
    props.close();
  };

  return (
    <div>
      <style type="text/css">
        {`
  @media (min-width: 576px){
  .modal-dialog {
      max-width: 671px;
      margin: 1.75rem auto;
  }
  }
      `}
      </style>
      <Modal show={props.show} onHide={props.close} centered>
        <div className="post-modal mx-2">
          <div
            className=" d-flex justify-content-end align-items-center pt-3 pe-3 modal_header"
            style={{ cursor: "pointer" }}
          >
            <GrClose onClick={props.close} />
          </div>
          <div>
            <AddStudyProgramModalWrapper>
              <Row>
                <h3>
                  {t("Are you sure you want to delete")}{" "}
                  <b>{props.studyProgram.Name}</b>?
                </h3>
              </Row>
              <Row>
                <span>
                  <Button
                    name={t("Yes")}
                    onClick={deleteStudyProgram}
                    style={{
                      marginRight: "10px",
                      minWidth: "100px",
                      fontSize: "18px",
                      borderRadius: "24px",
                      padding: "8px 8px 8px 8px",
                    }}
                  />
                  <Button
                    name={t("No")}
                    onClick={props.close}
                    style={{
                      marginRight: "10px",
                      minWidth: "100px",
                      fontSize: "18px",
                      borderRadius: "24px",
                      padding: "8px 8px 8px 8px",
                    }}
                  />
                </span>
              </Row>
            </AddStudyProgramModalWrapper>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export {
  CreateStudyProgramModal,
  EditStudyProgramModal,
  DeleteStudyProgramModal,
};
