import styled from 'styled-components';
import { useTranslation } from "react-i18next";

import { Colors } from '../../utilities/colors';

const RadioButtonGroup = styled.div`
  margin-bottom: ${(props) => (props.margin ? props.margin : '0px')};
  cursor: pointer;
`;
const Label = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
`;

export default function RadioInput({ name, label, margin, checked, onChange }) {
  
  const { t, } = useTranslation();
  return (
    <RadioButtonGroup onClick={onChange} margin={margin} className="radio">
      <input checked={checked} onChange={onChange} name={label} type="radio" />
      <Label className="radio-label">{t(label)}</Label>&nbsp;&nbsp;&nbsp;&nbsp;
    </RadioButtonGroup>
  );
}
