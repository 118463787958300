import * as yup from 'yup';
import { isValidPhoneNumber } from "react-phone-number-input";

export const schema = yup.object().shape({
  overview: yup.string().required(),
  website: yup.string().required(),
  founded: yup.string().required(),
  bsn_nature: yup.string().required(),
  bsn_address: yup.string().required(),
  org_size: yup.string().required(),
});

export const schemaObj = yup.object().shape({
  sector: yup.string().required(),
  type_of_org: yup.string().required(),
  org_email: yup.string().email('Email is required').required(),
  org_number: yup.string().required(),
});

export const schemaNewObj = yup.object().shape({
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  email: yup.string().email('Email is required').required('Email is required'),
  job_title: yup.string().required('Job Title is required'),
  department: yup.string().required('Department is required'),
  
  mob_number: yup
    .string()
    .required()
    .test("mob_number", "Incorrect mobile number", (value) =>
      value ? isValidPhoneNumber(value) : false
    ),
});
