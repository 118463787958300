import styled from "styled-components";
import EmptyImage from "../../assets/emptyImage.png";
import Button from "../../components/button/button";
import { useTranslation } from "react-i18next";

const CardsWrapper = styled.div`
  padding: 40px 30px;
  background-color: white;
  border: 1px solid #d04c8d;
  border-style: dashed;
  border-radius: 50px;
`;
const DragText = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: #202020;
`;

const DragSpanText = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #9f9f9f;
`;
const BrowseText = styled.span`
  font-weight: 500;
  font-size: 18px;
  color: #d04c8d;
`;

export default function ImageUploader() {
  const { t } = useTranslation();
  return (
    <CardsWrapper>
      <img
        alt="EmptyImage"
        style={{ margin: "25px 0", cursor: "pointer" }}
        src={EmptyImage}
      />
      <DragText>
        {t("Drag your image here or")}
        <BrowseText>{t("Browse")}</BrowseText>
      </DragText>
      <DragSpanText>
        {t("Your file must be in JPG or PNG format and does not exceed 5 MB")}
      </DragSpanText>
      <DragSpanText>
        {t("For best results, image should be in 16:9 aspect ratio")}
      </DragSpanText>
      <DragSpanText>
      {t("(e.g. 1280px × 720px, 1920px × 1080px)")}
      </DragSpanText>
    </CardsWrapper>
  );
}
