import React from "react";
import Chart from "chart.js/auto";

class DoghnutChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.chart = null;
  }

  makeChart() {
    const ctx = this.chartRef.current.getContext("2d");

    this.chart = new Chart(ctx, {
      type: "doughnut",
      data: this.props.data,
      options: {
        tooltips: {
          enabled: false,
        },
        responsive: true,
        maintainAspectRatio: true,
        showScale: true,
        aspectRatio: 1,
        plugins: {
          legend: {
            display: true,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let sum = 0;
                context.dataset.data.forEach((item) => {
                  sum += item;
                });

                let percentage =
                  context.label +
                  " " +
                  ((context.raw * 100) / sum).toFixed(2) +
                  "%";
                return percentage + " - " + context.raw;
              },
            },
          },
        },
      },
    });
  }

  componentDidMount() {
    this.makeChart();

    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.chart.data) !== JSON.stringify(nextProps.data)) {
      this.chart.data = nextProps.data;

      this.chart.update();
    }
  }

  render() {
    return (
      <div>
        <canvas className="mx-auto" ref={this.chartRef} />
      </div>
    );
  }
}
export default DoghnutChart;
