import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import styled from "styled-components";

import Button from "../../components/button/button";
import { useLocation, Link } from "react-router-dom";
import AlertModal from "../../components/alertModal/alertModal";
import AlertModalReview from "../../components/alertModal/alertModalReview";
import moment from "moment";
import {
  jobAction,
  jobActionReview,
  jobAddNotes,
} from "../../services/apiCalls"; 
import { Colors } from "../../utilities/colors";
import MainLayout from "../../layouts/mainLayout/mainlayout";
import InterShipCard from "../../components/interShipCard/interShipCard";
import InternIntroSection from "../../components/internIntoSection/internIntroSection";
import InternShipDetails from "../../components/internShipDetails/internShipDetails";
import {
  ongettingInternInfo,
  getApplicantDetails,
  
} from "../../services/apiCalls";
import { useForm, Controller } from "react-hook-form";
import Loader from "../../components/loader/loader";
import Application from "../applicants/index";
import { toast } from "react-toastify";
import TextArea from "../../components/description/description";
import ErrorField from "../../components/errorField/errorField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TablePagination } from "@mui/base";
import { fetchInternshipLog } from "./reducer";
import { useTranslation } from "react-i18next";
import AlertModalCompany from "../../components/alertModal/alertModalCompany"; //INIVOS

const Message = styled.div`
  padding-left: 20px;
  font-size: 22px;
  padding-bottom: 5px;
`;
const FormWrapper = styled.div`
  margin: 25px 0px;
  padding: 25px 70px 29px 63px;
  background: #ffffff;
  border-radius: 10px;

  > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin: 18px 0px;
    color: ${Colors.labelText};
  }
  > p1 {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: ${Colors.labelText};
  }
  > p2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: ${Colors.labelText};
  }
`;
const SingleField = styled.div`
  margin-bottom: 30px;
`;
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;

const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${grey[100]};
  }
  `
);

const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .MuiTablePaginationUnstyled-spacer {
    display: none;
  }
  & .MuiTablePaginationUnstyled-toolbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }
  & .MuiTablePaginationUnstyled-selectLabel {
    margin: 0;
  }
  & .MuiTablePaginationUnstyled-select {
    padding: 2px;
    border: 1px solid ${grey[200]};
    border-radius: 50px;
    background-color: transparent;
    &:hover {
      background-color: ${grey[50]};
    }
    &:focus {
      outline: 1px solid ${blue[200]};
    }
  }
  & .MuiTablePaginationUnstyled-displayedRows {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }
  & .MuiTablePaginationUnstyled-actions {
    padding: 2px;
    border: 1px solid ${grey[200]};
    border-radius: 50px;
    text-align: center;
  }
  & .MuiTablePaginationUnstyled-actions > button {
    margin: 0 8px;
    border: transparent;
    border-radius: 2px;
    background-color: transparent;
    &:hover {
      background-color: ${grey[50]};
    }
    &:focus {
      outline: 1px solid ${blue[200]};
    }
  }
  `
);

export default function ViewDetails() {
  const schema = yup.object().shape({
    comments: yup.string().required("Comments is a required field."),
  });

  const schema2 = yup.object().shape({
    notes: yup.string().required("Message is a required field."),
  });

  
  const denySchema = yup.object().shape({
    comments: yup.string().required("Comment is a required field."),
  });

  const {
    control,
    handleSubmit,
    setValue,
   
    formState: { errors },
  } = useForm({
    
    resolver: yupResolver(schema),
    
  });

  const {
    control: control2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    
    formState: { errors: errors2 },
  } = useForm({
    
    resolver: yupResolver(schema2),
    
  });

  
  const {
    control: denyControl,
    handleSubmit: handleDenySubmit,
   
    formState: { errors: denyErrors },
    reset,
  } = useForm({
    
    resolver: yupResolver(denySchema),
    
  });

  const [loading, setLoading] = useState(false);
  const [showApplicants, setShowApplicants] = useState(false);
  const [, setShow] = useState(false);
  const [, setStatus] = useState("all");
  const [updateTime, setUpdateTime] = useState("");
  const [internData, setInternData] = useState("");
  const [internLogData] = useState("");
  

  const [applicantData, setApplicantData] = useState("");
  const [notes, setNotes] = useState([]);
  const [action, setAction] = useState("");
  const [, setActionReview] = useState("");
  const [code, setCode] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showConfirmationModalSendReview, setShowConfirmationModalReview] =
    useState(false);
  const [showConfirmationModalAddNotes, setShowConfirmationModalAddNotes] =
    useState(false);
  const [showConfirmationModalDeny, setshowConfirmationModalDeny] =
    useState(false); 
  const [idInternShip, setInternShip] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [theArray, setTheArray] = useState([]);
  const [app_id, setAppId] = useState("");

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - theArray?.length) : 0;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };
  const closeConfirmationModalAddNotes = () => {
    setShowConfirmationModalAddNotes(false);
  };
  const closeConfirmationModalReview = () => {
    setShowConfirmationModalReview(false);
  };

  
  const handleActionCall = (e, code, actionName, data) => {
    e.stopPropagation();
    setAction(actionName);
    setCode(code);
    setShowConfirmationModal(true);
    setUpdateTime(data);
  };

  
  const handleActionDenyCall = (e, code, actionName, data) => {
    e.stopPropagation();
    setAction(actionName); 
    setCode(code);
    setshowConfirmationModalDeny(true);
    setUpdateTime(data);
  };

  
  const handleActionCallReview = (e, code, actionName, data) => {
    e.stopPropagation();
    setActionReview(actionName);
    setCode(code);
    setShowConfirmationModalReview(true);
    setUpdateTime(data);
  };

  const handleActionAddNotes = (e) => {
    e.stopPropagation();
    
    setShowConfirmationModalAddNotes(true);
  };

  const handleId = (e, data) => {
    e.stopPropagation();
    
    setInternShip(data.user_documents);
    setAppId(data.applied_user_id);
    
  };
  const handleCall = () => {
    handleAction();
  };
  

  const handleAction = async () => {
    const body = {
      id: code,
      status: action === "accepted" ? "accepted" : "rejected",
      update_time: updateTime,
    };

    setStatus(true);
    const { data, status } = await jobAction(body);
    if (status === 200) {
      setShow(false);
      if (data.status === 1) {
        setStatus(false);
        toast.success(t(data.message), {
          position: toast.POSITION.TOP_RIGHT,
        });
        getInternData();
        
      }
    }
    setShowConfirmationModal(false);
  };

  

  const onSubmit = async (data) => {
    const body = {
      id: code,
      status: action === "review" ? "review" : "review",
      comments: data.comments,
      update_time: updateTime,
    };
    
    await setStatus(true);
    const result = await jobActionReview(body);
    if (result.status === 200) {
      await setShow(false);
      if (result.data.status === 1) {
        await setShowConfirmationModalReview(false);
        await setStatus(false);
        await toast.success(t("Review comments has been sent successfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        getInternData();
        setValue("comments", " ");
      }
    }
  };

  const onSubmit2 = async (data) => {
    
    const body = {
      internship: idInternShip.apply_internship,
      notes: data.notes,
      intern_user: idInternShip.apply_user,
    };
   
    await setStatus(true);
    const result = await jobAddNotes(body);
    if (result.status === 200) {
      await setShow(false);
      if (result.data.message === "success") {
        setShowConfirmationModalAddNotes(false);
       
        getApplicantData();
        
        await toast.success(t("Note added successfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        
        data.notes = "";
        setValue2("notes", "");
      }
    }
  };

  
  const onSubmitReject = async (formData) => {
    var body = {
      id: code,
      status: "rejected",
      comments: formData.comments ? formData.comments : "No Reject Reason",
      update_time: updateTime,
    };

    setStatus(true);
    const { data, status } = await jobAction(body); 

    if (status === 200) {
      setShow(false);
      if (data.status === 1) {
        setStatus(false);
        toast.success(t(data.message), {
          position: toast.POSITION.TOP_RIGHT,
        });
        getInternData(); 
      }
    }
    setshowConfirmationModalDeny(false);
  };

  const content = () => {
    return (
      <div style={{ padding: "0 40px 20px 40px" }}>
        <Message
          style={{
            textAlign: "center",
            fontSize: "26px",
            fontWeight: "bold",
            margin: "0px auto 10px auto",
          }}
        >
          {t("Are you sure you want to")}{" "}
          {action === "accepted" ? t("approve") : t("deny")}{" "}
          {t("this internship ?")}
        </Message>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <Button
            width="180px"
            name={t("Yes")}
            onClick={handleCall}
            style={{ marginRight: "30px" }}
          />
          <Button
            width="180px"
            name={t("No")}
            color="#FFFFFF"
            onClick={() => setShowConfirmationModal(false)}
          />
        </div>
      </div>
    );
  };

  const content2 = () => {
    return (
      <>
        
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container">
            <SingleField>
              <FormLabel>{t("Comment")}</FormLabel>
              <Controller
                name="comments"
                control={control}
                render={({ field }) => (
                  <TextArea
                    style={{ background: "#F3F3F3" }}
                    {...field}
                    rows="6"
                    placeholder={t("Enter Comment")}
                  />
                )}
              />
              {errors.comments && (
                <ErrorField err={t(errors.comments.message)} />
              )}
            </SingleField>
            <Button
              width="100px"
              name={t("Save")}
              className="mt-0 mb-5"
              type="submit"
              style={{ float: "right" }}
            />
          </div>
        </form>
        <div className="container mb-5">
          <Root sx={{ width: 200, maxWidth: "50%" }}>
            <table aria-label="custom pagination table">
              <thead>
                <tr>
                  <th
                    style={{
                      background: "rgb(250, 74, 161)",
                      color: "#fff",
                    }}
                  >
                    {t("Comments")}
                  </th>
                  <th
                    style={{
                      background: "rgb(250, 74, 161)",
                      color: "#fff",
                    }}
                  >
                    {t("Created")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {theArray?.length > 0
                  ? (rowsPerPage > 0 && theArray?.length
                    ? theArray.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    : theArray
                  ).map((row) => (
                    <tr key={row.comment}>
                      <td>{row.comment}</td>
                      <td style={{ width: 200 }} align="right">
                        {moment(new Date(row.created_at)).format(
                          "DD/MM/YYYY hh:mm:ssA"
                        )}
                      </td>
                    </tr>
                  ))
                  : ""}

                {emptyRows > 0 && (
                  <tr style={{ height: 41 * emptyRows }}>
                    <td colSpan={3} />
                  </tr>
                )}
              </tbody>
              {theArray?.length > 0 ? (
                <tfoot>
                  <tr>
                    <CustomTablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={3}
                      count={theArray?.length ?? "0"}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      componentsProps={{
                        select: {
                          "aria-label": "rows per page",
                        },
                        actions: {
                          showFirstButton: true,
                          showLastButton: true,
                        },
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </tr>
                </tfoot>
              ) : (
                <div className="container col-lg-12">{t("No Comments")}</div>
              )}
            </table>
          </Root>
        </div>
      </>
    );
  };

  const content3 = () => {
    return (
      <>
       
        <form onSubmit={handleSubmit2(onSubmit2)}>
          <div className="container">
            <SingleField>
              <FormLabel>{t("Add Notes")}</FormLabel>
              <Controller
                name="notes"
                control={control2}
                render={({ field }) => (
                  <TextArea
                    style={{ background: "#F3F3F3" }}
                    {...field}
                    rows="6"
                    placeholder={t("Enter Note")}
                  />
                )}
              />
              {errors2.notes && <ErrorField err={t(errors2.notes.message)} />}
            </SingleField>
            <Button
              width="100px"
              name={t("Save")}
              className="mt-0 mb-5"
              type="submit"
              style={{ float: "right" }}
            />
          </div>
        </form>
        <div className="container mb-5">
          <Root sx={{ width: 200, maxWidth: "50%" }}>
            <table aria-label="custom pagination table">
              <thead>
                <tr>
                  <th
                    style={{
                      background: "rgb(250, 74, 161)",
                      color: "#fff",
                    }}
                  >
                    {t("Notes")}
                  </th>
                  <th
                    style={{
                      background: "rgb(250, 74, 161)",
                      color: "#fff",
                    }}
                  >
                    {t("Created")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {notes.map((note) => (
                  <>
                    {note.internship_notes.map((row) => (
                      <>
                        {row.intern_user === app_id ? (
                          <tr key={row.notes}>
                            <td>{row.notes}</td>
                            <td style={{ width: 200 }} align="right">
                              {row.updated_at}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </>
                      
                    ))}
                  </>
                ))}
              </tbody>
            </table>
          </Root>
        </div>
      </>
    );
  };

  
  const denyContent = () => {
    return (
      <>
        <div style={{ padding: "0 40px 20px 40px" }}>
          <Message
            style={{
              textAlign: "center",
              fontSize: "26px",
              fontWeight: "bold",
              margin: "0px auto 10px auto",
            }}
          >
            {t("Are you sure you want to deny this internship ?")}
          </Message>
          <form onSubmit={handleDenySubmit(onSubmitReject)}>
            <div className="container">
              <SingleField style={{ marginBottom: 0 }}>
                <FormLabel>{t("Rejection Note")}</FormLabel>
                <Controller
                  name="comments"
                  control={denyControl}
                  render={({ field }) => (
                    <TextArea
                      style={{ background: "#F3F3F3" }}
                      {...field}
                      rows="3"
                      placeholder={t("Enter Comment")}
                    />
                  )}
                />
                {denyErrors.comments && (
                  <ErrorField err={t(denyErrors.comments.message)} />
                )}
              </SingleField>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Button
                  width="180px"
                  name={t("Yes")}
                  type="submit"
                  style={{ marginRight: "30px" }}
                />
                <Button
                  width="180px"
                  name={t("No")}
                  color="#FFFFFF"
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    reset();
                    setshowConfirmationModalDeny(false);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </>
    );
  };

  const search = useLocation().search;
  var id = new URLSearchParams(search).get("id") || "";

  useEffect(() => {
    getInternData();
    getApplicantData();
    getInternLogData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getInternData = async () => {
    setLoading(true);
    const { data } = await ongettingInternInfo(id);
    if (data && data.status === 1) {
      setInternData(data.data);
      setTheArray(data.data.review_comments);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const dispatch = useDispatch();
  const getInternLogData = async () => {
    dispatch(fetchInternshipLog(id));
  };

  const getApplicantData = async () => {
    const { data } = await getApplicantDetails(id);
    if (data && data.status === 1) {
      setApplicantData(data.applicants);
      setNotes(data.applicants);
    }
  };

  const setShowApplicantStatus = () => {
    setShowApplicants(true);
  };
  const showDetails = () => {
    showApplicants(false);
  };
  const { t } = useTranslation();
  return (
    <MainLayout>
     

      {loading ? (
        <Loader />
      ) : !showApplicants ? (
        <>
          <div role="presentation">
            <div
              className="d-flex mb-5"
              style={{
                fontSize: "1.1rem",
                fontWeight: "400",
                marginLeft: "15px",
              }}
            >
              
              <Link
                to="/internships"
                style={{
                  textDecoration: "none",
                  color: "#ACACAC",
                  marginLeft: "15px",
                }}
              >
                {t("Internships")} &nbsp; {">"}
              </Link>
              <div className="mx-2" style={{ color: "#ACACAC" }}></div>
              <span style={{ textDecoration: "none", color: "#D04C8D" }}>
                {t("Internship Detail")}
              </span>
            </div>
          </div>
          <FormWrapper>
            <InternIntroSection
              handleApprove={handleActionCall}
              handleDeny={handleActionDenyCall}
              handleApproveReview={handleActionCallReview}
              data={internData}
            />
            <InterShipCard data={internData} />
            <InternShipDetails
              data={internData}
              logData={internLogData}
              setShowApplicants={setShowApplicantStatus}
              noOfApplicants={applicantData.length}
              theArray={theArray}
            />
          </FormWrapper>
        </>
      ) : (
        <>
          <div role="presentation">
            <div
              className="d-flex mb-5"
              style={{
                fontSize: "1.1rem",
                fontWeight: "400",
                marginLeft: "15px",
              }}
            >
              <Link
                to="/internships"
                style={{ textDecoration: "none", color: "#ACACAC" }}
              >
                {t("Internships")} &nbsp; &#62;
              </Link>{" "}
              <Link
                to={"/internships/details?id=" + id}
                style={{ textDecoration: "none", color: "#ACACAC" }}
                className="mx-2"
                onClick={showDetails}
              >
                {t("Internship Detail")} &nbsp; &#62;
              </Link>
              <div className="" style={{ color: "#ACACAC" }}></div>
              <span style={{ textDecoration: "none", color: "#D04C8D" }}>
                {t("View Applicants")}
              </span>
            </div>
          </div>
          <Application
            handleApproveAddNotes={handleActionAddNotes}
            handleIdNotes={handleId}
            data={internData}
            applicantData={applicantData}
          />
        </>
      )}
      <AlertModal
        show={showConfirmationModal}
        close={closeConfirmationModal}
        content={content()}
      />
      <AlertModalReview
        show={showConfirmationModalSendReview}
        close={closeConfirmationModalReview}
        content={content2()}
      />
      <AlertModalReview
        show={showConfirmationModalAddNotes}
        close={closeConfirmationModalAddNotes}
        content={content3()}
        id={idInternShip}
      />

      {/* INIVOS */}
      <AlertModalCompany
        show={showConfirmationModalDeny}
        close={showConfirmationModalDeny}
        content={denyContent()}
        id={idInternShip}
      />
    </MainLayout>
  );
}
