import React from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import { Colors } from '../../utilities/colors';
import Table from '../tableNew/table';
import AddContact from '../AddContact/AddContact';

const FlexWrapper = styled.div`
  display: flex;
`;
const SectionWrapper = styled.div`
  background: #fdfdfd;
  border: 1px solid rgba(228, 228, 228, 0.26);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 25px;
  margin: 30px 0;
`;
const HeaderSection = styled(FlexWrapper)`
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.3px solid ${Colors.labelText};
  padding-bottom: 10px;
  > h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${(props) =>
    props.settings ? Colors.labelText : Colors.primaryText};
    margin: 0;
  }
`;

const Description = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #878787;
  margin: 20px 0;
`;

const DescriptionSection = styled(FlexWrapper)`
  align-items: center;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
const TableWrapper = styled.div`
  margin: 10px 0;
`;
const AddButton = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  color: #d04c8d;
`;
export default function CompanyContactPerson(props) {
  const [open, setOpen] = React.useState(false);
  const onChangeContent = () => {
    setOpen(true);
  };
  const onCloseModal = () => {
    setOpen(false);
  };

  const company = useSelector((state) => state.company.companyData);
  const { t, } = useTranslation();
  return (
    <SectionWrapper>
      <HeaderSection settings={props.settings}>
        <h3>{t("Contact Person")}</h3>
      </HeaderSection>
      <DescriptionSection>
        <Description>
          {t("Edit or add contact person for your organization changes will be sent to talent corp for approval.")}
        </Description>
       
      </DescriptionSection>
      <TableWrapper>
        {company && company?.org_user?.length > 0 ? (
          <Table data={company && company.org_user} />
        ) : (
          ''
        )}
      </TableWrapper>
      {company.status !== "accepted" ?
        
        ""
        :
        <AddButton onClick={onChangeContent}>
          + {t("Add another contact person")}
        </AddButton>
      }
      {open && (
        <AddContact
          loadichekng={props.loadichekng}
          open={open}
          onClose={onCloseModal}
        />
      )}
    </SectionWrapper>
  );
}
