import httpClient from "./httpClient";
import httpMasterdataClient from "./httpMasterdataClient";
import httpDashboardClient from "./httpDashboardClient";

export const onApiCall = async ({
  method,
  url,
  data,
  params,
  isFileUpload = false,
  signal,
}) => {
  const constructHeaders = () => {
    if (isFileUpload) {
      return {
        common: { "Content-Type": "multipart/form-data" },
      };
    } else {
      return {
        common: { "Content-Type": "application/json" },
      };
    }
  };

  try {
    const response = await httpClient.request({
      url,
      method,
      data,
      params,
      headers: constructHeaders(),
      signal,
    });
    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    if (error.response) {
     
      return {
        data: error.response.data.error ?? error.response.data,
        status: error.response.status,
      };
    } else if (error.request) {
      
      return {
        data: "api error",
      };
    } else {
     
      return {
        data: "api error",
      };
    }
  }
};

export const onMasterdataApiCall = async ({
  method,
  url,
  data,
  params = {},
  isFileUpload = false,
  controller = null,
}) => {
  return onApiCallAction({
    method,
    url,
    data,
    params,
    isFileUpload,
    controller,
    apiCallType: "master",
  });
};

const defaultHeaders = {
  "x-api-key": "lmcBgFcmS2lwZcCIjpJClcs4NdvoC9R9i4TWgSsg",
  Acept: "application/json",
};

export const onDashboardApiCall = async ({
  method,
  url,
  data,
  params = {},
  headers = defaultHeaders,
  isFileUpload = false,
  controller = null,
}) => {
  return onApiCallAction({
    method,
    url,
    data,
    params,
    headers,
    isFileUpload,
    controller,
    apiCallType: "dashboard",
  });
};

export const onApiCallAction = async ({
  method,
  url,
  data,
  params = {},
  headers,
  isFileUpload = false,
  controller = null,
  apiCallType = "",
}) => {
  

  const constructHeaders = (headers) => {
    if (isFileUpload) {
      return {
        common: { ...headers, "Content-Type": "multipart/form-data" },
      };
    } else {
      return {
        common: { ...headers, "Content-Type": "application/json" },
      };
    }
  };

  try {
    const response = await (apiCallType === "master"
      ? httpMasterdataClient
      : apiCallType === "dashboard"
      ? httpDashboardClient
      : httpClient
    ).request({
      url,
      method,
      data,
      headers: constructHeaders(headers),
      params,
      signal: controller && controller.signal,
    });
    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    if (error.response) {
     
      console.log("Err", error.response.data);

      
      return {
        data: error.response.data.error,
        status: error.response.status,
      };
    } else if (error.request) {
      
      return {
        data: "api error",
      };
    } else {
      
      console.log("Error", error.message);
      return {
        data: "api error",
      };
    }
  }
};

export const getError = (error) =>
  (error.response && error.response.data && error.response.data.message) ||
  error.message ||
  error.toString();
