import React from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextArea from "../../../components/description/description";
import Button from "../../../components/button/button";


const Overlay = styled.div`

    background: grey;
    opacity:0.5;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: space-around;
  
`
const Title = styled.div`
font-family: 'General Sans';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
margin:20px 0 0 20px;
color: #202020;
`
const Wrapper = styled.div`
background:white;
width:645px;
height:497px;
border-radius: 32px;
@media (max-width:500px){
    width:345px;
    height:497px;
}
table{
    width:600px;
@media (max-width:600px){
    width:300px;
}
}
`
const Icon =styled.div`
    position:relative;
    margin-right:10px;
    left:610px;
    top:5px;
    @media (max-width:500px){
      left:310px;
      top:5px;
    }
`
const Review = ({open,onClose}) => {
   
    const schema = yup.object().shape({
        comments: yup.string().required("Comments is a required field."),
      });
    const {
        control,
        handleSubmit,
        
        formState: { errors, },
        reset,
      } = useForm({
        resolver: yupResolver(schema),
      });

      if(!open) {return null}

      if(open) {
        document.body.classList.add('active-modal')
      } else {
        document.body.classList.remove('active-modal')
      }
    
      const data =  [{name:"Test Comments",status:"24-02-2023"}]
    return(
        <div>
            <Overlay>
            <Wrapper>
                <Icon onClick={onClose} >X</Icon>
                <Title>Comments</Title>
                <Controller
                  name="comments"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      style={{ background: "#F3F3F3",margin:"20px" }}
                      {...field}
                      rows="3"
                      placeholder={("Review the company details")}
                    />
                  )}
                  
                />
                <Button name="Save" style={{position:"relative",left:"500px",width:"120px"}}></Button>
                <table style={{background:"#F84B9C",overflow:"hidden",margin:"20px"}}>
          <thead style={{background:"#F84B9C"}}>
            <tr >
                <th style={{color:"white"}}>Comments</th>
                <th style={{color:"white"}}>Created</th>
            </tr>
          </thead>
          <tbody style={{paddingBottom:"25px"}}>
            {data.length> 0 ? 
            data.map((comment,index)=>
            <>
                <tr key={index} style={{background:"white"}}>
            <td>{comment.name}</td>
            <td>{comment.status}</td>
            </tr>
            </>
            ) : null
            }
            
            
          </tbody>        
        </table>     
            </Wrapper>
            </Overlay>
        </div>
    )
}
export default Review