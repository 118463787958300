import { useState } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { Colors } from '../../utilities/colors';
import UserCreationLayout from '../../layouts/userCreation/userCreationLayout';
import verifyImg from '../../assets/verifyCover.png';
import { IoIosArrowBack } from 'react-icons/io';
import IntroSection from '../../components/introSection/introSection';
import Button from '../../components/button/button';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  adminLoginValidation,
  onTriggerOtp,
  onOtpVerify,
} from '../../services/apiCalls';
import OtpVerification from '../../components/otpverification/otpverification';
import ErrorField from '../../components/errorField/errorField';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from "react-i18next";

const LoginPageWrapper = styled.section``;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: -5px;
  justify-content: none;
  cursor: pointer;
  @media (max-width: 650px) {
    display: block;
  }
`;
const LabelText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;
const LabelText_1 = styled.label`
  position: absloute;
  font-family: General Sans;
  font-style: italic;
  font-weight: 500;
  display: block;
  font-size: 16px;
  line-height: 24px;
  margin-top: 6%;
  color: #696f79;
`;
const LightText = styled(LabelText)`
  color: ${Colors.pink};
  font-size: 20px;
`;


const Intro = styled.div`
  margin-top: 10%;
`;
const LabelText_des = styled.div`
  font-family: General Sans;
  font-size: 16px;
  line-height: 28px;
  color: #707070;
`;

export default function Verify() {
  
  const [otpVerification, setOtpVerification] = useState(false);
  const [otpErrors, setOtpErrors] = useState('');
  const [, setShowEmailVerification] = useState(false);
  const [verify, setVerify] = useState(false);
  const { t, } = useTranslation();
  const location = useLocation();
  const onEmailVerification = async () => {
    if (location.state.name) {
      const { data, status } = await onTriggerOtp({
        email_address: location.state.name,
      });
      if (status === 200) {
        onEmailTriggered(data);
        toast.success(
          t('OTP has been sent successfully to your registered Email ID'),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    }
  };

  const fetchOTPVerify = async (otp) => {
    const { data, status } = await onOtpVerify({
      email_address: location.state.name,
      otp_in: otp,
    });
    if (status === 200) {
      console.log(data);
      if (data.status === 0) {
        toast.error(t(data.message), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        onOtpVerification(otp);
      }
    }
  };
  const onEmailTriggered = ({ data, message, status }) => {
    if (status === 1) {
      setShowEmailVerification(true);
    }
  };
  


  const onOtpVerification = async (otp) => {
    if (otp) {
      const { data, status } = await adminLoginValidation({
        email_address: location.state.name,
        otp_in: otp,
      });
      if (status === 200) {
        onOtpVerified(data);
      }
    }
  };
  const onOtpVerified = ({ data, message, status }) => {
    if (status === 1) {
      setOtpVerification(true);
      Cookies.set('token', data.token);
    } else {
      setOtpVerification(false);
      setOtpErrors(t(message));
    }
  };


  let navigate = useNavigate();
  const ChangeNavigate = (path) => {
    navigate(`${path}`);
  };

  const handleNavigateLogin = () => {
    if (otpVerification) {
      navigate('/');
    } else {
      setVerify(true);
    }
  };

  return (
    <UserCreationLayout coverImg={verifyImg}>
      <LoginPageWrapper>
        <HeaderWrapper
          onClick={() => {
            ChangeNavigate('/');
          }}
        >
          <IoIosArrowBack color={Colors.pink} size={20} />
          <LightText>{t("Back")}</LightText>
        </HeaderWrapper>
        <Intro>
          <IntroSection name="verify" />
        </Intro>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <LabelText_des>{t("This is a two-step authentication process")}</LabelText_des>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <LabelText_1>
          {t("We’ve sent a verification code to your email address")}
        </LabelText_1>
        <ReactTooltip
          id="otp"
          globalEventOff="focusin"
          place="top"
          effect="solid"
        >
          {t("Enter verification code")}{' '}
        </ReactTooltip>
        <OtpVerification
          dataId="otp"
          verifyCode={fetchOTPVerify}
          isSuccess={otpVerification}
          onSendNewCode={onEmailVerification}
        />
        {!otpVerification && otpErrors && <ErrorField err={otpErrors} />}
        <br />
        {!otpVerification && verify && (
          <ErrorField
            err={
              t('Please enter the verification code received on your e-mail address')
            }
          />
        )}
        <br />
        <Button width="100%" name={t("Sign in")} onClick={handleNavigateLogin} />
      </LoginPageWrapper>
    </UserCreationLayout>
  );
}
