import { onApiCall } from "./CommonApi";

export const authSignin = (request) => {
  return onApiCall({
    url: `auth/api/admin/login/`,
    method: "POST",
    data: request,
  });
};
export const onCompanyUserProfileUpdate = (request) => {
  console.log(request);
  return onApiCall({
    
    url: `/admin/api/company-user-profile-update/` + request.ids,
    method: "PUT",
    data: request,
  });
};
export const adminRegister = (request) => {
  return onApiCall({
    url: `auth/api/admin/register/`,
    method: "POST",
    data: request,
  });
};
export const adminLogout = (request) => {
  return onApiCall({
    url: `auth/api/admin/logout/`,
    method: "GET", 
    data: request,
  });
};

export const forceLogout = (request) => {
  console.log(request, "email,email");
  return onApiCall({
    url: `/auth/api/force-logout/`,
    method: "POST",
    data: { email: request },
  });
};

export const getAdminUserInfo = (formdata) => {
  return onApiCall({
    url: `auth/api/admin/token-object/`,
    method: "GET",
    data: formdata,
  });
};



export const onTriggerOtp = (request) => {
  return onApiCall({
    url: `base/api/email-validation-trigger/`,
    method: "POST",
    data: request,
  });
};

export const onOtpVerify = (request) => {
  return onApiCall({
    url: `base/api/email-validation/`,
    method: "POST",
    data: request,
  });
};
export const adminLoginValidation = (request) => {
  return onApiCall({
    url: `auth/api/admin/login-validation/`,
    method: "POST",
    data: request,
  });
};
export const statusDropdownCompany = () => {
  return onApiCall({
    url: `admin/api/company-status-scroll-list/`,
    method: "GET",
    
  });
};
export const statusDropdownCompanyUser = () => {
  return onApiCall({
    url: `admin/api/company-user-status-scroll-list/`,
    method: "GET",
    
  });
};
export const statusDropdownJob = () => {
  return onApiCall({
    url: `admin/api/internship-status-scroll-list/`,
    method: "GET",
    
  });
};

export const companyStatusApi = (request) => {
  return onApiCall({
    url: `admin/api/company-list/`,
    method: "POST",
    data: request,
  });
};
export const companyUserStatusApi = (request) => {
  return onApiCall({
    url: `admin/api/company-user-list/`,
    method: "POST",
    data: request,
  });
};
export const jobStatusApi = (request) => {
  return onApiCall({
    url: `admin/api/internship-list/`,
    method: "POST",
    data: request,
  });
};

export const getInternLists = (request) => {
  return onApiCall({
    url: `internship/api/internship-list/`,
    method: "POST",
    data: request,
  });
};
export const companyAction = (request) => {
  return onApiCall({
    url: `admin/api/company-status-update/`,
    method: "POST",
    data: request,
  });
};

export const uploadObject = (formdata, id) => {
  return onApiCall({
    url: `company/api/company-picture-update/${id}/`,
    method: "PUT",
    data: formdata,
    isFileUpload: true,
  });
};

export const onCompanyProfilePicUpdate = (request) => {
  return onApiCall({
    url: `admin/api/company-picture-update/${request.code}/`,
    method: "PUT",
    data: request.data,
  });
};

export const companyUserAction = (request) => {
  return onApiCall({
    url: `admin/api/company-user-status-update/`,
    method: "POST",
    data: request,
  });
};
export const jobAction = (request) => {
  return onApiCall({
    url: `admin/api/internship-status-update/`,
    method: "POST",
    data: request,
  });
};
export const jobActionReview = (request) => {
  return onApiCall({
    url: `internship/internship-update-status/`,
    method: "POST",
    data: request,
  });
};

export const jobAddNotes = (request) => {
  return onApiCall({
    url: `internship/intern-notes/`,
    method: "POST",
    data: request,
  });
};

export const CreateBlog = (request) => {
  return onApiCall({
    url: `admin/api/blogs-create/`,
    method: "POST",
    data: request,
  });
};
export const allBlogList = (request) => {
  return onApiCall({
    url: `admin/api/blogs-list/`,
    method: "POST",
    data: request,
  });
};
export const onChangePassword = (formdata) => {
  console.log(formdata);
  return onApiCall({
    url: `admin/api/change-password/`,
    method: "POST",
    data: formdata,
  });
};
export const onChangeCompanyUserPassword = (formdata) => {
  console.log(formdata);
  return onApiCall({
    url: `admin/api/company-user-change-password/${formdata.id}`,
    method: "POST",
    data: formdata,
  });
};
export const changeAvatar = (formdata) => {
  return onApiCall({
    url: `admin/api/change-avatar/`,
    method: "PUT",
    data: formdata,
  });
};
export const updateBlog = (request) => {
  return onApiCall({
    url: `admin/api/blogs-update/`,
    method: "PUT",
    data: request,
  });
};
export const blogStatusUpdate = (code, value) => {
  return onApiCall({
    url: `admin/api/blogs-status-update/${code}/${value}/`,
    method: "GET",
  });
};
export const viewBlog = (code) => {
  return onApiCall({
    url: `admin/api/blogs-view/${code}/`,
    method: "GET",
  });
};
export const dashBoardApi = () => {
  return onApiCall({
    url: `admin/api/dashboard-analytic-data/`,
    method: "GET",
  });
};
export const fileUpload = (request) => {
  return onApiCall({
    url: `base/api/file-obj-upload/`,
    method: "POST",
    data: request,
    isFileUpload: true,
  });
};

export const getCompanyData = (formdata) => {
  return onApiCall({
    url: `company/api/company-view/${formdata.code}/`,
    method: "GET",
    data: formdata,
  });
};
export const onGettingUserInfo = (request) => {
  console.log(request);
  return onApiCall({
    url: `company/api/company-user-view/${request.code}/`,
    method: "GET",
    data: request,
  });
};
export const onUserProfilePicUpdate = (request) => {
  console.log(request);
  return onApiCall({
    url: `admin/api/user-picture-update/${request.code}/${request.ids}`,
    method: "PUT",
    data: request.data,
  });
};
export const getCompanyUserInfo = (request) => {
  return onApiCall({
    url: `admin/api/company-user-profile/${request}/`,
    method: "GET",
    data: request,
  });
};
export const updateCompanyUserInfo = (request) => {
  return onApiCall({
    url: `admin/api/company-user-profile/${request.ids}/`,
    method: "PUT",
    data: request,
  });
};
export const onGettingCompanyInfo = (request) => {
 
  return onApiCall({
    url: `company/api/company-view/${request.code}/`,
    method: "GET",
    data: request,
  });
};
export const deleteContactData = (formdata) => {
  return onApiCall({
    url: `company/api/company-user-update/${formdata.code}/${formdata.id}`,
    method: "DELETE",
    data: formdata.data,
  });
};
export const viewContactData = (formdata) => {
  return onApiCall({
    url: `company/api/company-user-view/${formdata.id}/${formdata.code}`,
    method: "GET",
    data: formdata.data,
  });
};
export const updateContactDataNew = (formdata) => {
  return onApiCall({
    url: `company/api/company-user-update/${formdata.code}/${formdata.id}`,
    method: "PUT",
    data: formdata.data,
  });
};

export const onDeleteLocation = (formdata) => {
  return onApiCall({
    url: `admin/api/company-location-delete/${formdata.id}/`,
    method: "DELETE",
    data: formdata.data,
  });
};

export const onDeleteCompanyLocation = (formdata) => {
  return onApiCall({
    url: `company/api/company-location-delete/${formdata.id}/`,
    method: "DELETE",
    data: formdata.data,
  });
};

export const onViewLocation = (formdata) => {
  return onApiCall({
    url: `admin/api/company-location-view/${formdata.id}/`,
    method: "GET",
    data: formdata.data,
  });
};
export const onViewCompanyLocation = (formdata) => {
  return onApiCall({
    url: `company/api/company-location-view/${formdata.id}/`,
    method: "GET",
    data: formdata.data,
  });
};
export const onUpdateCompanyDatas = (formdata) => {
  return onApiCall({
    url: `admin/api/company-update/${formdata.code}/`,
    method: "PUT",
    data: formdata.data,
  });
};

export const getOrgData = (formdata) => {
  return onApiCall({
    url: `auth/api/company/token-object/`,
    method: "GET",
    data: formdata,
  });
};
export const onAddNewContact = (formdata) => {
  return onApiCall({
    url: `admin/api/company-user-create/`,
    method: "POST",
    data: formdata.data,
  });
};

export const onAddNewLocation = (formdata) => {
  return onApiCall({
    url: `admin/api/company-location-add/${formdata.code}/`,
    method: "POST",
    data: formdata.data,
  });
};

export const onAddCompanyNewLocation = (formdata) => {
  return onApiCall({
    url: `company/api/company-location-add/${formdata.code}/`,
    method: "POST",
    data: formdata.data,
  });
};

export const onUpdateCompanyLocation = (formdata) => {
  return onApiCall({
    url: `company/api/company-location-update/${formdata.id}/${formdata.code}/`,
    method: "PUT",
    data: formdata.data,
  });
};

export const onUpdateLocation = (formdata) => {
  return onApiCall({
    url: `admin/api/company-location-update/${formdata.id}/${formdata.code}/`,
    method: "PUT",
    data: formdata.data,
  });
};
export const onBoardGetData = (formdata) => {
  return onApiCall({
    url: `base/api/scroll-list/${formdata}/`,
    method: "POST",
    data: { search_key: "" },
  });
};

export const ongettingInternInfo = (id) => {
  return onApiCall({
    url: `/internship/api/internship-view/${id}`,
    method: "GET",
  });
};

export const getApplicantDetails = (id) => {
  return onApiCall({
    url: `/internship/internship-view-applicants/${id}`,
    method: "GET",
  });
};

export const internshipApply = (formdata, id) => {
  return onApiCall({
    url: `/internship/internship-apply/${id}`,
    method: "PATCH",
    data: formdata,
  });
};



export const getUniversity = () => {
  return onApiCall({
    url: `/api/master/universities/`,
    method: "GET",
  });
};

export const getCountriesMasterList = () => {
  return onApiCall({
    url: `/api/master/countries/`,
    method: "GET",
  });
};

export const getSatesMasterList = (country_id) => {
  return onApiCall({
    url: `/api/master/states/${country_id}/`,
    method: "GET",
  });
};

export const getCitieMasterList = (country_id, state_id) => {
  return onApiCall({
    url: `/api/master/cities/${country_id}/${state_id}/`,
    method: "GET",
  });
};

export const getScopeOfStudyMasterList = () => {
  return onApiCall({
    url: `/api/master/scope-of-studies/`,
    method: "GET",
  });
};

export const getOrganisationTypesMasterList = () => {
  return onApiCall({
    url: `/api/master/organisation-types/`,
    method: "GET",
  });
};

export const getSectorMasterList = () => {
  return onApiCall({
    url: `/api/master/sector/`,
    method: "GET",
  });
};

export const getAdminData = () => {
  return onApiCall({
    url: `admin/api/profile/`,
    method: "GET",
  });
};

export const changeAdminInfo = (data) => {
  return onApiCall({
    url: `admin/api/profile/`,
    method: "PUT",
    data: data,
  });
};



export const getUniversityList = (params, signal) => {
  return onApiCall({
    url: `api/master/v1/universities`,
    method: "GET",
    params: params,
    signal: signal,
  });
};

export const getUniversityDropdownList = (params) => {
  return onApiCall({
    url: `api/master/universities/`,
    method: "GET",
    params: params,
  });
};

export const createUniversity = (data) => {
  return onApiCall({
    url: `api/master/v1/universities/`,
    method: "POST",
    data: data,
  });
};

export const updateUniversity = (id, data) => {
  return onApiCall({
    url: `api/master/v1/universities/${id}/`,
    method: "PUT",
    data: data,
  });
};

export const deleteUniversity = (id) => {
  return onApiCall({
    url: `api/master/v1/universities/${id}/`,
    method: "DELETE",
  });
};

export const universityHasCampus = (id) => {
  return onApiCall({
    url: `api/master/v1/universities/has-campus/${id}/`,
  });
};

export const getCampusList = (params, signal) => {
  return onApiCall({
    url: `api/master/v1/campus`,
    method: "GET",
    params: params,
    signal: signal,
  });
};

export const getCampusDropwdownList = (params) => {
  return onApiCall({
    url: `api/master/campus/`,
    method: "GET",
    params: params,
  });
};

export const createCampus = (data) => {
  return onApiCall({
    url: `api/master/v1/campus/`,
    method: "POST",
    data: data,
  });
};

export const updateCampus = (id, data) => {
  return onApiCall({
    url: `api/master/v1/campus/${id}/`,
    method: "PUT",
    data: data,
  });
};

export const deleteCampus = (id) => {
  return onApiCall({
    url: `api/master/v1/campus/${id}/`,
    method: "DELETE",
  });
};

export const campusHasFaculty = (id) => {
  return onApiCall({
    url: `api/master/v1/campus/has-faculty/${id}/`,
  });
};

export const getFacultyList = (params, signal) => {
  return onApiCall({
    url: `api/master/v1/faculty`,
    method: "GET",
    params: params,
    signal: signal,
  });
};

export const getFacultyDropdownList = (params) => {
  return onApiCall({
    url: `api/master/faculty/`,
    method: "GET",
    params: params,
  });
};

export const createFaculty = (data) => {
  return onApiCall({
    url: `api/master/v1/faculty/`,
    method: "POST",
    data: data,
  });
};

export const updateFaculty = (id, data) => {
  return onApiCall({
    url: `api/master/v1/faculty/${id}/`,
    method: "PUT",
    data: data,
  });
};

export const deleteFaculty = (id) => {
  return onApiCall({
    url: `api/master/v1/faculty/${id}/`,
    method: "DELETE",
  });
};

export const facultyHasStudyProgram = (id) => {
  return onApiCall({
    url: `api/master/v1/faculty/has-study-program/${id}/`,
  });
};

export const getStudyProgramList = (params, signal) => {
  return onApiCall({
    url: `api/master/v1/study-program`,
    method: "GET",
    params: params,
    signal: signal,
  });
};

export const createStudyProgram = (data) => {
  return onApiCall({
    url: `api/master/v1/study-program/`,
    method: "POST",
    data: data,
  });
};

export const updateStudyProgram = (id, data) => {
  return onApiCall({
    url: `api/master/v1/study-program/${id}/`,
    method: "PUT",
    data: data,
  });
};

export const deleteStudyProgram = (id) => {
  return onApiCall({
    url: `api/master/v1/study-program/${id}/`,
    method: "DELETE",
  });
};

export const getOrganizationHistory = (formdata) => {
  return onApiCall({
    url: `/admin/api/organization-history/${formdata.id}`,
    method: "GET",
  });
};

export const getOrganizationUserHistory = (formdata) => {
  return onApiCall({
    url: `/admin/api/organization-user-history/${formdata.id}`,
    method: "GET",
  });
};

export const getInternshipHistory = (formdata) => {
  return onApiCall({
    url: `/admin/api/internship-history/${formdata.id}`,
    method: "GET",
  });
};

export const deleteBlog = (code) => {
  return onApiCall({
    url: `/admin/api/blogs-delete/${code}/`,
    method: "DELETE",
  });
};
