import { useState } from 'react';
import { useTranslation } from "react-i18next";

import styled from 'styled-components';
import OtpInput from 'react-otp-input';

import { Colors } from '../../utilities/colors';


const GroupWrapper = styled.div`
  margin-bottom: 20px;
  margin-top: 50px;
`;
const Label = styled.label`
  font-weight: 500;
  display: block;
`;
const FormLabel = styled(Label)`
  font-size: 16px;
  font-family: General Sans;
  line-height: 22px;
  color: ${Colors.verifyCode};
  margin-bottom: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
`;
const LabelHighlightText = styled(Label)`
  color: ${Colors.inputHighlightText};
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  margin-left: 10px;
  > span {
    margin-right: 10px;
  }
`;
const FlexBox = styled.div`
  display: flex;
`;
const InputWrapper = styled(FlexBox)`
  justify-content: start;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
const Input = {
  background: Colors.blogTextBox,
  padding: '12px 15px',
  outline: 'none',
  width: '-webkit-fill-available',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '22px',
  color: Colors.primary,
  boxSizing: 'border-box',
  borderRadius: '10px',
  maxWidth: '54px',
  marginRight: '5px',
  border: `2px solid transparent`,
};
const focusInput = {
  border: `1px solid ${Colors.primary}`,
};
const VerifyCodeText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #504f8c;
  margin: 0;
  text-decoration: none;
  align-self: end;
  cursor: ${(props) => (props.cursor ? 'default' : 'pointer')};
  margin-left: 10px;
  text-decoration: underline;
  @media (max-width: 500px) {
    align-self: center;
    margin-top: 5px;
    margin-left: 0px;
  }
`;


export default function Otpverification({
  onSendNewCode,
  verifyCode,
  isSuccess,
  dataId,
}) {
  const [otp, setOtp] = useState('');

  const handleChange = (otp) => {
    setOtp(otp);
    if (otp.length === 5) {
      verifyCode(otp);
    }
  };

  const onVerify = () => {
    if (!isSuccess) {
      verifyCode(otp);
    }
  };
  const { t, } = useTranslation();
  return (
    <GroupWrapper>
      <FormLabel>
        {t("Verification code")}{' '}
        <LabelHighlightText onClick={onSendNewCode}>
          {t("Send new code")}
        </LabelHighlightText>
      </FormLabel>
      <InputWrapper>
        <FlexBox data-tip data-for={dataId ? dataId : null}>
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={5}
            focusStyle={focusInput}
            inputStyle={Input}
            shouldAutoFocus
            separator={<span></span>}
          />
        </FlexBox>
        <VerifyCodeText
          cursor={isSuccess}
          onClick={isSuccess ? () => {} : onVerify}
        >
          {isSuccess ? t('Verified') : t('Verify code')}
        </VerifyCodeText>
      </InputWrapper>
    </GroupWrapper>
  );
}
