import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

import Mainlayout from "../../layouts/mainLayout/mainlayout";
import { getAdminUserInfo } from "../../services/apiCalls";
import { useTranslation } from "react-i18next";

import {
  CardPadded39,
  LoaderWrapper,
  ViewDetails,
  AddUserWrapper,
  DataTableWrapper,
} from "../analyticalDashboardPage/commonStyles";

import Loader from "../../components/loader/loader";

import { useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useNavigate } from "react-router-dom";




const DataTableLoaderWrapper = styled.div`
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;


const UniversityRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.4px solid rgba(32, 32, 32, 0.22);
  padding: 20px 50px;
  .left-content {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 50px;
      width: 50px;
      margin-right: 20px;
    }
    .name {
      font-family: "General Sans", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #202020;
    }
  }
  .right-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const PageTitle = styled.h1`
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;
`;
export default function UniversityMasterData() {
  const {
    
    getValues,
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      search: "",
    },
  });
  const [, setUniversities] = useState([]);
  const [universitiesFull, ] = useState([]);
  const [scrollIndex, setScrollIndex] = useState(1);
  const [toggle,] = useState(true);
  const [searchval,] = useState("");
  const [showOne, setShowNewOne] = useState(false);
  const { t } = useTranslation();

  let navigate = useNavigate();

  const getDatasD = useCallback (async () => {
    var result1 = await getAdminUserInfo();
    
    if (result1.status === "401") {
      navigate("/login");
    } else {
      setShowNewOne(true);
    }
  }, [navigate])

  useEffect(() => {
    getDatasD();
  }, [getDatasD]);

 

  useEffect(() => {
    setScrollIndex(1);
  }, [searchval]);

  useEffect(() => {
    const values = getValues();
    setUniversities(
      universitiesFull
        .filter((data) =>
          values.search
            ? data.name.toLowerCase().includes(values.search.toLowerCase())
            : true
        )
        
    );
    return () => {
      setUniversities([]);
    };
  }, [scrollIndex, toggle, getValues, universitiesFull]);

  const fetchData = () => {
    setTimeout(() => {
      setScrollIndex(scrollIndex + 1);
    }, 2000);
  };
  
  
  

  

  const masterDataList = [
    {
      name: "Universities",
      link: "/master-data/universities/", 
    },
    {
      name: "Campus",
      link: "/master-data/campuses/",
    },
    {
      name: "Faculty",
      link: "/master-data/faculties/",
    },
    {
      name: "Study Program",
      link: "/master-data/study-programs/",
    },

  ]

  return (
    <>
      <Mainlayout>
        {showOne ? (
          <div>
            <PageTitle>{t("Master Data")}</PageTitle>
            
            <Row>
              <Col>
                <AddUserWrapper>
                  
                </AddUserWrapper>
              </Col>
            </Row>
            <Row>
              <Col>
                <CardPadded39>
                  <DataTableWrapper>
                    <InfiniteScroll
                      dataLength={masterDataList.length}
                      hasMore={
                        false
                      }
                      next={fetchData}
                      loader={
                        masterDataList.length > 0 ? (
                          <div
                            style={{
                              minHeight: "586px",
                              
                            }}
                          >
                            <DataTableLoaderWrapper>
                              <LoaderWrapper>
                                <Loader />
                              </LoaderWrapper>
                            </DataTableLoaderWrapper>
                          </div>
                        ) : (
                          <p className="noDataFound">{t("No data found")}</p>
                        )
                      }
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          
                        </p>
                      }
                      refreshFunction={fetchData}
                    >
                      {masterDataList.map((masterData, index) => (
                        <UniversityRow key={index}>
                          <span className="left-content">
                            <span className="name">
                              {t(masterData.name)}
                            </span>
                          </span>
                          <span className="right-content">
                            <Link to={masterData.link}>
                              {" "}
                              <ViewDetails>{t("Edit")}</ViewDetails>
                            </Link>
                            
                          </span>
                        </UniversityRow>
                      ))}
                    </InfiniteScroll>
                  </DataTableWrapper>
                </CardPadded39>
              </Col>
            </Row>
          </div>
        ) : (
          <Loader />
        )}
      </Mainlayout>
    </>
  );
}
