import React from 'react';
import MainLayout from '../../../layouts/mainLayout/mainlayout';
import HorizontalBarChart from '../../../components/analyticalDashboard/charts/horizontalBarChart.js';
import { Link } from "react-router-dom";
import { onApiCall } from '../../../services/CommonApi';
import { withTranslation } from "react-i18next";

class Education extends React.Component {

  constructor(props) {

    super(props);
    this.state = { country: {}, state: {}, city: {} }

  }

  componentDidMount() {

    onApiCall({
      url: `/api/dashboard/analytical/demographics/geography/usersByResidence/`,
      method: 'GET'
    }).then(res => {
      if (res.status === 200) {
        this.setState({ country: res.data.data.results });
      }
    })

    onApiCall({
      url: `/api/dashboard/analytical/demographics/geography/usersByState/`,
      method: 'GET'
    }).then(res => {
      if (res.status === 200) {
        this.setState({ state: res.data.data.results });
      }
    })

    onApiCall({
      url: `/api/dashboard/analytical/demographics/geography/usersByCity/`,
      method: 'GET'
    }).then(res => {
      if (res.status === 200) {
        this.setState({ city: res.data.data.results });
      }
    })
  }

  format(item, color) {

    const data = { "labels": [], "datasets": [{ "data": [], "backgroundColor": [color] }] }

    for (const [, value] of Object.entries(item)) {

      data.labels.push(value['_id'][0])

      data.datasets[0].data.push(value['total'])

    }

    return data

  }

  formatCity(item, color) {

    const data = { "labels": [], "datasets": [{ "data": [], "backgroundColor": [color] }] }

    for (const [, value] of Object.entries(item)) {
      data.labels.push(value['_id'][0])
      data.datasets[0].data.push(value['total'])
    }

    var n = item.length;
    data.labels.splice(1,n-20)
    data.datasets[0].data.splice(1,n-20)

    return data

  }

  render() {
    const { t } = this.props;

    return (
      <MainLayout>

        <div className="d-flex mb-5" style={{ fontSize: '1.1rem', fontWeight: '400' }}>

          <Link to="/analyticaldashboard" style={{ textDecoration: 'none', color: '#ACACAC' }}>{t("Analytical Dashboard")}</Link>

          <div className='mx-2' style={{ color: '#ACACAC' }}> > </div>

          <Link to="/analyticaldashboard/demographics" style={{ textDecoration: 'none', color: '#ACACAC' }}>{t("Demographics")}</Link>

          <div className='mx-2' style={{ color: '#ACACAC' }}> > </div>

          <span style={{ textDecoration: 'none', color: '#D04C8D' }}>{t("Geography")}</span>

        </div>

        <div className='row'>

          <div className='col-12 col-lg-6 col-xl-6 mb-4'>
            <div className='p-4 text-center bg-white rounded shadow' style={{ minHeight: '100%' }}>
              <h3 className='mb-3' style={{ fontWeight: '300' }}>{t("COUNTRY OF RESIDENCE")}</h3>
              <HorizontalBarChart data={this.format(this.state.country, '#ef886d')} />
            </div>
          </div>

          <div className='col-12 col-lg-6 col-xl-6 mb-4'>
            <div className='p-4 text-center bg-white rounded shadow' style={{ minHeight: '100%' }}>
              <h3 className='mb-3' style={{ fontWeight: '300' }}>{t("STATE")}</h3>
              <HorizontalBarChart data={this.format(this.state.state, '#6aa1c7')} />
            </div>
          </div>

        </div>

        <div className='row'>
          <div className='col-12 col-lg-12 col-xl-12 mb-4'>
            <div className='p-4 text-center bg-white rounded shadow' style={{ minHeight: '100%'}}>
              <h3 className='mb-3' style={{ fontWeight: '300' }}>{t("CITY (TOP 20)")}</h3>
                <div style={{ overflowY: 'scroll' }}>
                  <HorizontalBarChart 
                    data={this.formatCity(this.state.city, '#fbd185')} 
                    aspect_ratio={25}
                  />
              </div>
            </div>
          </div>
        </div>

      </MainLayout>
    );

  }

}

export default withTranslation()(Education);
