import { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Mui from '@material-ui/core';
import CustomButton from '../../components/button/button';
import { schema } from '../newIntern/staticContent';
import styled from 'styled-components';
import { Colors } from '../../utilities/colors';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { createTheme } from '@material-ui/core';
import ViewProfile from '../applicants/ViewProfile';
import { ThemeProvider } from '@material-ui/styles';
import { ReactComponent as CalenderIcon } from '../../assets/icons/svg/calendarIcon.svg';
import ErrorField from '../../components/errorField/errorField';
import { useSelector } from 'react-redux';
import Button from '../../components/button/button';
import { toast } from 'react-toastify';
import { internshipApply, getApplicantDetails } from "../../services/apiCalls";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

const materialTheme = createTheme({
  palette: {
    primary: {
      main: '#D44B9C',
    },
  },
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: '#D44B9C',
      },
    },
  },
});

const useStyles = Mui.makeStyles((theme) => ({
  avatarStyle: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const JobsWrapper = styled.span`
  margin: 0;
  font-weight: 500;
  font-size: 0.75em;
  line-height: 24px;
  text-decoration-line: underline;
  color: ${Colors.primaryText};
  cursor: pointer;
`;

const ResumeName = styled.div`
  font-size: 1em;
  font-weight: 500;
`;

const SubHeaderName = styled.div`
  font-size: 0.75em;
  font-weight: 500;
  color: #656565;
`;

const GridWrapper = styled.div`
  display: grid;
  margin-bottom: 30px;
`;

const AcademicGridWrapper = styled(GridWrapper)`
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  padding-top: 15px;
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
  label {
    position: relative;
    //width: -webkit-fill-available;
  }
`;

const CalenderIconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  cursor: pointer;
`;

const FormGroup = styled.div`
  width: 100%;
`;
const Buttonwrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > h4 {
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #b3b3b3;
  }
`;
const IconSingleSection = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${(props) => (props.margin ? props.margin : "0px")};
`;
const OtherText = styled.p`
  margin: 0 0 0 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #525252;
`;
export default function Applicants(props) {
  const [profileModal, setProfileModal] = useState(false);
  const classes = useStyles();
  const internFields = useSelector((state) => state.intern?.value);
  const handleApproveAddNotes = props.handleApproveAddNotes;
  const [applicantFiles, setApplicantFiles] = useState(null);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const { t, } = useTranslation();

  const {
    control,
    formState: { errors, },
  } = useForm({
    defaultValues: { ...internFields },
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });
  const {
    first_name,
    scope_abbr,
    curr_qualification_abbr,
    profile_pic,
    applied_user_id,
    update_time
  } = props.data;
  const [new_update_time, setNewUpdateTime] = useState(update_time)

  const [joiningDate, setJoiningDate] = useState(
    props?.joiningDate ? props?.joiningDate : ''
  );

  const [leavingDate, setLeavingDate] = useState(
    props?.leavingDate ? props?.leavingDate : ''
  );

  const [status, setStatus] = useState(props?.data?.talent_intern_status);

  const onCloseModal = () => {
    setProfileModal(false);
  };

  const viewProfileClick = () => {
    setProfileModal(true);
  };

  const dateChange = (val, name) => {
    if (name === 'joiningDate') {
      
      const date1 = moment(val).format("YYYY-MM-DD");
      setJoiningDate(date1);
    } else if (name === 'leavingDate') {
      const date2 = moment(val).format("YYYY-MM-DD");
      setLeavingDate(date2);
    }
  };

  const approveOrRejectApplicant = async (action) => {
    
    const { data } = await internshipApply(
      { status: action, applied_user_id, joiningDate, leavingDate, update_time: new_update_time },
      props?.id
    );
    if (data && data.status === 1) {
      if (action === "applied") {
        toast.success(t('Internship recalled successfully'), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (action === "offered") {
        toast.success(t('Internship approved successfully'), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success(t('Internship rejected successfully'), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setStatus(action);
      setNewUpdateTime(data.data.update_time)
    } else {
      toast.error(t('Internship not saved'), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const StatusText = styled.p`
    text-transform: capitalize;
    color: ${(props) =>
      props.status === 'offered' ? Colors.successText : Colors.errorText};
  `;

  console.log(internFields, '============', props.data);

  const getApplicantData = useCallback (async () => {
    const { data } = await getApplicantDetails(id);
    if (data && data.status === 1) {
      const applicantDocument = data["applicants"][0]["user_documents"];
      const filesArr = [
        "cover_path",
        "resume_path",
        "transcript",
        "writing_sample_path",
        "other_docs"
      ];
      const tempApplicantFiles = {};
      filesArr.forEach((item) => {
        if (
          applicantDocument[item] !== "" &&
          applicantDocument[item] !== "/storage/False"
        ) {
          tempApplicantFiles[item] = applicantDocument[item];
        }
      });
      setApplicantFiles(tempApplicantFiles);
    }
  }, [id])

  useEffect(() => {
    getApplicantData()
  }, [id, getApplicantData])

  return (
    <>
      {profileModal && (
        <ViewProfile
          id="viewProfile"
          open={profileModal}
          onClose={onCloseModal}
          data={props.data}
        />
      )}
      <Row>
        <Col sm={12} md={5} xd={6}>
          <div style={{ display: 'flex' }}>
            <Mui.Avatar src={profile_pic} className={classes.avatarStyle} />
            <div style={{ margin: 'auto 10px', minWidth: '70%' }}>
              <ResumeName>{first_name}</ResumeName>
              <SubHeaderName>
                {scope_abbr} | {curr_qualification_abbr}
              </SubHeaderName>
              <JobsWrapper onClick={viewProfileClick}>{t("View Profile")}</JobsWrapper>
              {applicantFiles &&
                Object.keys(applicantFiles).map((file_path, index) => {
                  return (
                    <IconSingleSection key={file_path + index}>
                      <OtherText>
                        
                        <a
                          target="_blank" rel="noopener noreferrer"
                          href={props.data.user_documents[file_path]}
                          download
                        >
                          
                          <JobsWrapper style={{ margin: 0 }}>
                            {file_path === "cover_path" &&
                              applicantFiles.cover_path !== null
                              ? t("Cover letter")
                              : ""}
                            {file_path === "resume_path" &&
                              applicantFiles.resume_path !== null
                              ? t("CV")
                              : ""}
                            {file_path === "transcript" &&
                              applicantFiles.transcript !== null
                              ? t("Transcript")
                              : ""}
                            {file_path === "writing_sample_path" &&
                              applicantFiles.writing_sample_path !== null
                              ? t("Writing sample")
                              : ""}
                            {file_path === "other_docs" &&
                              applicantFiles.other_docs !== null
                              ? t("Optional Document")
                              : ""}
                          </JobsWrapper>
                        </a>
                       
                      </OtherText>
                    </IconSingleSection>
                  );
                })}
              
            </div>
          </div>
        </Col>
        <Col sm={12} md={2} xd={2}>
          <AcademicGridWrapper template="1fr 1fr">
            <FormGroup>
              <Controller
                name="joining_date"
                control={control}
                render={({ field }) => (
                  <label style={{ width: 'inherit' }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          autoOk
                          variant="inline"
                          format="dd/MM/yyyy"
                          placeholder="dd/mm/yyyy"
                          views={['year', 'month', 'date']}
                          value={joiningDate}
                          disabled={status !== 'applied'}
                          onChange={(val) => dateChange(val, 'joiningDate')}
                          fullWidth
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                    <CalenderIconWrapper>
                      <CalenderIcon />
                    </CalenderIconWrapper>
                  </label>
                )}
              />
              {errors.joining_date && (
                <ErrorField err={t(errors.joining_date.message)} />
              )}
            </FormGroup>
          </AcademicGridWrapper>
        </Col>
        <Col sm={12} md={2} xd={2}>
          <AcademicGridWrapper template="1fr 1fr">
            <FormGroup>
              <Controller
                name="leaving_date"
                control={control}
                render={({ field }) => (
                  <label style={{ width: 'inherit' }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          autoOk
                          variant="inline"
                          format="dd/MM/yyyy"
                          placeholder="dd/mm/yyyy"
                          views={['year', 'month', 'date']}
                          value={leavingDate}
                          disabled={status !== 'applied'}
                          onChange={(val) => dateChange(val, 'leavingDate')}
                          fullWidth
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                    <CalenderIconWrapper>
                      <CalenderIcon />
                    </CalenderIconWrapper>
                  </label>
                )}
              />
              {errors.joining_date && (
                <ErrorField err={t(errors.joining_date.message)} />
              )}
            </FormGroup>
          </AcademicGridWrapper>
        </Col>
        <Col sm={12} md={2} xd={2}>
          {status !== 'applied' ? (
            <Row className="d-flex align-items-baseline">
              
              <Col className="mr-1" style={{ 'margin-left': '80px' }}>
                <StatusText status={status}>{t(status)}</StatusText>
              </Col>
              <Col style={{ 'margin-left': '37px' }}>
                <CustomButton
                  secondary
                  width="8em"
                  name={t("Recall")}
                  fontSize="14px"
                  lineHeight="18px"
                  minWidth="0"
                  color="#fff"
                  onClick={() => approveOrRejectApplicant('applied')}
                />
              </Col>
            </Row>
          ) : (
            <Row style={{ 'margin-left': '27px' }}>
              <Col>
                <CustomButton
                  margin="15px 10px 15px 0px"
                  width="8em"
                  fontSize="14px"
                  lineHeight="18px"
                  minWidth="0"
                  name={t("Approve")}
                  onClick={() => approveOrRejectApplicant('offered')}
                />
              </Col>
              <Col>
                <CustomButton
                  secondary
                  width="8em"
                  name={t("Deny")}
                  fontSize="14px"
                  lineHeight="18px"
                  minWidth="0"
                  color="#fff"
                  onClick={() => approveOrRejectApplicant('rejected')}
                />
              </Col>
            </Row>
          )}
        </Col>
        <Col sm={12} md={1} xd={1}>
          <Buttonwrapper>
            <Button
              width="100%"
              style={{ 'margin-left': '95px' }}
              name="+"
              onClick={(e) => {
                handleApproveAddNotes(e);
                props.handleIdNotes(e, props.data);
              }}
            />
          </Buttonwrapper>
        </Col>
      </Row>
    </>
  );
}
