import { useState, useEffect, useCallback } from 'react';

import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PhoneInput from 'react-phone-number-input';
import { Grid } from '@material-ui/core';
import { toast } from "react-toastify";
import { Colors } from '../../utilities/colors';
import CustomModal from '../modal/modal';
import Input from '../input/input';
import Button from '../button/button';
import { schemaNewObj } from './staticContent';
import ErrorField from '../errorField/errorField';
import { updateContactDataNew, getCompanyData } from '../../services/apiCalls';
import { updateCompanyData } from '../../pages/companies/viewDetails/reducer';
import Loader from '../loader/loader';
import { viewContactData } from '../../services/apiCalls';
import { useTranslation } from "react-i18next";
import {
  updateContactData,
  updateEdit,
} from '../../components/AddContact/reducer';
import {
  CancelAndExit,
} from '../../pages/analyticalDashboardPage/commonStyles';

const ModalWrapper = styled.div`
  padding: 10px;
  width: '100%';
  min-width: 420px;
  > h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color: #696969;
    margin: 0;
    margin-bottom: 10px;
  }
  @media (max-width: 500px) {
    padding: 0px;
    min-width: 15px;
  }
`;
const FormWrapper = styled.div`
  @media (max-width: 500px) {
    width: 200px;
  }
`;

const SingleField = styled.div`
  margin-bottom: 20px;
  @media (max-width: 500px) {
    overflow-x: auto;
  }
`;


const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.primaryText};
  margin-bottom: 0px;
`;


export default function UpdateContact(props) {
  const onClose = props.onClose;
  const ids = props.id;
  const data = props.data;
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('code');
  const contact = useSelector((state) => state.contact.contactNewData);
  const { t, } = useTranslation();
  const dispatch = useDispatch();
  const defaultVal = {
    email: contact && contact.email ? contact.email : '',
    first_name: contact && contact.first_name ? contact.first_name : '',
    last_name: contact && contact.last_name ? contact.last_name : '',
    job_title: contact && contact.job_title ? contact.job_title : '',
    department: contact && contact.department ? contact.department : '',
    mob_number: contact && contact.mob_number ? contact.mob_number : '',
   
  };

  const getData = useCallback (async () => {
    const { data, } = await viewContactData({
      code: ids,
      id: code,
    });
    if (data && data.status === 1) {
      dispatch(updateContactData(data.data));
      dispatch(updateEdit(true));
    }
  }, [code, dispatch, ids])
  useEffect(() => {
    getData();
  }, [getData]);

  

  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState('');
  console.log(data, 'hai iam fawaz k ');
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, },
  } = useForm({
    defaultValues: { ...defaultVal },
    resolver: yupResolver(schemaNewObj),
  });
  
  const formVal = watch();
  const onMblNumberChange = (val) => {
    if (val) {
      setValue('mob_number', val);
    }
  };

  const [mobNumErr, setMobNumErr] = useState('');

  const onSubmit = async () => {
    setLoading(true);
    const { data, } = await updateContactDataNew({
      code: code,
      id: ids,
      data: { ...formVal },
    });
    if (formVal.mob_number.length < 12) {
      setMobNumErr("Invalid Mobile Number")
      setLoading(false);
      return;
    }
    else {
      if (data && data.status === 1) {
        toast.success(t('Details updated successfully.'), {
          position: toast.POSITION.TOP_RIGHT,
        });
        props.onClose();
        getMainData();
        setFormErrors('');
      } else {
        setLoading(false);
        setFormErrors(data && data.message);
      }
    }
  };
  const getMainData = async () => {
    const { data, } = await getCompanyData({
      code: code,
    });
    if (data && data.status === 1) {
      setLoading(false);
      dispatch(updateCompanyData(data.data));
    } else {
      setLoading(false);
    }
  };

  return (
    <CustomModal open={props.open} onCloseModal={props.onClose}>
      <ModalWrapper>
        <Grid container>
          <Grid item lg={12}>
            <h3 style={{ width: 'auto' }}>{t("Contact Edit")}</h3>
            <hr />
            <FormWrapper>
              <Grid container>
                <Grid item lg={4}>
                  <SingleField style={{ marginRight: '2%' }}>
                    <FormLabel>{t("Email Address")}*</FormLabel>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          style={{ background: '#f3f3f3' }}
                          disabled={true}
                          placeholder={t("Email Address")}
                        />
                      )}
                    />
                    {errors.email && <ErrorField err={t(errors.email.message)} />}
                  </SingleField>
                </Grid>
                <Grid item lg={4}>
                  <SingleField style={{ marginRight: '2%' }}>
                    <FormLabel>{t("First Name")}*</FormLabel>
                    <Controller
                      name="first_name"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          style={{ background: '#f3f3f3' }}
                          placeholder={t("First Name")}
                        />
                      )}
                    />
                    {errors.first_name && (
                      <ErrorField err={t(errors.first_name.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ marginRight: '2%' }}>
                    <FormLabel>{t("Last Name")}*</FormLabel>
                    <Controller
                      name="last_name"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          style={{ background: '#f3f3f3' }}
                          placeholder={t("Last Name")}
                        />
                      )}
                    />
                    {errors.last_name && (
                      <ErrorField err={t(errors.last_name.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ marginRight: '2%' }}>
                    <FormLabel>{t("Job Title")}*</FormLabel>
                    <Controller
                      name="job_title"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          style={{ background: '#f3f3f3' }}
                          placeholder={t("Job Title")}
                        />
                      )}
                    />
                    {errors.job_title && (
                      <ErrorField err={t(errors.job_title.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ marginRight: '2%' }}>
                    <FormLabel>{t("Department")}*</FormLabel>
                    <Controller
                      name="department"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          style={{ background: '#f3f3f3' }}
                          placeholder={t("Department")}
                        />
                      )}
                    />
                    {errors.department && (
                      <ErrorField err={t(errors.department.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ marginRight: '2%' }}>
                    <FormLabel>{t("Phone Number")}*</FormLabel>
                    <Controller
                      name="mob_number"
                      control={control}
                      render={({ field }) => (
                        <PhoneInput
                          value={formVal.mob_number}
                          onChange={onMblNumberChange}
                          defaultCountry="MY"
                          international
                          countryCallingCodeEditable={false}
                        />
                      )}
                    />
                   
                    {errors.mob_number && (
                      <ErrorField err={t(errors.mob_number.message)} />
                    )}
                    {mobNumErr && (
                      <ErrorField err={t(mobNumErr)} />
                    )}
                  </SingleField>
                </Grid>

                
              </Grid>
              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "end",
                }}>
                {formErrors && <ErrorField err={t(formErrors)} />}
                {loading ? (
                  <Loader />
                ) : (
                  <Button
                    style={{
                      borderRadius: '128px',
                      marginLeft: '10px',
                      width: '200px',
                      padding: '1.25%',
                      borderColor: '#D04C8D',
                    }}
                    name={t("Confirm")}
                    onClick={handleSubmit(onSubmit)}
                  />
                )}
                <CancelAndExit
                  style={{ width: "20%", marginLeft: "10px" }}
                  type="button"
                  className="cancelBtn"
                  onClick={onClose}
                >
                  {t("Cancel")}
                </CancelAndExit>
              </Grid>
            </FormWrapper>
          </Grid>
        </Grid>
      </ModalWrapper >
    </CustomModal >
  );
}
