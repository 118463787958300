import { useEffect, useState, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import Input from "../../../components/input/input";
import Mainlayout from "../../../layouts/mainLayout/mainlayout";
import { getUniversityList} from "../../../services/apiCalls";
import Button from "../../../components/button/button";
import { CreateUniversityModal, EditUniversityModal, DeleteUniversityModal } from "../modals/universityModals";
import { universityHasCampus as universityHasCampusAPI} from '../../../services/apiCalls';
import { UniversityListMenu } from "../components/listMenu";
import { useTranslation } from "react-i18next";

import {
  CardPadded39,
  AddUserWrapper,
  DataTableWrapper,
} from "../../analyticalDashboardPage/commonStyles";

import Loader from "../../../components/loader/loader";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";


const UniversityRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.4px solid rgba(32, 32, 32, 0.22);
  padding: 20px 50px;
  .left-content {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 50px;
      width: 50px;
      margin-right: 20px;
    }
    .name {
      font-family: "General Sans", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #202020;
    }
  }
  .right-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const PageTitle = styled.h1`
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;
`;

export default function UniversityMasterData() {

  const [universities, setUniversities] = useState([]);
  const [addUniversityModalShow, setAddUniversityModalShow] = useState(false)
  const [editUniversityModalShow, setEditUniversityModalShow] = useState(false)
  const [deleteUniversityModalShow, setDeleteUniversityModalShow] = useState(false)
  const [university, setUniverstiy] = useState("")

  const [dataCount, setDataCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  
  const { t } = useTranslation();
  const [, setLoading] = useState(true);
  const [showOne, setShowNewOne] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [campusCount, setCampusCount] = useState(0);

  const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    var page = params.get("page_number")
    
    if(!page){
      page = 1
    } 
    const [pageNumber, setPageNumber] = useState(page);

  useEffect(() => {
    
    
    setPageCount(Math.ceil(dataCount / itemsPerPage));
  }, [dataCount, itemOffset, itemsPerPage]);

  
  var controller = null;

  const getData = useCallback (async (pageNumber) => {
    const pageSize = 10
    // eslint-disable-next-line react-hooks/exhaustive-deps
    controller = new AbortController();
    const {data,} = await getUniversityList({
      page: pageNumber, 
      page_size: pageSize,
      search: searchField,
    }, controller.signal);

    if (data !== "api error"){
      setUniversities(data.results);
      setDataCount(data.count);
      setItemsPerPage(pageSize)
      setLoading(false);
      setShowNewOne(true);
    }
  }, [searchField])

  useEffect(() => {
    getData(pageNumber)
  }, [pageNumber, getData]);

  useEffect(() => {
    setPageNumber(1)
    getData(1)
  }, [searchField, getData]);


  const refreshUniversityList = async () => {
    getData(pageNumber)
  }

  const addUniversityToggle = () => {
    setAddUniversityModalShow(true);
  }

  const editUniversityToggle = (e, university) => {
    setEditUniversityModalShow(true)
    setUniverstiy(university)
  }

  const deleteUniversityToggle = async (e, university) => {
    const {data,} = await universityHasCampusAPI(university.Id)
    setDeleteUniversityModalShow(true)
    setUniverstiy(university)
    setCampusCount(data.count)
  }

  const onCloseModal = () => {
    setAddUniversityModalShow(false)
    setEditUniversityModalShow(false)
    setDeleteUniversityModalShow(false)
  }

  const handleSearchChange = (e) => {
    if (controller){
      controller.abort()
    }
    setSearchField(e.target.value)
  };
  
 
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % universities.length;
    setPageNumber(event.selected + 1)
    setItemOffset(newOffset);
  };

  return (
    <>
      <Mainlayout>
        <div
            className="d-flex mb-5"
            style={{ fontSize: "1.1rem", fontWeight: "400" }}
        >
            <Link
              to="/master-data"
              style={{ textDecoration: "none", color: "#ACACAC" }}
            >
              {t("Master Data")}
            </Link>
            <div className='mx-2' style={{ color: '#ACACAC' }}> {">"} </div>
            <span style={{ textDecoration: "none", color: "#D04C8D" }}>
            {t("Universities")}
            </span>
        </div>

      {addUniversityModalShow && (
        <CreateUniversityModal
        
          show={addUniversityModalShow}
          close={onCloseModal}
        />
      )}

      {editUniversityModalShow && (
        <EditUniversityModal
         
          show={editUniversityModalShow}
          close={onCloseModal}
          university = {university}
          refresh = {refreshUniversityList}
        />
      )}

      {deleteUniversityModalShow && (
        <DeleteUniversityModal
          university = {university}
          refresh = {refreshUniversityList}
          campusCount ={campusCount}
          show={deleteUniversityModalShow}
          close={onCloseModal}
        />
      )}
      
        {showOne ? (
          <div style={{ width: "100% !important" }}>
            <PageTitle>{t("Universities")}</PageTitle>
            <Row
              xs={1}
              md={3}
              lg={4}
              style={{ minHeight: "102px", alignItems: "center" }}
            >
              <Col lg="12">
                <span className="left-content">
                  <Input
                    type = "search" 
                    width="60%"
                    color="#fff"
                    placeholder={t("Search Keywords for Universities Name")}
                    onChange={handleSearchChange}
                  />
                </span>
                <span className="right-content">
                  <Button 
                    onClick={addUniversityToggle}
                    
                    name={t("+ Add University")}
                    type="submit"
                    style={{ marginRight: "30px" }}
                  />
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <AddUserWrapper>
                 
                </AddUserWrapper>
              </Col>
            </Row>
            <Row>
              <Col>
                <CardPadded39>
                  <DataTableWrapper>
                    <Row>
                      {universities.map((university, index) => (
                        <UniversityRow key={index}>
                          <span className="left-content">
                            <span className="name">
                              {university.Name}
                            </span>
                          </span>
                          <div className="right-content">  
                            <UniversityListMenu 
                                university={university}
                                edit={editUniversityToggle}
                                delete={deleteUniversityToggle}
                              />                                               
                          </div>
                        </UniversityRow>
                      ))}
                    </Row>
                      <span id="container">
                        <ReactPaginate
                          breakLabel={null}
                          nextLabel={t("next >")}
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={0}
                          pageCount={pageCount}
                          previousLabel={t("< previous")}
                          renderOnZeroPageCount={null}
                          itemsPerPage={itemsPerPage}
                          dataCount = {dataCount}   
                          setItems = {setUniversities}    
                          items = {universities}
                          forcePage = {pageNumber - 1}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                        />
                      </span>
                  </DataTableWrapper>
                </CardPadded39>
              </Col>
            </Row>
          </div>
        ) : (
          <Loader />
        )}
      </Mainlayout>
    </>
  );
}
