import { useState } from "react";

import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PhoneInput from "react-phone-number-input";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import { Colors } from "../../utilities/colors";
import CustomModal from "../modal/modal";
import Input from "../input/input";
import Button from "../button/button";
import { schemaNewObj } from "./staticContent";
import ErrorField from "../errorField/errorField";
import { getCompanyData, onAddNewContact } from "../../services/apiCalls";
import { updateCompanyData } from "../../pages/companies/viewDetails/reducer";
import Loader from "../loader/loader";
import { CancelAndExit } from "../../pages/analyticalDashboardPage/commonStyles";
import { useTranslation } from "react-i18next";



const ModalWrapper = styled.div`
  padding: 20px;
  width: "100%";
  min-width: 420px;
  > h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color: #696969;
    margin: 0;
    margin-bottom: 10px;
  }
  @media (max-width: 500px) {
    padding: 0px;
    min-width: 15px;
  }
`;
const FormWrapper = styled.div`
  @media (max-width: 500px) {
    width: 200px;
  }
`;
const SingleField = styled.div`
  margin-bottom: 20px;
  @media (max-width: 500px) {
    overflow-x: auto;
  }
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.primaryText};
  margin-bottom: 0px;
`;






export default function AddContact(props) {
  const onClose = props.onClose;
  const loadichekng = props.loadichekng;
  const { t, } = useTranslation();

  

  const search = useLocation().search;
  const code = new URLSearchParams(search).get("code");
  const contact = useSelector((state) => state.contact.contactData);

  

  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { ...contact },
    resolver: yupResolver(schemaNewObj),
  });
  
  const onMblNumberChange = (val) => {
    if (val) {
      setValue("mob_number", val);
    }
  };
  const formVal = watch();
  formVal.status = "accepted"
  const dispatch = useDispatch();

  const onSubmit = async () => {
    
    const { data, } = await onAddNewContact({
      org_code: code,
      data: { ...formVal, org_code: code },
    });
    loadichekng();
    getData();
    if (data && data.status === 1) {
      props.onClose();
      toast.success(t("User Created Successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFormErrors("");
    } else {
      setLoading(false);
      
      setFormErrors(data && data.message);
      toast.error(t(data.message), {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log(formErrors);
    }
  };
  const getData = async () => {
    const { data, } = await getCompanyData({
      code: code,
    });
    if (data && data.status === 1) {
      setLoading(false);
      dispatch(updateCompanyData(data.data));
    } else {
      setLoading(false);
    }
  };

  return (
    <CustomModal open={props.open} onCloseModal={props.onClose}>
      <ModalWrapper>
        <Grid container>
          <Grid item lg={12}>
            <h3 style={{ width: "auto" }}>{t("Add Contact")}</h3>
            <hr />
            <FormWrapper>
              <Grid container>
                <Grid item lg={4}>
                  <SingleField style={{ marginRight: "2%" }}>
                    <FormLabel>{t("Email Address")}*</FormLabel>
                    <Controller
                      name={t("email")}
                      control={control}
                      render={({ field }) => (
                        <Input
                          style={{ background: "#f3f3f3" }}
                          {...field}
                          placeholder={t("Email")}
                        />
                      )}
                    />
                    
                      {errors.email && (
                      <ErrorField err={t(errors.email.message)} />
                    )}
                  </SingleField>
                </Grid>
                <Grid item lg={4}>
                  <SingleField style={{ marginRight: "2%" }}>
                    <FormLabel>{t("First Name")}*</FormLabel>
                    <Controller
                      name="first_name"
                      control={control}
                      render={({ field }) => (
                        <Input
                          style={{ background: "#f3f3f3" }}
                          {...field}
                          placeholder={t("First Name")}
                        />
                      )}
                    />
                    {errors.first_name && (
                      <ErrorField err={t(errors.first_name.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ marginRight: "2%" }}>
                    <FormLabel>{t("Last Name")}*</FormLabel>
                    <Controller
                      name="last_name"
                      control={control}
                      render={({ field }) => (
                        <Input
                          style={{ background: "#f3f3f3" }}
                          {...field}
                          placeholder={t("Last Name")}
                        />
                      )}
                    />
                    {errors.last_name && (
                      <ErrorField err={t(errors.last_name.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ marginRight: "2%" }}>
                    <FormLabel>{t("Job Title")}*</FormLabel>
                    <Controller
                      name="job_title"
                      control={control}
                      render={({ field }) => (
                        <Input
                          style={{ background: "#f3f3f3" }}
                          {...field}
                          placeholder={t("Job Title")}
                        />
                      )}
                    />
                    {errors.job_title && (
                      <ErrorField err={t(errors.job_title.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ marginRight: "2%" }}>
                    <FormLabel>{t("Department")}*</FormLabel>
                    <Controller
                      name="department"
                      control={control}
                      render={({ field }) => (
                        <Input
                          style={{ background: "#f3f3f3" }}
                          {...field}
                          placeholder={t("Department")}
                        />
                      )}
                    />
                    {errors.department && (
                      <ErrorField err={t(errors.department.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ marginRight: "2%" }}>
                    <FormLabel>{t("Phone Number")}*</FormLabel>
                    
                    <Controller
                      name="mob_number"
                      control={control}
                      render={({ field }) => (
                        <PhoneInput
                          containerStyle={{ height: "48px" }}
                          inputStyle={{ height: "48px" }}
                          value={formVal.mob_number}
                          onChange={onMblNumberChange}
                          defaultCountry="MY"
                          international
                          countryCallingCodeEditable={false}
                          maxLength={15}
                          minLength={12}
                          
                        />
                      )}
                    />
                    {errors.mob_number && (
                      <ErrorField err={t(errors.mob_number.message)} />
                    )}
                  </SingleField>
                </Grid>

                
              </Grid>
              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "end",
                }}
              >
                
                {loading ? (
                  <Loader />
                ) : (
                  <Button
                    style={{
                      borderRadius: "128px",
                      marginLeft: "10px",
                      width: "200px",
                      padding: "1.25%",
                      borderColor: "#D04C8D",
                    }}
                    name={t("Confirm")}
                    onClick={handleSubmit(onSubmit)}
                  />
                )}

                <CancelAndExit
                  style={{ width: "20%", marginLeft: "10px" }}
                  type="button"
                  className="cancelBtn"
                  onClick={onClose}
                >
                  {t("Cancel")}
                </CancelAndExit>
              </Grid>
            </FormWrapper>
          </Grid>
        </Grid>
      </ModalWrapper>
    </CustomModal>
  );
}
