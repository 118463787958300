import { useCallback, useEffect, useState } from 'react';
import MainLayout from '../../layouts/mainLayout/mainlayout';
import styled from 'styled-components';
import { Colors } from '../../utilities/colors';
import MyCompanyIntro from '../../components/myCompanyIntro/myCompanyIntro';
import SettingsChangePassword from '../../components/settingsChangePassword/settingsChangePassword';
import { useNavigate } from 'react-router';
import { getAdminUserInfo } from '../../services/apiCalls';
import Loader from '../../components/loader/loader';


const SettingsWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 2rem;
  margin: 2rem 0;
  min-height: 90vh;
`;

export default function Settings() {
  const [showOne, setShowNewOne] = useState(false);
  const [admin_data, setAdminData] = useState('');

  let navigate = useNavigate();

  const getDatas = useCallback (async () => {
    var result1 = await getAdminUserInfo();
    
    setAdminData(result1.data)
    if (result1.status === '401') {
      navigate('/login');
    } else {
      setShowNewOne(true);
    }
  }, [navigate])

  useEffect(() => {
    getDatas();
  }, [getDatas]);
  
  return (
    <MainLayout>
      {showOne ? (
        <SettingsWrapper>
          
          <MyCompanyIntro admin_data={admin_data} />
          
          <SettingsChangePassword />
        </SettingsWrapper>
      ) : (
        <Loader />
      )}
    </MainLayout>
  );
}
