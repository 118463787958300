import { useState, useEffect } from "react";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import { useTranslation } from "react-i18next";


function UniversityListMenu(props) {    
  const [anchor, setAnchor] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (currentEvent?.type === 'delete'){ 
      props.delete(currentEvent?.event, props.university); 
    } else if (currentEvent?.type === 'edit'){
      props.edit(currentEvent?.event, props.university); 
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEvent]);

    const handleClick = (e) => {
      setAnchor(e.currentTarget);
    };
  
    const handleClose = () => {
      setAnchor(false);
    };

    return (
        <>
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="outlined"
                onClick={handleClick}
                style={{marginRight: '15px', padding: '5px'}}
            >
                <MoreVertIcon fontSize="small" />
            </Button>
            <Menu
                elevation={2}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                anchorEl={anchor}
                keepMounted
                open={Boolean(anchor)}
                onClose={handleClose}
                style={{                  
                  border: '1px solid #d3d4d5'
                }}
            >
              <MenuItem
                onClick={(event) => { 
                  setCurrentEvent({type:'edit', event: event});
                  handleClose(); 
                }}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                  <ListItemText primary={t("Edit")} />
              </MenuItem>
              <MenuItem
                onClick={(event) => { 
                  setCurrentEvent({type:'delete', event: event});
                  handleClose(); 
                }}
              >
                <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={t("Delete")} />
              </MenuItem>

            </Menu>
        </>
    );
}

function CampusListMenu(props) {    
  const [anchor, setAnchor] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (currentEvent?.type === 'delete'){
      props.delete(currentEvent?.event, props.campus); 
    } else if (currentEvent?.type === 'edit'){
      props.edit(currentEvent?.event, props.campus); 
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEvent]);

    const handleClick = (e) => {
      setAnchor(e.currentTarget);
    };
  
    const handleClose = () => {
      setAnchor(false);
    };

    return (
        <>
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="outlined"
                onClick={handleClick}
                style={{marginRight: '15px', padding: '5px'}}
            >
                <MoreVertIcon fontSize="small" />
            </Button>
            <Menu
                elevation={2}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                anchorEl={anchor}
                keepMounted
                open={Boolean(anchor)}
                onClose={handleClose}
                style={{                  
                  border: '1px solid #d3d4d5'
                }}
            >
              <MenuItem
                onClick={(event) => { 
                  setCurrentEvent({type:'edit', event: event});
                  handleClose(); 
                }}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                  <ListItemText primary={t("Edit")} />
              </MenuItem>
              <MenuItem
                onClick={(event) => { 
                  setCurrentEvent({type:'delete', event: event});
                  handleClose(); 
                }}
              >
                <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={t("Delete")} />
              </MenuItem>

            </Menu>
        </>
    );
}

function FacultyListMenu(props) {    
  const [anchor, setAnchor] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (currentEvent?.type === 'delete'){
      props.delete(currentEvent?.event, props.faculty); 
    } else if (currentEvent?.type === 'edit'){
      props.edit(currentEvent?.event, props.faculty); 
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEvent]);

    const handleClick = (e) => {
      setAnchor(e.currentTarget);
    };
  
    const handleClose = () => {
      setAnchor(false);
    };

    return (
        <>
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="outlined"
                onClick={handleClick}
                style={{marginRight: '15px', padding: '5px'}}
            >
                <MoreVertIcon fontSize="small" />
            </Button>
            <Menu
                elevation={2}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                anchorEl={anchor}
                keepMounted
                open={Boolean(anchor)}
                onClose={handleClose}
                style={{                  
                  border: '1px solid #d3d4d5'
                }}
            >
              <MenuItem
                onClick={(event) => { 
                  setCurrentEvent({type:'edit', event: event});
                  handleClose(); 
                }}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                  <ListItemText primary={t("Edit")} />
              </MenuItem>
              <MenuItem
                onClick={(event) => { 
                  setCurrentEvent({type:'delete', event: event});
                  handleClose(); 
                }}
              >
                <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={t("Delete")} />
              </MenuItem>

            </Menu>
        </>
    );
}

function StudyProgramListMenu(props) {    
  const [anchor, setAnchor] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (currentEvent?.type === 'delete'){
      props.delete(currentEvent?.event, props?.studyProgram); 
    } else if (currentEvent?.type === 'edit'){
      props.edit(currentEvent?.event, props?.studyProgram); 
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEvent]);

    const handleClick = (e) => {
      setAnchor(e.currentTarget);
    };
  
    const handleClose = () => {
      setAnchor(false);
    };

    return (
        <>
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="outlined"
                onClick={handleClick}
                style={{marginRight: '15px', padding: '5px'}}
            >
                <MoreVertIcon fontSize="small" />
            </Button>
            <Menu
                elevation={2}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                anchorEl={anchor}
                keepMounted
                open={Boolean(anchor)}
                onClose={handleClose}
                style={{                  
                  border: '1px solid #d3d4d5'
                }}
            >
              <MenuItem
                onClick={(event) => { 
                  setCurrentEvent({type:'edit', event: event});
                  handleClose(); 
                }}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                  <ListItemText primary={t("Edit")} />
              </MenuItem>
              <MenuItem
                onClick={(event) => { 
                  setCurrentEvent({type:'delete', event: event});
                  handleClose(); 
                }}
              >
                <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={t("Delete")} />
              </MenuItem>

            </Menu>
        </>
    );
}

export { UniversityListMenu, CampusListMenu, FacultyListMenu, StudyProgramListMenu };