import styled from 'styled-components';
import { Colors } from '../../utilities/colors';
import { Link } from 'react-router-dom';
import Card from "../../components/card/cardImage";

export const ImageDropdown = styled.img`
  width: 20px;
  height: 20px;
`;

export const SingleField = styled.div`
  margin-bottom: 30px;
`;

export const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;

export const IconsLocation = styled.div`
  position: relative;
  width: 15%;
  height: 50px;
  float: right;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
export const LabelText = styled.label`
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;
`;
export const StartDate = styled.div`
  width: 22%;
  @media (max-width: 900px) {
    width: 95%;
  }
`;

export const EndDate = styled.div`
  width: 22%;
  @media (max-width: 900px) {
    width: 95%;
  }
`;

export const LightText = styled(LabelText)`
  color: ${Colors.black};
  margin-left: 0vh;
`;

export const InputFieldDiv = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 1400px) {
    display: block;
  }
`;

export const InputFieldDivLeft = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 900px) {
    /* margin-left: 70px;
    display: grid;
    justify-content: start; */
    flex-direction: column;
    justify-content: center;
  }
`;

export const Textspan = styled.span`
  font-weight: 500;
  font-size: 20px;
`;
export const DisplayBlock = styled.div`
  width: 25%;
  height: 50px;
  @media (max-width: 900px) {
    width: 95%;
  }
`;

export const ButtonSize = styled.div`
  margin-top: 1rem;
  width: 100%;
  @media (max-width: 1200px) {
    margin-left: 5px;
  }
`;
export const ButtonSizeClear = styled.div`
  width: 100%;
  margin-right: 20px;
  align-self: center;
  @media (max-width: 1200px) {
    margin-left: 5px;
  }
`;
export const FilterWrapper = styled.div`
  align-self: center;
  margin-left: 30px;
  height: 50px;
  margin-top: 30px;
  @media (max-width: 1200px) {
    margin-left: 5px;
  }
`;
export const Clear = styled.div`
  width: 15%;
`;
export const Image_1 = styled.img`
  cursor: pointer;
`;
export const ManageCompany = styled.div`
  display: flex;
  width: 100%;
`;
export const FooterWrapper = styled.div`
  margin-left: 0vh;
  margin-right: 0vh;
`;

export const AcceptedDiv = styled.div`
  margin-left: 5vh;
  margin-right: 5vh;
  margin-top: 5vh;
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
`;
export const Foot = styled.div`
  margin-right: 200px;
`;
export const Companydiv = styled.div`
  width: 20px;
  height: 20px;
`;
export const ComapanyImage = styled.img`
  width: fit-content;
  height: fit-content;
  align-self: center;
`;

export const CompanyName = styled.span`
  align-self: center;
  font-size: 14px;
`;
export const CreatedBy = styled.span`
  align-self: center;
  color: ${Colors.createdBy};
  font-size: 14px;
`;

export const LightText_ = styled(LabelText)`
  color: ${Colors.pink};
  font-size: 20px;
  cursor: pointer;
  align-self: center;
`;
export const DividerDiv = styled.div`
  margin-left: 5vh;
  margin-right: 5vh;
`;

export const RouteLink = styled(Link)`
  align-self: center;
  font-size: 14px;
`;

export const CardDivision = styled(Card)`
  overflow-y: auto;
`;
export const OptionDivision = styled.div`
  display: block;
  width: 100%;
  position: relative;
`;
export const OptionLeft = styled.div`
  position: relative;
  float: left;
`;
export const Message = styled.div`
  padding-left: 20px;
  font-size: 22px;
  padding-bottom: 5px;
`;
export const OptionRight = styled.div`
  position: absloute;
  float: right;
`;
export const OptionImg = styled.img`
  width: 30px;
  height: 30px;
`;
export const OptionText = styled.span`
  font-weight: 500;
  color: #000;
  margin-left: 10px;
  font-size: 16px;
  vertical-align: middle;
`;
export const Division = styled.div`
  overflow-y: auto;
`;
export const Selectdivision = styled.div`
  width: 200px;
  height: 50px;
`;

export const SelectTag = styled.select`
  width: 100%;
  height: 50px;
  border-radius: 50px;
  border: 1px solid #bcbcbc;
  font-size: 16px;

  option {
  }
`;
export const SelectOptions = styled.option`
  font-weight: 500;
  font-size: 18px;
`;
export const Optioncount = styled.span``;
export const HamburgerDiv = styled.div`
  display: flex;
`;
export const HamburgerLayout = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
`;
export const LightDiv = styled.div`
  align-self: center;
`;
export const Block = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1200px) {
    display: flex;
  }
`;
export const LeftDivision = styled.div`
  width: 40%;
  justify-content: space-between;
  display: flex;
  @media (max-width: 1200px) {
    display: grid;
  }
`;
export const Block_flex = styled.div`
  width: 22%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 800px) {
    display: flex;
    margin-top: 50px;
    width: auto;
  }
`;
export const DropdownWrapper = styled.div`
  margin-top: 2rem;
  font-weight: 500;
  font-size: 16px;
`;
export const CompanyHead = styled.div``;
export const DivWrapper = styled.div`
  width: 1080px;
`;


export const stylesq = {
    control: (base, state) => ({
      ...base,
      height: "45px",
      padding: "5.5px 5px",
      transition: "none",
      textTransform: "capitalize",
      width: "100%",
      zIndex: "100",
      background: `${Colors.light}`,
      border: "0px",
      outline: "none",
      boxShadow: " inset 0px 0px 4px rgba(0, 0, 0, 0)",
      borderRadius: "128px",
      "&:hover": {
        boxShadow: "0 0 0 1px #ff60b1",
        borderColor: "#ff60b1",
      },
    }),
    menu: (base) => ({
      ...base,
      marginTop: "0px",
      outline: "none",
      zIndex: "3",
      textTransform: "capitalize",
      border: "0px solid white",
      transition: "none",
      minWidth: "234px",
    }),
    menuList: (base) => ({
      ...base,
      color: "#575757;",
      fontSize: "12px",
      
      textTransform: "capitalize",
    }),
    option: (provided, state) => ({
      ...provided,
      width: "100%",
      height: "100%",
      fontSize: `14px`,
      
      fontWeight: `500`,
      backgroundColor: state.isSelected ? `#D04C8D` : `transparent`,
      color: state.isSelected ? `white` : `#202020`,
      "&:hover": {
        backgroundColor: state.isSelected ? `#D04C8D` : `#FFE9F4`,
        Color: `#D04C8D`,
      },
    }),
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        color: `#575757; !important`,
      },
    }),
  };
  export const styles = {
    control: (styles) => ({
      ...styles,
      borderRadius: "20px !important",
      height: "45px",
      border: "none",
      marginTop: "5px",
      color: "#ffffff!important",
      background: "linear-gradient(90deg, #FA4AA1 0%, #504F8C 100%);",
    }),
    option: (provider) => ({
      ...provider,
      width: "100%",
      fontSize: "15px",
      height: "100%",
      display: "flex",
      alignSelf: "center",
    }),
    singleValue: (styles) => ({ ...styles, color: `${Colors.light};` }),
  };
  export const whiteStyle = {
    control: (styles) => ({
      ...styles,
      borderRadius: "20px !important",
      height: "45px",
      border: "none",
      background: "#ffffff",
      marginTop: "30px",
    }),
    option: (provider) => ({
      ...provider,
      width: "100%",
      fontSize: "15px",
      height: "100%",
      display: "flex",
      alignSelf: "center",
    }),
    singleValue: (styles) => ({ ...styles, color: `${Colors.light};` }),
  };