import { Container } from 'react-bootstrap';
import UniversityHeader from './universityHeaderAdd/universityHeader';
import Mainlayout from '../../layouts/mainLayout/mainlayout';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export default function AddUniversity() {
  const { t, } = useTranslation();
  return (
    <Mainlayout>
      <div role="presentation">
        <div
          className="d-flex mb-5"
          style={{ fontSize: '1.1rem', fontWeight: '400', marginLeft: '15px' }}
        >
          <Link
            to="/universities"
            style={{ textDecoration: 'none', color: '#ACACAC' }}
          >
            {t("Universities")} &nbsp; {">"}
          </Link>
          <div className="mx-2" style={{ color: '#ACACAC' }}>

          </div>
          <span style={{ textDecoration: 'none', color: '#D04C8D' }}>
            {t("Add University")}
          </span>
        </div>
      </div>
      <Container>
        
        <UniversityHeader />
        
      </Container>
    </Mainlayout>
  );
}
