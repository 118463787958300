import { useEffect, useState, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import Input from "../../components/input/input";
import Mainlayout from "../../layouts/mainLayout/mainlayout";
import { getAdminUserInfo } from "../../services/apiCalls";
import { useTranslation } from "react-i18next";

import {
  CardPadded39,
  LoaderWrapper,
  ViewDetails,
  AddUser,
  AddUserWrapper,
  DataTableWrapper,
} from "../analyticalDashboardPage/commonStyles";
import Loader from "../../components/loader/loader";
import { useForm } from "react-hook-form";
import universityService from "../../services/university.service";
import InfiniteScroll from "react-infinite-scroll-component";
import universityLogo from "../../assets/institute.jpg";
import { Link, useNavigate } from "react-router-dom";


const DataTableLoaderWrapper = styled.div`
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;


const UniversityRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.4px solid rgba(32, 32, 32, 0.22);
  padding: 20px 50px;
  .left-content {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 50px;
      width: 50px;
      margin-right: 20px;
    }
    .name {
      font-family: "General Sans", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #202020;
    }
  }
  .right-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const PageTitle = styled.h1`
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;
`;
export default function UserLandingScreen() {
  const {
    
    
    getValues,
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      search: "",
    },
  });
  const [universities, setUniversities] = useState([]);
  const [universitiesFull, setUniversitiesFull] = useState([]);
  const [scrollIndex, setScrollIndex] = useState(1);
  const [toggle, ] = useState(true);
  const [searchval, setSearchFilter] = useState("");
  
  const [showOne, setShowNewOne] = useState(false);
  const { t, } = useTranslation();

  let navigate = useNavigate();
  const getDatasD = useCallback (async () => {
    var result1 = await getAdminUserInfo();
    
    if (result1.status === "401") {
      navigate("/login");
    } else {
      setShowNewOne(true);
    }
  }, [navigate])


  useEffect(() => {
    getDatasD();
  }, [getDatasD]);
  
  const maxRowsPerCall = 10;
  const getUniversityData = useCallback (() => {
    const body = {
      name: searchval,
      search: searchval,
    };
    universityService
      .getUniversities({
        ...body,
      })
      .then((response) => {
        if (response && response.data && response.data.count > 0) {
          const dummyData = response.data.results;
          setUniversitiesFull(dummyData);
          setUniversities(dummyData.slice(0, scrollIndex * maxRowsPerCall));
        } else {
          setUniversitiesFull([]);
          setUniversities([]);
        }
      });
  }, [scrollIndex, searchval])
  useEffect(() => {
    setScrollIndex(1);
    getUniversityData();
  }, [searchval, getUniversityData]);

  useEffect(() => {
    const values = getValues();
    setUniversities(
      universitiesFull
        .filter((data) =>
          values.search
            ? data.name.toLowerCase().includes(values.search.toLowerCase())
            : true
        )
        .slice(0, scrollIndex * maxRowsPerCall)
    );
    return () => {
      setUniversities([]);
    };
  }, [scrollIndex, toggle, getValues, universitiesFull]);

  const fetchData = () => {
    setTimeout(() => {
      setScrollIndex(scrollIndex + 1);
    }, 2000);
  };
  
  const onSearch = (e) => {
    console.log(e.target.value, "=====================");
   
    setSearchFilter(e.target.value);
    
  };
  
  return (
    <>
      <Mainlayout>
        {showOne ? (
          <div>
            
            <PageTitle>{t("Manage Universities")}</PageTitle>
            <Row
              xs={1}
              md={3}
              lg={4}
              style={{ minHeight: "102px", alignItems: "center" }}
            >
              <Col lg="12">
                <Input
                  color="#fff"
                  placeholder={t("Search Keywords for Universities Name")}
                  value={searchval}
                  onChange={onSearch}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <AddUserWrapper>
                  <AddUser to="add-university">+ {t("Add University")}</AddUser>
                </AddUserWrapper>
              </Col>
            </Row>
            <Row>
              <Col>
                <CardPadded39>
                  <DataTableWrapper>
                    <InfiniteScroll
                      dataLength={universities.length}
                      hasMore={
                        universities.length
                          ? universities.length < universitiesFull.length
                          : true
                      }
                      next={fetchData}
                      loader={
                        universities.length > 0 ? (
                          <div
                            style={{
                              minHeight: "586px",
                             
                            }}
                          >
                            <DataTableLoaderWrapper>
                              <LoaderWrapper>
                                <Loader />
                              </LoaderWrapper>
                            </DataTableLoaderWrapper>
                          </div>
                        ) : (
                          <p className="noDataFound">{t("No data found")}</p>
                        )
                      }
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          
                        </p>
                      }
                      refreshFunction={fetchData}
                    >
                      {universities.map((university, index) => (
                        <UniversityRow key={index}>
                          <span className="left-content">
                            <img
                              src={university.logo ?? universityLogo}
                              alt="dummy"
                            />
                            <span className="name">
                              {university.name}
                            </span>
                          </span>
                          <span className="right-content">
                            <Link to={"/universities/detail/" + university.id}>
                              {" "}
                              <ViewDetails>{t("Edit")}</ViewDetails>
                            </Link>

                            
                          </span>
                        </UniversityRow>
                      ))}
                    </InfiniteScroll>
                  </DataTableWrapper>
                </CardPadded39>
              </Col>
            </Row>
          </div>
        ) : (
          <Loader />
        )}
      </Mainlayout>
    </>
  );
}
