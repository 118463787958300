import { useState } from 'react';

import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { Colors } from '../../utilities/colors';
import Input from '../input/input';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '../button/button';
import { Col, Row } from 'react-bootstrap';
import { isEmpty } from '../../utilities/commonFunctions';
import { onChangePassword } from '../../services/apiCalls';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";

import * as yup from 'yup';
import { Label, SelectorWrapper, StyledSimpleCardCustom, CancelAndExit } from '../../pages/analyticalDashboardPage/commonStyles';
import ChangePasswordFormErrors from './changePasswordFormErrors';

const ButtonGroup = styled.div`
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
`;

const SectionDivisionWrapper = styled.div`
  display: flex;
  padding: 0.5rem 1.5rem;
  flex-direction: column;
  background: #fdfdfd;
  border: 1px solid rgba(228, 228, 228, 0.26);
  box-sizing: border-box;
  border-radius: 5px;
  margin: 30px 0;
`;


const CompanyAddress = styled.span`
  font-family: 'General Sans', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: ${Colors.labelText};
`;

const UnderLineText = styled.label`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
  color: ${Colors.primaryText};
  cursor: pointer;
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.4px solid ${Colors.labelText};
  padding: 12px 0;
  margin-bottom: 25px;
`;

const HeaderText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.dark};
`;
const DescriptionText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 10px 0;
  color: ${Colors.lightGray};
`;
const DetailsSection = styled.div`
  border-bottom: ${(props) =>
    props.bottom ? props.bottom : '0.4px solid rgba(32, 32, 32, 0.22)'};
  margin-bottom: ${(props) => (props.bottom ? props.bottom : '25px')};
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;

const schema = yup.object().shape({
  curr_pswd: yup.string().required('Current Password is required.'),
  new_pswd: yup.string().required('New Password is required.'),
  cnfrm_pswd: yup
    .string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.new_pswd === value;
    }),
});
export default function SettingsChangePassword() {
  const [isEditable, setIsEditable] = useState(false);
  const { t } = useTranslation();

  const defaultVal = {
    curr_pswd: '',
    new_pswd: '',
    cnfrm_pswd: '',
  };
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { ...defaultVal },
    resolver: yupResolver(schema),
  });
  const onCancel = () => {
    reset();
    setIsEditable(false);
    
  };

  const getPasswordDetails = async ({ curr_pswd, new_pswd }) => {
    const { data, status } = await onChangePassword({
      curr_pswd: curr_pswd,
      new_pswd: new_pswd,
    });
    if (status === 200) {
      if (data.status === 0) {
        toast.error(t(data.message));
      } else if (data.status === 1) {
        toast.success(t(data.message));
        setIsEditable(false);
        reset({
          curr_pswd: '',
          new_pswd: '',
          cnfrm_pswd: '',
        });
      }
    }
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    console.log(data);
    if (!isEmpty(errors)) {
      console.log('errors there', errors);
      return;
    }
    getPasswordDetails(data);
   
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SectionDivisionWrapper>
          <HeaderSection>
            <CompanyAddress>{t("Change Password")}</CompanyAddress>
            
            {!isEditable && (
              <UnderLineText
                className="changeText"
                onClick={() => setIsEditable(true)}
              >
                {t("Edit")}
              </UnderLineText>
            )}{' '}
          </HeaderSection>
          <DetailsSection bottom={'0px'}>
            <HeaderText>
              <DescriptionText>
              {t("Choose a unique password to protect your account")}
              </DescriptionText>
            </HeaderText>
          </DetailsSection>

          <Row>
            <Col lg="6">
              <div className="mb-4 mt-4">
                <Label>{t("Type your current password")} *</Label>
                <SelectorWrapper>
                  {isEditable ? (
                    <>
                      <SelectorWrapper>
                        <Controller
                          control={control}
                          name="curr_pswd"
                          render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState: { error },
                          }) => (
                            <Input
                              style={{ background: '#f3f3f3', width: '100%' }}
                              type="password"
                              placeholder={t("Current password")}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              className={error ? 'invalid' : ''}
                            />
                          )}
                        />
                        <ChangePasswordFormErrors errors={errors} formControlName="curr_pswd" />
                      </SelectorWrapper>
                    </>
                  ) : (
                    <>
                      <StyledSimpleCardCustom className="inputReadOnly">
                        
                        {t("Current password")}
                      </StyledSimpleCardCustom>
                    </>
                  )}
                </SelectorWrapper>
              </div>
            </Col>

            <Col lg="6">
              <div className="mb-4 mt-4">
                <Label>{t("Type your new password")} *</Label>
                <SelectorWrapper>
                  {isEditable ? (
                    <>
                      <SelectorWrapper>
                        <Controller
                          control={control}
                          name="new_pswd"
                          render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState: { error },
                          }) => (
                            <Input
                              style={{ background: '#f3f3f3', width: '100%' }}
                              type="password"
                              placeholder={t("New password")}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              className={error ? 'invalid' : ''}
                            />
                          )}
                        />
                        <ChangePasswordFormErrors errors={errors} formControlName="new_pswd" />
                      </SelectorWrapper>
                    </>
                  ) : (
                    <>
                      <StyledSimpleCardCustom className="inputReadOnly">
                        
                        {t("New password")}
                      </StyledSimpleCardCustom>
                    </>
                  )}
                </SelectorWrapper>
              </div>
            </Col>

            <Col lg="6">
              <div className="mb-4 mt-4">
                <Label>{t("Retype your new password")}</Label>
                <SelectorWrapper>
                  {isEditable ? (
                    <>
                      <SelectorWrapper>
                        <Controller
                          control={control}
                          name="cnfrm_pswd"
                          render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState: { error },
                          }) => (
                            <Input
                              style={{ background: '#f3f3f3', width: '100%' }}
                              type="password"
                              placeholder={t("Confirm password")}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              className={error ? 'invalid' : ''}
                            />
                          )}
                        />
                        <ChangePasswordFormErrors errors={errors} formControlName="cnfrm_pswd" />
                      </SelectorWrapper>
                    </>
                  ) : (
                    <>
                      <StyledSimpleCardCustom className="inputReadOnly">
                        
                        {t("Confirm password")}
                      </StyledSimpleCardCustom>
                    </>
                  )}
                </SelectorWrapper>
              </div>
            </Col>
          </Row>

          
        </SectionDivisionWrapper>
        {isEditable && (
          <Row>
            <ButtonGroup>
              <div lg="3">
                <Button
                  style={{
                    margin: 0,
                    width: '200px',
                    padding: '7%',
                    borderColor: '#D04C8D',
                  }}
                  name={t("Confirm")}
                
                />
              </div>
              <div lg="3">
                <CancelAndExit
                  style={{ width: '200px', margin: "0px", marginLeft: "10px" }}
                  type="button"
                  className="cancelBtn"
                  onClick={onCancel}
                >
                  {t("Cancel")}
                </CancelAndExit>
              </div>
            </ButtonGroup>
          </Row>
        )}
      </form>
    </>
  );
}
