import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Colors } from '../../utilities/colors';
import Button from '../../components/button/button';
import Divider from '../../components/divider/divider';
import Card from '../../components/card/cardImage';
import FirstIcon from '../../assets/DemoIcon.png';
import { useNavigate } from 'react-router-dom';
import FooterAddress from '../../components/footeraddress/footeraddress';
import Loader from '../../components/loader/loader';
import { ReactComponent as AcceptedIcon } from "../../assets/icons/svg/acceptedIcon.svg";
import { ReactComponent as PendingIcon } from "../../assets/icons/svg/pendingIcon.svg";
import { useTranslation } from "react-i18next";

import Reject from "../../assets/icons/reject-48.png";
const ImageDropdown = styled.img`
  width: 20px;
  height: 20px;
`;

const LabelText = styled.label`
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;
`;

const LightText = styled(LabelText)`
  color: ${Colors.black};
  margin-left: 0vh;
  font-size: 28px;
`;

const ButtonView = styled.div`
  text-decoration: underline;
  border: none;
  font-weight: 600;
  font-size: 14px;
  color: #d04c8d;
  background: none;
  text-align: right;
  display: flex;
  align-items: center;
  cursor: pointer;
  // margin-right: 20px;
`;
const NoData = styled.div`
  font-size: 20px;
  width: 100%;
  background: ${Colors.secondaryColor};
  color: black;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;
const ViewButton = styled.button`
  text-decoration: underline;
  border: none;
  font-weight: 600;
  font-size: 14px;
  color: #d04c8d;
  background: none;
`;

const RightTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PendingRight = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  @media (max-width: 767px) {
    gap: 20px;
    width: auto;
    justify-content: unset;
  }
`;

const SetOne = styled.div`
  display: flex;
  width: 10%;
  justify-content: space-between;
  padding-right: 10px;
  @media (max-width: 500px) {
    width: auto;
  }
`;
const PendingSetOne = styled.div`
  display: flex;
  width: 13%;
  justify-content: space-between;
  padding-right: 10px;
  @media (max-width: 500px) {
    width: auto;
  }
`;
const Footer = styled.div`
  margin-top: 100px;
`;
const PendingLeft = styled.div`
  display: flex;
  width: 70%;
  cursor: pointer;
  @media (max-width: 500px) {
    width: auto;
    margin-top: 10px;
  }
`;
const DividerWrapper = styled.div`
  // margin-left: 10vh;
  // margin-right: 10vh;
`;
const LeftTag = styled.div`
  display: flex;
  width: 90%;
  @media (max-width: 500px) {
    width: auto;
  }
`;

const AcceptedDiv = styled.div`
  margin: 0 5vh;
  overflow-y: auto;
  cursor: pointer;
  display: flex;
  border-bottom: 1px solid #dcdcdc;
  justify-content: space-between;
  @media (max-width: 800px) {
    display: flex;
    gap: 30px;
    /* flex-direction:column; */
  }
`;
const PendingDiv = styled.div`
  margin: 0 5vh;
  overflow-y: auto;
  display: flex;
  border-bottom: 1px solid #dcdcdc;
  justify-content: space-between;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

const StatusIcon = styled.div`
  align-self: center;
  margin-right: 5px;
`;
const Bold = styled.div`
  font-weight: 600;
  padding-left: 3px;
`;

const ComapanyImage = styled.img`
  width: 45px;
  height: 80px;
  align-self: center;
  padding: 18px 0px;
  border-radius: 50%;
`;

const CompanyName = styled.span`
  align-self: center;
  font-size: 16px;
  width: 50%;
  color: #202020;
  padding-left: 10px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;
const CreatedBy = styled.span`
  align-self: center;
  color: ${Colors.createdBy};
  font-size: 14px;
  font-style: italic;
  display: flex;
  width: 45%;
  @media (max-width: 500px) {
    width: 100%;
  }
`;


const StatusFilterScreen = styled.div``;


const TextDivision = styled.div`
  align-self: center;
  display: flex;
  width: 85%;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const AcceptTextDivision = styled.div`
  align-self: center;
  display: flex;
  width: 80%;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export default function Pendingpage(props) {

  const [pendingArray, setPendingArray] = useState([]);

  useEffect(() => {
   
    setPendingArray(props.value); 
    console.log(pendingArray,"pendingarray");
  }, [props, pendingArray]);


  const navigate = useNavigate();
  
  
  const { t, } = useTranslation();
  return (
    <StatusFilterScreen>
      <DividerWrapper>
        <Divider />
      </DividerWrapper>
      <div>
        <LightText>
          {props.name === "pending"
            ? t("Pending Requests")
            : t(props.title)
          }
        </LightText>
      </div>{' '}
      <br />
      {props.loading ? (
        <Loader />
      ) : (
        <>
          {props.name === 'pending' ? (
            <>
              {props.pendingArrayNew.length > 0 ? (
                <Card size="100" left="0">
                  {props.pendingArrayNew &&
                    props.pendingArrayNew.length > 0 &&
                    props.pendingArrayNew.map((a) => {
                      return (
                        <PendingDiv>
                          <PendingLeft>
                            <PendingSetOne>
                              <StatusIcon>
                                <PendingIcon />
                              </StatusIcon>
                              <ComapanyImage
                                src={
                                  a.profile_picture
                                    ? a.profile_picture
                                    : FirstIcon
                                }
                                onClick={() => {
                                  navigate(
                                    '/companiesusers/view/' +
                                    a.org_code +
                                    '/' +
                                    a.id
                                  );
                                }}
                              />
                            </PendingSetOne>

                            <TextDivision>
                              <CompanyName>{a.name}</CompanyName>
                              &nbsp;&nbsp;
                              {a.name === 'N/A' ? (
                                <CreatedBy>&nbsp;N/A</CreatedBy>
                              ) : (
                                <CreatedBy>
                                  <></>
                                  <Bold>{a.org_name}</Bold>
                                </CreatedBy>
                              )}
                            </TextDivision>
                          </PendingLeft>
                          <PendingRight>
                            <Button
                              width="120px"
                              name={t("Approve")}
                              onClick={(e) => {
                                props.handleApprove(
                                  e,
                                  a.id,
                                  'accepted',
                                  a.update_time
                                );
                              }}
                            />
                            <Button
                              width="120px"
                              name={t("Deny")}
                              color="#FFFFFF"
                              onClick={(e) => {
                                props.handleApprove(
                                  e,
                                  a.id,
                                  'rejected',
                                  a.update_time
                                );
                              }}
                            />
                          </PendingRight>
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          <ViewButton
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              navigate(
                                '/companiesusers/view/' +
                                a.org_code +
                                '/' +
                                a.id
                              );
                            }}
                          >
                            {t("View")}
                          </ViewButton>
                        </PendingDiv>
                      );
                    })}
                </Card>
              ) : (
                <NoData>
                  <p className="noDataFound">{t("No data found")}</p>
                </NoData>
              )}
              <Footer>
                <FooterAddress />
              </Footer>
            </>
          ) : null}
          {(props.name === 'accepted') ? (
            <>
              {props.approvedArray.length > 0 ? (
                <Card size="100" left="0">
                  {props.approvedArray &&
                    props.approvedArray.length > 0 &&
                    props.approvedArray.map((a) => {
                      return (
                        <AcceptedDiv
                          data={a}
                        >
                          <LeftTag>
                            <SetOne>
                              <StatusIcon>
                                <AcceptedIcon />
                              </StatusIcon>
                              <ComapanyImage
                                src={
                                  a.profile_picture
                                    ? a.profile_picture
                                    : FirstIcon
                                }
                                onClick={() => {
                                  navigate(
                                    '/companiesusers/view/' +
                                    a.org_code +
                                    '/' +
                                    a.id
                                  );
                                }}
                              />
                            </SetOne>
                            <AcceptTextDivision>
                              <CompanyName>{a.name}</CompanyName>
                              &nbsp;&nbsp;
                              <CreatedBy>
                                <Bold>&nbsp;{a.org_name}</Bold>
                              </CreatedBy>
                            </AcceptTextDivision>
                          </LeftTag>
                          <RightTag>
                            <ViewButton
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                navigate(
                                  '/companiesusers/view/' +
                                  a.org_code +
                                  '/' +
                                  a.id
                                );
                              }}
                            >
                              {t("View")}
                            </ViewButton>
                           
                          </RightTag>
                        </AcceptedDiv>
                      );
                    })}
                </Card>
              ) : (
                <NoData>
                  <p className="noDataFound">{t("No data found")}</p>
                </NoData>
              )}
              <Footer>
                <FooterAddress />
              </Footer>
            </>
          ) : null}

          {props.name === 'rejected' ? (
            <>
              {props.deniedArray.length > 0 ? (
                <Card size="100" left="0">
                  {props.deniedArray &&
                    props.deniedArray.length > 0 &&
                    props.deniedArray.map((a) => (
                      
                      a.status === "rejected" ?
                        <AcceptedDiv data={a}>
                          <LeftTag>
                            <SetOne>
                              <StatusIcon>
                                <ImageDropdown src={Reject} />
                              </StatusIcon>
                              <ComapanyImage
                                src={
                                  a.profile_picture
                                    ? a.profile_picture
                                    : FirstIcon
                                }
                                onClick={() => {
                                  navigate(
                                    '/companiesusers/view/' +
                                    a.org_code +
                                    '/' +
                                    a.id
                                  );
                                }}
                              />
                            </SetOne>
                            <AcceptTextDivision>
                              <CompanyName>{a.name}</CompanyName>
                              &nbsp;&nbsp;
                              <CreatedBy>
                                <Bold>&nbsp;{a.org_name}</Bold>
                              </CreatedBy>
                            </AcceptTextDivision>
                          </LeftTag>
                          <RightTag>
                            <ViewButton
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                navigate(
                                  '/companiesusers/view/' +
                                  a.org_code +
                                  '/' +
                                  a.id
                                );
                              }}
                            >
                              {t("View")}
                            </ViewButton>
                           
                          </RightTag>
                        </AcceptedDiv>
                        : ""
                      // );
                    ))}
                </Card>
              ) : (
                <NoData>
                  <p className="noDataFound">{t("No data found")}</p>
                </NoData>
              )}
              <Footer>
                <FooterAddress />
              </Footer>
            </>
          ) : null}



          {props.name === 'all' ? (
            <>
              {pendingArray.length > 0 ? (
                <Card size="100" left="0">
                  {pendingArray &&
                    pendingArray.length > 0 &&
                    pendingArray.map((a) => {
                      return (
                        <PendingDiv>
                          <PendingLeft
                          >
                            <PendingSetOne>
                              <StatusIcon>
                                {a.status === "pending"? <PendingIcon /> : ""}
                                {a.status === "rejected"? <ImageDropdown src={Reject} /> : ""}
                                {a.status === "accepted"? <AcceptedIcon /> : ""}
                                {a.status === "N/A"? <PendingIcon /> : ""}
                              </StatusIcon>
                              <ComapanyImage
                                src={
                                  a.profile_picture
                                    ? a.profile_picture
                                    : FirstIcon
                                }
                                onClick={() => {
                                  navigate(
                                    '/companiesusers/view/' +
                                    a.org_code +
                                    '/' +
                                    a.id
                                  );
                                }}
                              />
                            </PendingSetOne>

                            <TextDivision>
                              <CompanyName>{a.name}</CompanyName>
                              &nbsp;&nbsp;
                              {a.name === 'N/A' ? (
                                <CreatedBy>&nbsp;N/A</CreatedBy>
                              ) : (
                                <CreatedBy>
                                  <></>
                                  <Bold>{a.org_name}</Bold>
                                </CreatedBy>
                              )}
                            </TextDivision>
                          </PendingLeft>
                          {a.status === 'pending' || a.status === 'N/A' ? (
                            <PendingRight>
                              <>
                                <Button
                                  width="120px"
                                  name={t("Approve")}
                                  onClick={(e) => {
                                    props.handleApprove(
                                      e,
                                      a.id,
                                      'accepted',
                                      a.update_time
                                    );
                                  }}
                                />
                                <Button
                                  width="120px"
                                  name={t("Deny")}
                                  color="#FFFFFF"
                                  onClick={(e) => {
                                    props.handleApprove(
                                      e,
                                      a.id,
                                      'rejected',
                                      a.update_time
                                    );
                                  }}
                                />
                              </>
                            </PendingRight>
                          ) : ""}
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          <ButtonView
                            onClick={() => {
                              navigate(
                                '/companiesusers/view/' +
                                a.org_code +
                                '/' +
                                a.id
                              );
                            }}
                          >
                            {t("View")}
                          </ButtonView>
                        </PendingDiv>
                      );
                    })}
                </Card>
              ) : (
                <NoData>
                  <p className="noDataFound">{t("No data found")}</p>
                </NoData>
              )}
              <Footer>
                <FooterAddress />
              </Footer>
            </>
          ) : null}
        </>
      )}
    </StatusFilterScreen>
  );
}
