import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getInternshipHistory } from "../../services/apiCalls";

const initialState = {
    internshipLogData: {
        status: 0,
        logs:[]
    },
  };

export const myInternshipLogReducer = createSlice({
    name: "internshiplog",
    initialState,
    extraReducers : builder => {
        builder.addCase(fetchInternshipLog.fulfilled, (state,action) => {
            state.internshipLogData = action.payload;
        })
    } 
  })
  
  export const fetchInternshipLog = createAsyncThunk(
    'internshiplog/fetchInternshipLog', 
    async (id)=>{
        const response = await getInternshipHistory({id:id})
        return response.data;
    }
  )

export default myInternshipLogReducer.reducer;