import { GrClose } from "react-icons/gr";
import { Modal } from "react-bootstrap";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import Button from "../../../components/button/button";
import { useEffect, useState } from "react";
import {
  createCampus as createCampusAPI,
  updateCampus as updateCampusAPI,
  deleteCampus as deleteCampusAPI,
  getUniversityDropdownList,
} from "../../../services/apiCalls";
import { useTranslation } from "react-i18next";

import Dropdown from "../components/dropdown";

const AddCampusModalWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 30px;
  margin-right: 30px;
`;

function CreateCampusModal(props) {
  const [name, setName] = useState("");
  const [error, setError] = useState({});

  const [universitiesList, setUniversitiesList] = useState([]);
  const [universitySelect, setUniversitySelect] = useState(null);
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  const createCampus = async () => {
    if (name === "") {
      setError((prevState) => ({
        ...prevState,
        name: "Field name cannot be blank.",
      }));
    }

    if (universitySelect === null) {
      setError((prevState) => ({
        ...prevState,
        university: "University is required field.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        university: "",
      }));
    }

    if (universitySelect !== null && name !== "") {
      const { data, status } = await createCampusAPI({
        Name: name,
        UniversityId: universitySelect.value,
      });

      if (status !== 201 && status !== 200) {
        var message;
        if (data.message) {
          message = data.message;
        } else {
          message = "Error. Please try again or contact the administrator.";
        }
        setError((prevState) => ({
          ...prevState,
          name: message,
        }));
      } else {
        props.close();
      }
    }
  };

  const handleChange = (e) => {
    setError((prevState) => ({
      ...prevState,
      name: "",
    }));
  };

  const handleUniversitySelect = (e) => {
    setUniversitySelect(e);
  };

  useEffect(() => {
    getUniversitiesDropdown();
  }, []);

  const getUniversitiesDropdown = async () => {
    const { data } = await getUniversityDropdownList({});

    var uniList = [];
    for (var key in data.data) {
      var uni = data.data[key];
      uniList.push({ value: uni.Id, label: uni.Name });
    }
    setUniversitiesList(uniList);
    setLoading(false);
  };

  return (
    <div>
      <style type="text/css">
        {`
    @media (min-width: 576px){
    .modal-dialog {
        max-width: 671px;
        margin: 1.75rem auto;
    }
    }
        `}
      </style>
      <Modal show={props.show} onHide={props.close} centered>
        <div className="post-modal mx-2">
          <div
            className=" d-flex justify-content-end align-items-center pt-3 pe-3 modal_header"
            style={{ cursor: "pointer" }}
          >
            <GrClose onClick={props.close} />
          </div>
          <div>
            <AddCampusModalWrapper>
              <Row style={{ marginBottom: "20px", marginRight: "20px" }}>
                <h3>{t("Add Campus")}</h3>
              </Row>

              <Container>
                <Row style={{ marginBottom: "20px", marginRight: "20px" }}>
                  <Col md="4">
                    <h6 style={{ marginTop: "12px" }}>
                      {t("University Name")}:{" "}
                    </h6>
                  </Col>
                  <Col md="8">
                    <Dropdown
                      options={universitiesList}
                      placeholder={t("Enter University")}
                      minWidth="auto"
                      showLoader={loading}
                      change={(e) => handleUniversitySelect(e)}
                    />
                    <div>
                      {error.university !== "" && (
                        <span style={{ color: "red" }}>
                          {t(error.university)}
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row style={{ marginBottom: "20px", marginRight: "20px" }}>
                  <Col md="4">
                    <label className="pe-3" name="campus-name">
                      <h6>{t("Campus Name")}: </h6>
                    </label>
                    {"  "}
                  </Col>
                  <Col md="8">
                    <input
                      style={{ width: "70%" }}
                      type="text"
                      name="campus-name"
                      value={name}
                      onInput={(e) => setName(e.target.value)}
                      onChange={handleChange}
                    />
                    <div>
                      {error.name !== "" && (
                        <span style={{ color: "red" }}>{t(error.name)}</span>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>

              <Row>
                <span>
                  <Button
                    name={t("Submit")}
                    onClick={createCampus}
                    style={{
                      marginRight: "10px",
                      minWidth: "100px",
                      fontSize: "18px",
                      borderRadius: "24px",
                      padding: "8px 8px 8px 8px",
                    }}
                  />
                  <Button
                    name={t("Cancel")}
                    onClick={props.close}
                    style={{
                      marginRight: "10px",
                      minWidth: "100px",
                      fontSize: "18px",
                      borderRadius: "24px",
                      padding: "8px 8px 8px 8px",
                    }}
                  />
                </span>
              </Row>
            </AddCampusModalWrapper>
          </div>
        </div>
      </Modal>
    </div>
  );
}

function EditCampusModal(props) {
  const [name, setName] = useState("");
  const [error, setError] = useState({});
  const { t } = useTranslation();

  const updateCampus = async () => {
    if (name === "") {
      setError((prevState) => ({
        ...prevState,
        name: "Field name cannot be blank.",
      }));
    } else {
      const { data, status } = await updateCampusAPI(props.campus.Id, {
        Name: name,
      });

      if (status !== 201 && status !== 200) {
        var message;
        if (data.message) {
          message = data.message;
        } else {
          message = "Error. Please try again or contact the administrator.";
        }
        setError((prevState) => ({
          ...prevState,
          name: message,
        }));
      } else {
        props.close();
        props.refresh();
      }
    }
  };

  const handleChange = (e) => {
    setError((prevState) => ({
      ...prevState,
      name: "",
    }));
  };

  return (
    <div>
      <style type="text/css">
        {`
  @media (min-width: 576px){
  .modal-dialog {
      max-width: 671px;
      margin: 1.75rem auto;
  }
  }
      `}
      </style>
      <Modal show={props.show} onHide={props.close} centered>
        <div className="post-modal mx-2">
          <div
            className=" d-flex justify-content-end align-items-center pt-3 pe-3 modal_header"
            style={{ cursor: "pointer" }}
          >
            <GrClose onClick={props.close} />
          </div>
          <div>
            <AddCampusModalWrapper>
              <Row>
                <h3>
                  {t("Edit")} <b>{props.campus.Name}</b>{" "}
                </h3>
              </Row>
              <Row>
                <span>
                  <label className="pe-3" name="university-name">
                    <h6>{t("New Name")}: </h6>
                  </label>
                  {"  "}
                  <input
                    style={{ width: "70%" }}
                    type="text"
                    name="university-name"
                    value={name}
                    onInput={(e) => setName(e.target.value)}
                    onChange={handleChange}
                  />
                  <div>
                    {error.name !== "" && (
                      <span style={{ color: "red" }}>{t(error.name)}</span>
                    )}
                  </div>
                </span>
              </Row>
              <Row>
                <span>
                  <Button
                    name={t("Submit")}
                    onClick={updateCampus}
                    style={{
                      marginRight: "10px",
                      minWidth: "100px",
                      fontSize: "18px",
                      borderRadius: "24px",
                      padding: "8px 8px 8px 8px",
                    }}
                  />
                  <Button
                    name={t("Cancel")}
                    onClick={props.close}
                    style={{
                      marginRight: "10px",
                      minWidth: "100px",
                      fontSize: "18px",
                      borderRadius: "24px",
                      padding: "8px 8px 8px 8px",
                    }}
                  />
                </span>
              </Row>
            </AddCampusModalWrapper>
          </div>
        </div>
      </Modal>
    </div>
  );
}

function DeleteCampusModal(props) {
  const { t } = useTranslation();

  const deleteCampus = async () => {
    const {data, status} = await deleteCampusAPI(props.campus.Id)
    props.refresh();
    props.close();
  };

  return (
    <div>
      <style type="text/css">
        {`
  @media (min-width: 576px){
  .modal-dialog {
      max-width: 671px;
      margin: 1.75rem auto;
  }
      `}
      </style>
      <Modal show={props.show} onHide={props.close} centered>
        <div className="post-modal mx-2">
          <div
            className=" d-flex justify-content-end align-items-center pt-3 pe-3 modal_header"
            style={{ cursor: "pointer" }}
          >
            <GrClose onClick={props.close} />
          </div>
          <div>
            <AddCampusModalWrapper>
              <Row>
                <h3>
                  {t("Are you sure you want to delete")}{" "}
                  <b>{props.campus.Name}</b>?
                </h3>
                <div>{props.campus.university_name}</div>
                {props.facultyCount ? (
                  <h6>
                    {t("Faculty")} : {props.facultyCount}
                  </h6>
                ) : (
                  " "
                )}
              </Row>
              <Row>
                <span>
                  <Button
                    name={t("Yes")}
                    onClick={deleteCampus}
                    style={{
                      marginRight: "10px",
                      minWidth: "100px",
                      fontSize: "18px",
                      borderRadius: "24px",
                      padding: "8px 8px 8px 8px",
                    }}
                  />
                  <Button
                    name={t("No")}
                    onClick={props.close}
                    style={{
                      marginRight: "10px",
                      minWidth: "100px",
                      fontSize: "18px",
                      borderRadius: "24px",
                      padding: "8px 8px 8px 8px",
                    }}
                  />
                </span>
              </Row>
            </AddCampusModalWrapper>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export { CreateCampusModal, EditCampusModal, DeleteCampusModal };
