import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  LabelHeading,
  MainTitle,
  SelectorWrapper,
  StyledDarkerCardCustom,
  SubTitle,
} from "../../analyticalDashboardPage/commonStyles";
import { ReactComponent as Email } from "../../../assets/svg/email.svg";
import { ReactComponent as Phone } from "../../../assets/svg/phone.svg";
import { Controller } from "react-hook-form";
import FormErrors from "../../../components/formErrors/formErrors";
import Input from "../../../components/input/input";
import { getLocationFormatted } from "../../../utilities/commonFunctions";
import EventBus from "../../../utilities/eventBus";
import PhoneInput from "react-phone-number-input";
import { getUniversity } from "../../../services/apiCalls";
import Dropdown from "../../../components/dropdown/dropdown";
import { useTranslation } from "react-i18next";

export default function UniversityPrimaryDetails({
  totalForm,
  isEditable,
  datas,
}) {
  const [location, setLocation] = useState(null);
  const {
    
    formState: { errors },
    control,
    getValues,
    setValue,
    
  } = totalForm;

  const [institutionList, setinstitutionList] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState([]);
  const { t, } = useTranslation();

  useEffect(() => {
    fetchUniversity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datas]);

  useEffect(() => {
    EventBus.on("updatePrimaryLocationToUniversityHeader", (data) => {
      setLocation(data);
    });

    fetchUniversity();
    return () => {
      setLocation(null);
      EventBus.remove("updatePrimaryLocationToUniversityHeader");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLocation]);

  const fetchUniversity = async () => {
    const { data, status } = await getUniversity();
    if (status === 200) {
      if (data.status === 1) {
        var data2 = [];
        if (datas.length > 0) {
          data.data.map((e) => {
            if (!datas.includes(e.Id)) {
              var ft = { ...e, label: e.Name, value: e.Id };
              data2.push(ft);
            }
            return ft;
          });

         
          setinstitutionList(data2);
        } else {
          data.data.map((e) => {
            var ft = { ...e, label: e.Name, value: e.Id };
            data2.push(ft);
            return ft;
          });

          
          setinstitutionList(data2);
        }
      }
    }
  };

  const dropdownChange = async (name, val) => {
   
    console.log(name, val, "=========================");
    setValue("name", val.value);
    setValue("univ_name", val.Name);
    switch (name) {
      default:
        break;
      case "insti_name":
        setSelectedInstitution(val);
        break;
    }
  };

  return (
    <>
      {!isEditable ? (
        <Row>
          <Col xs="12" className="mb-2 mt-5">
            <MainTitle>{getValues().name}</MainTitle>
          </Col>
          {location && (
            <Col xs="12">
              <SubTitle>{getLocationFormatted(location)}</SubTitle>
            </Col>
          )}
          <Col xs="12">
            <hr />
          </Col>
          <Col xs="12" className="mb-4">
            <SubTitle>{getValues().motto}</SubTitle>
          </Col>
          <Col xs="12">
            <StyledDarkerCardCustom>
              <Phone />
              {getValues().phone}
            </StyledDarkerCardCustom>
            <StyledDarkerCardCustom>
              <Email />
              {getValues().email}
            </StyledDarkerCardCustom>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col md="8">
              <LabelHeading>{t("Name")}</LabelHeading>
              <SelectorWrapper>
                <Controller
                  control={control}
                  name="name"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    
                    <Dropdown
                      options={institutionList}
                      placeholder={t("Select Name of Institution")}
                      change={(val) => dropdownChange("insti_name", val)}
                      val={selectedInstitution.value}
                      label={selectedInstitution.label}
                    />
                  )}
                />
                {errors?.name?.message ? (
                  <div style={{ "margin-left": "20px", color: "red" }}>
                    {errors.name && t(errors.name.message)}
                  </div>
                ) : (
                  <FormErrors errors={errors} formControlName="name" />
                )}
              </SelectorWrapper>
            </Col>
            <Col md="4" style={{ minWidth: "110px" }}>
              <LabelHeading>{t("Short Name")}</LabelHeading>
              <SelectorWrapper>
                <Controller
                  control={control}
                  name="shortName"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <Input
                      placeholder={t("Short Name")}
                      onChange={onChange}
                      disabled={!isEditable}
                      onBlur={onBlur}
                      type="text"
                      value={value}
                      style={{ backgroundColor: "rgb(243, 243, 243)" }}
                      className={error ? "invalid" : ""}
                    />
                  )}
                />
                <FormErrors errors={errors} formControlName="shortName" />
              </SelectorWrapper>
            </Col>
          </Row>
          <Row>
            <Col>
              <LabelHeading>{t("Motto")}</LabelHeading>
              <SelectorWrapper>
                <Controller
                  control={control}
                  name="motto"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <Input
                      placeholder={t("Motto")}
                      onChange={onChange}
                      disabled={!isEditable}
                      onBlur={onBlur}
                      type="text"
                      value={value}
                      className={error ? "invalid" : ""}
                      style={{ backgroundColor: "rgb(243, 243, 243)" }}
                    />
                  )}
                />
                <FormErrors errors={errors} formControlName="motto" />
              </SelectorWrapper>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <LabelHeading>{t("Phone")}</LabelHeading>
              <SelectorWrapper>
                <Controller
                  control={control}
                  name="phone"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <PhoneInput
                      value={value}
                      onChange={onChange}
                      defaultCountry="MY"
                      international
                      countryCallingCodeEditable={false}
                    />
                  )}
                />
                {errors?.phone?.message ? (
                  <div style={{ "margin-left": "60px", color: "red" }}>
                    {errors.phone && t(errors.phone.message)}
                  </div>
                ) : (
                  <FormErrors errors={errors} formControlName="phone" />
                )}
              </SelectorWrapper>
            </Col>
            <Col md="6">
              <LabelHeading>{t("Email")}</LabelHeading>
              <SelectorWrapper>
                <Controller
                  control={control}
                  name="email"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <Input
                      placeholder={t("Email")}
                      onChange={onChange}
                      disabled={!isEditable}
                      onBlur={onBlur}
                      type="email"
                      value={value}
                      className={error ? "invalid" : ""}
                      style={{ backgroundColor: "rgb(243, 243, 243)" }}
                    />
                  )}
                />
                <FormErrors errors={errors} formControlName="email" />
              </SelectorWrapper>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
