import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  CardPadded39Darker,
  CancelAndExit,
  ImageResponsive,
  ImageWrapperResponsive,
} from "../../analyticalDashboardPage/commonStyles";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import UniversityPrimaryDetails from "../universityPrimaryDetails/universityPrimaryDetails";
import UniversityOverview from "../universityOverview/universityOverview";
import Loader from "../../../components/loader/loader";
import Button from "../../../components/button/button";
import { isEmpty } from "../../../utilities/commonFunctions";
import { toast } from "react-toastify";
import universityService from "../../../services/university.service";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function UniversityHeader() {
  const params = useParams();
  const id = params && params.id;
  const [isEditable, setIsEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState({
    name: "",
    shortName: "",
    motto: "",
    description: "",
    website: "",
    phone: "",
    yearFounded: "",
    noOfEmployees: "",
    logo: "",
  });
  const { t, } = useTranslation();
  const formSchema = Yup.object().shape({
   
    email: Yup.string()
      .email(
        'Invalid email format'
      )
      .required("Required"),
    shortName: Yup.string()
      .max(50, "should be within 50 characters")
      .required("Short name is required"),
    motto: Yup.string()
      .max(255, "should be within 255 characters")
      .required("Motto is required"),
    description: Yup.string().required("Description is required"),
    website: Yup.string()
      .max(150, "should be within 150 characters")
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      )
      .required("Please enter website"),
    phone: Yup.string()
      .max(128, "should be within 128 characters")
      .required("Phone is required"),
    yearFounded: Yup.string()
      .length(4, "should be 4 characters long")
      .required("Year founded is required"),
    noOfEmployees: Yup.string()
    .nullable()
    .default(1)
    
    .min(1, "should be at least one")
    .max(1000000000, "should be less than 1,000,000,000")
   
    .required("University Population Size is required")
  });
  const totalForm = useForm({
    criteriaMode: "all",
    resolver: yupResolver(formSchema),
    defaultValues: defaultValues,
  });
  const {
    
    formState: { errors },
    
    handleSubmit,
    
    getValues,
    reset,
  } = totalForm;

  const [selectedImage, setSelectedImage] = useState();

  const imageChange = (e) => {
    
    const univ = id;
    if (e?.target?.files[0].size <= 5000000) {
      if (e.target.files && e.target.files.length > 0) {
        
        universityService
          .changeUniversityLogo(univ, e.target.files[0])
          .then((response) => {
            if (response.status === 200) {
              const avt_img = response.data.data.logo;
              setSelectedImage(avt_img);
              toast.success(t("Logo updated successfully!"));
            }
          });
      }
    } else {
      toast.error(t("File size exceeds maximum limit."));
    }
  };

  const getCurrentUniversityDetails = useCallback(() => {
    const universityId = id;
    universityService.getUniversityById(universityId).then((response) => {
      const formValues = {
        name: response.data.name,
        shortName: response.data.short_name,
        motto: response.data.motto,
        description: response.data.description,
        website: response.data.url,
        email: response.data.email,
        phone: response.data.phone_number,
        yearFounded: response.data.year_founded,
        noOfEmployees: response.data.no_of_employees,
        logo: response.data.logo,
      };
      setDefaultValues(formValues);
      setSelectedImage(response.data.logo);
      reset(formValues);
    });
  }, [reset, id]);

  useEffect(() => {
    getCurrentUniversityDetails();
  }, [getCurrentUniversityDetails]);

  const onCancel = () => {
    reset(defaultValues);
    const avatar = getValues().avatar;
    if (avatar) {
      setSelectedImage(avatar);
    } else {
      setSelectedImage(null);
    }
    setIsEditable(false);
    setLoading(false);
  };

  const onSubmit = (data) => {
    if (!isEmpty(errors)) {
      console.log("errors there", errors);
      return;
    }
    setLoading(true);
    const form = new FormData();
    form.append("name", data.name);
    form.append("short_name", data.shortName);
    form.append("motto", data.motto);
    form.append("description", data.description);
    form.append("url", data.website);
    form.append("email", data.email);
    form.append("phone_number", data.phone);
    console.log(data.phone);
    form.append("year_founded", data.yearFounded);
    form.append("no_of_employees", data.noOfEmployees);
    form.append("logo", selectedImage);
    const universityId = id;
    universityService.updateUniversity(universityId, form).then((response) => {
      console.log(response);
      if (response.status === 200) {
        toast.success(t("University details updated successfully!"));
        getCurrentUniversityDetails();
        setLoading(false);
        setIsEditable(false);
      } else {
        toast.error(t("University update failed."));
        onCancel();
      }
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg="3" className="d-flex justify-content-center">
            <ImageWrapperResponsive>
              {selectedImage ? (
                <ImageResponsive  width="242" height="363" src={selectedImage} alt="university-logo" />
              ) : getValues().avatar ? (
                <ImageResponsive  width="242" height="363" src={getValues().avatar} alt="university-logo" />
              ) : (
                <ImageResponsive  width="242" height="363" src={defaultValues.logo} alt="university-logo" />
              )}

              <div
                className="changeImage"
                style={{ fontFamily: "General Sans,sans-serif" }}
              >
                <input
                  accept="image/x-png,image/jpeg"
                  type="file"
                  className="position-absolute opacity-0"
                  onChange={imageChange}
                />
                {t("Change Logo")}
              </div>
              <p style={{ fontSize: "12px", color: "gray" }}>
                {t("The maximum image size limit is 5 MB")}
              </p>
            </ImageWrapperResponsive>
          </Col>
          <Col lg="9">
            <UniversityPrimaryDetails
              totalForm={totalForm}
              isEditable={isEditable}
              setIsEditable={setIsEditable}
            />
          </Col>
        </Row>
        <CardPadded39Darker className="mt-5">
          <Row>
            <Col>
              <UniversityOverview
                totalForm={totalForm}
                setIsEditable={setIsEditable}
                isEditable={isEditable}
              />
            </Col>
          </Row>
        </CardPadded39Darker>
        {isEditable && (
          <Row className="d-flex justify-content-end mb-4">
            <Col lg="3">
              {loading ? (
                <Loader />
              ) : (
                <Button name={t("Save")} type="submit" style={{ width: "100%" }} />
              )}
            </Col>
            <Col lg="3">
              <CancelAndExit
                type="button"
                
                className="cancelBtn"
                onClick={onCancel}
              >
                {t("Cancel")}
              </CancelAndExit>
            </Col>
          </Row>
        )}
      </form>
    </>
  );
}
