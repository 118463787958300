import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../utilities/colors';
import TableTimestamp from '../tableTimestamp/tableTimestamp.js';
import { useTranslation } from "react-i18next";


const FlexWrapper = styled.div`
  display: flex;
`;
const SectionWrapper = styled.div`
  background: #fdfdfd;
  border: 1px solid rgba(228, 228, 228, 0.26);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 25px;
  margin: 30px 0;
`;
const HeaderSection = styled(FlexWrapper)`
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.3px solid ${Colors.labelText};
  padding-bottom: 10px;
  > h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${(props) =>
    props.settings ? Colors.labelText : Colors.primaryText};
    margin: 0;
  }
`;
const Description = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #878787;
  margin: 10px 0;
`;
const DescriptionSection = styled(FlexWrapper)`
  align-items: left;
  @media (max-width: 5000px) {
    flex-direction: column;
  }
`;
const TableWrapper = styled.div`
  margin: 10px 0px;
`;

export default function Timestamp(props) {
  const { t, } = useTranslation();

  return (
    <SectionWrapper>
        <HeaderSection >
            <h3>{t("Logs")}</h3>
        </HeaderSection>
        <DescriptionSection>
            <Description>
                <TableWrapper>
                    <TableTimestamp/>
                </TableWrapper>
            </Description>
        </DescriptionSection>
    </SectionWrapper>
    );
 
}


