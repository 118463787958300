import { useState } from 'react';
import styled from 'styled-components';
import UserCreationLayout from '../../layouts/userCreation/userCreationLayout';
import Divider from '../../components/divider/divider';
import InputGroup from '../../components/inputGroup/inputGroup';
import Button from '../../components/button/button';
import { Colors } from '../../utilities/colors';
import { useNavigate } from 'react-router-dom';

import { IoIosArrowBack } from 'react-icons/io';
import { validEmailRegex } from '../../utilities/commonFunctions';
import { errorMessages } from '../../utilities/commonMessages';
import ErrorField from '../../components/errorField/errorField';



const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: 650px) {
    display: block;
  }
`;
const LabelText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;

const SignUpBtn = styled(LabelText)`
  color: ${Colors.primary};
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
`;

const FormWrapper = styled.div`
  margin: 10px 0px;
  max-width: 440px;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;


export default function ForgotPassword() {
  const [userDetail, setUserDetail] = useState({
    username: '',
    password: '',
  });

  const [formErrors,] = useState('');
  const [fieldErrors, setErrors] = useState('');

  const { username, } = userDetail;

  const navigate = useNavigate();
  const onNavigate = (path) => {
    navigate(path);
  };

  const inputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'username') {
      const errVal = validEmailRegex.test(value)
        ? ''
        : { [name]: errorMessages.emailField };
      setErrors(errVal);
    }
    
    const newUserDetail = { ...userDetail, [name]: value.toLowerCase() };
    setUserDetail(newUserDetail);
  };
  

  const onLogin = () => {};

  return (
    <UserCreationLayout>
      <HeaderWrapper onClick={() => onNavigate('/login')}>
        <IoIosArrowBack color={Colors.primary} size={20} />
        <SignUpBtn>Back</SignUpBtn>
      </HeaderWrapper>

     
      <Divider />
      <FormWrapper>
        <InputGroup
          name="Email address*"
          err={fieldErrors && fieldErrors['username']}
          input={{
            placeholder: 'Enter email address',
            value: username,
            name: 'username',
            onChange: inputChange,
          }}
          needHelp
          helpText="What’s this?"
          helperFunc={() => onNavigate('/reset')}
        />
        {formErrors && <ErrorField err={formErrors} />}
        <Button
          disabled={fieldErrors}
          onClick={onLogin}
          name="Send Verification Code"
        />
      </FormWrapper>
    </UserCreationLayout>
  );
}
