import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalIsOpen: false,
  cache: null,
};

export const universityModalReducer = createSlice({
  name: "modal",
  initialState,
  reducers: {
    updateModal: (state, action) => {
      if (typeof action.payload === "boolean") {
        state.modalIsOpen = action.payload;
        state.cache = null;
      } else {
        state.modalIsOpen = action.payload.modalIsOpen;
        state.cache = action.payload.cache;
      }
    },
  },
});


export const { updateModal } = universityModalReducer.actions;

export default universityModalReducer.reducer;
