import React, { useLayoutEffect, useRef } from "react";
import ProgressBar from "progressbar.js";


const CircleProgressBar = (props) => {
  const { percent } = props;
  const progressBarRef = useRef(null);
  const progressBarElement = useRef(null);

  useLayoutEffect(() => {
    progressBarElement.current = new ProgressBar.Circle(
      progressBarRef.current,
      {
        color: "#5c5b9d",
        
        strokeWidth: 15,
        trailWidth: 15,
        easing: "easeIn",
        duration: 2500,
        text: {
          autoStyleContainer: false,
        },
        from: { color: "#5C5B9D", width: 13 },
        to: { color: "#FE60B1", width: 15 },
        
        step: function (state, circle) {
          circle.path.setAttribute("stroke", state.color);
          circle.path.setAttribute("stroke-width", state.width);

          var value = Math.round(circle.value() * 100);
          if (value === 0) {
            circle.setText("0%");
          } else {
            circle.setText(value + "%");
          }
        },
      }
    );

    progressBarElement.current.text.style.fontSize = "2rem";
    progressBarElement.current.animate(Number(percent));

    return () => {
      if (progressBarElement.current) {
        progressBarElement.current.destroy();
      }
    };
  });

  return (
    <>
      <div
        className="mx-auto position-relative mb-4"
        style={{ width: "200px", height: "200px" }}
        ref={progressBarRef}
      />
    </>
  );
};

export default CircleProgressBar;
