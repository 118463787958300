import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Mainlayout from "../../layouts/mainLayout/mainlayout";
import { Colors } from "../../utilities/colors";
import InputGroup from "../../components/inputGroup/inputGroup";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactTooltip from "react-tooltip";
import Button from "../../components/button/button";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Select from "react-select";


import PendingPage from "./pendingpage.js";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import TextArea from "../../components/description/description";
import ErrorField from "../../components/errorField/errorField";


import { ReactComponent as AcceptedIcon } from "../../assets/icons/svg/acceptedIcon.svg";
import { ReactComponent as PendingIcon } from "../../assets/icons/svg/pendingIcon.svg";
import { ReactComponent as AllIcon } from "../../assets/icons/svg/all.svg";
import {
  companyUserAction,
  companyUserStatusApi,
  statusDropdownCompanyUser,
  getAdminUserInfo,
} from "../../services/apiCalls";
import AlertModalCompany from "../../components/alertModal/alertModalCompany";
import Loader from "../../components/loader/loader";
import Reject from "../../assets/icons/reject-48.png";
import { useDispatch, useSelector } from "react-redux";
import {
  resetAllCompanyUsers,
  setStatus as setFilterStatus,
} from "./companyUsersFilter";




const ImageDropdown = styled.img`
  width: 20px;
  height: 20px;
`;

const SingleField = styled.div`
  margin-bottom: 30px;
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;


const LabelText = styled.label`
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;
`;

const LightText = styled(LabelText)`
  color: ${Colors.black};
  margin-left: 0vh;
`;




const Message = styled.div`
  padding-left: 20px;
  font-size: 22px;
  padding-bottom: 5px;
`;




const DropdownWrapper = styled.div`
  margin-top: 2rem;
  font-weight: 500;
  font-size: 16px;
`;
const CompanyHead = styled.div``;


export default function Companies() {
  const [selectOptions, setSelectOptions] = useState("all");
  const [updateTime, setUpdateTime] = useState("");

  const [, setPendingpage] = useState(false);

  const [datas, setData] = useState([]);
  const [period,] = useState({
    start_date: "",
    end_date: "",
  });
  
  const [search, setSearch] = useState("");
  
  const [name, setName] = useState("all");
  const [qty, setQty] = useState([]);
  const [status, setStatus] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();
  const [show, setShow] = useState(false);
  const [action, setAction] = useState("");
  const [approve, setApprove] = useState(false);
  const [code, setCode] = useState();
  const [, setOptionValue] = useState();
  const [showOne, setShowNewOne] = useState(false);
  const [deniedArray, setDeniedArray] = useState([]);
  const [pendingArrayNew, setPendingArrayNew] = useState([]);
  const [approvedArray, setApprovedArray] = useState([]);
  const statusFilter = useSelector((state) => state.companyUsersFilter.status);
  

  
  const dispatch = useDispatch();



  let navigate = useNavigate();
  const getDatas = useCallback (async () => {
    var result1 = await getAdminUserInfo();
    
    if (result1.status === "401") {
      navigate("/login");
    } else {
      setShowNewOne(true);
    }
  }, [navigate])

  
  const selectState = useCallback (() => {
  
    if (selectOptions === "pending") {

      setPendingpage(true);
      setName("Pending");

    } else if (selectOptions === "accepted") {
      setPendingpage(true);
      setName("Approved");
    } else if (selectOptions === "rejected") {
      setPendingpage(true);
      setName("Denied");
    } else {
      setPendingpage(true);
      setName("All");
    }
  }, [selectOptions])

  useEffect(() => {

    selectState();

  }, [selectOptions, selectState]);

  useEffect(() => {
    getDatas();
    setOptionValue(statusFilter ? statusFilter:"all");
    statusDropdownApi();
    
    setSelectOptions(statusFilter ? statusFilter:"all");
  }, [getDatas, statusFilter]);

  const options = [
    {
      value: "all",
      label: "All",
      customAbbreviation: qty ? qty.all : null,
      icon: <AllIcon />,
    },
    {
      value: "pending",
      label: "Pending",
      customAbbreviation: qty ? qty.pending : null,
      icon: <PendingIcon />,
    },
    {
      value: "accepted",
      label: "Approved",
      customAbbreviation: qty ? qty.accepted : null,
      icon: <AcceptedIcon />,
    },
    {
      value: "rejected",
      label: "Denied",
      customAbbreviation: qty ? qty.rejected : null,
      icon: <ImageDropdown src={Reject} />,
    },

    
  ];


    const defaultStatusValue=()=>{
    return options?.filter((e)=>{
      return e.value === statusFilter;
    });
  } 


  const setFun = () => {
    setTotal();
    setPage(0);
    setData([]);
    setApprove(!approve);
  };

  const closeModal = () => {
    setShow(false);
  };
 

  const schema = yup.object().shape({
    comments: yup.string().required("Comments is a required field."),
  });
  const {
    control,
    handleSubmit,
    
    formState: { errors, },
    
  } = useForm({
   
    resolver: yupResolver(schema),
    
  });

  const content = () => {
    var ds = false;
    if (action === "rejected") {
      ds = true;
    }
    return (
      <div style={{ padding: "0 40px 20px 40px" }}>
        <Message
          style={{
            textAlign: "center",
            fontSize: "26px",
            fontWeight: "bold",
            margin: "0px auto 10px auto",
          }}
        >
          {ds === true
            ? `Are you sure you would like to deny (Newly added user)?`
            : "Are you sure you want to"}{" "}
          {ds === false ? (action === "accepted" ? "approve" : "deny") : ""}{" "}
          {ds === false ? "this company user ?" : ""}
        </Message>
        {action !== "accepted" ? (
          <form onSubmit={handleSubmit(onRejectionSubmit)}>
            <div className="container">
              <SingleField style={{ marginBottom: 0 }}>
                <FormLabel>Rejection Note</FormLabel>
                <Controller
                  name="comments"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      style={{ background: "#F3F3F3" }}
                      {...field}
                      rows="3"
                      placeholder="Enter Comment"
                    />
                  )}
                />
                {errors.comments && (
                  <ErrorField err={errors.comments.message} />
                )}
              </SingleField>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Button
                  width="180px"
                  name="Yes"
                  type="submit"
                  style={{ marginRight: "30px" }}
                />
                <Button
                  width="180px"
                  name="No"
                  color="#FFFFFF"
                  onClick={() => setShow(false)}
                />
              </div>
            </div>
          </form>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <Button
              width="180px"
              name="Yes"
              onClick={() => approveCompanyUser()}
              style={{ marginRight: "30px" }}
            />
            <Button
              width="180px"
              name="No"
              color="#FFFFFF"
              onClick={() => setShow(false)}
            />
          </div>
        )}
        
      </div>
    );
  };
  const stylesq = {
    control: (base, state) => ({
      ...base,
      height: "45px",
      padding: "5.5px 5px",
      transition: "none",
      textTransform: "capitalize",
      width: "100%",
      zIndex: "100",
      background: `${Colors.light}`,
      border: "0px",
      outline: "none",
      boxShadow: " inset 0px 0px 4px rgba(0, 0, 0, 0)",
      borderRadius: "128px",
      "&:hover": {
        boxShadow: "0 0 0 1px #ff60b1",
        borderColor: "#ff60b1",
      },
    }),
    menu: (base) => ({
      ...base,
      marginTop: "0px",
      outline: "none",
      zIndex: "3",
      textTransform: "capitalize",
      border: "0px solid white",
      transition: "none",
      minWidth: "234px",
    }),
    menuList: (base) => ({
      ...base,
      color: "#575757;",
      fontSize: "12px",
     
      textTransform: "capitalize",
    }),
    option: (provided, state) => ({
      ...provided,
      width: "100%",
      height: "100%",
      fontSize: `14px`,
      
      fontWeight: `500`,
      backgroundColor: state.isSelected ? `#D04C8D` : `transparent`,
      color: state.isSelected ? `white` : `#202020`,
      "&:hover": {
        backgroundColor: state.isSelected ? `#D04C8D` : `#FFE9F4`,
        Color: `#D04C8D`,
      },
    }),
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        color: `#575757; !important`,
      },
    }),
  };
  

  const compo = {
    IndicatorSeparator: () => null,
    dropdownIndicator: () => null,
  };

  const formatOptionLabel = ({ value, label, customAbbreviation, icon }) => (
    <>
      <Row>
        <Col xs={2}>{icon}</Col>
        <Col xs={7}>{label}</Col>
        <Col xs={3} className="text-end">
          {customAbbreviation}
        </Col>
      </Row>
    </>
  );

  
  const handleSelect = (value) => {
    setData([]);
    setSelectOptions(value.value);
    setPage(0);
    dispatch(setFilterStatus(value.value));    
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setTotal();
    setPage(0);
    setData([]);
    setApprove(!approve);
  };

  const handleActionCall = (e, code, actionName, a) => {
    setShow(true);
    setAction(actionName);
    setCode(code);
    setUpdateTime(a);
  };
  const onRejectionSubmit = async (values) => {
    const body = {
      id: code,
      status: action,
      comments: values.comments,
      update_time: updateTime,
    };

    setStatus(true);
    const { data, status } = await companyUserAction(body);

    if (status === 200) {
      setStatus(false);
      setShow(false);
      if (data.status === 1) {
        setStatus(false);
        toast.success(
          data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setFun();
      } else {
        toast.error(
          data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const approveCompanyUser = async () => {
    const body = {
      id: code,
      status: action,
      update_time: updateTime,
    };

    setStatus(true);
    const { data, status } = await companyUserAction(body);

    if (status === 200) {
      setStatus(false);
      setShow(false);
      if (data.status === 1) {
        setStatus(false);
        toast.success(
          data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setFun();
      } else {
        toast.error(
          data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approve]);


  const fetchData = () => {
    console.log(total,"26586");
    if (total) {
      const count = page + 1;
      setPage(count);
      if (count <= total) {
        statusAPiCall(count);
      }
    } else {
      const count = page;
      setPage(count);
      statusAPiCall(count);
    }
  };
  const statusAPiCall = async (count) => {

    const body = {
      page_number: count ? count : page,
      count_per_page: 10,
      search: search,
      filters: {
        status: selectOptions,
        ...(period.start_date && period.end_date
          ? { period: { ...period } }
          : { period: {} }),
      },
    };  

    if (count === 1) {
      setStatus(true);
    }
    const { data, status } = await companyUserStatusApi(body);
    if (status === 200) {

      if (selectOptions === "rejected") {
        setStatus(false);
        setDeniedArray([...deniedArray, ...data.data])
        setTotal(data.pagination.total_pages);
      }

      if (selectOptions === "pending") {
        setStatus(false);
        setPendingArrayNew([...pendingArrayNew, ...data.data])
        setTotal(data.pagination.total_pages);
      }
      if (selectOptions === "accepted") {
        setStatus(false);
        setApprovedArray([...approvedArray, ...data.data])
        setTotal(data.pagination.total_pages);
      }
      setStatus(false);
      
      setData([...datas, ...data.data]);
      setTotal(data.pagination.total_pages);

    }
  };

  useEffect(() => {
    if (selectOptions === "rejected" || selectOptions === "pending" || selectOptions === "accepted" || selectOptions === "all" ) { // UDPATED BY INIVOS : SARITHA
      
      setDeniedArray([]);
      setPendingArrayNew([]);
      setApprovedArray([]);
      setData([]);
      fetchData()
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions])

  useEffect(() => {
      return ()=>{
      if (window.location.href.indexOf("companiesusers") === -1 && window.location.href.indexOf("companiesuserslist") === -1) {
       dispatch(resetAllCompanyUsers());
      }
      }
  }, [dispatch]);

  const statusDropdownApi = async () => {
    const { data, status } = await statusDropdownCompanyUser();
    if (status === 200) {
      if (data.status === 1) {
        setQty(data.data);
      }
    }
  };

  return (
    <Mainlayout>
      {showOne ? (
        <CompanyHead>

          <LightText> Manage Company Users </LightText>
          <ReactTooltip
            id="search"
            globalEventOff="focusin"
            place="top"
            effect="solid"
          >
            Search Company user by name
          </ReactTooltip>
         
          <Row>
            <Col lg="8" xl="8">
              <InputGroup
                dataId="search"
                input={{
                  placeholder:
                    " Search keywords for Company Name or Company User Name",
                  name: "search",
                  onChange: handleSearch,
                  value: search,
                }}
                type="text"
              />
            </Col>
            <Col lg="4" xl="4">
              <DropdownWrapper>
                <Select
                  
                  formatOptionLabel={formatOptionLabel}
                  options={options}
                  components={compo}
                  styles={stylesq}
                  onChange={handleSelect}
                  defaultValue={statusFilter ? defaultStatusValue:"all"}
                  val={statusFilter ? defaultStatusValue : options[0]}
                />
              </DropdownWrapper>
            </Col>
          </Row>
          
          <InfiniteScroll
            dataLength={datas.length}
            next={fetchData}
            hasMore={true}
           
            loader={page === 1 ? '' : page <= total ? <Loader /> : ''}
            scrollThreshold={0.5}
          >
            
            <PendingPage
              name={selectOptions}
              title={name}
              value={datas} 
              deniedArray={deniedArray} 
              pendingArrayNew={pendingArrayNew} 
              approvedArray={approvedArray} 
              handleApprove={handleActionCall}
              loading={status}
            />

          </InfiniteScroll>
          <AlertModalCompany
            show={show}
            close={closeModal}
            content={content()}
          />
        </CompanyHead>
      ) : (
        <Loader />
      )}
    </Mainlayout>
  );
}
