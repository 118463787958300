import ApplicantsHeader from './ApplicantsHeader';
import Applicants from './Applicants';
import styled from 'styled-components';
import { Colors } from '../../utilities/colors';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
const FormWrapper = styled.div`
  margin: 25px 0px;
  padding: 25px 70px 29px 63px;
  background: #ffffff;
  border-radius: 10px;

  > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin: 18px 0px;
    color: ${Colors.labelText};
  }
  > p1 {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: ${Colors.labelText};
  }
  > p2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: ${Colors.labelText};
  }
`;

const HrLine = styled.hr`
  border: 0.6px solid #c4c4c4;
  margin: 10px auto;
`;

const HeaderTitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
`;

export default function Application(props) {
  const applicants = props.applicantData;
  const data = props.data;
  const handleApproveAddNotes = props.handleApproveAddNotes;
  const { t, } = useTranslation();
  return (
    <FormWrapper>
      <ApplicantsHeader data={props.data} />
      
      <HrLine />

      {applicants.length <= 0 ? (
        <Row className="my-2">
          <Col sm={3}>
            <HeaderTitle>{t("Applicants")}</HeaderTitle>
          </Col>
          <Col sm={3} className="d-flex justify-content-center">
            <HeaderTitle>{t("Joining Date")}</HeaderTitle>
          </Col>
          <Col sm={3} className="d-flex justify-content-center">
            <HeaderTitle>{t("Leaving Date")}</HeaderTitle>
          </Col>
          <Col sm={3} className="d-flex justify-content-center">
            <HeaderTitle>{t("Status")}</HeaderTitle>
          </Col>
        </Row>
      ) : (
        <Row className="my-2">
          <Col sm={12} md={5} xd={6}>
            <HeaderTitle>{t("Applicants")}</HeaderTitle>
          </Col>
          <Col sm={12} md={2} xd={2} className="d-flex justify-content-center">
            <HeaderTitle>{t("Joining Date")}</HeaderTitle>
          </Col>
          <Col sm={12} md={2} xd={2} className="d-flex justify-content-center">
            <HeaderTitle>{t("Leaving Date")}</HeaderTitle>
          </Col>
          <Col sm={12} md={3} xd={5} className="d-flex justify-content-center">
            <HeaderTitle>{t("Status")}</HeaderTitle>
          </Col>
        </Row>
      )}
      {applicants.length <= 0 ? (
        <p className="d-flex justify-content-center">{t("No Applicant found")}</p>
      ) : (
        applicants.map((applicant, key) => {
          return (
            <>
              <Applicants
                key={applicant.emailId + key}
                data={applicant}
                id={data?.id}
                joiningDate={applicant.joining_date}
                leavingDate={applicant.leaving_date}
                handleApproveAddNotes={handleApproveAddNotes}
                handleIdNotes={props.handleIdNotes}
              />
              {key !== applicants.length - 1 ? <HrLine /> : null}
            </>
          );
        })
      )}
    </FormWrapper>
  );
}
