import React from 'react';
import Select from 'react-select';

class Dropdown extends React.Component {
  customStyles = {
    dropdownIndicator: this.dropdownIndicatorStyles,
    control: (base, state) => ({
      ...base,
      padding: '5.5px 5px',
      transition: 'none',
      textTransform: 'capitalize',
      width: '100%',
      background: this.props.bgWhite ? '#ffffff' : '#f3f3f3',
      minWidth: '234px',
      border: '0px',
      outline: 'none',
      boxShadow: ' inset 0px 0px 4px rgba(0, 0, 0, 0)',
      borderRadius: '128px',
      '&:hover': {
        
      },
    }),
    menu: (base) => ({
      ...base,
      marginTop: '0px',
      outline: 'none',
      zIndex: '3',
      textTransform: 'capitalize',
      border: '0px solid white',
      transition: 'none',
      minWidth: '234px',
    }),
    menuList: (base) => ({
      ...base,
      color: '#575757;',
      fontSize: '14px',
      minWidth: '234px',
      textTransform: 'capitalize',
      
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? `#D04C8D` : `transparent`,
      color: state.isSelected ? `white` : `#202020`,
      '&:hover': {
        backgroundColor: state.isSelected ? `#D04C8D` : `#FFE9F4`,
        Color: `#D04C8D`,
      },
    }),

    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        color: `#575757 !important`,
      },
    }),
  };

  state = {
    selectedOption: null,
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };
  render() {
    
    const loading = this.props.options
      ? this.props.options.length > 0
        ? false
        : true
      : true;
    return (
      <div data-tip data-for={this.props.dataId ? this.props.dataId : ''}>
        <Select
          className={this.props.classname}
          
          styles={this.customStyles}
          
          value={this.props.value}
          onInputChange={this.props.inputChange}
          onChange={this.props.change}
          options={this.props.options}
          isSearchable
          isLoading={
            this.props.showLoader !== undefined
              ? this.props.showLoader
              : loading
          }
          isDisabled={this.props.disable}
          placeholder={this.props.placeholder}
          onFocus={this.props.onFocus}
          menuIsOpen={this.props.menuIsOpen}
          
          getOptionLabel={this.props.getOptionLabel}
          bgWhite={this.props.bgWhite}
        />
      </div>
    );
  }
}

export default Dropdown;
