export const staticContent = {
  login: {
    name: 'Welcome to mynext Admin',
    description:
      'A proprietary national smart system designed for professional development.',
    descriptionStyle: {
      fw: 'normal',
      lh: '25px',
      color: '#707070',
    },
    nameStyle: {
      size: '30px',
      lh: '41px',
    },
  },
  

  verify: {
    name: 'Verify it’s you',
    
    descriptionStyle: {
      fw: '500',
      lh: '22px',
      color: '#858585',
      fwv: '400',
    },
    nameStyle: {
      size: '28px',
      lh: '38px',
    },
  },
};
