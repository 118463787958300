import styled from 'styled-components';

const Label = styled.label`
  color: red;
  font-size: 12px;
  margin: 0;
  margin-bottom: 5px;
  font-weight: 500;
`;

export default function ErrorField({ err }) {
  return <Label>{err}</Label>;
}
