import React from 'react';
import MainLayout from '../../../layouts/mainLayout/mainlayout';
import DoghnutChart from '../../../components/analyticalDashboard/charts/doughnutChart.js';
import HorizontalBarChart from '../../../components/analyticalDashboard/charts/horizontalBarChart.js';
import { Link } from "react-router-dom";
import { onApiCall } from '../../../services/CommonApi';
import { withTranslation } from "react-i18next";

class Nationality extends React.Component {

  constructor(props) {

    super(props);
    this.state = { usersByNationality: {}, usersByGender: {}, usersByEthnicity: {}, usersOtherNationalities: {} }

  }

  componentDidMount() {

    onApiCall({
      url: `/api/dashboard/analytical/demographics/nationality/usersByNationality/`,
      method: 'GET'
    }).then(res => {
      if (res.status === 200) {
        this.setState({ usersByNationality: res.data.data.results });
      }
    })

    onApiCall({
      url: `/api/dashboard/analytical/demographics/nationality/usersByGender/`,
      method: 'GET'
    }).then(res => {
      if (res.status === 200) {
        this.setState({ usersByGender: res.data.data.results });
      }
    })

    onApiCall({
      url: `/api/dashboard/analytical/demographics/nationality/usersByEthnicity/`,
      method: 'GET'
    }).then(res => {
      if (res.status === 200) {
        this.setState({ usersByEthnicity: res.data.data.results });
      }
    })

    onApiCall({
      url: `/api/dashboard/analytical/demographics/nationality/usersOtherNationalities/`,
      method: 'GET'
    }).then(res => {
      if (res.status === 200) {
        this.setState({ usersOtherNationalities: res.data.data.results });
      }
    })
  }

  formatDoghnut(item) {

    const data = { "labels": [], "datasets": [{ "data": [], "backgroundColor": ["#77c7e8", "#4571b0"] }] }

    for (const [key, value] of Object.entries(item)) {

      data.labels.push(key)

      data.datasets[0].data.push(value)

    }

    return data

  }

  format(item, color) {

    const data = { "labels": [], "datasets": [{ "data": [], "backgroundColor": [color] }] }

    for (const [, value] of Object.entries(item)) {

      data.labels.push(value['_id'][0])

      data.datasets[0].data.push(value['total'])

    }

    return data

  }

  render() {
    const { t } = this.props;

    return (
      <MainLayout>

        <div className="d-flex mb-2" style={{ fontSize: '1.1rem', fontWeight: '400' }}>

          <Link to="/analyticaldashboard" style={{ textDecoration: 'none', color: '#ACACAC' }}>{t("Analytical Dashboard")}</Link>

          <div className='mx-2' style={{ color: '#ACACAC' }}> > </div>

          <Link to="/analyticaldashboard/demographics" style={{ textDecoration: 'none', color: '#ACACAC' }}>{t("Demographics")}</Link>

          <div className='mx-2' style={{ color: '#ACACAC' }}> > </div>

          <span style={{ textDecoration: 'none', color: '#D04C8D' }}>{t("Nationality and Ethnicity")}</span>

        </div>

        <div className='row'>

          <div className='col-12 col-lg-6 col-xl-4 mb-4'>

            <div className='row'>

              <div className='col-12 mb-4'>

                <div className='p-4 text-center bg-white rounded shadow' style={{ minHeight: '100%' }}>

                  <h3 className='mb-3' style={{ fontWeight: '300' }}>{t("NATIONALITY")}</h3>

                  <DoghnutChart data={this.formatDoghnut(this.state.usersByNationality)} />

                </div>

              </div>

              <div className='col-12'>

                <div className='p-4 text-center bg-white rounded shadow' style={{ minHeight: '100%' }}>

                  <h3 className='mt-5 mb-3' style={{ fontWeight: '300' }}>{t("GENDER")}</h3>

                  <DoghnutChart data={this.formatDoghnut(this.state.usersByGender)} />

                </div>

              </div>

            </div>

          </div>

          <div className='col-12 col-lg-6 col-xl-4 mb-4'>

            <div className='p-4 text-center bg-white rounded shadow' style={{ minHeight: '100%' }}>

              <h3 className='mb-3' style={{ fontWeight: '300' }}>{t("ETHNICITY")}</h3>

              <p className='text-secondary mb-4'>{t("Talents that selected “Malaysian” as their nationality have selected the following ethnicities:")}</p>

              <HorizontalBarChart data={this.format(this.state.usersByEthnicity, '#fbd185')} />

            </div>

          </div>

          <div className='col-12 col-lg-6 col-xl-4 mb-4'>

            <div className='p-4 text-center bg-white rounded shadow' style={{ minHeight: '100%' }}>

              <h3 className='mb-3' style={{ fontWeight: '300' }}>{t("OTHER NATIONALITIES")}</h3>

              <p className='text-secondary mb-4'>{t("Talents that selected “Non-Malaysian” have declared the following nationalities:")}</p>

              <HorizontalBarChart data={this.format(this.state.usersOtherNationalities, '#6aa1c7')} />

            </div>

          </div>

        </div>
      </MainLayout>
    );

  }

}

export default withTranslation()(Nationality);
