import React, { useState, useEffect, useCallback } from 'react';

import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';

import { Colors } from '../../utilities/colors';
import CustomModal from '../modal/modal';
import Input from '../input/input';
import Button from '../button/button';
import { schema } from './staticContent';
import ErrorField from '../errorField/errorField';
import {
  onUpdateCompanyDatas,
  getCompanyData,
  getCountriesMasterList,
  getSatesMasterList,
  getCitieMasterList,
} from '../../services/apiCalls';
import { updateCompanyData } from '../../pages/companies/viewDetails/reducer';
import Loader from '../loader/loader';
import Description from '../description/description';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TextField from '@mui/material/TextField';
import Dropdown from '../dropdown/dropdown';
import RichTextEditor from 'react-rte';
import {
  CancelAndExit,
} from '../../pages/analyticalDashboardPage/commonStyles';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ModalWrapper = styled.div`
  padding: 10px;
  min-width: 420px;
  > h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 3px;
    color: ${Colors.primaryText};
    margin: 0;
    margin-bottom: 10px;
  }
  @media (max-width: 500px) {
    padding: 0px;
    min-width: 15px;
  }
`;
const FormWrapper = styled.div`
  @media (max-width: 500px) {
    width: 200px;
  }
`;

const SingleField = styled.div`
  margin-bottom: 20px;
  @media (max-width: 500px) {
    overflow-x: auto;
  }
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.primaryText};
  margin-bottom: 10px;
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;



export default function UpdateCompany(props) {
  const onClose = props.onClose;
  const company = useSelector((state) => state.company.companyData);
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('code');

  

  const defaultVal = {
    overview: company && company.overview ? company.overview : '',
    website: company && company.website ? company.website : '',
    created_at: company && company.created_at ? company.created_at : '',
    org_size: company && company.org_size ? company.org_size : '',
    bsn_city: company && company.bsn_city ? company.bsn_city : '',
    bsn_address: company && company.bsn_address ? company.bsn_address : '',
    bsn_state: company && company.bsn_state ? company.bsn_state : '',
    bsn_country: company && company.bsn_country ? company.bsn_country : '',
    founded: company && company.founded ? company.founded : '',
    bsn_nature: company && company.bsn_nature ? company.bsn_nature : '',
    country: company && company.country ? company.country : '',
    bsn_country_code: company && company.bsn_country_code ? company.bsn_country_code : '',
    update_time: company && company.update_time ? company.update_time : '',
  };

  const [loading, setLoading] = useState(false);
  const [editor, setEditor] = useState(
    RichTextEditor.createValueFromString(company.overview ?? ' ', 'html')
  );
  const [formErrors, setFormErrors] = useState('');
  const [value,] = React.useState(null);
 
  

  const [countryList, setCountryList] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState([]);

  const [stateList, setStateList] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState([]);

  const [cityList, setCityList] = React.useState([]);
  const [selectedCity, setSelectedCity] = React.useState([]);

  
  console.log(company, 'hai iam fawaz k ');
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, },
  } = useForm({
    defaultValues: { ...defaultVal },
    resolver: yupResolver(schema),
  });

  const formVal = watch();
  console.log(formVal, 'hai iam fawaz k');
  const dispatch = useDispatch();

  const onSubmit = async () => {
    setLoading(true);
    console.log(defaultVal.update_time, 'ssssssssssssssssssssssssssssss');
    formVal.overview = editor.toString('html');
    const { data, } = await onUpdateCompanyDatas({
      code: code,
      data: { ...formVal },
      update_time: defaultVal.update_time,
    });
    if (data && data.status === 1) {
      getData();
      props.onClose();
      toast.success(t("Details updated successfully."), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFormErrors('');
    } else {
      setLoading(false);
      setFormErrors(data && data.message);
    }
  };

  const getData = useCallback (async () => {
    const { data, } = await getCompanyData({
      code: code,
    });
    if (data && data.status === 1) {
      setLoading(false);
      dispatch(updateCompanyData(data.data));
    } else {
      setLoading(false);
    }
    console.log(company.country);
    setValue('bsn_country', defaultVal.bsn_country);
    setValue('bsn_state', defaultVal.bsn_state);
    setValue('bsn_city', defaultVal.bsn_city);
  }, [defaultVal.bsn_country, defaultVal.bsn_state, defaultVal.bsn_city, code, company.country, dispatch, setValue])

  const fetchCounty = useCallback (async () => {
    const { data, status } = await getCountriesMasterList();
    if (status === 200) {
      if (data.status === 1) {
        const countryList = data.data;
        const country = countryList.map((x) => {
          return { ...x, label: x.Name, value: x.Id };
        });
        setCountryList(country);
        if (formVal.bsn_country) {
          let selCountry = country.filter(
            (x) => x.value === formVal.bsn_country
          );
          if (selCountry.length > 0) {
            selCountry = selCountry[0];
            setValue('bsn_country', selCountry.value);
          }
          setSelectedCountry(selCountry);
        }
      }
    }
  },[formVal.bsn_country, setValue])

  const fetchState = useCallback (async (CountryId) => {
    const { data, status } = await getSatesMasterList(CountryId);
    if (status === 200) {
      if (data.status === 1) {
        const StateList = data.data;
        const state = StateList.map((x) => {
          return { ...x, label: x.Name, value: x.Id };
        });
        setStateList(state);
        if (formVal.bsn_state) {
          let selState = state.filter((x) => x.value === formVal.bsn_state);
          if (selState.length > 0) {
            selState = selState[0];
            setValue('bsn_state', selState.value);
          }
          setSelectedState(selState);
        }
      }
    }
  }, [formVal.bsn_state, setValue])

  const fetchSavedState = useCallback (() => {
    if (formVal.bsn_country) {
      fetchState(formVal.bsn_country);
    }
  }, [formVal.bsn_country, fetchState])

  const fetchCity = useCallback (async (CountryId, StateId) => {
    const { data, status } = await getCitieMasterList(CountryId, StateId);
    if (status === 200) {
      if (data.status === 1) {
        const CityList = data.data;
        const city = CityList.map((x) => {
          return { ...x, label: x.Name, value: x.Id };
        });
        setCityList(city);
        if (formVal.bsn_city) {
          let selCity = city.filter((x) => x.value === formVal.bsn_city);
          if (selCity.length > 0) {
            selCity = selCity[0];
            setValue('bsn_city', selCity.value);
          }
          setSelectedCity(selCity);
        }
      }
    }
  }, [formVal.bsn_city, setValue])

  const fetchSavedCity = useCallback (() => {
    if (formVal.bsn_country || formVal.bsn_state) {
      fetchCity(formVal.bsn_country, formVal.bsn_state);
    }
  }, [fetchCity, formVal.bsn_country, formVal.bsn_state])

  useEffect(() => {
    fetchCounty();
    getData();
    fetchSavedState();
    fetchSavedCity();
  }, [fetchCounty, getData, fetchSavedCity, fetchSavedState]);

  

  const dropdownChange = (name, val) => {
    console.log(name, val.value, 'name, val,name, val,name, val,');
    setValue(name, val.value);
    switch (name) {
      default:
        break;
      case 'bsn_country':
        setSelectedCountry(val);
       
        setValue('bsn_state', '');
        setValue('bsn_city', '');
        fetchState(val.value);
        break;
      case 'bsn_state':
        setSelectedState(val);
        
        setValue('bsn_city', '');
        fetchCity(formVal.bsn_country, val.value);
        break;
      case 'bsn_city':
        setSelectedCity(val);
        
        break;
    }
  };

 
  const { t, } = useTranslation();

  return (
    <CustomModal open={props.open} onCloseModal={props.onClose}>
      <ModalWrapper>
        <Grid container>
          <Grid item lg={12}>
            <h3 style={{ color: '#D04C8D' }}>{t("Overview")}</h3>
            <hr />
            <FormWrapper>
              <RichTextEditor value={editor} onChange={setEditor} />
              

              <Grid container>
                <Grid item lg={4}>
                  <SingleField style={{ marginRight: '2%' }}>
                    <FormLabel>{t("Website")}</FormLabel>
                    <Controller
                      name="website"
                      control={control}
                      render={({ field }) => (
                        <Input
                          style={{ background: '#f3f3f3' }}
                          {...field}
                          placeholder={t("Website")}
                        />
                      )}
                    />
                    {errors.website && (
                      <ErrorField err={t(errors.website.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ marginLeft: '2%' }}>
                    <FormLabel>{t("SSM Registration Date")}</FormLabel>
                    
                       <Controller
                      name="created_at"
                      readOnly={true}
                      disabled={true}
                      control={control}
                      value={value ?? defaultVal.created_at}
                      render={({ field }) => (
                          <div className='d-flex'>
                          <TextField readOnly={true} disabled={true} variant="standard" {...field} />
                          <CalendarTodayIcon style={{marginTop:"10",color:'gray'}} />
                          </div>
                     
                      )}
                    />
                    

                    {errors.created_at && (
                      <ErrorField err={t(errors.created_at.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ marginLeft: '2%' }}>
                    <FormLabel>{t("Company Size")}</FormLabel>
                    <StatusWrapper>
                      <Controller
                        name="org_size"
                        control={control}
                        render={({ field }) => (
                          <Input
                            style={{ background: '#f3f3f3' }}
                            {...field}
                            type="number"
                            placeholder={t("Size")}
                          />
                        )}
                      />
                    </StatusWrapper>
                    {errors.org_size && (
                      <ErrorField err={t(errors.org_size.message)} />
                    )}
                  </SingleField>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item lg={8}>
                  <SingleField style={{ marginRight: '2%' }}>
                    <FormLabel>{t("Address")}*</FormLabel>
                    <Controller
                      name="bsn_address"
                      control={control}
                      render={({ field }) => (
                        <Input
                          style={{ background: '#f3f3f3' }}
                          {...field}
                          placeholder={t("Address")}
                        />
                      )}
                    />
                    {errors.bsn_address && (
                      <ErrorField err={t(errors.bsn_address.message)} />
                    )}
                  </SingleField>
                </Grid>
                

                <Grid item lg={4}>
                  <SingleField style={{ marginLeft: '2%' }}>
                    <FormLabel>{t("Postcode")}*</FormLabel>
                    <Controller
                      name="bsn_country_code"
                      control={control}
                      render={({ field }) => (
                        <Input
                          style={{ background: '#f3f3f3' }}
                          {...field}
                          placeholder={t("country_code")}
                        />
                      )}
                    />
                    {errors.bsn_country_code && (
                      <ErrorField err={t(errors.bsn_country_code.message)} />
                    )}
                  </SingleField>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item lg={4}>
                  <SingleField style={{ margin: '1%' }}>
                    <FormLabel>{t("Country")}*</FormLabel>
                    <Controller
                      name="bsn_country"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          search={true}
                          options={countryList}
                          placeholder={t("Enter country")}
                          val={selectedCountry?.value}
                          label={selectedCountry?.label}
                          change={(val) => dropdownChange('bsn_country', val)}
                          {...field}
                        />
                        
                      )}
                    />
                    {errors.bsn_country && (
                      <ErrorField err={t(errors.bsn_country.message)} />
                    )}
                  </SingleField>
                </Grid>
                <Grid item lg={4}>
                  <SingleField style={{ margin: '1%' }}>
                    <FormLabel>{t("State")}*</FormLabel>
                    <Controller
                      name="bsn_state"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          search={true}
                          options={stateList}
                          placeholder={t("Enter Location")}
                          minWidth="auto"
                          val={selectedState?.value}
                          label={selectedState?.label}
                          change={(val) => dropdownChange('bsn_state', val)}
                          {...field}
                        />
                      )}
                    />
                    {errors.bsn_state && (
                      <ErrorField err={t(errors.bsn_state.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ margin: '1%' }}>
                    <FormLabel>{t("City")}*</FormLabel>
                    <Controller
                      name="bsn_city"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          search={true}
                          options={cityList}
                          placeholder={t("Enter Location")}
                          minWidth="auto"
                          val={selectedCity?.value}
                          label={selectedCity?.label}
                          change={(val) => dropdownChange('bsn_city', val)}
                          {...field}
                        />
                      )}
                    />
                    {errors.bsn_city && (
                      <ErrorField err={t(errors.bsn_city.message)} />
                    )}
                  </SingleField>
                </Grid>
              </Grid>

              <SingleField>
                <FormLabel>{t("Nature of Business")}*</FormLabel>
                <Controller
                  name="bsn_nature"
                  control={control}
                  render={({ field }) => (
                    <Description
                      style={{ background: '#f3f3f3' }}
                      rows="5"
                      {...field}
                      placeholder={t("bsn_nature")}
                    />
                  )}
                />
                {errors.bsn_nature && (
                  <ErrorField err={t(errors.bsn_nature.message)} />
                )}
              </SingleField>
              <Grid
                 container
                 style={{
                   display: "flex",
                   alignItems: "end",
                   justifyContent: "end",
                 }}>
              {formErrors && <ErrorField err={t(formErrors)} />}
              {loading ? (
                <Loader />
              ) : (
                <Button
                style={{
                  borderRadius: '128px',
                  marginLeft: '10px',
                  width: '200px',
                  padding: "13px 20px",
                  borderColor: '#D04C8D',
                }}
                  name={t("Confirm")}
                  onClick={handleSubmit(onSubmit)}
                />
              )}
              <CancelAndExit
                style={{width:"20%",marginLeft:"10px"}}
                type="button"
                className="cancelBtn"
                onClick={onClose}
                >
                {t("Cancel")}
              </CancelAndExit>
                 </Grid>
            </FormWrapper>
          </Grid>
        </Grid>
      </ModalWrapper>
    </CustomModal>
  );
}
