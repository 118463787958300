import { useCallback, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import MainLayout from '../../../layouts/mainLayout/mainlayout';
import InterShipCard from '../../../components/interShipCard/interShipCard';
import InternIntroSection from '../../../components/internIntoSection/internIntroSection';
import InternShipDetails from '../../../components/internShipDetails/internShipDetails';
import { ongettingInternInfo } from '../../../services/apiCalls';
import Loader from '../../../components/loader/loader';


const FormWrapper = styled.div`
  margin: 25px 0px;
  padding: 25px 70px 29px 63px;
  background: #ffffff;
  border-radius: 10px;

  > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin: 18px 0px;
  }
  > p1 {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
  }
  > p2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
`;

export default function ViewInternship() {
  const [loading, setLoading] = useState(false);
  const [showApplicants, setShowApplicants] = useState(false);
  const [internData, setInternData] = useState('');

  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  const code = new URLSearchParams(search).get('code');
 

  const getInternData = useCallback (async () => {
    setLoading(true);
    const { data, } = await ongettingInternInfo({
      id: id,
      code: code,
    });
    if (data && data.status === 1) {
      setInternData(data.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [code, id])

  useEffect(() => {
    if (id && code) {
      getInternData();
    }
  }, [id, code, getInternData]);

  

  const setShowApplicantStatus = () => {
    setShowApplicants(true);
  };

  return (
    <MainLayout>
      {loading ? (
        <Loader />
      ) : !showApplicants ? (
        <FormWrapper>
          <InternIntroSection data={internData} />
          <InterShipCard />
          <InternShipDetails
            data={internData}
            setShowApplicants={setShowApplicantStatus}
          />
        </FormWrapper>
      ) : (
        <></>
      )}
    </MainLayout>
  );
}
