import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Mainlayout from "../../layouts/mainLayout/mainlayout";
import InputGroup from "../../components/inputGroup/inputGroup";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactTooltip from "react-tooltip";
import { Row, Col } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import ResultTable from "./resultTable";
import { Colors } from '../../utilities/colors';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  ImageDropdown,
  CompanyHead,
  LightText,
  DropdownWrapper,
  stylesq,
} from "./companyUserListStyles";


import {
  companyUserStatusApi,
  statusDropdownCompanyUser,
  getAdminUserInfo,
} from "../../services/apiCalls";
import AlertModalCompany from "../../components/alertModal/alertModalCompany";
import Loader from "../../components/loader/loader";


import Reject from "../../assets/icons/reject-48.png";
import { ReactComponent as AcceptedIcon } from "../../assets/icons/svg/acceptedIcon.svg";
import { ReactComponent as PendingIcon } from "../../assets/icons/svg/pendingIcon.svg";
import { ReactComponent as AllIcon } from "../../assets/icons/svg/all.svg";


import { debounce } from "../../utilities/commonFunctions";
import ApprovalAlertBody from "./approvalAlertBody";
import DeniedAlertBody from "./deniedAlertBody";
import {
  resetAllCompanyUsers,
  setStatus as setFilterStatus
  
} from "./companyUsersFilter";

import {
  resetAllCompanies
} from "../companies/viewDetails/companyFilter";



export default function Companies() {
  
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const [totalRecords, setTotalRecords] = useState(0);
  const [filterOptions, setFilterOptions] = useState([]);


  const [dropdownFilterSync, setDropdownFilterSync] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermTemp, setSearchTermTemp] = useState("");
  const [syncing, setSyncing] = useState(false);

  const [firstSync, setFirstSync] = useState(true);

  const [show, setShow] = useState(false);
  const [approvalAction, setApprovalAction] = useState(0);

  const [selectedUserId, setSelectedUserId] = useState();
  const [updateTime, setUpdateTime] = useState();
  const dispatch = useDispatch();

  

  const [selectedOption, setSelectedOption] = useState("all");
  const [selectedOptionLable, setSelectedOptionLable] = useState("All");
  const { t, } = useTranslation();

  let navigate = useNavigate();

  
  const checkAdminPrevilages = async () => {

    var admin = await getAdminUserInfo();
    
    if (admin.status === "401") {

      navigate("/login");

    }

  };


  
  const fetchData = async () => {

    const body = {
      page_number: currentPage + 1,
      count_per_page: 10,
      search: searchTerm,
      filters: {
        status: selectedOption, 
        period: {},
      },
    };

    setSyncing(true);

    
    const { data, status } = await companyUserStatusApi(body);

    setSyncing(false);
    setFirstSync(false);

    var tempusers = [...users];

    if (status === 200) {
      for (let index = 0; index < data.data.length; index++) {
        const element = data.data[index];
        tempusers.push(element);
      }

      setUsers(tempusers);
      setTotalRecords(data.pagination.total_counts);
      setCurrentPage(data.pagination.current_page);
    } else {
      setUsers([]);
    }

  };



  const getFilterOptions = async () => {
    setDropdownFilterSync(true);

    const { data, status } = await statusDropdownCompanyUser();
    if (status === 200) {
      if (data.status === 1) {
        const tempOptions = [
          {
            value: "all",
            label: t("All"),
            customAbbreviation: data.data.all ? data.data.all : null,
            icon: <AllIcon />,
          },
          {
            value: "pending",
            label: t("Pending"),
            customAbbreviation: data.data.pending ? data.data.pending : null,
            icon: <PendingIcon />,
          },
          {
            value: "accepted",
            label: t("Approved"),
            customAbbreviation: data.data.accepted ? data.data.accepted : null,
            icon: <AcceptedIcon />,
          },
          {
            value: "rejected",
            label: t("Denied"),
            customAbbreviation: data.data.rejected ? data.data.rejected : null,
            icon: <ImageDropdown src={Reject} />,
          },
        ];
        setFilterOptions(tempOptions);
      }
    }
    setDropdownFilterSync(false);
  };

  const formatOptionLabel = ({ value, label, customAbbreviation, icon }) => (
    <>
      <Row>
        <Col xs={2}>{icon}</Col>
        <Col xs={7}>{label}</Col>
        <Col xs={3} className="text-end">
          {customAbbreviation}
        </Col>
      </Row>
    </>
  );

  const compo = {
    IndicatorSeparator: () => null,
    dropdownIndicator: () => null,
  };

  const handleSelect = (value) => {
    setUsers([]);
    setTotalRecords(10);
    setCurrentPage(0);
    setFirstSync(true);
    setSelectedOptionLable(value.label);
    dispatch(setFilterStatus(value.value));
    setSelectedOption(value.value);
  };

  const showAlert = (action, id, update_time) => {
    setShow(true);
    setApprovalAction(action);
    setSelectedUserId(id);
    setUpdateTime(update_time);
  };

  const closeAlert = () => {
    setShow(false);
    getFilterOptions();
  };

  
  useEffect(() => {
    fetchData();
    
    if (filterOptions.length === 0) {
      
      
      checkAdminPrevilages();
      getFilterOptions();

    }
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, searchTerm]);


    useEffect(() => {
      dispatch(resetAllCompanies());
      return ()=>{
      if (window.location.href.indexOf("companiesusers") === -1 && window.location.href.indexOf("companiesuserslist") === -1) {
       dispatch(resetAllCompanyUsers());
      }
      }
    }, [dispatch]);


  const handleSearch = (e) => {
    setSearchTermTemp(e.target.value);
    debounceChangesetSearch(e.target.value);
  };

  const setSerch = (value) => {
    setUsers([]);
    setTotalRecords(10);
    setFirstSync(true);
    setCurrentPage(0);
    setSearchTerm(value);
  };
 // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangesetSearch = useCallback(debounce(setSerch, 1000), []);

  

  const NoData = styled.div`
  font-size: 20px;
  width: 100%;
  background: ${Colors.secondaryColor};
  color: black;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;
  
  

  return (
    <Mainlayout>
      <CompanyHead>
        <LightText> {t("Manage Company Users")} </LightText>
        <ReactTooltip
          id="search"
          globalEventOff="focusin"
          place="top"
          effect="solid"
        >
          {t("Search Company user by name")}
        </ReactTooltip>
        <Row>
          <Col lg="8" xl="8">
            <InputGroup
              dataId="search"
              input={{
                placeholder:
                  t("Search keywords for Company Name or Company User Name"),
                name: "search",
                onChange: handleSearch,
                value: searchTermTemp,
              }}
              type="text"
            />
          </Col>
          <Col lg="4" xl="4">
            <DropdownWrapper>
              <Select
                search={true}
                formatOptionLabel={formatOptionLabel}
                options={filterOptions}
                components={compo}
                styles={stylesq}
                onChange={handleSelect}
                defaultValue={{
                  value: "all",
                  label: t("All"),
                  customAbbreviation: null,
                  icon: <AllIcon />,
                }}
                val={selectedOption}
                isLoading={dropdownFilterSync}
                isDisabled={syncing}
              />
            </DropdownWrapper>
          </Col>
        </Row>
        <InfiniteScroll
          dataLength={users.length}
          next={fetchData}
          hasMore={totalRecords > currentPage * 10 ? true : false}
          loader={syncing ? <Loader /> : ""}
        >
          <ResultTable
            data={users}
            title={selectedOptionLable}
            showAlert={showAlert}
          ></ResultTable>
          {firstSync ? <Loader /> : ""}
        </InfiniteScroll>
        <AlertModalCompany
          show={show}
          close={closeAlert}
          content={
            approvalAction === 1 ? (
              <ApprovalAlertBody
                close={closeAlert}
                user_id={selectedUserId}
                update_time={updateTime}
                users={users}
                setUsers={setUsers}
              />
            ) : (
              <DeniedAlertBody
                close={closeAlert}
                user_id={selectedUserId}
                update_time={updateTime}
                users={users}
                setUsers={setUsers}
              />
            )
          }
        />
        {users.length === 0 && syncing === false && firstSync === false ?
        <NoData>
                  <p class="noDataFound">No data found</p>
        </NoData>
        : ''}
      </CompanyHead>
    </Mainlayout>
  );
}
