import React from 'react';
import MainLayout from '../../../layouts/mainLayout/mainlayout';
import DoubleBarChart from '../../../components/analyticalDashboard/charts/doubleBarChart.js';
import HorizontalBarChart from '../../../components/analyticalDashboard/charts/horizontalBarChart.js';
import { Link } from "react-router-dom";
import { onApiCall } from '../../../services/CommonApi';
import { withTranslation } from "react-i18next";

class SignupInfo extends React.Component {

  constructor(props) {

    super(props);
    this.state = { monthlySignups: {}, weeklySignups: {}, weeklyCompanySignups: {}, companiesStatus: {}, userStatusPerUniversity: {} }

  }

  componentDidMount() {

    onApiCall({
      url: `/api/dashboard/analytical/demographics/signup-info/monthlySignups/`,
      method: 'GET'
    }).then(res => {
      if (res.status === 200) {
        this.setState({ monthlySignups: res.data.data.results });
      }
    })

    onApiCall({
      url: `/api/dashboard/analytical/demographics/signup-info/weeklySignups/`,
      method: 'GET'
    }).then(res => {
      if (res.status === 200) {
        this.setState({ weeklySignups: res.data.data.results });
      }
    })

    onApiCall({
      url: `/api/dashboard/analytical/demographics/signup-info/weeklyCompanySignups/`,
      method: 'GET'
    }).then(res => {
      if (res.status === 200) {
        this.setState({ weeklyCompanySignups: res.data?.data });
      }
    })

    onApiCall({
      url: `/api/dashboard/analytical/demographics/signup-info/companiesStatus/`,
      method: 'GET'
    }).then(res => {
      if (res.status === 200) {
        this.setState({ companiesStatus: res.data?.data });
      }
    })

    onApiCall({
      url: `/api/dashboard/analytical/demographics/signup-info/userStatusPerUniversity/`,
      method: 'GET'
    }).then(res => {
      if (res.status === 200) {
        this.setState({ userStatusPerUniversity: res.data?.data?.results[0] });
      }
    })
  }


  format(item, color) {

    const data = { "labels": [], "datasets": [{ "data": [], "backgroundColor": [color] }] }

    for (const [key, value] of Object.entries(item)) {

      data.labels.push(key)

      data.datasets[0].data.push(value)

    }

    return data

  }


  render() {
    console.log(this.state.userStatusPerUniversity);
    const { t } = this.props;
    return (
      <MainLayout>

        <div className="d-flex mb-5" style={{ fontSize: '1.1rem', fontWeight: '400' }}>

          <Link to="/analyticaldashboard" style={{ textDecoration: 'none', color: '#ACACAC' }}>{t("Analytical Dashboard")}</Link>

          <div className='mx-2' style={{ color: '#ACACAC' }}> { ">" } </div>

          <Link to="/analyticaldashboard/demographics" style={{ textDecoration: 'none', color: '#ACACAC' }}>{t("Demographics")}</Link>

          <div className='mx-2' style={{ color: '#ACACAC' }}> { ">" } </div>

          <span style={{ textDecoration: 'none', color: '#D04C8D' }}>{t("Sign Up Info")}</span>

        </div>

        <div className='row'>

          <div className='col-12 col-lg-6 mb-4'>

            <div className='p-4 text-center bg-white rounded shadow' style={{ minHeight: '100%' }}>

              <h3 className='mb-3' style={{ fontWeight: '300' }}>{t("NEW USER SIGN-UP (30 DAYS)")}</h3>

              <DoubleBarChart data={this.format(this.state.monthlySignups, '#4bc2c1')} />

            </div>

          </div>

          <div className='col-12 col-lg-6 mb-4'>

            <div className='p-4 text-center bg-white rounded shadow' style={{ minHeight: '100%' }}>

              <h3 className='mb-3' style={{ fontWeight: '300' }}>{t("NEW USER SIGN-UP (7 DAYS)")}</h3>

              <DoubleBarChart data={this.format(this.state.weeklySignups, '#4571b0')} />

            </div>

          </div>

          <div className='col-12 col-lg-6 mb-4'>

            <div className='p-4 text-center bg-white rounded shadow' style={{ minHeight: '100%' }}>

              <h3 className='mb-3' style={{ fontWeight: '300' }}>{t("WEEKLY NEW COMPANIES")}</h3>

              <table className="table text-start table-striped table-bordered">

                <thead>

                  <tr>

                    <th scope="col">{t("Organisation Name")}</th>

                    <th scope="col">{t("Registration Date")}</th>

                  </tr>

                </thead>

                <tbody>

                  {Object.keys(this.state.weeklyCompanySignups).map((key, index) =>

                    <tr key={index}>

                      <td>{this.state.weeklyCompanySignups[key].name}</td>

                      <td>{this.state.weeklyCompanySignups[key].created_at}</td>

                    </tr>

                  )}

                </tbody>

              </table>

            </div>

          </div>

          <div className='col-12 col-lg-6 mb-4'>

            <div className='p-4 text-center bg-white rounded shadow' style={{ minHeight: '100%' }}>

              <h3 className='mb-3' style={{ fontWeight: '300' }}>{t("TOTAL TALENTS BY INSTITUTION AND STATUS")}</h3>
              <div style={{ minWWidth: '300px', overflowX: 'scroll' }}>
                <table className="table text-start table-striped table-bordered">

                  <thead>

                    <tr>

                      <th scope="col">{t("University")}</th>

                      <th scope="col">{t("Employed")}</th>

                      <th scope="col">{t("Internship")}</th>

                      <th scope="col">{t("Studying")}</th>

                      <th scope="col">{t("Unemployed")}</th>

                      <th scope="col">{t("(Blank)")}</th>

                    </tr>

                  </thead>

                  <tbody>

                    {Object.keys(this.state.userStatusPerUniversity).map((key, index) =>

                      <tr key={index}>

                        <td>{key}</td>

                        <td>{this.state.userStatusPerUniversity[key].employed}</td>

                        <td>{this.state.userStatusPerUniversity[key].internship}</td>

                        <td>{this.state.userStatusPerUniversity[key].studying}</td>

                        <td>{this.state.userStatusPerUniversity[key].unemployed}</td>

                        <td>{this.state.userStatusPerUniversity[key][""]}</td>

                      </tr>

                    )}

                  </tbody>

                </table>
              </div>
            </div>

          </div>

          <div className='col-12 col-lg-6 mb-4'>

            <div className='p-4 text-center bg-white rounded shadow' style={{ minHeight: '100%' }}>

              <h3 className='mb-3' style={{ fontWeight: '300' }}>{t("TOTAL COMPANIES BY STATUS")}</h3>

              <HorizontalBarChart data={this.format(this.state.companiesStatus, '#fbd185')} />

            </div>

          </div>

        </div>
      </MainLayout >
    );

  }

}

export default withTranslation()(SignupInfo);
