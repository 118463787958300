import { useState, useEffect } from 'react';

import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInput from 'react-phone-number-input';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import 'react-phone-number-input/style.css';
import { ReactComponent as CalenderIcon } from '../../assets/icons/svg/calendarIcon.svg';
import { Colors } from '../../utilities/colors';
import Mainlayout from '../../layouts/mainLayout/mainlayout';
import { newInternStaticObj, schema } from './staticContent';
import Logo from '../../assets/internLogo.png';
import Input from '../../components/input/input';
import TextArea from '../../components/description/description';
import RadioInput from '../../components/radioInput/radioInput';
import CheckboxInput from '../../components/checkboxInput/checkboxInput';
import SkillCard from '../../components/skillCard/skillCard';
import Button from '../../components/button/button';
import ErrorField from '../../components/errorField/errorField';
import Loader from '../../components/loader/loader';
import Dropdown from '../../components/dropdown/dropdown';
import { uploadObject, onBoardGetData } from '../../services/apiCalls';
import LocationTable from '../../components/locationTable/locationTable';
import { formatDate } from '../../utilities/commonFunctions';
import { updateValues } from './reducer';
import Tooltip from '../../components/reactTooltip/tooltip';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

const materialTheme = createTheme({
  palette: {
    primary: {
      main: '#D44B9C',
    },
  },
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: '#D44B9C',
      },
    },
  },
});

const NewInternPageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 2rem;
  margin: 2rem 0;
`;
const SectionWrapper = styled.section``;
const HeaderText = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  margin: 0;
  color: ${Colors.dark};
`;
const Description = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin: 5px 0;
  color: #858585;
`;
const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const FileWrapper = styled(FlexWrapper)`
  margin: 10px 0;
  flex-wrap: wrap;
`;
const GridWrapper = styled.div`
  display: grid;
  margin-bottom: 30px;
`;
const LogoWrapper = styled(FlexWrapper)`
  margin: 20px 0;
  > img {
    margin-right: 20px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
const CompanyHeader = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  margin: 0;
`;
const FormWrapper = styled.div``;
const FormGroup = styled.div`
  width: 100%;
`;
const SingleField = styled.div`
  margin-bottom: 30px;
`;
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? 'hidden' : 'default')};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;
const InternTypeWrapper = styled(FlexWrapper)``;

const UploadText = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  color: ${Colors.labelText};
`;
const UploadNotes = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  margin: 0;
  color: #a5a0a0;
`;
const UploadFileText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-decoration-line: underline;
  color: ${Colors.primaryText};
`;
const UploadFileWrapper = styled.label`
  position: relative;
  cursor: pointer;
  > input {
    position: absolute;
    opacity: 0;
    width: 100%;
  }
`;
const DividerLine = styled.hr`
  border: 0.5px solid #acacac;
  margin: 30px 0;
`;
const FormHeaderText = styled.h3`
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 25px;
  color: ${Colors.dark};
`;
const AcademicGridWrapper = styled(GridWrapper)`
  grid-template-columns: ${(props) =>
    props.template ? props.template : '3fr 1fr'};
  grid-column-gap: 20px;
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
  label {
    position: relative;
    //width: -webkit-fill-available;
  }
`;

const AddAnotherLocationText = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #202020;
  margin: 0;
  cursor: pointer;
`;

const CalenderIconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  cursor: pointer;
`;



export default function NewIntern() {
  const internFields = useSelector((state) => state.intern.value);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, },
  } = useForm({
    defaultValues: { ...internFields },
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });
 
  const [loading, setLoading] = useState(false);
  const [isUpdate,] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [userArr,] = useState('');
  const [options1, setOptions1] = useState({});
  const [newSkill, setSkill] = useState('');
  

  const formVal = watch();
  const org = useSelector((state) => state.org.orgData);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  const code = org?.additional_info?.org_code;

  useEffect(() => {
    if (id && code) {
      getInternData();
    }
  }, [id, org, code]);

  const getInternData = async () => {
    setPageLoading(true);
    
    setPageLoading(false);
  };

  
  const dispatch = useDispatch();
  const paidOptions = [
    { value: 'paid', label: 'Paid' },
    { value: 'unpaid', label: 'UnPaid' },
  ];

  useEffect(() => {
    getDropDownData();
    getcountryList();
  }, []);

  const dropdownChange = (name, val, index) => {
    if (name === 'country') {
      dispatch(
        updateValues({
          ...formVal,
          ...internFields,
          [name]: val.value,
          state: '',
          city: '',
        })
      );
      setValue('state', '');
      setValue('city', '');
    }
    if (name === 'state') {
      dispatch(
        updateValues({
          ...formVal,
          ...internFields,
          [name]: val.value,
          city: '',
        })
      );
      setValue('city', '');
    }
    setValue(name, val.value);
  };
  const getcountryList = async () => {
    
  };

  
  const getDropDownData = async () => {
    
  };
 

  const onSubmit = async () => {
    setLoading(true);
    if (isUpdate) {
      onUpdateIntern();
    } else {
      onNewIntern();
    }
  };

  const onUpdateIntern = async () => {
    
  };

  const onNewIntern = async () => {
    
  };

  const radioChange = (value, name) => {
    setValue(name, value);
  };

  const onMblNumberChange = (val) => {
    if (val) {
      setValue('contact_mob', val);
    }
  };

  const checkboxChange = (name, type) => {
    let arr = [...formVal[type]];
    let keyWord = type === 'grade_levels' ? 'grade' : 'doc_type';
    const isChecked = arr.some((grd) => grd[keyWord] === name);
    if (isChecked) {
      arr = [...arr].filter((grd) => grd[keyWord] !== name);
      setValue(type, arr);
    } else {
      const obj = { [keyWord]: name };
      arr.push(obj);
      setValue(type, arr);
    }
   
  };

  

  const onAddAnotherLocation = () => {
    let arr = [...formVal.locations];
    const isValid = formVal.country && formVal.state && formVal.city;
    let obj = {
      country: formVal.country,
      state: formVal.state,
      city: formVal.city,
    };
    const isAlreadyAdded = arr.some(
      (loc) => JSON.stringify(loc) === JSON.stringify(obj)
    );
    if (isValid && !isAlreadyAdded) {
      arr.push(obj);
      setValue('locations', arr);
      setValue('state', '');
      setValue('country', '');
      setValue('city', '');
    }
  };

  const onDeleteLocation = (index) => {
    let arr = [...formVal.locations];
    let newArr = arr.filter((loc, ind) => ind !== index);
    setValue('locations', newArr);
  };

  const contactPersonChange = (val) => {
    setValue('contact_person', val.label);
    setValue('contact_name', val.label);
    setValue('contact_email', val.email);
    setValue('contact_mob', val.mob_number);
  };

  
  const scopeAddChange = (val) => {
    const newVal = val.map((x) => {
      return { ...x, scope: x.value };
    });
    setValue('scopes', newVal);
  };

  const getOptions = async (name, type) => {
    const { data, } = await onBoardGetData(name);
    if (data && data.status === 1) {
      let newData = data.data.map((x) => {
        return {
          label: x,
          value: x,
        };
      });
      let obj = { ...options1 };
      obj[type] = newData;
      setOptions1(obj);
    } else {
      return null;
    }
  };

  const uploadFile = async (event) => {
    const formdata = new FormData();
    formdata.append(
      'obj_content',
      event.target.files[0],
      event.target.files[0].name
    );
    const { data, status } = await uploadObject(formdata);
    if (status === 200) onUploaded(data);
  };

  const onUploaded = (data) => {
    const { status, } = data;
    if (status === 1) {
      let obj = { file_path: data.data.obj_content };
      const arr = [...formVal.file_paths];
      arr.push(obj);
      setValue('file_paths', arr);
    }
  };
  

  const onChangeSkill = (e) => {
    setSkill(e.target.value);
  };

  const addSkill = () => {
    const arr = [...formVal.skills];
    const isNewSkill =
      arr.length > 0 ? arr.some((skill) => skill.skill !== newSkill) : true;
    if (isNewSkill) {
      arr.push({ skill: newSkill });
      setValue('skills', arr);
      setSkill('');
    }
  };

  const onDeleteSkill = (index) => {
    const arr = [...formVal.skills];
    const newArr = arr.filter((skill, ind) => ind !== index);
    setValue('skills', newArr);
  };

  const onDeleteFiles = (index) => {
    const arr = [...formVal.file_paths];
    const newArr = arr.filter((skill, ind) => ind !== index);
    setValue('file_paths', newArr);
  };

  const dateChange = (name, val) => {
    setValue(name, formatDate(val));
  };

  return (
    <Mainlayout>
      {pageLoading ? (
        <Loader />
      ) : (
        <NewInternPageWrapper>
          <SectionWrapper>
            <HeaderText>{newInternStaticObj.heading}</HeaderText>
            <Description>{newInternStaticObj.description}</Description>
            <LogoWrapper>
              <img alt="company logo" src={Logo} />
              <CompanyHeader>QL Company</CompanyHeader>
            </LogoWrapper>
            <FormWrapper>
             
              <FormGroup>
                <SingleField>
                  <FormLabel>{newInternStaticObj.formTitle}</FormLabel>
                  <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="HR Executive, Project Manager, etc."
                        dataId="internTitle"
                      />
                    )}
                  />
                  {errors.title && <ErrorField err={errors.title.message} />}
                </SingleField>
                <SingleField>
                  <FormLabel>{newInternStaticObj.formDescription}</FormLabel>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <TextArea
                        {...field}
                        rows="6"
                        placeholder="Enter internship description as elaborate as possible"
                      />
                    )}
                  />
                  {errors.description && (
                    <ErrorField err={errors.description.message} />
                  )}
                </SingleField>
                <SingleField>
                  <FormLabel>{newInternStaticObj.formInternType}</FormLabel>
                  <InternTypeWrapper>
                    <Controller
                      name="intern_type"
                      control={control}
                      render={({ field }) =>
                        newInternStaticObj.internTypes.map((intrn, index) => (
                          <RadioInput
                            key={intrn + index}
                            {...field}
                            checked={intrn === formVal.intern_type}
                            onChange={() => radioChange(intrn, 'intern_type')}
                            label={intrn}
                          />
                        ))
                      }
                    />
                  </InternTypeWrapper>
                  {errors.intern_type && (
                    <ErrorField err={errors.intern_type.message} />
                  )}
                </SingleField>
                <Row className="mb-4">
                  <Col sm={12} md={4}>
                    <AcademicGridWrapper>
                      <FormGroup>
                        <FormLabel>
                          {newInternStaticObj.internStartDate}
                        </FormLabel>
                        <Controller
                          name="exp_start_date"
                          control={control}
                          render={({ field }) => (
                            <label style={{ width: 'max-content' }}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <ThemeProvider theme={materialTheme}>
                                  <DatePicker
                                    autoOk
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    placeholder="dd/mm/yyyy"
                                    views={['year', 'month', 'date']}
                                    value={
                                      formVal.exp_start_date
                                        ? new Date(formVal.exp_start_date)
                                        : null
                                    }
                                    onChange={(val) =>
                                      dateChange('exp_start_date', val)
                                    }
                                    fullWidth
                                  />
                                </ThemeProvider>
                              </MuiPickersUtilsProvider>
                              <CalenderIconWrapper>
                                <CalenderIcon />
                              </CalenderIconWrapper>
                            </label>
                          )}
                        />
                        {errors.exp_start_date && (
                          <ErrorField err={errors.exp_start_date.message} />
                        )}
                      </FormGroup>
                    </AcademicGridWrapper>
                  </Col>
                  <Col sm={12} md={4}>
                    <AcademicGridWrapper>
                      <FormGroup>
                        <FormLabel>
                          {newInternStaticObj.internEndDate}
                        </FormLabel>
                        <Controller
                          name="exp_end_date"
                          control={control}
                          render={({ field }) => (
                            <label style={{ width: 'max-content' }}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <ThemeProvider theme={materialTheme}>
                                  <DatePicker
                                    autoOk
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    placeholder="dd/mm/yyyy"
                                    views={['year', 'month', 'date']}
                                    value={
                                      formVal.exp_end_date
                                        ? new Date(formVal.exp_end_date)
                                        : null
                                    }
                                    onChange={(val) =>
                                      dateChange('exp_end_date', val)
                                    }
                                    fullWidth
                                  />
                                </ThemeProvider>
                              </MuiPickersUtilsProvider>
                              <CalenderIconWrapper>
                                <CalenderIcon />
                              </CalenderIconWrapper>
                            </label>
                          )}
                        />
                        {errors.exp_end_date && (
                          <ErrorField err={errors.exp_end_date.message} />
                        )}
                      </FormGroup>
                    </AcademicGridWrapper>
                  </Col>
                  <Col sm={12} md={4}>
                    <FormGroup>
                      <FormLabel>
                        {newInternStaticObj.internPositions}
                      </FormLabel>
                      <Controller
                        name="no_positions"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            placeholder="Enter no. of positions"
                            type="number"
                            min={1}
                          />
                        )}
                      />
                      {errors.no_positions && (
                        <ErrorField err={errors.no_positions.message} />
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <SingleField>
                  <UploadText>{newInternStaticObj.uploadTitle}</UploadText>
                  <UploadNotes>{newInternStaticObj.uploadNotes}</UploadNotes>
                </SingleField>
                <UploadFileWrapper data-tip data-for="pdf">
                  <input
                    onChange={uploadFile}
                    accept="application/pdf"
                    type="file"
                  />
                  <UploadFileText>
                    {newInternStaticObj.uploadFileText}
                  </UploadFileText>
                </UploadFileWrapper>
                <FileWrapper>
                  {formVal?.file_paths.length > 0
                    ? formVal.file_paths.map((x, index) => (
                        <SkillCard
                          key={index + x.file_path}
                          delete={() => onDeleteFiles(index)}
                          name={x.file_path}
                        />
                      ))
                    : ''}
                </FileWrapper>

                {errors.file_paths && (
                  <ErrorField err={errors.file_paths.message} />
                )}
              </FormGroup>
              <DividerLine />
              {/* intern detail section */}
              {/* academic requirements starts */}
              <FormGroup>
                <FormHeaderText>
                  {newInternStaticObj.academicTitle}
                </FormHeaderText>
                <AcademicGridWrapper>
                  <FormGroup>
                    <FormLabel>{newInternStaticObj.scopeOfStudy}</FormLabel>
                    <Controller
                      name="scopes"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          bgGrey
                          options={options1 && options1.SOS}
                          placeholder="HR Executive, Project Manager, etc."
                          val={formVal.scopes.length > 0 ? formVal.scopes : ''}
                          change={scopeAddChange}
                          onFocus={() => {
                            getOptions('hint/loc/SOS', 'SOS');
                          }}
                          isMulti={true}
                          {...field}
                        />
                      )}
                    />
                    {errors.scopes && (
                      <ErrorField err={errors.scopes.message} />
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>{newInternStaticObj.gpaText}</FormLabel>
                    <Controller
                      name="req_gpa"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="Enter CGPA"
                          type="number"
                          min={1}
                          max={4}
                        />
                      )}
                    />
                    {errors.req_gpa && (
                      <ErrorField err={errors.req_gpa.message} />
                    )}
                  </FormGroup>
                </AcademicGridWrapper>
                <AcademicGridWrapper template="1fr 1fr">
                  <FormGroup>
                    <FormLabel>{newInternStaticObj.degreeText}</FormLabel>
                    <Controller
                      name="min_degree"
                      control={control}
                      render={({ field }) =>
                        newInternStaticObj.degrees.map((degree, index) => (
                          <div key={degree + index}>
                            <RadioInput
                              {...field}
                              checked={degree === formVal.min_degree}
                              onChange={() => radioChange(degree, 'min_degree')}
                              margin="15px"
                              label={degree}
                            />
                          </div>
                        ))
                      }
                    />
                    {errors.min_degree && (
                      <ErrorField err={errors.min_degree.message} />
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>
                      Minimum Year of Study*{' '}
                      <span>you can select multiple</span>
                    </FormLabel>
                    <Controller
                      name="grade_levels"
                      control={control}
                      render={({ field }) =>
                        newInternStaticObj.grades.map((grade, index) => (
                          <div key={grade + index}>
                            <RadioInput
                              {...field}
                              checked={grade === formVal.grade}
                              onChange={() => radioChange(grade, 'grade')}
                              margin="15px"
                              label={grade}
                            />
                            
                          </div>
                        ))
                      }
                    />
                    {errors.grade_levels && (
                      <ErrorField err={errors.grade_levels.message} />
                    )}
                  </FormGroup>
                </AcademicGridWrapper>
                <FormGroup>
                  <FormLabel>{newInternStaticObj.addSkillText}</FormLabel>
                  <AcademicGridWrapper>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        
                        <Input
                          {...field}
                          
                          onChange={onChangeSkill}
                          value={newSkill}
                          placeholder="Search and add skills"
                          dataId="skill"
                        />
                      )}
                    />
                    <Button
                      className="mb-1 mt-0 py-auto"
                      name="Add"
                      onClick={addSkill}
                    />
                  </AcademicGridWrapper>
                </FormGroup>
                <FlexWrapper>
                  {formVal.skills.map((skill, index) => (
                    <SkillCard
                      key={index + skill.skill}
                      delete={() => onDeleteSkill(index)}
                      name={skill.skill}
                    />
                  ))}
                </FlexWrapper>
                {errors.skills && <ErrorField err={errors.skills.message} />}
              </FormGroup>
              <DividerLine />
             
              <FormGroup>
                <FormHeaderText>
                  {newInternStaticObj.applicantDocumentTitle}
                </FormHeaderText>
                <AcademicGridWrapper template="1fr 1fr">
                  <FormGroup>
                    <FormLabel>
                      Required Document(s) <span> you can select multiple</span>
                    </FormLabel>
                    <Controller
                      name="documents"
                      control={control}
                      render={({ field }) =>
                        newInternStaticObj.requiredDocs.map((doc, index) => (
                          <div key={doc + index}>
                            <CheckboxInput
                              margin="15px"
                              onChange={() => checkboxChange(doc, 'documents')}
                              label={doc}
                              name={doc}
                              checked={formVal.documents.some(
                                (grd) => grd.doc_type === doc
                              )}
                            />
                          </div>
                        ))
                      }
                    />
                    {errors.documents && (
                      <ErrorField err={errors.documents.message} />
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Optional Document(s) </FormLabel>
                    <Controller
                      name="optional_documents"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="Mention the optional documents, if any."
                        />
                      )}
                    />
                    {errors.optional_documents && (
                      <ErrorField err={errors.optional_documents.message} />
                    )}
                  </FormGroup>
                </AcademicGridWrapper>
              </FormGroup>
              
              <FormGroup>
                <FormHeaderText>{newInternStaticObj.hoursTitle}</FormHeaderText>
                <AcademicGridWrapper template="1fr 1fr">
                  <FormGroup>
                    <FormLabel>{newInternStaticObj.workingHours}</FormLabel>
                    <Controller
                      name="working_hours"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="1 to 24"
                          type="number"
                          dataId="hours"
                        />
                      )}
                    />
                    {errors.working_hours && (
                      <ErrorField err={errors.working_hours.message} />
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>{newInternStaticObj.padiText}</FormLabel>
                    <Controller
                      name="payment_status"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          bgGrey
                          options={paidOptions}
                          placeholder="Paid/UnPaid"
                          val={formVal.payment_status}
                          change={(val) =>
                            dropdownChange('payment_status', val)
                          }
                          {...field}
                        />
                      )}
                    />
                    {errors.payment_status && (
                      <ErrorField err={errors.payment_status.message} />
                    )}
                  </FormGroup>
                </AcademicGridWrapper>
                <AcademicGridWrapper template="1fr 1fr">
                  <FormGroup>
                    <FormLabel>{newInternStaticObj.compensation}</FormLabel>
                    <Controller
                      name="min_compensation"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="Minimum Compensation. e.g., RM25"
                          dataId="minComp"
                        />
                      )}
                    />
                    {errors.min_compensation && (
                      <ErrorField err={errors.min_compensation.message} />
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel visible={true}>Label</FormLabel>
                    <Controller
                      name="max_compensation"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="Maximum Compensation. e.g., RM50"
                          dataId="maxComp"
                        />
                      )}
                    />
                    {errors.max_compensation && (
                      <ErrorField err={errors.max_compensation.message} />
                    )}
                  </FormGroup>
                </AcademicGridWrapper>
              </FormGroup>
              <DividerLine />
              
              <FormGroup>
                <FormHeaderText>{newInternStaticObj.location}</FormHeaderText>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <AcademicGridWrapper template="1fr 1fr">
                      <FormGroup>
                        <FormLabel>{newInternStaticObj.country}</FormLabel>
                       
                        <Dropdown
                          bgGrey
                          
                          options={options1.country}
                          placeholder="Enter Location"
                          val={formVal.country}
                          dataId="country"
                          onFocus={() => {
                            getOptions('all/loc/country', 'country');
                          }}
                          change={(val) => dropdownChange('country', val)}
                          {...field}
                        />
                        {errors.country && (
                          <ErrorField err={errors.country.message} />
                        )}
                      </FormGroup>
                    </AcademicGridWrapper>
                  )}
                />
                <AcademicGridWrapper template="1fr 1fr">
                  <FormGroup>
                    <FormLabel>{newInternStaticObj.state}</FormLabel>
                    <Controller
                      name="state"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          bgGrey
                          dataId="state"
                          options={options1.state}
                          placeholder="Enter Location"
                          val={formVal.state ? formVal.state : ''}
                          onFocus={() => {
                            getOptions(
                              `hint/${formVal.country}/state`,
                              'state'
                            );
                          }}
                          change={(val) => dropdownChange('state', val)}
                          {...field}
                        />
                      )}
                    />
                    {errors.state && <ErrorField err={errors.state.message} />}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>{newInternStaticObj.city}</FormLabel>
                    <Controller
                      name="city"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          bgGrey
                          options={options1.city}
                          placeholder="Enter Location"
                          dataId="city"
                          val={formVal.city}
                          onFocus={() => {
                            getOptions(
                              `${formVal.country}/${formVal.state}/city`,
                              'city'
                            );
                          }}
                          change={(val) => dropdownChange('city', val)}
                          {...field}
                        />
                      )}
                    />
                    {errors.city && <ErrorField err={errors.city.message} />}
                  </FormGroup>
                </AcademicGridWrapper>
                <AddAnotherLocationText onClick={onAddAnotherLocation}>
                  {newInternStaticObj.anotherLocation}
                </AddAnotherLocationText>
                {errors.locations && (
                  <ErrorField err={errors.locations.message} />
                )}
              </FormGroup>
              <FormGroup>
                <Controller
                  name="locations"
                  control={control}
                  render={({ field }) => (
                    <LocationTable
                      delete={onDeleteLocation}
                      data={formVal.locations}
                    />
                  )}
                />
              </FormGroup>
              <DividerLine />
             
              <FormGroup>
                <FormHeaderText>
                  {newInternStaticObj.postingDate}
                </FormHeaderText>
                <AcademicGridWrapper template="1fr 1fr">
                  <FormGroup>
                    <FormLabel>{newInternStaticObj.postStartDate}</FormLabel>
                    <Controller
                      name="position_start_date"
                      control={control}
                      render={({ field }) => (
                        <label style={{ width: 'inherit' }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={materialTheme}>
                              <DatePicker
                                autoOk
                                variant="inline"
                                format="dd/MM/yyyy"
                                placeholder="dd/mm/yyyy"
                                views={['year', 'month', 'date']}
                                value={
                                  formVal.position_start_date
                                    ? new Date(formVal.position_start_date)
                                    : null
                                }
                                onChange={(val) =>
                                  dateChange('position_start_date', val)
                                }
                                fullWidth
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                          <CalenderIconWrapper>
                            <CalenderIcon />
                          </CalenderIconWrapper>
                        </label>
                      )}
                    />
                    {errors.position_start_date && (
                      <ErrorField err={errors.position_start_date.message} />
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>{newInternStaticObj.postEndDate}</FormLabel>
                    <Controller
                      name="position_end_date"
                      control={control}
                      render={({ field }) => (
                        <label style={{ width: 'inherit' }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={materialTheme}>
                              <DatePicker
                                autoOk
                                variant="inline"
                                format="dd/MM/yyyy"
                                placeholder="dd/mm/yyyy"
                                views={['year', 'month', 'date']}
                                value={
                                  formVal.position_end_date
                                    ? new Date(formVal.position_end_date)
                                    : null
                                }
                                onChange={(val) =>
                                  dateChange('position_end_date', val)
                                }
                                fullWidth
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                          <CalenderIconWrapper>
                            <CalenderIcon />
                          </CalenderIconWrapper>
                        </label>
                      )}
                    />
                    {errors.position_end_date && (
                      <ErrorField err={errors.position_end_date.message} />
                    )}
                  </FormGroup>
                </AcademicGridWrapper>
              </FormGroup>
              <DividerLine />
             
              <FormGroup>
                <FormHeaderText>
                  {newInternStaticObj.contactTitle}
                </FormHeaderText>
                <AcademicGridWrapper template="1fr 1fr">
                  <FormGroup>
                    <FormLabel>{newInternStaticObj.contactPerson}</FormLabel>
                    <Controller
                      name="contact_person"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          bgGrey
                          options={userArr}
                          placeholder="Select"
                          val={formVal.contact_person}
                          
                          change={contactPersonChange}
                          {...field}
                        />
                      )}
                    />
                    {errors.contact_person && (
                      <ErrorField err={errors.contact_person.message} />
                    )}
                  </FormGroup>
                </AcademicGridWrapper>
                <SingleField>
                  <FormLabel>{newInternStaticObj.fullName}</FormLabel>
                  <Controller
                    name="contact_name"
                    control={control}
                    render={({ field }) => (
                      <Input {...field} disabled placeholder="Full Name" maxLength="100" />
                    )}
                  />
                  {errors.contact_name && (
                    <ErrorField err={errors.contact_name.message} />
                  )}
                </SingleField>
                <SingleField>
                  <FormLabel>E-mail Address</FormLabel>
                  <Controller
                    name="contact_email"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        disabled
                        placeholder="contactperson@company.com"
                      />
                    )}
                  />
                  {errors.contact_email && (
                    <ErrorField err={errors.contact_email.message} />
                  )}
                </SingleField>
                <AcademicGridWrapper template="400px">
                  <FormGroup>
                    <FormLabel>{newInternStaticObj.mobileNumber}</FormLabel>
                    <Controller
                      name="contact_mob"
                      control={control}
                      render={({ field }) => (
                        <PhoneInput
                          value={formVal.contact_mob}
                          onChange={onMblNumberChange}
                          defaultCountry="MY"
                          international
                          countryCallingCodeEditable={false}
                        />
                      )}
                    />
                    {errors.contact_mob && (
                      <ErrorField err={errors.contact_mob.message} />
                    )}
                  </FormGroup>
                </AcademicGridWrapper>
                <SingleField>
                  <Controller
                    name="visibility_status"
                    control={control}
                    render={({ field }) => (
                      <RadioInput
                        {...field}
                        checked={1 === formVal.visibility_status}
                        onChange={() => radioChange(1, 'visibility_status')}
                        label={newInternStaticObj.displayContact}
                      />
                    )}
                  />
                  {errors.visibility_status && (
                    <ErrorField err={errors.visibility_status.message} />
                  )}
                </SingleField>
              
              </FormGroup>
              <DividerLine />
             
              <FormGroup>
                <FormHeaderText>
                  Application Information Collection
                </FormHeaderText>
                <AcademicGridWrapper template="1fr 1fr">
                  <FormGroup>
                    <FormLabel>Collection Type*</FormLabel>
                    <Controller
                      name="collection_type"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="Receive applications by email"
                        />
                      )}
                    />
                    {errors.collection_type && (
                      <ErrorField err={errors.collection_type.message} />
                    )}
                  </FormGroup>
                </AcademicGridWrapper>
                <SingleField>
                  <FormLabel>
                    Recipient(s) E-mail Address*{' '}
                    <span>multiple with commas</span>
                  </FormLabel>
                  <Controller
                    name="re_email_address"
                    control={control}
                    render={({ field }) => (
                      <Input {...field} placeholder="Full Name" maxLength="100" />
                    )}
                  />
                  {errors.re_email_address && (
                    <ErrorField err={errors.re_email_address.message} />
                  )}
                </SingleField>
                <SingleField>
                  <FormLabel>Collection Type</FormLabel>
                  <Controller
                    name="collection_radio_type"
                    control={control}
                    render={({ field }) =>
                      newInternStaticObj.collectionTypes.map((type, index) => (
                        <div key={type + index}>
                          <RadioInput
                            dataId="contact"
                            {...field}
                            checked={type === formVal.collection_radio_type}
                            onChange={() =>
                              radioChange(type, 'collection_radio_type')
                            }
                            margin="15px"
                            label={type}
                          />
                        </div>
                      ))
                    }
                  />
                  {errors.collection_radio_type && (
                    <ErrorField err={errors.collection_radio_type.message} />
                  )}
                </SingleField>
                <AcademicGridWrapper template="1fr 1fr">
                  <FormGroup>
                    <FormLabel>Date</FormLabel>
                    <Controller
                      name="collection_date"
                      control={control}
                      render={({ field }) => (
                        <label style={{ width: 'inherit' }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={materialTheme}>
                              <DatePicker
                                autoOk
                                variant="inline"
                                format="dd/MM/yyyy"
                                placeholder="dd/mm/yyyy"
                                views={['year', 'month', 'date']}
                                value={
                                  formVal.collection_date
                                    ? new Date(formVal.collection_date)
                                    : null
                                }
                                onChange={(val) =>
                                  dateChange('collection_date', val)
                                }
                                fullWidth
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                          <CalenderIconWrapper>
                            <CalenderIcon />
                          </CalenderIconWrapper>
                        </label>
                      )}
                    />
                    {errors.collection_date && (
                      <ErrorField err={errors.collection_date.message} />
                    )}
                  </FormGroup>
                </AcademicGridWrapper>
               
              </FormGroup>
             
              {loading ? (
                <Loader />
              ) : (
                <Button onClick={handleSubmit(onSubmit)} name="Submit" />
              )}
            </FormWrapper>
            <Tooltip id="internTitle" content="Enter internship title" />
            <Tooltip id="uploadDocuments" content="Upload Documents" />
            <Tooltip
              id="cgpa"
              content="Enter required CGPA (Range 0.0 to 4.0)"
            />
            <Tooltip
              id="skill"
              content="Type here to search and add skill(s)"
            />
            <Tooltip
              id="document"
              content="Mention the optional documents, if any"
            />
            <Tooltip id="hours" content="Enter hours (Range 1 to 24)" />
            <Tooltip id="minComp" content="Min. compensation" />
            <Tooltip id="maxComp" content="Max. compensation" />
            <Tooltip id="country" content="Select Country" />
            <Tooltip id="state" content="Select State" />
            <Tooltip id="city" content="Select city" />
            <Tooltip
              id="pdf"
              content="Upload PDF document to provide applicants with more information"
            />
            <Tooltip
              id="contact"
              content="DISPLAY CONTACT INFORMATION TO STUDENTS"
            />
          </SectionWrapper>
        </NewInternPageWrapper>
      )}
    </Mainlayout>
  );
}
